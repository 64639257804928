@import "./SearchPanel/styles";
@import "./DrawParcelInformationPanel/styles";

.component--parcel-finder-panel {
  background: white;
  width: 100%;
  pointer-events: all;
  display: flex;
  overflow: hidden;
}
