td.component--cell-text {
  text-align: left;
  max-width: 200px;

  &.bold {
    font-weight: bold;
  }

  &.blurry-text,
  .blurry-text {
    color: transparent;
    text-shadow: 0 0 8px rgba(0,0,0,0.5);
    user-select: none;
    pointer-events: none;
  }

  &.justify-left {
    text-align: left;
  }

  .value,
  .array-item {
    white-space: pre-line;
  }
}
