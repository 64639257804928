@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400,700|Roboto:300,400,500,700,900");

html,
body,
#root,
#root > * {
  height: 1px;
  min-height: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  background-color: rgba(231, 239, 242, 0.6);
  color: #000;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;

  // Forcing antialiasing so the font looks thinner on Mac and matches the look on Windows machines.
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 300;
  margin: 0;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

a {
  cursor: pointer;
  outline: none;
  text-decoration: none;
}

::-webkit-input-placeholder {
  color: #585858;
}

::-moz-placeholder {
  color: #585858;
}

:-ms-input-placeholder {
  color: #585858;
}

:-moz-placeholder {
  color: #585858;
}

video[poster] {
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
}

input[type="text"],
input[type="email"],
input[type="password"] {
  background-color: transparent;
  padding: 10px 5px;
  border: 1px solid transparent;
  border-radius: 3px;
  font-size: 15px;
  font-family: "Roboto", sans-serif;
  &:focus {
    outline: none;
  }
}

input[type="submit"] {
  cursor: pointer;
  &:focus {
    outline: none;
  }
}

// These forms sub-elements do not inherit the font by default.
textarea,
input,
button {
  font-family: inherit;
  font-size: inherit;
}

mark {
  background-color: transparent;
  padding: 0;
}

table {
  width: 100%;
  padding: 70px 0px;

  tr {
    height: 27px;
  }

  td {
    width: 50%;
    word-wrap: break-word;

    &.text-left {
      text-align: left;
    }

    &.text-right {
      text-align: right;
    }

    &.bold-17 {
      font-weight: bold;
      font-size: 17px;
    }

    &.bold-15 {
      font-weight: bold;
      font-size: 15px;
    }

    &.border-below {
      border-bottom: 2px solid black;
      border-top-width: 0px;
    }

    &.border-above {
      border-top: 2px solid black;
      border-bottom-width: 0px;
    }

    .abbreviation-expanded-text {
      font-size: 16px;
      font-weight: 400;
    }
  }
}

input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.rotation-control {
  bottom: 40px !important;
  right: 100px !important;
}
