.toggle-button-wrapper {

  .toggle-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .icon {
    width: 55px;
    height: 46px;
    box-shadow: 0px 3px 6px #0000001a;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 28px 26px;

    &.condo {
      background-image: url("assets/images/icon-condo-black.svg");

      &.active {
        background-image: url("assets/images/icon-condo-white.svg");
      }
    }

    &.multifamily {
      background-image: url("assets/images/icon-residential-black.svg");

      &.active {
        background-image: url("assets/images/icon-multifamily-white.svg");
      }
    }

    &.hotel {
      background-image: url("assets/images/icon-hotel-black.svg");

      &.active {
        background-image: url("assets/images/icon-hotel-white.svg");
      }
    }

    &.office {
      background-image: url("assets/images/icon-office-black.svg");

      &.active {
        background-image: url("assets/images/icon-office-white.svg");
      }
    }

    &.industrial {
      background-image: url("assets/images/icon-industrial-black.svg");

      &.active {
        background-image: url("assets/images/icon-industrial-white.svg");
      }
    }

    &.retail {
      background-image: url("assets/images/icon-retail-black.svg");

      &.active {
        background-image: url("assets/images/icon-retail-white.svg");
      }
    }
  }

  .toggle-label {
    margin-top: 5px;
    font: normal 12px/12px Roboto;
    color: #313131;
  }
}
