.component--developments-dropdown {
  width: 439px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 6px #00000066;
  border-radius: 10px;
  padding: 14px 18px;

  .title-wrapper {
    letter-spacing: 0px;
    color: #3A4276;
    display: flex;
    justify-content: space-between;
    padding-bottom: 3px;
    align-items: flex-end;
    border-bottom: 1px solid #3A4276;

    .title {
      display:flex;
      align-items: center;
      justify-content: center;
      font: 700 14px/21px Roboto;
      border-bottom: none;
      padding: 0;
    }

    .saved-message {
      display:flex;
      align-items: center;
      justify-content: center;
      width: 66px;
      height: 19px;
      background-color: #F9CB58;
      color: #3A4276;
      font: 500 12px/14px Roboto;
      border-radius: 5px;
    }
  }

  .projects-container {
    display: flex;
    height: 34px;
    margin-top: 12px;
    background: #3A4276 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000033;
    border-radius: 5px;
    color: #fff;
    font: bold 14px/24px Roboto;
    cursor: pointer;
    width: 70%;

    .component--dropdown {
      padding: 0 11px;

      .project-name-container {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .project-name {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 230px;
        }

        .dropdown-icon {
          width: 20px;
          height: 12px;
          background: url("assets/images/icon-down-arrow-white.svg") no-repeat;
        }
      }

      .dropdown-content {
        width: 100%;
        top: 35px;
        left: 0;

        .button {
          max-width: none;
        }
      }
    }

    .button {
      justify-content: space-between;
      max-width: 210px;

      .component--delete-development {

        .icon {
          min-width: 11.3px;
          height: 12.4px;
          background-image: none;
        }

        li:hover .button .component--delete-development .icon {
          background-image: url("assets/images/icon-trash-can-light.svg");
        }
      }

      &:hover {

        .icon {
          background-image: url("assets/images/icon-trash-can-red.svg");
        }
      }
    }
  }

  .street-view-button {
    width: 30%;
  }

  .project-header-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
