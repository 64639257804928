.component--dashboard-content {

  .component--footer{
    margin-top: auto;
    margin-bottom: 19px;

    .component--branding-watermark {
      position: relative;
      margin-left: auto;
      margin-right: auto;
      bottom: 0;
      left: 0;
      width: 110.5px;
      height: 21px;
    }
  }
}
