// TODO: This file contains miscellaneous styles, many of which may be unused.
// We are working towards removing this file completely. Please help us clean up
// these styles by removing any that you notice are unused, and moving styles
// you are using to a more appropriate home.

input, textarea {
  &::placeholder { color: #888; }
}

// NOTE: This rule includes two selectors to provide extra specificity. With
// only the target class selected, a discrepancy was observed between how this
// rule is applied in development vs. production builds. The extra specificity
// is intended to ensure that this rule is properly applied in all environments.
// See https://github.com/DeepBlocks/developer/pull/348
.mapboxgl-control-container .mapboxgl-ctrl-bottom-left {
  left: 10px;
}

iframe {
  width: 100%;
  height: 100vh;
}
