@import "../sharedStyles/styles";

.component--unauthenticated-copy-project-popup {
  @include popupStyle();

  .top {
    width: 100%;
    background-color: #3A4276;
    padding: 15px 12px 10px;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 6px 6px 0 0;

    .message {
      width: 310px;
    }
  }

  .bottom {
    width: 100%;
    padding: 22px 36px;
    display: flex;
    justify-content: space-between;

    button {
      width: 128px;
      height: 25px;
      background: #F26C6C 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000066;
      color: #fff;
      border-radius: 5px;
      font: Bold 13px/21px Roboto;

      &.cancel {
        background-color: #FFFFFF;
        color: #333333;
      }

      &.create-account {
        border: none;
        margin: 0;
        padding: 0;
        min-height: auto;
      }
    }
  }
}
