@import "./ResetPasswordButton/ResetPasswordPopup/styles";
@import "./UpgradePopup/styles";

.component--display-information {

  .bottom {

    span {
      margin-left: 5px;
      font: 700 14px/14px Roboto;
      color: #3A4276;
    }

    .bar {
      height: 6px;
      background-color: #D5D5E0;
      border-radius: 3px;
      margin-top: 8px;

      &.active {
        position: relative;
        top: -14px;
        background-color: #3A4276;
        box-shadow: 0px 3px 8px #3A427666;
      }
    }
  }

  .subscription-status-label {
    color: #333333;
    font-family: "Roboto Condensed";
    font-size: 14px;
  }
}
