.component--toolbar-settings {
  position: relative;
  display: flex;
  align-items: center;

  .dropdown-content {
    line-height: 1;
    position: absolute;
    width: 162px;
    right: 10px;
    top: 40px;

    a {
      font-weight: 400;
    }
  }

  .user-settings-menu {
    width: 40px;
    height: 50px;
    right: 0;
    top: 0;
    outline: none;

    .toggle-dropdown {
      height: 100%;
      width: 100%;
      cursor: pointer;

      .menu-button {
        position: relative;
        margin-right: auto;
        margin-left: auto;
        height: 20px;
        width: 4px;
        top: 15px;
        background-image: url("assets/images/toolbar-settings.svg");
      }
    }

    &:hover .menu-button,
    .menu-button.active {
      background-image: url("assets/images/toolbar-settings-hover.svg");
    }
  }
}
