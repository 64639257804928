.component--user-loading-feedback {
  height: 100%;
  weight: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;

  .text-box {
    font-family: "Roboto Condensed";
    font-weight: 700;
    font-size: 20px;
    color: #333333;
    text-align: center;
  }

  .component--busy-spinner {
    height: 120px;

    .spinner {
      width: 60px;
      height: 60px;
    }
  }
}
