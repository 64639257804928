.component--dropdown {
  width: 100%;
  display: flex;
  align-items: center;
  outline: none;
  position: relative;

  .dropdown-content {
    background-color: #59618D;
    border-radius: 10px;
    box-sizing: border-box;
    opacity: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    pointer-events: none;
    position: absolute;
    transition: all .2s linear;
    transition-property: height, opacity, overflow;
    z-index: 200;
    width: 240px;
    display: flex;
    top: 45px;

    &.empty {
      padding: 0;
    }

    .button {
      display: flex;
      align-items: center;
      font-family: inherit;
      font-size: 13px;
      line-height: 20px;
      height: 27px;
      border-top: 0.5px solid #3A4377;
      color: #EBECF1;

      span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding-right: 10px;
      }

      &:hover {
        text-decoration: none;
      }
    }

    .dropdown-content-inner {
      width: 100%;
      height: fit-content;
      max-height: 410px;
      overflow-y: auto;
    }

    ul {
      overflow-y: auto;
      overflow-x: hidden;
    }

    li {
      padding-left: 15px;
      padding-right: 15px;
      margin: 0;

      &:hover {
        background-color: #3A4377;
        cursor: pointer;

        .button {
          border-color: transparent;
        }
      }

      &:first-child {
        .button {
          border: none;
        }
      }
    }

    li:hover + li {
      .button {
        border-color: transparent;
      }
    }

    ::-webkit-scrollbar-track {
      border: none;
      background-color: #59618D;
    }

    ::-webkit-scrollbar-thumb {
      background: #9db0b8;
    }

    &.active {
      opacity: 1;
      pointer-events: auto;
    }
  }
}
