.component--subscribe-button {
  height:48px;
  width: 240px;
  border: none;
  border-radius: 4px;
  background-color: #FFFFFF;
  box-shadow: 0px 3px 6px #00000040;
  font: 700 15px/48px Roboto;
  color: #333333;
  text-transform: uppercase;
  text-align: center;

  &:hover {
    background-color: #3A4276;
    color: #FFFFFF;
    text-decoration: none;
  }
}

.disabled-button {
  @extend .component--subscribe-button;
  background-color: #33333315;
  color: #33333325;

  &:hover {
    background-color: #33333315;
    color: #33333325;
    cursor: default;
  }
}