@mixin pressedButton {
  box-shadow: 1px 0 1px 1px #f9f9f9, inset 1px 1px 1px #d9d9d9;
  border-radius: 5px;
}

.component--sidebar {
  display: flex;
  flex-direction: column;
  min-width: 195px;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #F8F8F8;
  padding: 21px 0 13px;

  .sidebar-block {
    margin-bottom: 38px;
    padding: 0px 16px;

    h5 {
      text-transform: uppercase;
      font-size: 13px;
      font-weight: 400;
      color: #777777;
      padding-bottom: 3px;
      border-bottom: 1px solid #777777;
    }

    .sidebar-nav {
      margin: 8px 0;

      .side-bar-links {
        margin-bottom: 5px;
        color: #777777;
        font-size: 13px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        transition-property: height;
        height: 28px;

        .icon {
          width: 30px;
          height: 25px;
          margin-right: 7px;
        }

        &.hidden {
          visibility: hidden;
          height: 0px;
          margin: 0px;
        }
      }
    }
  }
}
