.component--keep-project {
  $border-radius: 7px;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 182px;
  background-color: #ffffff;
  border-radius: $border-radius;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
  margin-left: 10px;

  .text {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #3a4276 0% 0% no-repeat padding-box;
    border-radius: $border-radius $border-radius 0px 0px;
    height: 37px;
    text-transform: uppercase;
    font: 900 13px/21px Roboto;
    color: #ffffff;

    .close {
      position: absolute;
      top: 3px;
      right: 3px;
      width: 15px;
      height: 15px;
      background-image: url("assets/images/icon-close-transparent.svg");
      background-size: contain;
      background-repeat: no-repeat;
      cursor: pointer;
      opacity: 1;
    }
  }

  .project-name {
    width: 180px;
    margin-top: 10px;
    font: 900 15px/15px Roboto;
    color: #333333;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
    overflow: hidden;
  }

  .button-container {
    display: flex;
    justify-content: center;

    button {
      width: 94px;
      height: 27px;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000033;
      border-radius: $border-radius;
      margin-top: 18px;
      font: 900 12px/21px Roboto;
      color: #333333;
      letter-spacing: 0.18px;
    }
  }

  .underlined-text {
    margin-top: 6px;
    font: 300 12px/12px Roboto;
    color: #999999;
    text-decoration: underline;
    cursor: pointer;
  }
}
