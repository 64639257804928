@import "./PropertyUses/styles";

.component--assumptions-panel {
  display: flex;
  background-color: #FFFFFF;
  height: 100%;
  transition: height 0.3s linear, visibility 0.2s linear, opacity 0.2s linear;
  overflow: auto;

  .panel-content {
    padding-bottom: 24px;
  }
}
