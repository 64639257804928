.component--welcome-slideshow {
  display: flex;
  flex-direction: column;
  width: 570px;
  height: 480px;
  background-color: #fff;
  border-radius: 10px;

  .header {
    background: #3a4276;
    color: #ffffff;
    border-radius: 9px 9px 0 0;
    padding: 8px 0 0 0;
    height: 50px;
    border-bottom: 0.5px solid #707070;
    font: 30px Roboto;
    font-weight: 800;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    text-align: center;
  }

  .content {
    display: flex;
    justify-content: space-around;
    height: 240px;
    margin-top: 10px;
    padding: 15px 15px 0 15px;

    label {
      margin-top: 30px;
      color: #3a4276;
      font: 22px/22px Roboto;
      font-weight: 800;
      letter-spacing: 0.6px;
      text-transform: uppercase;
    }

    p {
      font: 16px/23px Roboto;
      font-weight: 400;
      letter-spacing: 0.4px;
      color: #333333;
      max-width: 240px;
    }

    .image {
      flex-shrink: 0;
      width: 250px;
      background-repeat: no-repeat;
      background-size: contain;

      &.smart-search {
        background-image: url("assets/images/slideshow-smart-search.png");
      }

      &.zoning-data {
        background-image: url("assets/images/slideshow-zoning-data.png");
      }

      &.assumptions {
        background-image: url("assets/images/slideshow-assumptions.png");
      }

      &.back-of-envelope {
        background-image: url("assets/images/slideshow-back-of-envelope.png");
      }

      &.custom-pdf {
        background-image: url("assets/images/slideshow-custom-pdf.png");
      }

      &.parcel-and-uses {
        background-image: url("assets/images/slideshow-parcel-and-uses.png");
      }

      &.draw-parcel {
        background-image: url("assets/images/slideshow-draw-parcel.png");
      }
    }
  }

  .dots-container {
    display: flex;
    margin-top: 20px;
    justify-content: center;
    align-items: center;

    .dot {
      $small-radius: 12px;
      $big-radius: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: $big-radius;
      height: $big-radius;
      margin-right: 40px;
      cursor: pointer;

      &::before {
        content: "";
        display: block;
        width: $small-radius;
        height: $small-radius;
        border-radius: calc($small-radius / 2);
        background-color: #a3b5d3;
      }

      &:last-child {
        margin-right: 0;
      }

      &.active {
        border-radius: calc($big-radius / 2);

        background-color: #3a4276;

        &::before {
          background-color: #fff;
        }
      }
    }
  }

  .buttons-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;

    button {
      width: 150px;
      height: 45px;
      background-color: #3a4276;
      border-radius: 5px;
      font: 20px/20px Roboto;
      font-weight: 600;
      letter-spacing: 0.5px;
      color: #ffffff;

      &:hover {
        background-color: #2b325c;
      }
    }

    .skip {
      margin: 15px 0 0 0;
      text-decoration: underline;
      font: normal 20px/25px Roboto;
      letter-spacing: 0.4px;
      color: #999999;
      cursor: pointer;
    }
  }
}
