.component--initial-values-panel {
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 552px;
  padding: 46px 63px;
  color: #333333;

  p {
    font-size: 17px;
  }

  .input-container {
    display: flex;
    padding: 21px 10px 0 0;

    .note-container {
      margin-left: 15px;

      .note {
        font-size: 12px;
        margin-bottom: 5px;
        font: normal 14px/16px Roboto;
      }

      .default-far-note {
        color: #F65959;
        font-size: 12px;
        margin: 0;
      }
    }
  }

  .section-header {
    margin: 0;
    font: Bold 14px/16px Roboto;
    color: #333333;
    border-bottom: 1px solid #3A4276;
    padding-bottom: 4px;
  }

  .bold {
    font-weight: bold;
  }

  .uses-container {
    display: flex;
    justify-content: space-between;
    margin-top: 23px;
    margin-bottom: 30px;

    .toggle-field-wrapper {

      .toggle-container {
        padding-left: 6px;
      }
    }
  }

  .far-container {

    input {
      border: 1px solid #CCCCCC;
      border-radius: 8px;
      width: 116px;
      height: 37px;
      color: #999999;
      text-align: center;
      font-size: 15px;
      font-family: "Roboto", sans-serif;

      &:focus,
      &:hover {
        outline: none;
        outline-offset: 0;
      }
    }
  }

  .buttons-container {
    display: flex;
    justify-content: center;
    margin-top: 39px;

    button {
      font: Bold 13px/21px Roboto;
      border-radius: 6px;
      width: 188px;
      height: 41px;
      box-shadow: 0px 5px 10px #3A427666;

      &:first-child {
        margin-right: 46px;
      }

      &.disabled {
        background: #CCCCCC;
        cursor: none;
      }

      &.continue {
        background: #3A4276 0% 0% no-repeat padding-box;
      }

      &.back {
        color: #333333;
        background: #FFFFFF 0% 0% no-repeat padding-box;
      }
    }
  }
}
