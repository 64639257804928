.button-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 100%;
  background: #7dc896 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 6px #00000033;
  border-radius: 7px;
  padding: 0 10px;
  text-align: center;
  font-family: "Roboto Condensed", sans-serif;
  color: white;
  font-weight: 600;
  font-size: 20px;
  margin-left: 10px;

  &:hover {
    background-color: #3a4276;
    color: white;
  }
}
