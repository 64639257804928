.component--reset-password-page {
  @include information-pages;

  .info-message {
    margin-top: 5px;
    margin-bottom: 8px;
  }

  .error-message {
    margin-top: 0;
    margin-bottom: 0;
    height: 16px;
  }

  .submit {
    margin-top: 10px;
  }
}
