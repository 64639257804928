.component--search-bar {
  z-index: 200;
  pointer-events: all;
  display: flex;

  .geocoder-container {
    width: 439px;
    padding: 16px 19px 19px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000080;
    border-radius: 10px;

    .geocoder-input {
      font-size: 17px;
      position: relative;
      height: 32px;
      min-width: 240px;
      border: 1px solid #d5d7e2;
      background: #f6f6fb 0% 0% no-repeat padding-box;
      border-radius: 16px;

      input {
        font-size: 15px;
        width: 90%;
        height: 28px;
        padding: 5px 10px;
        border-radius: 15px;
        border: 0;
        margin: 0;

        &:focus {
          color: rgba(0, 0, 0, 0.75);
          box-shadow: none;
          outline: thin dotted\8;
        }
      }
    }
  }

  .component--unit-system-input {
    margin: 16px 0 0 0px;
  }
}
