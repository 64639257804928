@import "./FromContactDetails/styles";
@import "./ToContactDetails/styles";
@import "./Summary/styles";
@import "./ImageUpload/styles";
@import "./ColorPalette/styles";

.component--pdf-panel {
  color: #464A53;
  width: 100%;
  padding: 0 18px;

  & * {
    flex-shrink: 0;
  }

  .panel-inner-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;

    .export-button-box {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    #export-pdf-button {
      font: 12px/21px Roboto;
      font-weight: 600;
      line-height: 1.5;
      border-radius: 3px;
      background: #F26C6C;
      box-shadow: 3px 3px 6px #00000040;
      padding: 0 20px;
      height: 32px;
      vertical-align: middle;
      align-self: center;
      margin: 18px 0 10px;
    }

    .end {
      height: 1px;
    }
  }

  @mixin inputStyle {
    border: none;
    border-bottom: 1px solid #CCCCCC;
    padding-bottom: 4px;
    font: normal 15px/18px Roboto;
    outline: none;

    ::placeholder {
      color: #CCCCCC;
    }
  }

  .report-title {
    @include inputStyle();
    margin-top: 15px;
  }

  .address {
    @include inputStyle();
    margin-top: 18px;
  }

  .city,
  .state,
  .zip-code {
    @include inputStyle();
    width: 100px
  }

  .city {
    flex: 2;
  }

  .state,
  .zip-code {
    flex: 1;
  }

  .address-container {
    display: flex;
    margin-top: 18px;

    input {
      margin-right: 20px;
      flex: 1 0 auto;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .label-container {
    margin-right: 20px;
    width: 80px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    label {
      font: Bold 12px Roboto;
      margin: 4px 0 0;
    }
  }

  .dimension-message {
    font: 400 Italic 12px/13px Roboto;
    text-align: right;
    margin-top: 5px;
    color: #4f4f4f;
  }
}
