.component--setbacks-floor-area {
  align-items: center;
  background-color: black;
  color: white;
  padding: 10px;
  border-radius: 3px;
  transition: padding .3s ease;

  .area-value {
    font: 500 16px/12px Roboto;
    margin: 0;
  }
}
