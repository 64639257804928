.component--drag-and-drop {
  box-sizing: content-box;

  .drop-container {
    position: relative;
    border-radius: 3px;
    border: 0.5px dashed #CCCCCC;
    height: 49px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &.drag-over {
      border-color: black;
    }

    .upload-logo {
      display: flex;
      align-items: flex-end;
      background: url("assets/images/icon-upload.svg") no-repeat;
      background-position: center;
      background-size: 25px 25px;
      height: 45px;
      font: normal 9px/10px Roboto
    }
  }
}
