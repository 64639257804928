@mixin information-pages {
  $inner-container-width: 395px;
  color: #333333;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  background-color: #F6F6F6;
  min-height: 100%;

  .hidden {
    display: none;
  }

  a {
    font: inherit;
    font-weight: bold;
    color: #3A4276;
  }

  button {
    background-color: #3A4276;
    border-radius: 8px;
    font-weight: 500;
    cursor: pointer;
  }

  .underlined-link {
    font-weight: 500;
    color: #333333;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      color: #3A4276;
      text-decoration: underline;
    }
  }

  .flex-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .content-wrapper {
    position: absolute;
    top: 241px;
  }

  .header {
    font-size: 25px;
    font-family: "Roboto Condensed";
    font-weight: 700;
  }

  .container {
    width: $inner-container-width;
    background-color: #fbfbfb;
    display: flex;
    flex-direction: column;
    padding: 35px 30px 38px;
    box-shadow: 0px 8px 20px #00000017;
    border-radius: 10px;
  }

  .content-header {
    flex-shrink: 0;
    width: 240.5px;
    height: 39.5px;
    background-image: url("assets/images/logo-inline.svg");
    background-repeat: no-repeat;
    background-size: contain;
  }

  .text-box {
    height: auto;
    width: auto;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 16px;
    line-height: 19px;
  }

  .form-container {

    & + .text-box {
      margin-top: 33px;
    }

    .input-section {
      width: auto;
      margin-bottom: 16px;

      .input-label {
        width: auto;
        font-family: "Roboto Condensed", sans-serif;
        font-size: 14px;
        font-weight: bold;
        margin: 15px 0 5px 10px;
        line-height: 10px;
      }

      .input-field {
        position: relative;
        height: 32px;
        width: 335px;
        background-color: #FDFDFD;
        padding: 4px 13px 4px 4px;
        border: 1px solid #CCCCCC;
        border-radius: 8px;

        input {
          border: none;
          width: 100%;
          height: 100%;
          padding-left: 10px;
          padding-right: 15px;
          font-family: inherit;
          font-size: 15px;
          background-color: transparent;

          &:-webkit-autofill {
            border: none;
            box-shadow: 0 0 0px 1000px white inset;
          }

          &::placeholder {
            color: #CCCCCC;
          }
        }

        &.required:after {
          content: "*";
          position: absolute;
          top: 5px;
          right: 8px;
          font-family: "Roboto", sans-serif;
          font-size: 18px;
          font-weight: bold;
          color: #F26C6C;
        }
      }
    }

    .submit {
      height: 35px;
      width: 97px;
      letter-spacing: 0.25px;
      padding: 0;
      pointer-events: all;

      &.disabled {
        cursor: inherit;
        color: white;
      }
    }
  }

  .bold {
    font-weight: bold;
  }

  .error-message {
    display: flex;
    align-items: flex-end;
    height: 31px;
    margin: 25px 0 13px 5px;
    width: 294px;
    text-align: left;
    vertical-align: middle;
    font-family: "Roboto Condensed", "Regular";
    font-size: 13px;
    color: #F26C6C;
    line-height: 16px;
  }

  .reset-password {
    margin-top: 33px;
  }

  .tos-agreement {
    height: 19px;
    display: flex;
    align-items: center;
    margin-bottom: 36px;

    input[type="checkbox"] {
      appearance: none;
      background-color: #F5F7F8;
      border: 1px solid #CCCCCC;
      padding: 6.5px;
      border-radius: 5px;
      width: 0;
      height: 0;

      &:checked {
        border: 1px solid #333333;
        background: url("assets/images/checkmark.svg");
        background-size: contain;
        background-repeat: no-repeat;
      }

      &:focus {
        outline: none;
      }
    }

    .text-box {
      margin-left: 14px;
    }
  }

  @media screen and (max-width: 794px) {
    .flex-wrapper {
      flex-direction: column-reverse;
    }
  }
}
