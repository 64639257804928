@import "./sharedComponents/styles";

.component--map-legend-panel {
  width: 100%;
  background-color: #ffffff;
  padding: 30px 18px;
  pointer-events: all;
  display: flex;
  flex-direction: column;

  .section-divisor {
    padding-bottom: 5px;
    border-bottom: 1px solid #3a4276;
    margin-bottom: 27px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;

    label {
      margin: 0;
      font: bold 16px/21px Roboto;
      color: #333333;
    }
  }

  .component--layer-legend {
    margin-bottom: 28px;
    border-bottom: 1px solid #f2f2f2;

    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
    }
  }

  .buildable-area-ratio {
    .icon {
      background-image: url("assets/images/icon-legend-businesses.png");
    }
  }

  .businesses {
    .icon {
      background-image: url("assets/images/icon-legend-businesses.png");
    }
  }

  .historic-buildings {
    .icon {
      background-image: url("assets/images/icon-legend-historic-buildings.jpg");
    }
  }

  .reits-projects {
    .icon {
      background-image: url("assets/images/icon-reits-project.png");
    }
  }

  .hotel-average-daily-rate {
    .icon {
      background-image: url("assets/images/icon-hotel-average-daily-rate.png");
    }
  }

  .office-rate {
    .icon {
      background-image: url("assets/images/icon-office-rate.png");
    }
  }

  .retail-rate {
    .icon {
      background-image: url("assets/images/icon-retail-rate.png");
    }
  }

  .industrial-rate {
    .icon {
      background-image: url("assets/images/icon-industrial-rate.png");
    }
  }

  .multifamily-rent {
    .icon {
      background-image: url("assets/images/icon-multifamily-rent.png");
    }
  }

  .education {
    .icon {
      background-image: url("assets/images/icon-education.png");
    }
  }

  .residential-vacancy {
    .icon {
      background-image: url("assets/images/icon-legend-residential-vacancy.png");
    }
  }

  .rent-per-income {
    .icon {
      background-image: url("assets/images/icon-legend-rent-income-ratio.png");
    }
  }

  .traffic-count {
    .icon {
      background-image: url("assets/images/icon-legend-traffic-count.png");
    }
  }

  .road-type {
    .icon {
      background-image: url("assets/images/icon-legend-road-type.png");
    }
  }

  .permits {
    .icon {
      background-image: url("assets/images/icon-legend-permits.png");
    }
  }

  .permits-change {
    .icon {
      background-image: url("assets/images/icon-legend-permits-change.png");
    }
  }

  .smart-search-results {
    .icon {
      background-image: url("assets/images/icon-legend-search-results.png");
    }
  }

  .buildings {
    .icon {
      background-image: url("assets/images/icon-legend-buildings.png");
    }
  }

  .opportunity-zone {
    .icon {
      background-image: url("assets/images/icon-legend-opportunity-zone.png");
    }
  }

  .zoning {
    .icon {
      background-image: url("assets/images/icon-legend-zoning.png");
    }
  }

  .census-tracts {
    .icon {
      background-image: url("assets/images/icon-legend-census-tracts.png");
    }
  }

  .employment-per-population {
    .icon {
      background-image: url("assets/images/icon-employment-per-population.png");
    }
  }

  .hospitals {
    .icon {
      background-image: url("assets/images/icon-legend-reits-projects.png");
    }
  }

  .hotels {
    .icon {
      background-image: url("assets/images/icon-hotels.png");
    }
  }

  .median-income {
    .icon {
      background-image: url("assets/images/icon-median-income.png");
    }
  }

  .median-rent {
    .icon {
      background-image: url("assets/images/icon-median-rent.png");
    }
  }

  .population {
    .icon {
      background-image: url("assets/images/icon-population.png");
    }
  }

  .zoning-density {
    .icon {
      background-image: url("assets/images/icon-legend-zoning-density.png");
    }
  }

  .cap-rates {
    .icon {
      background-image: url("assets/images/icon-legend-cap-rates.png");
    }
  }

  .federal-land {
    .icon {
      background-image: url("assets/images/icon-legend-federal-land.png");
    }
  }

  .protected-areas {
    .icon {
      background-image: url("assets/images/icon-legend-protected-areas.png");
    }
  }

  .forests {
    .icon {
      background-image: url("assets/images/icon-legend-forests.png");
    }
  }

  .hazardous-sites {
    .icon {
      background-image: url("assets/images/icon-legend-hazardous-sites.png");
    }
  }

  .watersheds {
    .icon {
      background-image: url("assets/images/icon-legend-watersheds.png");
    }
  }

  .historic-sites {
    .icon {
      background-image: url("assets/images/icon-legend-historic-sites.png");
    }
  }

  .parks {
    .icon {
      background-image: url("assets/images/icon-legend-parks.png");
    }
  }

  .indian-reservations {
    .icon {
      background-image: url("assets/images/icon-legend-indian-reservations.png");
    }
  }

  .bay-area-sites {
    .icon {
      background-image: url("assets/images/icon-legend-bay-area-sites.png");
    }
  }

  .lihtc-inventory {
    .icon {
      background-image: url("assets/images/icon-legend-lihtc-inventory.png");
    }
  }

  .city-boundaries {
    .icon {
      background-image: url("assets/images/icon-legend-city-boundaries.png");
    }
  }

  .dda {
    .icon {
      background-image: url("assets/images/icon-legend-dda.png");
    }
  }

  .qct {
    .icon {
      background-image: url("assets/images/icon-legend-qct.png");
    }
  }

  .toc {
    .icon {
      background-image: url("assets/images/icon-legend-toc.png");
    }
  }

  .wetlands {
    .icon {
      background-image: url("assets/images/icon-legend-wetlands.png");
    }
  }

  .sea-level {
    .icon {
      background-image: url("assets/images/icon-legend-sea-level.png");
    }
  }

  .areas-with-parcels {
    .icon {
      background-image: url("assets/images/icon-legend-areas-with-parcels.png");
    }
  }

  .listings {
    .icon {
      background-image: url("assets/images/icon-legend-listing.png");
    }
  }

  .industrial-sites-legend {
    .icon {
      background-image: url("assets/images/industrial-sites-legend.png");
    }
  }

  .multifamily-sites-legend {
    .icon {
      background-image: url("assets/images/multifamily-sites-legend.png");
    }
  }
}
