.component--property-info-panel {
  background-color: #FFFFFF;
  overflow: hidden;
  display: flex;

  .component--parcel-information-table {
    padding: 28px 18px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
