.close-cross {
  background-image: url("assets/images/white-cross.svg");
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  top: 100px;
  right: 50px;
  width: 27.4px;
  height: 29.07px;
  cursor: pointer;
}
