.dynamic-layer-selection-panel {
  margin: 15px 25px 10px 25px;
}

.dynamic-steps-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  padding: 0 10px;
}

.dynamic-layer-panel-title-box {
  display: flex;
}

.dynamic-layer-panel-title {
  font: normal 14px/16px Roboto;
  color: #666666;
  margin-bottom: 0;
  margin-top: 15px;
  padding-left: 5px;

  &.value {
    font: bold 16px/18px Roboto;
    margin-top: 14px;
    color: #3A4276;
  }
}

.component--dynamic-layer-box {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.slider-inner-wrapper {
  background-color: #FFFFFF;
  border: 1px solid #E0E1E9;
  border-radius: 10px;
  width: 100%;
  height: 'auto';
  padding: 0 8px;
  margin: 1px 0px 0px 0px;
}

.slider-wrapper {
  background-color: #f5f8f9;
  border-radius: 10px;
  height: 16px;
  margin: 4px;
}

.years-label-box {
  display: flex;
  justify-content: space-between;
}

.year-label {
  font: bold 12px/12px Roboto;
  color: #3A4276;
  margin-top: 5px;
}
