.component--color-palette {
  display: flex;
  margin-top: 40px;
  margin-bottom: 20px;
  position: relative;

  .palette-icon {
    width: 25px;
    height: 25px;
    background: url("assets/images/icon-color-palette.svg") no-repeat;
    background-size: contain;
  }

  .color-selection-container {
    display: flex;
    flex: 1 1 100%;
    justify-content: space-evenly;
    height: 95px;
    border: 1px solid #CCCCCC;
    border-radius: 3px;

    .color-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1 1 auto;
      padding-top: 12px;

      label {
        margin: 0;
        font: 500 10px/13px Roboto;
      }

      .color-preview {
        margin-top: 20px;
        width: 22px;
        height: 22px;
        border-radius: 11px;
        cursor: pointer;
        box-shadow: 0px 3px 6px #00000029;
      }

      .pick-color {
        font: normal 8px/13px Roboto;
        color: #8D8D8D;
        margin-top: 5px;
      }

    }

  }

  .color-picker-wrapper {
    position: absolute;
    top: -60px;
    left: 30%;

    .cover {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }

    .close-color-picker {
      position: absolute;
      top: -20px;
      left: 2px;
      cursor: pointer;
    }
  }
}
