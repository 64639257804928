.toggle-field-wrapper {
  width: 100%;
  height: 35px;
  padding: 10px 0px;

  &.disable-for-demo {

    label {
      pointer-events: none;
    }
  }

  .toggle-container {
    display: flex;
    width: 100%;
    padding-left: 12px;
    padding-right: 8px;
    flex-direction: row;
    justify-content: flex-start;
  }

  .toggle-box {
    position: relative;
    display: inline-block;
    width: 29px;
    height: 19px;
    margin-right: 25px;

    input {
      opacity: 0;

      &:checked + .toggle:before{
        transform: translateX(10px);
      }

      &input:checked + .toggle:after {
        position: absolute;
        content: "";
        left: 14px;
        top: 50%;
        width: 18px;
        height: 17px;
        margin-top: -8px;
        background: transparent 0 0 no-repeat;
      }
    }

    .toggle {
      background: #dee2e4;
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transition: all 0.3s;
      border-radius: 34px;
    }

    .toggle:before {
      position: absolute;
      content: "";
      height: 15px;
      width: 15px;
      left: 2px;
      top: 2px;
      background-color: white;
      transition: all 0.3s;
      border-radius: 50%;
    }
  }

  .toggle-label {
    float: left;
    font-size: 15px;
    line-height: 20px;
  }

  label {
    margin: 0;
  }
}
