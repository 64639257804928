.component--parcel-information-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: white;
  overflow: hidden;

  label {
    margin: 0;
  }

  .component--parcel-information-table {
    padding: 28px 18px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.collaboration-container {
  align-self: center;
  padding: 15px;
  margin-bottom: 0;
  border: 1px solid #D1D5DD;
  color: #939393;
  font-size: 14px;

  a {
    color: #3A4276;
    font-weight: 700;
    text-decoration: underline;
  }
}
