.component--accordion-section {
  margin-bottom: 18px;
  width: 100%;

  .section-title {
    height: 35px;
    display: flex;
    padding: 0 16px;
    align-items: center;
    justify-content: space-between;
    font: 700 18px/21px Roboto;
    letter-spacing: 1.53px;
    text-transform: uppercase;
    cursor: pointer;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    color: #3A4276;
    border: 1px solid rgba(58, 66, 118, 0.5);
    margin-bottom: 20px;

    .title-wrapper {
      display: flex;
    }

    .icon {
      width: 18px;
      height: 18px;
      background-repeat: no-repeat;
      background-size: contain;
    }

    .title-icon {
      margin-right: 11px;
    }

    .open-close-icon {
      background-position: center;
      background-image: url("assets/images/icon-plus.svg");
    }

    .name-uses {
      background-image: url("assets/images/icon-name-uses-blue.svg");
    }

    .demographics {
      background-image: url("assets/images/icon-demographics-blue.svg");
    }

    .property-info {
      background-image: url("assets/images/icon-property-info-blue.svg");
    }

    .zoning {
      background-image: url("assets/images/icon-zoning-blue.svg");
    }

    &.open {
      background: #EFEFF4 0% 0% no-repeat padding-box;
      border: none;
      margin: 0;

      .open-close-icon {
        background-image: url("assets/images/icon-minus.svg");
      }
    }
  }
}
