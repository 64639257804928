@import "./SearchBar/styles";
@import "./ParcelInformationPanel/styles";
@import "./NewProjectMap/styles";
@import "./ParcelFinderPanel/styles";
@import "./InitialValuesPanel/styles";
@import "./WelcomeSlideshow/styles";
@import "./NewProjectToolbar/styles";
@import "./StartProjectButton/styles";
@import "./StreetViewButton/styles";
@import "./MarketGraphsPanel/styles";

.component--new-project-page {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  overflow: hidden;
  min-width: 937px;

  .content {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 11px 14px 30px 24px;
    z-index: 1;
    pointer-events: none;
  }

  .top-menus-container {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: space-between;
  }

  .panels-container {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    width: 100%;
    box-sizing: content-box;
    pointer-events: none;
    height: calc(100% - 200px);

    $panel-width: 459px;
    $padding: 10px;

    .left-panel-wrapper {
      position: absolute;
      left: -$padding;
      padding: $padding;
      width: $panel-width;
      box-sizing: border-box;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .right-panel-wrapper {
      position: absolute;
      right: -$padding;
      padding: $padding $padding 60px $padding;
      width: $panel-width;
      height: 100%;
      overflow: hidden;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
    }

    .component--with-panel-header {
      pointer-events: all;
      margin-bottom: 10px;

      .first-child {
        flex-shrink: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .map-loading-container {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    background-color: rgba(0, 0, 0, 0.6);
    justify-content: center;
    align-items: center;

    .component--busy-spinner {
      height: 120px;

      .spinner {
        background-image: url("assets/images/busy-spinner-white.svg");
      }
    }

    .header {
      color: white;
      font-family: Roboto Condensed;
      font-weight: 700;
      font-size: 20px;
      text-align: center;
    }
  }
}
