@import "./ParcelsCsvExportButton/styles";
@import "./ParcelCounter/styles";

.component--search-engine-header {
  display: flex;
  color: #333333;
  padding-bottom: 15px;
  flex-shrink: 0;
  width: 100%;
  border-bottom: solid 1px #99999920;
  margin-bottom: 20px;

  .icon {
    width: 100px;
    height: 57px;
    flex-shrink: 0;
    margin-right: 18px;
    background-size: cover;
    box-shadow: 0px 3px 6px #3A427666;
    border-radius: 5px;
    background-image: url("assets/images/icon-legend-search-results.png");
  }

  .smart-search-toggle {
    margin-top: -8px;
    margin-left: -10px;
  }

  .legend-content {
    width: 100%;
    margin-left: -10px;

    .content-wrapper {
      display: flex;
      justify-content: space-between;
    }

    .title-container {
      margin-right: 25px;

      .title {
        font: 900 16px/21px Roboto;
        text-transform: capitalize;
      }

      .text {
        font: 12px/14px Roboto;
        font-weight: 400;
        margin-top: 8px;
        max-width: 200px;
      }
    }

    .functionalities-container {
      display: flex;
      flex-direction: column;
      justify-content: right;
    }
  }
}
