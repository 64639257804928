@import "./ExplorerToolbar/styles";
@import "./Map/styles";
@import "./SetbackSelectionMap/styles";
@import "./ThumbnailMap/styles";
@import "./KeepProject/styles";
@import "./sharedComponents/styles";
@import "./DevelopmentsDropdown/styles";
@import "./DefineBuildingPanel/styles";
@import "./SetbackTools/styles";
@import "./SetbacksFloorArea/styles";
@import "./SetbackClosePanel/styles";
@import "./AssumptionsPanel/styles";
@import "./ReturnAnalysis/styles";
@import "./KpiPanelVisibility/styles";
@import "./PropertyInfoPanel/styles";
@import "./SetbackMeasurementTools/styles";
@import "./SetbackToolsWrapper/styles";

.component--explorer-page {
  height: 100vh;
  width: 100vw;
  overflow-x: auto;
  overflow-y: hidden;
  min-width: 937px;
  position: relative;

  .top-menus-container {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: space-between;

    .projects-wrapper {
      display: flex;
    }
  }

  .explorer-content {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 11px 14px 0px 24px;
    z-index: 5;
    pointer-events: none;

    .panels-container {
      position: relative;
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      width: 100%;
      box-sizing: content-box;
      pointer-events: none;
      height: calc(100% - 200px);

      &.setback-mode {
        .component--with-panel-header {
          &:first-child {
            flex-shrink: 1;
          }
        }
      }

      $panels-wrapper-width: 459px;
      $panels-wrapper-padding: 10px;

      .left-panel-wrapper {
        position: absolute;
        left: -$panels-wrapper-padding;
        padding: $panels-wrapper-padding;
        width: $panels-wrapper-width;
        box-sizing: border-box;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        height: 100%;
      }

      .right-panel-wrapper {
        position: absolute;
        right: -$panels-wrapper-padding;
        padding: $panels-wrapper-padding;
        width: $panels-wrapper-width;
        height: 100%;
        overflow: hidden;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
      }

      $panel-padding: 18px;

      .panel-content {
        width: 100%;
        padding-left: $panel-padding;
        padding-right: $panel-padding;

        .top-label {
          display: flex;
          justify-content: space-between;
          padding-bottom: 8px;
          font: 700 16px/21px Roboto;
          color: #3a4276;
          text-transform: capitalize;
          margin-top: 25px;
          border-bottom: 1px solid #3a4276;
        }
      }
    }

    .kpi-container {
      height: 100px;
    }
  }

  .map-loading-container {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    background-color: rgba(0, 0, 0, 0.6);
    justify-content: center;
    align-items: center;

    .component--busy-spinner {
      height: 120px;

      .spinner {
        background-image: url("assets/images/busy-spinner-white.svg");
      }
    }

    .header {
      color: white;
      font-family: Roboto Condensed;
      font-weight: 700;
      font-size: 20px;
      text-align: center;
    }
  }
}

.component--with-panel-header {
  pointer-events: all;
  margin-bottom: 10px;

  &.first-child {
    flex-shrink: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.component--developments-dropdown,
.component--setback-close-panel,
.component--keep-project {
  flex-shrink: 0;
}

.component--developments-dropdown,
.component--setback-close-panel,
.component--explorer-toolbar,
.component--keep-project {
  pointer-events: all;
}

.building-alert-wrapper {
  position: absolute;
  left: 50%;
  top: 152px;
  flex-shrink: 0;

  .building-alert {
    position: relative;
    left: -50%;
    margin: 0 10px;
    flex-shrink: 0;
    right: 30%;
    display: flex;
    align-items: center;
    background-color: #f26c6c;
    z-index: 2100;
    height: 36px;
    width: 631px;
    font: 400 13px/36px Roboto;
    color: #ffffff;
    padding: 0 15px;
    border-radius: 3px;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);

    .icon-info {
      height: 13px;
      width: 13px;
      margin: 0 10px 2px 0;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("assets/images/icon-information-white.svg");
    }

    .icon-close {
      position: absolute;
      top: -8px;
      right: -8px;
      height: 18px;
      width: 18px;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("assets/images/icon-close.svg");
      cursor: pointer;
    }
  }
}
