.component--subscription-required-content {
  height: 100%;
  display: flex;
  justify-content: center;
  padding: 50px 20%;

  .component--with-panel-header {
    background-color: #FFFFFF;
    flex: 1;
    min-width: 1070px;
    max-width: 1200px;
    min-height: 680px;
  }
}
