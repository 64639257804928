.component--home-page {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;

  .buttons-container {
    position: absolute;
    top: 50px;
    right: 20px;
    display: flex;
    align-items: center;
    gap: 14px;
    height: 70px;
  }

  .zoning-report-box {
    height: 70px;
    margin-bottom: 16px;
  }

  .disclaimer {
    position: absolute;
    font-family: "Roboto", sans-serif;
    font-size: 13px;
    font-weight: 400;
    bottom: 110px;
  }
}
