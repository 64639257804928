@import "./SignUpLink/styles";

.component--log-in {
  position: relative;
  background-color: #FFFFFF;
  padding: 20px 12px;
  border-radius: 3px;

  form {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }

  .header {
    height: 35px;
    border-bottom: 1px solid #E0E0E0;
    text-align: center;
    font: 700 20px/25px Roboto;
    color: #263238;
    text-transform: uppercase;
  }

  .input-field {
    margin-top: 16px;
  }

  .error-container {

    p {
      font: 300 10px/20px Roboto;
      font-style: italic;
      color: #F26C6C;
      margin-top: 7px;
      margin-bottom: -15px;
      white-space: pre-line;
    }
  }

  .tos-agreement {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }

  .text-box {
    font: 400 12px/14px Roboto;
    color: #777777;
  }

  a {
    font: 400 12px/14px Roboto;
    color: #777777;
    text-decoration: underline;

    &.forgot-password {
      margin-left: auto;
    }
  }

  input {
    width: 100%;
    background-color: #F1F5F7;
    border: 1px solid #EEEEEE;
    border-radius: 0;

    &.tos {
      width: 24px;
    }
  }

  button {
    width: 100%;
    height: 46px;
    background-color: #3A4276;
    border-radius: 3PX;
    margin-top: 24px;
    font-size: 16px;
    font-weight: 500;
    padding: 10px 30px;
    box-shadow: 0px 4px 10px #00000040;
  }

  .submit {
    &:hover {
      background-color: #2C3258;
    }
  }
}
