.component--sign-up {
  position: relative;
  background-color: #FFFFFF;
  padding: 20px 12px;
  border-radius: 0px 0px 3px 3px;
  overflow-y: auto;
  max-height: calc(100vh - 171px);

  form {
    display: flex;
    flex-direction: column;

    &.hide {
      display: none;
    }
  }

  .input-field {
    margin-top: 16px;
  }

  .error-container {

    p {
      font: 300 10px/20px Roboto;
      font-style: italic;
      color: #F26C6C;
      margin-top: 7px;
      margin-bottom: -15px;
      white-space: pre-line;
    }
  }

  .tos-agreement {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }

  .text-box {
    font: 400 12px/14px Roboto;
    color: #777777;

    .underlined {
      text-decoration: underline;
      cursor: pointer;
    }

    &.right {
      margin-left: auto;
    }
  }

  a {
    font: 400 12px/14px Roboto;
    color: #777777;
    text-decoration: underline;
  }

  input {
    width: 100%;
    background-color: #F1F5F7;
    border: 1px solid #EEEEEE;
    border-radius: 0;

    &.tos {
      width: 24px;
    }
  }

  .buttons-container {
    width: 100%;
  }

  button {
    height: 60px;
    width: 100%;
    background-color: #3A4276;
    border-radius: 3px;
    margin-top: 31px;
    font-size: 16px;
    font-weight: 500;
    padding: 10px 10px;
    box-shadow: 0px 4px 10px #00000040;
  }

  .submit {
    &:hover {
      background-color: #2C3258;
    }
  }
}
