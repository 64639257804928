@import "./DrawingInformation/styles";

.component--new-project-map {
  display: flex;
  width: 100%;
  height: 100%;

  .component--branding-watermark {
    position: absolute;
    bottom: 13px;
    left: 47.5px;
  }

  .tool-tip {
    background-color: #333333;
    color: #FFFFFF;
    border-radius: 5px;
    padding: 3px 10px;
  }

  .map-pin {
    z-index: 0 !important;
    width: 30px;
    height: 46px;
    background-image: url("assets/images/map-pin.svg");
  }

  // CSS for cursors.
  &.delete-parcel .mode-simple_select.mouse-pointer .mapboxgl-interactive {
    cursor: url("./assets/images/cursor-delete-parcel.svg") 6 15, auto;
  }

  &.select-parcel .hover-parcel {
    cursor: url("./assets/images/cursor-select-parcel.svg"), auto;
  }

  &.combine-parcels .hover-parcel {
    cursor: url("./assets/images/cursor-combine-parcels.svg"), auto;
  }

  .mouse-pointer .mapboxgl-interactive {
    cursor: pointer;
  }

  .mode-direct_select.mouse-move .mapboxgl-interactive {
    cursor: move;
  }

  .mouse-add .mapboxgl-interactive {
    cursor: cell;
  }
}
