.component--busy-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  .spinner {
    width: 40px;
    height: 40px;
    background-image: url("assets/images/busy-spinner-black.svg");
    animation: spin 1s infinite linear;
  }

  &.white-spinner {

    .spinner {
      background-image: url("assets/images/busy-spinner-white.svg");
    }
  }
}

@keyframes spin { 100% { transform:rotate(360deg); } }
