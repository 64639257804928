.component--explorer-toolbar {
  display: flex;
  height: 100%;
  gap: 10px;
  align-items: flex-start;
}
.explorer-toolbar {
  height: 100%;
  width: 439px;
  background: #fff 0 0 no-repeat padding-box;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;

  .component--logo .logo {
    margin: 0;
  }

  @media screen and (max-width: 800px) {
    .component--go-to-new-project-button {
      visibility: hidden;
    }
  }
}
