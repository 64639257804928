.component--authentication-buttons {
  position: relative;
  display: flex;
  flex-shrink: 0;
  align-items: center;

  .component--button {
    margin: 0;
    box-shadow: none;
  }
}
