@mixin scrollbar-vertical {
  &::-webkit-scrollbar {
    width: 5px;
    visibility: hidden;
  }

  &::-webkit-scrollbar-track {
    background-color: #D8DCDE;
    border-left: 1.5px solid white;
    border-right: 1.5px solid white;
    visibility: hidden;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #aeaeae;
    visibility: hidden;
  }

  &:hover::-webkit-scrollbar, &:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }

  &:hover::-webkit-scrollbar-track {
    visibility: hidden;
  }
}

@mixin scrollbar-horizontal {
  &::-webkit-scrollbar {
    height: 5px;
    visibility: hidden;
  }

  &::-webkit-scrollbar-track {
    background-color: #D8DCDE;
    border-top: 1.5px solid white;
    border-bottom: 1.5px solid white;
    visibility: hidden;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #aeaeae;
    visibility: hidden;
  }

  &:hover::-webkit-scrollbar, &:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }

  &:hover::-webkit-scrollbar-track {
    visibility: hidden;
  }
}

* {
  @include scrollbar-vertical;
  @include scrollbar-horizontal;
}
