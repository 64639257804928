.component--activity-feedback {

  .spinner-wrapper {
    height: 140px;
  }

  .secondary-text {
    text-align: center;
    font-size: 16px;
    color: #777777;
  }
}
