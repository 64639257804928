@import "./SubscriptionRequiredContent/styles";
@import "./SignUpFlowPopup/styles";

.component--subscription-required-page {
  position: absolute;
  width: 100%;
  height: 100%;
  min-width: 1280px;
  background-color: #E3E5E8;

  .buttons-container {
    position: absolute;
    top: 50px;
    right: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}
