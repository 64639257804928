@import "./RemoveSetbackPopup/styles";

.component--height-and-setbacks {

  .setback-mode-title {
    margin-top: 55px;
  }

  .text-box {
    color: #333333;
    margin: 10px 0 18px 0;
    font-size: 13px;
  }

  .button-wrapper {
    display: flex;
    justify-content: center;
    margin: 23px 0;

    button {
      width: 178px;
      height: 34px;
      border-radius: 6px;
      font: 900 14px/21px Roboto;
    }
  }

  .component--dropdown {
    width: 180px;
    background: #3A4276 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000033;
    color: #FFFFFF;
    border-radius: 3px;
    outline: none;
    margin-left: 5px;
    padding: 7px 9px;

    .floor-toggle-button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      cursor: pointer;

      .down-arrow {
        background-image: url("assets/images/icon-down-arrow-white.svg");
        background-size: contain;
        width: 15px;
        height: 9px;
      }
    }

    .dropdown-content {
      top: 35px;
      left: 0;
      width: 180px;
      border-radius: 0 0 3px 3px;

      li {
        padding: 0 10px;

        &.inactive-divisor {
          padding: 0 10px;
          display: flex;
          margin: 0 10px;
          justify-content: center;
          align-items: center;
          height: 35px;
          color: #fff;
          border-top: 1px solid #fff;
          cursor: inherit;

          &:hover {
            background-color: inherit;

            & + li .button {
              border-color: #3A4377;
            }
          }
        }
      }

      .button {
        justify-content: space-between;

        &.with-setback {
          color: #F9CB58;
        }

        &.inactive {
          color: #D5D5E0;

          .remove-setback {
            background-image: url("assets/images/icon-trash-can-light.svg");
          }
        }
      }
    }
  }

  .remove-setback {
    margin-right: 3px;
    min-width: 12px;
    height: 12px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("assets/images/icon-trash-can-yellow.svg");
  }
}
