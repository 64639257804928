.component--upgrade-popup {
  width: 430px;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;

  .payment-error-container {
    color: #F26C6C;
    align-self: flex-start;
    margin: 10px 0;
    padding: 0;
    width: 100%;

    p {
      font-size: 14px;
      text-align: center;
      line-height: 16px;
      margin: 0;
    }
  }

  .top {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #3A4276;
    padding: 15px 30px;
    color: #FFFFFF;

    .toggle-wrapper {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 162px;
      height: 36px;
      border-radius: 18px;
      background-color: #EDF1F7;
      margin: 20px auto 16px;
      z-index: 100;
      flex-shrink: 0;

      .toggle-button {
        width: 70px;
        height: 26px;
        border-radius: 13px;
        font: 700 10px/26px Roboto;
        text-align: center;
        color: #333333;
        cursor: pointer;

        &.selected {
          background-color: #F26C6C;
          color: #FFFFFF;
        }
      }
    }
  }

  .bottom {
    padding: 20px 0 25px;
  }

  .text {
    font: 400 16px/21px Roboto;
    text-align: center;
    color: #FFFFFF;
  }

  .price {
    font-weight: 700;
    font-size: 14px;
  }

  .input-field {
    width: 330px;
    margin: 20px 0 10px;

    input {
      height: 32px;
      width: 100%;
      padding: 9px 15px;
      border-radius: 5px;
      background-color: #F6F6FB;
      font: 400 13px/13px Roboto;
    }

    &.invalid {

      input {
        color: red;
      }
    }
  }

  .buttons-container {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  button {
    width: 160px;
    height: 34px;
    border-radius: 7px;
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px #00000033;
    font: 700 14px/14px Roboto;
    color: #333333;

    &:hover {
      background-color: #3A4276;
      color: #FFFFFF;
    }

    &.upgrade {
      margin-left: 20px;
    }

    &.upgrading {
      margin-left: 20px;
      background-color: #3A4276;
      color: #FFFFFF;
    }
  }
}
