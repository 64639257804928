.component--application-links {
  display: flex;
  justify-content: space-around;
  align-items: baseline;

  .application-link {
    width: 285px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &.coming-soon {
      p,
      h5 {
        color: #acb0c6;
      }
    }
  }

  .bottom {
    font-size: 13px;
    font-weight: bold;
  }

  h5 {
    color: #333333;
    font-family: "Roboto Condensed";
    font-size: 17.5px;
    font-weight: bold;
    margin-top: 20px;
  }

  p {
    color: #777777;
    font-size: 12.5px;
    text-align: center;
    margin: 15px 0 0;
  }

  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-color: #3a4276;
    width: 186px;
    font-size: 13px;
    color: white;
    background-color: #3a4276;
    margin-top: 15px;

    &:hover {
      background-color: #f26c6c;
      border-color: #f26c6c;
    }
  }

  .zoning-report-link {
    @extend.btn;
    background: #7dc896 0% 0% no-repeat padding-box;
    border-color: #7dc896;
  }

  @mixin icon-big($height, $url) {
    height: $height;
    width: 158px;
    background-size: contain;
    background-image: url("assets/images/" + $url);
    background-repeat: no-repeat;
  }

  .site-analysis-icon {
    @include icon-big(130px, "icon-dashboard-analyze-site.svg");
  }

  .existing-building-icon {
    @include icon-big(128px, "icon-dashboard-existing-building.svg");
  }

  .zoning-report-icon {
    @include icon-big(128px, "active-icon-dashboard-existing-building.svg");
  }

  .new-development-icon {
    @include icon-big(134px, "icon-dashboard-new-development.svg");
  }
}
