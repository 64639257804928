@import "./SearchRow/styles";
@import "./SearchEngineHeader/styles";
@import "./ZoningFiltersContainer/styles";
@import "./AllowedUsesFiltersContainer/styles";
@import "./OwnerNameFiltersContainer/styles";
@import "./OwnerAddressFiltersContainer/styles";
@import "./LandUseFiltersContainer/styles";

.component--search-panel {
  width: 100%;
  background-color: #fff;
  padding: 19px 18px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  overflow-y: scroll;

  .searchBox {
    width: 100%;

    &::-webkit-input-placeholder {
      font-size: 15px;
    }
  }

  .section-divisor {
    padding-bottom: 5px;
    border-bottom: 1px solid #3a4276;
    display: flex;
    align-items: center;
    justify-content: space-between;

    label {
      margin: 0;
      font: bold 16px/21px Roboto;
      color: #333333;
    }
  }

  .missing-data-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font: normal 14px/14px Roboto;
    color: #666666;
    text-align: center;
    padding: 40px 0;

    p {
      width: 324px;
      margin: 0;
    }

    .request-icon {
      width: 252px;
      height: 170px;
      margin-bottom: 18px;
      background: url("assets/images/icon-request-zoning-data.svg") no-repeat;
    }

    .request-button {
      width: 126px;
      height: 41px;
      margin-top: 18px;
      background: #3a4276 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000066;
      border-radius: 5px;
      color: #ffffff;
      font: Bold 12px/41px Roboto;
    }
  }

  .count-wrapper {
    color: #3a4276;
    font: Bold 16px/21px Roboto;
    width: 100%;
    margin-bottom: 5px;

    .count-container {
      font: Bold 14px/21px Roboto;
    }
  }

  .divisor {
    height: 1px;
    width: 100%;
    background: #000000 0% 0% no-repeat padding-box;
    opacity: 0.05;
    margin-bottom: 25px;
  }
}
