@import "./RequiredFieldsPopup/styles";
@import "./SendingInvitationPopup/styles";
@import "./InvitationSuccessPopup/styles";
@import "./InvitationFailurePopup/styles";
@import "./DuplicateInvitationPopup/styles";

.component--my-team {
  background-color: #FFFFFF;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 12px 18px;
    margin-top: 35px;
    background-color: #FFFFFF;
    box-shadow: 0.47px 3px 10px #7777771A;
    border-radius: 3px;
  }

  .title {
    margin: 36px 0 0;
    font: Bold 16px/19px Roboto;
    color: #3A4276;
  }

  .input-field {
    width: 330px;
    margin-bottom: 12px;

    &.invalid {

      input {
        color: red;
      }
    }
  }

  button {
    margin-top: 16px;
    margin-bottom: 36px;
  }

  .toggle-wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 162px;
    height: 36px;
    border-radius: 18px;
    background-color: #EDF1F7;
    margin: 20px auto 16px;
    box-shadow: 3px 3px 10px #00000029;
    flex-shrink: 0;

    .toggle-button {
      width: 70px;
      height: 26px;
      border-radius: 13px;
      font: 700 10px/26px Roboto;
      text-align: center;
      color: #333333;
      cursor: pointer;

      &.selected {
        background-color: #F26C6C;
        color: #FFFFFF;
      }
    }
  }

  .text {
    font: 700 14px/14px Roboto;
    margin-bottom: 20px;
  }
}
