.flx {
  display: flex;
  &.full{ width: 100%; }
  &.h-center{ justify-content: center; }
  &.v-center{ align-items: center; }
  &.hv-center{ justify-content: center; align-items: center; }
}

.flx-col {
  display: flex;
  flex-direction: column;
  &.full{ height: 100%; }
  &.v-center{ justify-content: center; }
  &.h-center{ align-items: center; }
  &.hv-center{ justify-content: center; align-items: center; }
}

.clearfix::after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.container {
  max-width: 1124px;
  margin: 0 auto;
}
