.component--drawing-information {
  background-color: #ffffff;
  position: absolute;
  width: 267px;
  height: 164px;
  top: 11px;
  left: 473px;
  padding: 13px 17px;
  font-family: "Roboto", sans-serif;
  font-size: 17px;
  border-radius: 8px;
  box-shadow: 4px 4px 12px rgba(51, 51, 51, 0.3);

  &.with-selected-feature {
    top: 121px;
  }

  .header {
    font-weight: 300;
    padding-bottom: 7px;
    border-bottom: #cccccc 1px solid;
  }

  .section {
    margin-top: 12px;
    height: 42px;

    .section-header {
      font-family: "Roboto Condensed", sans-serif;
      font-size: 15px;
      font-weight: bold;
    }

    .section-body {
      margin-top: 4px;
      font-weight: 400;
    }
  }
}
