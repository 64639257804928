.component--parcel-tools {
  display: flex;
  align-items: center;
  padding: 18px;
  height: 118px;
  background: #FFFFFF 0% 0% no-repeat padding-box;

  .column {
    height: 100%;
    display: flex;
    flex-direction: column;

    .control-icon {
      flex: 1 1 auto;

      &:not(:first-child) {
        margin-top: 6px;
      }
    }

    &:not(:first-child) {
      margin-left: 13px;
    }
  }

  .component--map-style-button {
    width: 112px;
    height: 81px;
    border-radius: 7px;
    box-shadow: 0px 3px 6px #3A427666;
    background-size: cover;
  }

  .control-icon {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #3A427666;
    border-radius: 7px;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-position: center;
    width: 45px;
    height: 38px;
    display: flex;
    align-items: center;
    font: 500 20px/21px Roboto;
    justify-content: center;
    cursor: pointer;

    &.disabled {
      pointer-events: none;
    }

    &:hover,
    &.active {
      background-color: #3A4276;
    }
  }

  .select-parcel {
    background-image: url("./assets/images/parcel-tools-select-parcel.svg");

    &:hover,
    &.active {
      background-image: url("./assets/images/parcel-tools-select-parcel-hover.svg");
    }
  }

  .combine-parcels {
    background-image: url("./assets/images/parcel-tools-combine-parcels.svg");

    &:hover,
    &.active {
      background-image: url("./assets/images/parcel-tools-combine-parcels-hover.svg");
    }
  }

  .copy-parcel {
    background-image: url("./assets/images/parcel-tools-copy-parcel.svg");

    &:hover,
    &.active {
      background-image: url("./assets/images/parcel-tools-copy-parcel-hover.svg");
    }
  }

  .draw-parcel {
    background-image: url("./assets/images/parcel-tools-draw-parcel.svg");

    &:hover,
    &.active {
      background-image: url("./assets/images/parcel-tools-draw-parcel-hover.svg");
    }
  }

  .edit-parcel {
    background-image: url("./assets/images/parcel-tools-edit-parcel.svg");

    &:hover,
    &.active {
      background-image: url("./assets/images/parcel-tools-edit-parcel-hover.svg");
    }
  }

  .delete-parcel {
    background-image: url("./assets/images/parcel-tools-delete-parcel.svg");

    &:hover,
    &.active {
      background-image: url("./assets/images/parcel-tools-delete-parcel-hover.svg");
    }
  }

  .zoom-in {
    font-size: 25px;
    line-height: 25px;

    &::after {
      content: "+";
    }

    &:hover {
      color: #ffffff;
    }
  }

  .zoom-out {
    font: normal 44px/45px Roboto;
    line-height: 40px;

    &::after {
      content: "-";
    }

    &:hover {
      color: #ffffff;
    }
  }

  .rotate {
    background-image: url("./assets/images/icon-maps-rotate-blue.svg");

    &:hover {
      background-image: url("./assets/images/icon-maps-rotate-white.svg");
    }
  }

  .toggle-pitch {

    &:hover {
      color: #ffffff;
    }

    &.is-3d::after {
      content: "2D";
    }

    &.is-2d::after {
      content: "3D";
    }
  }

  .component--share-project,
  .component--copy-project,
  .component--new-project {
    height: 100%;
    padding: 14px 0 8px;
    justify-content: space-between;

    .icon {
      margin-bottom: 10px;
    }

    .text {
      font: 500 12px/13px Roboto;
      color: #000000;
    }

    &:hover,
    &.active {

      .text {
        color: #ffffff;
      }
    }
  }

  .component--share-project {

    .icon {
      background-image: url("assets/images/icon-share-project-black.svg");
    }

    &:hover,
    &.active {

      .icon {
        background-image: url("assets/images/icon-share-project-white.svg");
      }

      .text {
        color: #ffffff;
      }
    }
  }
}
