.layer-disclaimer-component {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.title-label {
  font: 500 11px/15px Roboto;
}

.text-label {
  font: 400 11px/15px Roboto;
}