.component--mapbox-geocoder {

  .geocoder-input {
    position: relative;
    display: flex;
    align-items: center;
    font: 500 16px/16px Roboto;

    input {
      color: #777777;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      height: 32px;
    }

    /* Suggestions */
    ul {
      box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
      background-color: #fff;
      border-radius: 0 0 3px 3px;
      left: 0;
      list-style: none;
      margin: 0;
      padding: 0;
      position: absolute;
      width: 100%;
      top: 100%;
      overflow: hidden;
      font-size: 12px;
      z-index: 100;
    }

    li {
      margin-bottom: 0;
      padding: 0px 0px;
    }

    .suggestion {
      clear: both;
      cursor: default;
      display: block;
      padding: 5px 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      color: #404040;

      &:hover {
        color: #202020;
        background-color: #f3f3f3;
        text-decoration: none;
        cursor: pointer;
      }
    }

    ul > li:last-child > .suggestion {
      border-bottom: none;
    }

    ul > li.active > .suggestion {
      color: #202020;
      background-color: #e3e3e3;
      text-decoration: none;
      cursor: pointer;
    }

    .geocoder-icon {
      position: absolute;
      background-repeat: no-repeat;
      cursor: pointer;
    }

    .geocoder-icon-search {
      top: 7px;
      right: 8px;
      width: 15px;
      height: 17px;

      &.active {
        background-image: url("assets/images/icon-search-red.svg");
        pointer-events: all;
      }

      &.inactive {
        cursor: inherit;
        background-image: url("assets/images/icon-search-gray.svg");
        pointer-events: none;
      }
    }

    .geocoder-icon-close {
      top: 10px;
      right: 10px;
      width: 10px;
      height: 11px;
      background-image: url("assets/images/icon-cross-out.svg");
      background-color: white;
    }
  }
}
