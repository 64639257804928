@import "./DeleteInvitationPopup/styles";
@import "./CancelInvitationPopup/styles";
@import "./ReinvitePopup/styles";

.component--team-member-settings {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  padding: 30px 40px;

  .header {
    font: 700 16px/19px Roboto;
    color: #3A4276;
    text-transform: capitalize;
    border-bottom: 1px solid #3A4276;
    padding: 3px;
  }

  .information-container {
    display: flex;
    margin: 15px 0 60px;

    .thumbnail-container {
      display: flex;
      flex-direction: column;
      width: 160px;
      height: 160px;
      margin-right: 80px;
      align-items: center;
      justify-content: space-evenly;

      .thumbnail {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 5px;
        box-shadow: 0px 3px 6px #0000001A;
      }
    }

    .selected-name {
      font: 400 14px/16px Roboto;
      color: #333333;

      .bold {
        font-weight: 700;
        font-size: 16px;
        margin-bottom: 5px;
      }
    }

    .user-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .subscription-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 450px;
      margin-left: 80px;

      .top {
        display: flex;
      }

      .bottom {

        span {
          margin-left: 5px;
          font: 700 14px/14px Roboto;
          color: #3A4276;
        }

        .bar {
          height: 6px;
          background-color: #D5D5E0;
          border-radius: 3px;
          margin-top: 8px;

          &.active {
            position: relative;
            top: -14px;
            background-color: #3A4276;
            box-shadow: 0px 3px 8px #3A427666;
          }
        }
      }

      .section {
        width: 180px;
        font: 700 14px/14px Roboto;

        &:first-child {
          margin-right: 120px;
        }

        .section-header {
          color: #3A4276;
          border-bottom: 2px solid #D5D5E0;
          padding: 5px;
        }

        .section-content {
          display: flex;
          color: #333333;
          text-transform: capitalize;
          padding: 5px;
        }
      }
    }
  }

  .marker {
    height: 13px;
    width: 13px;
    border-radius: 7px;
    margin-right: 5px;

    &.pending {
      background-color: #F9CB58;
    }

    &.active {
      background-color: #5FB217;
    }

    &.cancelled {
      background-color: #F26C6C;
    }
  }

  .list-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-shadow: 0px 10px 20px #0000001A;
    border-radius: 5px;
    overflow: hidden;

    .bottom {
      width: 100%;
      height: 30px;
      margin-top: 10px;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px -1px 2px #0000001A;
      border-radius: 0px 0px 7px 7px;
    }

    .rows-container {
      display: flex;
      flex-direction: column;
      overflow: auto;
    }

    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 52px;
      text-align: center;
      font: 400 12px/12px Roboto;
      color: #464A53;
      margin-left: 20px;
      margin-right: 20px;
      flex-shrink: 0;

      &:not(:last-child) {
        border-bottom: 1px solid #DDDFE1;
      }

      .check {
        display: flex;
        justify-content: center;
        width: 61px;

        .checkmark {
          width: 21px;
          height: 21px;
          box-shadow: 0px 3px 6px #0000001A;
          border-radius: 2px;
          cursor: pointer;

          &.selected {
            background-color: #3A4276;
            background-image: url("assets/images/icon-check-active.svg");
            background-position: center;
            background-repeat: no-repeat;
          }
        }
      }

      .name {
        width: 180px;
      }

      .email {
        width: 180px;
      }

      .subscription {
        display: flex;
        width: 170px;
        text-transform: capitalize;
        padding-left: 45px;

        .marker {
          margin-right: 8px;
        }
      }

      .renewal {
        width: 170px;
      }

      .since {
        position: relative;
        width: 170px;

        .delete {
          position: absolute;
          top: -5px;
          right: 13px;
          width: 0;
          height: 0;
          background-image: url("assets/images/icon-trash-can-red.svg");
          background-position: center;
          background-repeat: no-repeat;
          cursor: pointer;
        }
      }

      &.selected, &:hover {
        background-color: #E2E5E8;

        .since {

          .delete {
            width: 15px;
            height: 17px;
          }
        }
      }

      &.top {
        height: 35px;
        background: #EFEFF4 0% 0% no-repeat padding-box;
        border: 1px solid #DDDFE1;
        font: 400 14px/14px Roboto;
        color: #3A4276;
        margin: 0 0 10px 0;
        padding: 0 20px;

        .subscription {
          justify-content: center;
          padding: 0;
        }
      }
    }
  }
}
