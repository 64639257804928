.component--recalculate-popup {
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 550px;
  padding: 37px;
  color: #333333;

  p {
    font-size: 15px;
  }

  .section-header {
    font: Bold 19px/23px Roboto Condensed;
    letter-spacing: 0;
    opacity: 1;
  }

  .bold {
    font-weight: bold;
  }

  .far-container {
    margin-bottom: 15px;

    input {
      border: 2px solid #CCCCCC;
      border-radius: 8px;
      width: 100px;
      height: 37px;
      margin: 0 10px;
      text-align: center;
      font-size: 15px;
      font-family: "Roboto", sans-serif;

      &:focus,
      &:hover {
        outline: none;
        outline-offset: 0;
      }
    }
  }

  .buttons-container {
    display: flex;
    justify-content: center;
    margin-top: 25px;

    button {
      flex-shrink: 0;
      flex-grow: 0;
      padding: 0;
      width: 115px;
      height: 32px;
      border-radius: 16px;
      font: Bold 13px/21px Roboto;

      &:first-child {
        margin-right: 11px;
      }

      &.disabled {
        background: #CCCCCC;
        cursor: none;
      }

      &.continue {
        width: 200px;
        background: #F65959 0% 0% no-repeat padding-box;

        &:hover {
          background-color: #3A4276;
        }
      }

      &.cancel {
        color: #F26C6C;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 2px solid #F26C6C;

        &:hover {
          background-color: #F65959;
          color: white;
        }
      }
    }
  }
}
