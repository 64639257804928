.dynamic-layer-button {
  margin-top: 15px;
  margin-right: 10px;
  align-items: center;
  width: 90px;
  min-height: 35px;
  background: #fff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #3A427666;
  border-radius: 5px;
  cursor: pointer;
  font: 400 12px/13px Roboto;
  color: #333333;
  padding: 5px;
  text-align: center;

  &.enabled {
    background: #3A4276;
    color: #ffffff;
  }

  &:hover {
    background: #3A4276;
    color: #ffffff;
  }
}