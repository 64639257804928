.component--geocoder-search {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  .logo {
    width: 600px;
    height: 239px;
    background-image: url("assets/images/deepblocks-blue-logo-vertical.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 20px;
  }

  .geocoder-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
  }

  .count-container {
    margin-top: 18px;
  }

  .component--mapbox-geocoder {
    border: 3px solid #2691F3;
    border-radius: 29px;
    margin-bottom: 50px;
    box-shadow: 0px 3px 6px #0000000D;

    .geocoder-icon-search {
      opacity: 0;
    }

    .geocoder-icon-close {
      background-color: transparent;
      top: 21px;
      right: 18px;
      width: 13px;
      height: 14px;
    }

    input {
      width: 700px;
      height: 58px;
      padding: 17px 46px;
      color: #999999;
      font: 500 16px/16px Poppins;
      letter-spacing: 0.4px;
    }
  }

  .tagline-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 80px;
  }

  .tagline-label {
    text-align: center;
    font: 600 34px/20px Poppins;
    color: #2691F3;

    &.bold {
      color: #CA68F9;
      font: 700 34px/20px Poppins;
    }
  }

  .error-container {

    p {
      font: 300 13px/20px Roboto;
      font-style: italic;
      color: #F26C6C;
      margin-top: -40px;
      margin-bottom: 20px;
    }
  }

  .component--redirect-button {
    color: #FFFFFF;
    background-image: linear-gradient(to right, rgba(38,145,243,1) , rgba(202,104,249,1));
    height: 54px;
    width: 242px;
    border-radius: 27px;
    font: Bold 21px/34px Roboto;
    letter-spacing: 0.21px;
    padding: 0;
    margin: 0;
    border: none;

    &:hover {
      background-color: #2C3258;
    }
  }
}
