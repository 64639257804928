.component--to-contact-details {
  $toColor: #59608D;
  display: flex;
  align-items: center;
  margin-top: 25px;

  .label-container {
    height: 68px;
    border-right: 1px solid $toColor;

    label {
      font: Bold 15px Roboto;
      text-transform: uppercase;
      margin: 8px 0 0 0;
      color: $toColor;
    }

    .contact-icon {
      width: 28px;
      height: 28px;
      background: url("assets/images/icon-contact-blue.svg") no-repeat;
      background-size: contain;
    }
  }

  .inputs-container {
    $border-radius: 3px;
    width: 100%;
    flex-shrink: 1;

    input {
      width: 100%;
      height: 21px;
      border: 1px solid #CCCCCC;
      box-shadow: 2px 2px 5px #0000000D;
      margin-bottom: 4px;
      border-radius: $border-radius;
      padding-left: 8px;
      font: normal 12px/12px Roboto;
      text-transform: capitalize;
      outline: none;

      &::placeholder {
        color: #ABAFB3;
        text-transform: capitalize;
      }
    }

    .with-label {
      display: flex;

      & * {
        flex-shrink: 1;
      }

      .input-label {
        width: 21px;
        height: 21px;
        border-radius: $border-radius 0 0 $border-radius;
        background-repeat: no-repeat;
        background-size: 7px 12px;
        background-position: center;

        &.phone-icon {
          background-color: $toColor;
          background-image: url("assets/images/icon-phone-white.svg");
        }

        &.email-icon {
          background-color: $toColor;
          background-image: url("assets/images/icon-at-white.svg");
        }
      }

      input {
        border-radius: 0 $border-radius $border-radius 0;
        border-left: 0;
      }
    }

    .email {
      text-transform: none;
    }

    .phone-container {

      input {
        margin-bottom: 0;
      }
    }
  }
}
