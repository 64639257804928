td.component--cell {
  text-align: left;


  &.bold {
    font-weight: bold;
  }

  &.aligned-to-right {
    text-align: right;
    font-weight: bold;
  }

  &.blurry-text,
  .blurry-text {
    color: transparent;
    text-shadow: 0 0 8px rgba(0,0,0,0.5);
    user-select: none;
    pointer-events: none;
  }

  &.justify-left {
    text-align: left;
  }

  .value,
  .array-item {
    white-space: pre-line;
  }
}
