.component-verify-email {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 28px 0;

  .icon {
    width: 220px;
    height: 189px;
    background-image: url("assets/images/icon-email-verification.png");
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: 60px;
  }

  .content-wrapper {
    margin-top: 58px;
  }

  .text-box {
    text-align: center;
    font: 400 13px/20px Roboto;
    color: #777777;

    .bold {
      font-weight: 700;
      color: #3A4276;
    }

    .underlined-link {
      font-weight: 700;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .footer {
    width: 80%;
    margin-top: 65px;
    display: flex;
    justify-content: space-between;

    .text-box {
      font-size: 12px;
    }
  }

  button {
    height: 38px;
    width: 100%;
    background-color: #3A4276;
    border-radius: 3PX;
    margin-top: 24px;
    font-size: 16px;
    font-weight: 500;
    padding: 10px 30px;
    box-shadow: 0px 4px 10px #00000040;
  }
}
