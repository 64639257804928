.component--property-uses {
  width: 100%;

  .component--tabs {

    .component--tab-container {
      background: #FFFFFF;
      align-items: flex-end;
      flex-wrap: wrap;
      padding-bottom: 0;
      padding-left: 5px;
    }

    .component--tab {
      cursor: pointer;
      height: auto;
      width: auto;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      font: 400 12px/8px Roboto;
      flex-direction: column;
      box-shadow: none;

      &.active {
        color: inherit;

        .icon {
          width: 73px;
          height: 59px;
        }

        label {
          margin-top: 4px;
        }
      }

      &:first-child {
        margin-left: 13px;
      }

      label {
        margin-top: 6px;
        text-transform: capitalize;
        cursor: pointer;
      }

      .icon {
        width: 50px;
        height: 46px;
        box-shadow: 0px 3px 6px #0000001a;
        cursor: pointer;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 28px 26px;
        border-radius: 5px;

        &.condo {
          background-image: url("assets/images/icon-condo-white.svg");
        }

        &.multifamily {
          background-image: url("assets/images/icon-multifamily-white.svg");
        }

        &.hotel {
          background-image: url("assets/images/icon-hotel-white.svg");
        }

        &.office {
          background-image: url("assets/images/icon-office-white.svg");
        }

        &.industrial {
          background-image: url("assets/images/icon-industrial-white.svg");
        }

        &.retail {
          background-image: url("assets/images/icon-retail-white.svg");
        }

        &.parking {
          background-image: url("assets/images/icon-parking-white.svg");
        }
      }
    }
  }
}
