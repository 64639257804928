@import "./InvitationsButton/InvitationsPopup/styles";

.component--button {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  padding: 14px;
  width: 70px;
  height: 70px;
  box-shadow: 3px 3px 6px #00000033;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 16px;

  .button {
    width: 26px;
    height: 26px;
    margin-bottom: 6px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("assets/images/icon-demo-inactive.svg");
  }

  .text {
    font: 400 12px/12px Roboto;
    color: #3a4276;
  }

  &.demo {
    .button {
      background-image: url("assets/images/icon-demo-inactive.svg");
    }

    &:hover,
    &.active {
      .button {
        background-image: url("assets/images/icon-demo-active.svg");
      }
    }
  }

  &.login {
    .button {
      background-image: url("assets/images/icon-log-in-inactive.svg");
    }

    &:hover,
    &.active {
      .button {
        background-image: url("assets/images/icon-log-in-active.svg");
      }
    }
  }

  &.sign-up {
    .button {
      background-image: url("assets/images/icon-toolbar-sign-up-inactive.svg");
    }

    &:hover,
    &.active {
      .button {
        background-image: url("assets/images/icon-toolbar-sign-up-active.svg");
      }
    }
  }

  &.log-out {
    .button {
      background-image: url("assets/images/icon-log-out-inactive.svg");
    }

    &:hover,
    &.active {
      .button {
        background-image: url("assets/images/icon-log-out-active.svg");
      }
    }
  }

  &.dashboard {
    .button {
      background-image: url("assets/images/icon-dashboard-inactive.svg");
    }

    &:hover,
    &.active {
      .button {
        background-image: url("assets/images/icon-dashboard-active.svg");
      }
    }
  }

  &.tiers {
    .button {
      background-image: url("assets/images/icon-tiers-inactive.svg");
    }

    &:hover,
    &.active {
      .button {
        background-image: url("assets/images/icon-tiers-active.svg");
      }
    }
  }

  &.invitations {
    position: relative;

    .number {
      position: absolute;
      top: -10px;
      left: -10px;
      height: 25px;
      width: 25px;
      border-radius: 13px;
      background-color: #f26c6c;
      font: 700 16px/25px Roboto;
      text-align: center;
      color: #ffffff;
    }

    .button {
      background-image: url("assets/images/icon-bell-inactive.svg");
    }

    &:hover,
    &.active {
      .button {
        background-image: url("assets/images/icon-bell-active.svg");
      }
    }
  }

  &:hover,
  &.active {
    background-color: #3a4276;

    .text {
      color: #ffffff;
    }
  }
}
