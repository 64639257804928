button {
  outline: none;
  background: #F26C6C;
  transition: background-color 0.2s;
  color: #F5F7F8;
  font-size: 15px;
  border-radius: 7px;
  height: 32px;
  border: 0px;
  padding: 0;
  box-shadow: none;
  cursor: pointer;

  &.disabled {
    pointer-events: none;
    background: #CCCCCC;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: #3A4276;
  }
}

.btn {
  outline: none !important;
  transition: background-color 0.2s;
  background-color: transparent;
  color: #666666;
  min-width: 83px;
  min-height: 32px;
  padding: 0;
  border-radius: 16px;
  border: 1px solid #999999;
  user-select: none;
  display: inline-block;
  max-width: 90%;
  font-weight: bold;
  margin: auto 11px;
  cursor: pointer;

  &:hover {
    border-color: #F26C6C;
  }
}

.btn:focus {
  outline: none;
  box-shadow: none;
}

.btn:hover:before,
.btn:focus:before,
.btn:active:before {
  color: #fff;
}

.btn-signin {
  line-height: 21px;
  color: #00aeea;

  &:hover {
    color: #fff;
  }
}

.btn-transparent,
.btn-transparent-disabled {
  padding: 12px 18px;
  position: relative;
  overflow: hidden;
  vertical-align: middle;
  background-color: transparent;
  transition: background-color 0.3s;
}

.btn-transparent {
  border: 2px solid #00aeea;
  color: #00aeea;
}

.btn-transparent-disabled {
  border: 2px solid #a7aeb1;
  color: #a7aeb1;
}

.btn-transparent:hover,
.btn-transparent:focus,
.btn-transparent:active {
  color: #00aeea;
  background-color: #fff;
}

.btn-gray {
  background-color: #f1f5f7;
  font-weight: 500;
  color: #00aeea;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.btn-gray:hover {
  background-color: #fb6d57;
  color: #fff;
}

.btn-notification {
  background-color: #FB6D57;
  height: 50px;
  width: 50px;
}
