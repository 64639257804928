.component--subscribe {
  position: relative;
  padding: 13px 17px 0;
  overflow: scroll;

  .field {
    outline: none;
    height: 61px;
    background: #F1F5F7 0% 0% no-repeat padding-box;
    border: 1px solid #EEEEEE;
    margin-bottom: 21px;
    font: normal 14px/14px Roboto;
    padding: 0 18px;
    display: flex;
    align-items: center;
    overflow: hidden;

    .CardForm {
      background-color: red;
    }

    &::placeholder {
      color: #BDBDBD,
    }
  }

  .input-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 21px;

    .field {
      flex: 1 1 50%;
      margin-bottom: 0;
    }

    .error {

      &::placeholder {
        color: #F26C6C;
      }
    }

    label {
      margin: 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex: 1 1 50%;
      color: #BDBDBD;
    }

    .field:nth-child(2) {
      margin-left: 32px;
    }

    discount-input {
      text-transform: uppercase;
    }
  }

  .divisor {
    border-top: 1px solid #EFEFEF;
    margin: 22px 0;
  }

  .error-container {
    color: #F66C6C;
    font: 100 10px/11px Roboto;
    font-style: italic;
    text-align: center;
  }

  .prices-container {
    position: relative;

    .fetching-coupon-message {
      position: absolute;
      background-color: #fff;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .price-row {
      display: flex;
      justify-content: flex-end;
      color: #1A1824;
      font: normal 14px/14px Roboto;

      .price {
        width: 200px;
        text-align: right;
      }

      &.discount {
        color: #F26C6C;
      }

      &.final-price {
        color: #3A4276;
        font: bold 18px/24px Roboto;
      }
    }
  }

  .initial-spinner {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    z-index: 2;
  }

  .submit {
    width: 450px;
    height: 61px;
    background: #3A4276 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 10px #00000040;
    border-radius: 3px;
    margin-bottom: 20px;
  }
}
