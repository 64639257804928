@import "./DashboardSidebar/styles";
@import "./DashboardContent/styles";

.component--dashboard-page {
  display: grid;
  grid-template-columns: minmax(1147px, 1fr) 250px;
  grid-template-rows: 50px auto;
  grid-template-areas:
    "toolbar toolbar"
    "content sidebar";
  height: 100%;
  overflow: auto;
  background-color: #F6F6F6;
  color: #777777;
  font-weight: 400;
  font-size: 12.5px;

  .component--sidebar {
    grid-area: sidebar;
  }

  .component--dashboard-toolbar {
    grid-area: toolbar;
  }

  .component--dashboard-content {
    grid-area: content;
  }
}
