@import "./ShareProjectPopup/styles";

.component--share-project {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  .icon {
    width: 18px;
    height: 18px;
    margin-bottom: 3px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("assets/images/icon-share-project-inactive.svg");
  }

  .text {
    font: 500 8px/8px Roboto;
    color: #3A4276;
    text-align: center;
  }

  &:hover,&.active {
    .icon {
      background-image: url("assets/images/icon-share-project-active.svg");
    }

    .text {
      color: #4ACBC6;
    }
  }
}
