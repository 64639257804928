.component--demo-onboarding {
  position: fixed;
  bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 900;

  .process {
    display: flex;
    justify-content: space-around;
    background-image: url("assets/images/demo-onboarding-background.jpg");
    box-shadow: 3px 3px 6px #00000040;
    border-radius: 10px;
    width: 580px;
    height: 100px;
  }

  .step-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90px;
    height: 100px;

    .icon {
      width: 32px;
      height: 32px;
      margin: 15px 0;

      &.step-one {
        background-image: url("assets/images/icon-demo-onboarding-step-one.svg");
      }

      &.step-two {
        background-image: url("assets/images/icon-demo-onboarding-step-two.svg");
      }

      &.step-three {
        background-image: url("assets/images/icon-demo-onboarding-step-three.svg");
      }

      &.step-four {
        background-image: url("assets/images/icon-demo-onboarding-step-four.svg");
      }

      &.step-five {
        background-image: url("assets/images/icon-demo-onboarding-step-five.svg");
      }
    }

    .label {
      margin: 0px;
      text-align: center;
      font: 14px/20px Roboto;
      font-weight: normal;
      color: #D5D5E0;
    }

    &.active {
      .icon {
        width: 40px;
        height: 40px;
        margin: 10px 0;

        &.step-one {
          background-image: url("assets/images/icon-demo-onboarding-step-one-active.svg");
        }

        &.step-two {
          background-image: url("assets/images/icon-demo-onboarding-step-two-active.svg");
        }

        &.step-three {
          background-image: url("assets/images/icon-demo-onboarding-step-three-active.svg");
        }

        &.step-four {
          background-image: url("assets/images/icon-demo-onboarding-step-four-active.svg");
        }

        &.step-five {
          background-image: url("assets/images/icon-demo-onboarding-step-five-active.svg");
        }
      }

      .label {
        font: Bold 14px/20px Roboto;
        color: #3A4276;
        min-width: 100px;
      }
    }
  }
}
