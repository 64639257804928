@import "./DisplayInformation/styles";

.component--account-information {
  margin-bottom: 30px;

  .content {
    padding: 20px 0;

    .information-container {
      display: flex;

      .thumbnail-container {
        display: flex;
        flex-direction: column;
        width: 160px;
        height: 160px;
        margin-right: 40px;
        align-items: center;
        justify-content: space-evenly;

        .thumbnail {
          width: 100%;
          height: 100%;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          border-radius: 5px;
          box-shadow: 0px 3px 6px #0000001A;

          &.edit {
            width: 128px;
            height: 128px;
          }
        }

        .text {
          text-align: center;
          text-decoration: underline;
          font: Regular 12px/42px Roboto;
          letter-spacing: 0px;
          color: #3A4276;
          cursor: pointer;
        }
      }

      .data-container {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .name {
          font: 400 14px/16px Roboto;
          color: #333333;

          .bold {
            font-weight: 700;
            font-size: 16px;
            margin-bottom: 5px;
          }
        }

        .subscription-container {
          display: flex;
          justify-content: space-between;

          .section {
            width: 130px;
            font: 700 14px/14px Roboto;

            .section-header {
              color: #3A4276;
              border-bottom: 2px solid #D5D5E0;
              padding: 5px;
            }

            .section-content {
              display: flex;
              color: #333333;
              padding: 5px;

              .marker {
                height: 13px;
                width: 13px;
                border-radius: 7px;
                margin-right: 5px;

                &.none, &.cancelled {
                  background-color: #F9CB58;
                }

                &.active, &.non_renewing {
                  background-color: #5FB217;
                }
              }
            }
          }
        }
      }
    }

    .buttons-wrapper {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;

      .space {
        width: 160px;
      }
    }
  }
}
