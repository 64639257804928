.component-react-tooltip {
  // !important needed to override default
  opacity: 1 !important;
  margin-left: 63px !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  padding: 8px 10px !important;

  $triangleHeight: 85px;
  $triangleWidth: 85px;
  $coverWidth: 20px;

  &::before {
    background-image: url("assets/images/tooltip-triangle.svg");
    background-repeat: no-repeat;
    background-size: contain;
    border: none !important;
    width: $triangleHeight !important;
    height: $triangleWidth !important;
    margin: 0 !important;
    top: 155px !important;
    left: -60px !important;
  }

  &::after {
    top: 161px !important;
    left: 0px !important;
    border: none !important;
    width: $coverWidth !important;
    height: $triangleHeight !important;
    background-color: #fff;
  }

  &.no-margin {
    margin: 0 !important;
  }

  &.left {
    margin-left: -50px !important;

    &::before {
      transform: rotate(180deg);
      left: unset !important;
      right: -60px !important;
    }

    &::after {
      left: unset !important;
      right: 0 !important;
    }
  }
}

.component--tooltip {
  width: 325px;
  height: 377px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 14px 2px 0;

  a {
    color: #3A4276;
  }

  .image {
    width: 230px;
    height: 152px;
    margin-bottom: 26px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    &.tooltip-smart-search {
      background-image: url("assets/images/tooltip-smart-search.png");
    }

    &.tooltip-back-of-envelope {
      background-image: url("assets/images/tooltip-boe-panel.png");
    }

    &.tooltip-dashboard {
      background-image: url("assets/images/tooltip-dashboard.png");
    }

    &.tooltip-information-panel {
      background-image: url("assets/images/tooltip-information-panel.png");
    }

    &.tooltip-pdf {
      background-image: url("assets/images/tooltip-pdf-panel.png");
    }

    &.tooltip-layer-selection-panel {
      background-image: url("assets/images/tooltip-layer-selection.png");
    }

    &.tooltip-market-insight-panel {
      background-image: url("assets/images/tooltip-graph.png");
    }
  }

  .header {
    font: 16px/18px Roboto;
    letter-spacing: 0.32px;
    color: #3A4276;
    text-transform: uppercase;

    &.thin {
      font-weight: 100;
    }

    &.bold {
      font-weight: bold;
    }
  }

  .content {
    text-align: center;
    font: 100 14px/18px Roboto;
    letter-spacing: 0.24px;
    color: #999999;
    margin-top: 14px;
    white-space: pre-wrap;
  }

  .btn {
    width: 124px;
    height: 24px;
    min-height: 24px;
    color: #fff;
    padding: 0;
    background-color: #3A4276;
    border-radius: 5px;
    margin: 26px 0 0;
    font: normal 13px/100% Roboto;
    letter-spacing: 0.26px;
  }

  .not-now {
    text-decoration: underline;
    font: normal 10px/10px Roboto;
    letter-spacing: 0.2px;
    color: #999999;
    text-transform: capitalize;
    margin: 8px 0 0;
    cursor: pointer;
  }
}
