.component--setback-tools {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 18px 18px 0 18px;

  .column {
    height: 100%;
    display: flex;
    flex-direction: column;

    .control-icon {
      flex: 1 1 auto;

      &:not(:first-child) {
        margin-top: 6px;
      }
    }

    &:not(:first-child) {
      margin-left: 13px;
    }
  }

  .control-icon {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #3a427666;
    border-radius: 7px;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-position: center;
    width: 45px;
    height: 38px;
    display: flex;
    align-items: center;
    font: 500 20px/21px Roboto;
    justify-content: center;
    cursor: pointer;

    &.disabled {
      pointer-events: none;
    }

    &:hover,
    &.active {
      background-color: #3a4276;
    }
  }

  .zoom-in {
    font-size: 25px;
    line-height: 25px;

    &::after {
      content: "+";
    }

    &:hover {
      color: #ffffff;
    }
  }

  .zoom-out {
    font: normal 44px/45px Roboto;
    line-height: 40px;

    &::after {
      content: "-";
    }

    &:hover {
      color: #ffffff;
    }
  }

  .visibility-box {
    display: flex;
    justify-content: left;
    margin-top: 20px;
  }

  .check {
    display: flex;
    justify-content: center;
    width: 150px;
    margin-right: 15px;

    .checkmark {
      width: 18px;
      height: 18px;
      box-shadow: 0px 3px 6px #3a427666;
      border-radius: 2px;
      cursor: pointer;

      &.selected {
        background-color: #3a4276;
        background-image: url("assets/images/setback-check-active.svg");
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }

  .options-label {
    font: 500 13px Roboto;
    line-height: 20px;
    color: #333333;
    margin-left: 5px;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 3px 6px #0000001a;
    border-radius: 7px;
    flex: 1 1 auto;
    padding: 10px 25px;
    height: 82px;
  }

  .setback-list-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 14px 0 0 0;
  }

  .setback-row {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 14px;
    margin-bottom: 7px;
  }

  .header {
    font: 900 14px/14px Roboto;
    color: #3a4276;
  }

  .text {
    font: 400 12px/14px Roboto;
    color: #666666;
    float: left;
  }

  input[type="radio"] {
    cursor: pointer;
    position: relative;
    float: right;
    border-radius: 50%;
    margin-right: 9px;
    border: 2px solid #e3e5e8;
    width: 13px;
    height: 13px;
    outline: none;

    &:checked {
      background-color: #f26c6c;
      box-shadow: inset 0px 0px 0px 1px #ffffff;
      width: 13px;
      height: 13px;
    }
  }
}
