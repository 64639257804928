.component--account-links {

  .icon-billing {
    background-image: url("assets/images/icon-sidebar-billing-inactive.svg");
  }

  .icon-subscribe {
    background-image: url("assets/images/icon-sidebar-subscription-inactive.svg");
  }

  .side-bar-link {

    .number {
      position: relative;
      top: -12px;
      height: 25px;
      width: 25px;
      border-radius: 13px;
      background-color: #F26C6C;
      font: 700 16px/25px Roboto;
      text-align: center;
      color: #FFFFFF;
    }

    &:hover {

      .icon-billing {
        background-image: url("assets/images/icon-sidebar-billing-active.svg");
      }

      .icon-subscribe {
        background-image: url("assets/images/icon-sidebar-subscription-active.svg");
      }
    }
  }
}
