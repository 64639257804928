.component--slider-box {
  $border-color: #8E8E8E;
  $input-color: #13a7da;
  $font-color: #666666;
  $disabled-color: rgba(204, 204, 204, 1);
  $border-width: 1px;
  $box-height: 73px;
  height: $box-height;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  border-top: $border-width solid $border-color;

  &.disabled {
    pointer-events: none;

    ::placeholder {
      color: $disabled-color;
    }

    .slider-title {
      color: $disabled-color;
    }

    .slider-row-value {

      input {

        &::placeholder {
          color: $disabled-color;
        }
      }
    }

    .rc-slider-rail {
      background-color: #E9EAEF;
    }
  }

  &:first-child {
    border-top: none;
  }

  &:last-child {
    border-bottom: $border-width solid $border-color;
    height: $box-height + $border-width;
  }

  .slider-title {
    text-align: left;
    padding: 8px 15px 0px 0px;
    color: $font-color;
    width: 100%;
    font: 400 12px/14px Roboto;
  }

  .read-only-slider-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
    padding: 8px 15px 0px 0px;
    color: $font-color;
    width: 100%;
    font: 400 12px/14px Roboto;
  }

  .slider-inner-wrapper {
    background-color: #FFFFFF;
    border: 1px solid #E0E1E9;
    border-radius: 10px;
    width: 100%;
    height: 15px;
    padding-top: 0px;
    padding-left: 4px;
    padding-right: 4px;
    margin: 2px 0px 0px 0px;
  }

  .slider-limits-wrapper {
    display: flex;
    margin-top: 7px;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }

  .slider-row-limits {
    width: 50%;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #7d7d7d;
    white-space: pre-wrap;
    font: 400 12px/14px Roboto;

    input {
      padding-bottom: 3px;
      padding-top: 3px;
      height: 16px;
      width: 100px;
      background: #E0E1E9 0% 0% no-repeat padding-box;
      border-radius: 8px;

      &:focus {
        outline: 1px solid $input-color;
        outline-offset: 0;
      }
    }

    &:hover > input {

      &::placeholder {
        color: $input-color;
      }
    }

    //For min limit
    &:first-child {
      justify-content: flex-start;

      input {
        padding-left: 5px;
        text-align: left;
      }
    }

    //For max limit
    &:last-child {
      justify-content: flex-end;

      input {
        padding-right: 15px;
        text-align: right;
      }
    }
  }

  .slider-row-body {
    width: 268px;

    .toggle-field-wrapper {
      align-items: center;
      display: flex;
      width: 100%;
      height: 100%;
      color: $font-color;

      .toggle-container {
        align-items: center;
        align-content: flex-start;
        padding-left: 0px;

        .toggle-box {
          order: 2;
          margin-left: 26px;
          margin-bottom: 0;
        }

        .toggle-label {
          margin-bottom: 0;
          order: 1;
          font-size: 12px;
        }
      }
    }
  }

  .slider-row-value {
    padding: 1px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1 1 0;
    white-space: pre-wrap;

    input {
      background: #E9EAEF 0% 0% no-repeat padding-box;
      border-radius: 13px;
      width: 112px;
      height: 27px;
      font-size: 14px;

      &::placeholder {
        color: #333333;
      }

      &:hover {

        &::placeholder {
          color: $input-color;
        }
      }

      &:focus {
        outline: 1px solid $input-color;
        outline-offset: 1px;
      }
    }
  }

  input[type=submit] {
    display: none;
  }

  input {
    text-align: center;
    border: none;
    color: $input-color;
    font-size: 12px;
    width: 80%;

    ::placeholder {
      text-align: center;
      color: #333333;
    }
  }
}

.rc-slider-wrapper {
  background-color: #f5f8f9;
  border-radius: 10px;
  height: 16px;
  margin: 4px auto;
}

.rc-slider {
  position: relative;
  height: 12px;
  padding: 5px 0;
  width: 100%;
  border-radius: 6px;
  -ms-touch-action: none;
  touch-action: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rc-slider * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rc-slider-rail {
  position: absolute;
  width: 100%;
  background-color: #ACACAC;
  height: 6.4px;
  border-radius: 6px;
  top: 0px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto 0;
}

.rc-slider-track {
  position: absolute;
  left: 0;
  height: 4px;
  border-radius: 6px;
  background-color: #abe2fb;
  top: 0px;
  bottom: 0;
  height: 7px;
  left: 0;
  right: 0;
  margin: auto 0;
}

// For the sliders not to move when the handle is clicked the left margin
// needs to be half the width and negative. More info at:
// https://github.com/react-component/slider/issues/433
.rc-slider-handle {
  $handle-width: 10px;
  position: absolute;
  margin-left: -1 * $handle-width;
  margin-top: -8.5px;
  width: 2*$handle-width;
  height: 2*$handle-width;
  cursor: -webkit-grab;
  cursor: grab;
  border-radius: 50%;
  border: 4px solid #FFFFFF;
  box-shadow: 1.5px 1px 6px rgba(0, 0, 0, 0.5);
  transform: none !important;

  &:active {
    cursor: grabbing;
  }

  &:focus {
    outline: none;
  }
}

.rc-slider-mark {
  position: absolute;
  top: 18px;
  left: 0;
  width: 100%;
  font-size: 12px;
}

.rc-slider-mark-text {
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: #999;
}

.rc-slider-mark-text-active {
  color: #666;
}

.rc-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent;
}

.rc-slider-dot {
  position: absolute;
  bottom: -2px;
  margin-left: -4px;
  width: 8px;
  height: 8px;
  border: 2px solid #e9e9e9;
  background-color: #fff;
  cursor: pointer;
  border-radius: 50%;
  vertical-align: middle;
}

.rc-slider-dot:first-child {
  margin-left: -4px;
}

.rc-slider-dot:last-child {
  margin-left: -4px;
}

.rc-slider-dot-active {
  border-color: #96dbfa;
}

.rc-slider-vertical {
  width: 14px;
  height: 100%;
  padding: 0 5px;
}

.rc-slider-vertical .rc-slider-rail {
  height: 100%;
  width: 4px;
}

.rc-slider-vertical .rc-slider-track {
  left: 5px;
  bottom: 0;
  width: 4px;
}

.rc-slider-vertical .rc-slider-handle {
  margin-left: -5px;
  margin-bottom: -7px;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.rc-slider-vertical .rc-slider-mark {
  top: 0;
  left: 18px;
  height: 100%;
}

.rc-slider-vertical .rc-slider-step {
  height: 100%;
  width: 4px;
}

.rc-slider-vertical .rc-slider-dot {
  left: 2px;
  margin-bottom: -4px;
}

.rc-slider-vertical .rc-slider-dot:first-child {
  margin-bottom: -4px;
}

.rc-slider-vertical .rc-slider-dot:last-child {
  margin-bottom: -4px;
}

.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  -webkit-animation-duration: .3s;
  animation-duration: .3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  display: block !important;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.rc-slider-tooltip-zoom-down-leave {
  -webkit-animation-duration: .3s;
  animation-duration: .3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  display: block !important;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active,
.rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
  -webkit-animation-name: rcSliderTooltipZoomDownIn;
  animation-name: rcSliderTooltipZoomDownIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
  -webkit-animation-name: rcSliderTooltipZoomDownOut;
  animation-name: rcSliderTooltipZoomDownOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  -webkit-transform: scale(0, 0);
  transform: scale(0, 0);
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}

.rc-slider-tooltip-zoom-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

@-webkit-keyframes rcSliderTooltipZoomDownIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scale(0, 0);
    transform: scale(0, 0);
  }

  100% {
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

@keyframes rcSliderTooltipZoomDownIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scale(0, 0);
    transform: scale(0, 0);
  }

  100% {
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

@-webkit-keyframes rcSliderTooltipZoomDownOut {
  0% {
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  100% {
    opacity: 0;
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scale(0, 0);
    transform: scale(0, 0);
  }
}

@keyframes rcSliderTooltipZoomDownOut {
  0% {
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  100% {
    opacity: 0;
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scale(0, 0);
    transform: scale(0, 0);
  }
}

.rc-slider-tooltip {
  position: absolute;
  left: -9999px;
  top: -9999px;
  visibility: visible;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rc-slider-tooltip * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rc-slider-tooltip-hidden {
  display: none;
}

.rc-slider-tooltip-placement-top {
  padding: 4px 0 8px 0;
}

.rc-slider-tooltip-inner {
  padding: 6px 2px;
  min-width: 24px;
  height: 24px;
  font-size: 12px;
  line-height: 1;
  color: #fff;
  text-align: center;
  text-decoration: none;
  background-color: #6c6c6c;
  border-radius: 6px;
  box-shadow: 0 0 4px #d9d9d9;
}

.rc-slider-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
  bottom: 4px;
  left: 50%;
  margin-left: -4px;
  border-width: 4px 4px 0;
  border-top-color: #6c6c6c;
}
