@import "./RecalculatePopup/styles";
@import "./HeightAndSetbacks/styles";

.component--define-building-panel {
  $padding: 18px;
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  height: 100%;
  transition: height 0.3s linear,
  visibility 0.2s linear,
  opacity 0.2s linear;
  overflow: hidden;

  %border {
    border: 1px solid #cccccc;
    border-radius: 7px;
  }

  %input-field {
    @extend %border;
    width: 100%;
    padding: 8px 10px;
    font-size: 15px;
  }

  .panel-content {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;

    & * {
      flex-shrink: 0;
    }
  }

  .recalculate-button {
    display: block;
    width: 178px;
    height: 34px;
    text-transform: capitalize;
    font: 800 15px/21px Roboto;
    letter-spacing: 0.13px;
    margin: 30px auto 10px auto;
    border-radius: 7px;
    background: #F26C6C 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000050;

    &:hover {
      background-color: #3A4276;
    }
  }

  label {
    margin: 0;
  }

  .top-label {
    display: flex;
    justify-content: space-between;
    padding-bottom: 8px;
    font: 700 16px/21px Roboto;
    color: #3A4276;
    text-transform: capitalize;
    border-bottom: 1px solid #3A4276;
  }

  .uses-container {
    color: #3A4276;
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
  }

  .component--unit-system-input {
    color: #3A4276;
    padding: 0 $padding;
    height: 73px;
    background-color: #E9EAEF;
    flex-shrink: 0;

    label {
      font-size: 15px;

      &:first-child {
        margin-right: 35px;
      }
    }
  }

  .input-field-wrapper {

    .top-label {
      margin-bottom: 8px;
    }
  }

  .project-name {
    @extend %input-field;
    height: 30px;
  }

  .hidden {
    display: none;
  }

  .error-message {
    display: none;
  }
}
