@import "./AuthenticateWithFacebook/styles";
@import "./AuthenticateWithGoogle/styles";

.component--federated-authentication-providers {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;

  .top-separator {
    position: relative;
    display: flex;
    justify-content: center;
    height: 52px;
    width: 360px;
    background-color: #EEEEEE;
    border-top: solid #FFFFFF 23px;
    border-bottom: solid #FFFFFF 23px;
  }

  .circle {
    position: absolute;
    bottom: -23px;
    background-color: #FFFFFF;
    color: #E0E0E0;
    height: 52px;
    width: 52px;
    font: Bold 20px/40px Roboto;
    border-radius: 26px;
    border: solid #EEEEEE 6px;
    text-align: center;
  }
}
