.component--start-project-button {
  margin-left: 10px;

  .icon {
    width: 20px;
    height: 32px;
    background: url("assets/images/icon-right-arrow-white.svg") no-repeat;
    flex-shrink: 0;
  }
  button {
    display: flex;
    padding: 0 20px;
    align-items: center;
    width: 172px;
    height: 98px;
    background: #f26c6c 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000080;
    border-radius: 7px;
    text-align: left;
    font: Bold 16px/20px Roboto;

    &:hover {
      background-color: #3a4276;
    }
  }
}
