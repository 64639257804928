.component--unit-system-input {
  display: flex;
  align-items: center;
  font: normal 12px/22px Roboto;
  color: #333333;
  position: relative;

  .select-container {
    display: flex;
  }

  label {
    pointer-events: none;
    margin: 0 23px 0 0;
    padding-left: 25px;
    position: relative;
  }

  .custom-radio {
    position: absolute;
    top: 3px;
    left: 0;
    height: 15px;
    width: 15px;
    background: #FDFDFD 0% 0% no-repeat padding-box;
    border-radius: 50%;
    border: 2px solid #3A4276;

    &.selected:after {
      content: "";
      position: absolute;
      top: 1px;
      left: 1px;
      height: 9px;
      width: 9px;
      border-radius: 50%;
      background: #3A4276 0% 0% no-repeat padding-box;
    }
  }

  input[name="unitSystem"] {
    position: absolute;
    top: 2px;
    left: 0;
    height: 17px;
    width: 17px;
    pointer-events: all;
    cursor: pointer;
    opacity: 0;
  }
}
