@charset "UTF-8";
@import "~bootstrap/dist/css/bootstrap.min.css";
@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400,700|Roboto:300,400,500,700,900");
html,
body,
#root,
#root > * {
  height: 1px;
  min-height: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  background-color: rgba(231, 239, 242, 0.6);
  color: #000;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 300;
  margin: 0;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

a {
  cursor: pointer;
  outline: none;
  text-decoration: none;
}

::-webkit-input-placeholder {
  color: #585858;
}

::-moz-placeholder {
  color: #585858;
}

:-ms-input-placeholder {
  color: #585858;
}

:-moz-placeholder {
  color: #585858;
}

video[poster] {
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
}

input[type=text],
input[type=email],
input[type=password] {
  background-color: transparent;
  padding: 10px 5px;
  border: 1px solid transparent;
  border-radius: 3px;
  font-size: 15px;
  font-family: "Roboto", sans-serif;
}
input[type=text]:focus,
input[type=email]:focus,
input[type=password]:focus {
  outline: none;
}

input[type=submit] {
  cursor: pointer;
}
input[type=submit]:focus {
  outline: none;
}

textarea,
input,
button {
  font-family: inherit;
  font-size: inherit;
}

mark {
  background-color: transparent;
  padding: 0;
}

table {
  width: 100%;
  padding: 70px 0px;
}
table tr {
  height: 27px;
}
table td {
  width: 50%;
  word-wrap: break-word;
}
table td.text-left {
  text-align: left;
}
table td.text-right {
  text-align: right;
}
table td.bold-17 {
  font-weight: bold;
  font-size: 17px;
}
table td.bold-15 {
  font-weight: bold;
  font-size: 15px;
}
table td.border-below {
  border-bottom: 2px solid black;
  border-top-width: 0px;
}
table td.border-above {
  border-top: 2px solid black;
  border-bottom-width: 0px;
}
table td .abbreviation-expanded-text {
  font-size: 16px;
  font-weight: 400;
}

input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.rotation-control {
  bottom: 40px !important;
  right: 100px !important;
}

.flx {
  display: flex;
}
.flx.full {
  width: 100%;
}
.flx.h-center {
  justify-content: center;
}
.flx.v-center {
  align-items: center;
}
.flx.hv-center {
  justify-content: center;
  align-items: center;
}

.flx-col {
  display: flex;
  flex-direction: column;
}
.flx-col.full {
  height: 100%;
}
.flx-col.v-center {
  justify-content: center;
}
.flx-col.h-center {
  align-items: center;
}
.flx-col.hv-center {
  justify-content: center;
  align-items: center;
}

.clearfix::after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.container {
  max-width: 1124px;
  margin: 0 auto;
}

button {
  outline: none;
  background: #F26C6C;
  transition: background-color 0.2s;
  color: #F5F7F8;
  font-size: 15px;
  border-radius: 7px;
  height: 32px;
  border: 0px;
  padding: 0;
  box-shadow: none;
  cursor: pointer;
}
button.disabled {
  pointer-events: none;
  background: #CCCCCC;
}
button:focus {
  outline: none;
}
button:hover {
  background-color: #3A4276;
}

.btn, .component--application-links .zoning-report-link {
  outline: none !important;
  transition: background-color 0.2s;
  background-color: transparent;
  color: #666666;
  min-width: 83px;
  min-height: 32px;
  padding: 0;
  border-radius: 16px;
  border: 1px solid #999999;
  user-select: none;
  display: inline-block;
  max-width: 90%;
  font-weight: bold;
  margin: auto 11px;
  cursor: pointer;
}
.btn:hover, .component--application-links .zoning-report-link:hover {
  border-color: #F26C6C;
}

.btn:focus, .component--application-links .zoning-report-link:focus {
  outline: none;
  box-shadow: none;
}

.btn:hover:before, .component--application-links .zoning-report-link:hover:before,
.btn:focus:before,
.component--application-links .zoning-report-link:focus:before,
.btn:active:before,
.component--application-links .zoning-report-link:active:before {
  color: #fff;
}

.btn-signin {
  line-height: 21px;
  color: #00aeea;
}
.btn-signin:hover {
  color: #fff;
}

.btn-transparent,
.btn-transparent-disabled {
  padding: 12px 18px;
  position: relative;
  overflow: hidden;
  vertical-align: middle;
  background-color: transparent;
  transition: background-color 0.3s;
}

.btn-transparent {
  border: 2px solid #00aeea;
  color: #00aeea;
}

.btn-transparent-disabled {
  border: 2px solid #a7aeb1;
  color: #a7aeb1;
}

.btn-transparent:hover,
.btn-transparent:focus,
.btn-transparent:active {
  color: #00aeea;
  background-color: #fff;
}

.btn-gray {
  background-color: #f1f5f7;
  font-weight: 500;
  color: #00aeea;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.btn-gray:hover {
  background-color: #fb6d57;
  color: #fff;
}

.btn-notification {
  background-color: #FB6D57;
  height: 50px;
  width: 50px;
}

*::-webkit-scrollbar {
  width: 5px;
  visibility: hidden;
}
*::-webkit-scrollbar-track {
  background-color: #D8DCDE;
  border-left: 1.5px solid white;
  border-right: 1.5px solid white;
  visibility: hidden;
}
*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #aeaeae;
  visibility: hidden;
}
*:hover::-webkit-scrollbar, *:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}
*:hover::-webkit-scrollbar-track {
  visibility: hidden;
}
*::-webkit-scrollbar {
  height: 5px;
  visibility: hidden;
}
*::-webkit-scrollbar-track {
  background-color: #D8DCDE;
  border-top: 1.5px solid white;
  border-bottom: 1.5px solid white;
  visibility: hidden;
}
*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #aeaeae;
  visibility: hidden;
}
*:hover::-webkit-scrollbar, *:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}
*:hover::-webkit-scrollbar-track {
  visibility: hidden;
}

input::placeholder, textarea::placeholder {
  color: #888;
}

.mapboxgl-control-container .mapboxgl-ctrl-bottom-left {
  left: 10px;
}

iframe {
  width: 100%;
  height: 100vh;
}

.toggle-field-wrapper {
  width: 100%;
  height: 35px;
  padding: 10px 0px;
}
.toggle-field-wrapper.disable-for-demo label {
  pointer-events: none;
}
.toggle-field-wrapper .toggle-container {
  display: flex;
  width: 100%;
  padding-left: 12px;
  padding-right: 8px;
  flex-direction: row;
  justify-content: flex-start;
}
.toggle-field-wrapper .toggle-box {
  position: relative;
  display: inline-block;
  width: 29px;
  height: 19px;
  margin-right: 25px;
}
.toggle-field-wrapper .toggle-box input {
  opacity: 0;
}
.toggle-field-wrapper .toggle-box input:checked + .toggle:before {
  transform: translateX(10px);
}
.toggle-field-wrapper .toggle-box inputinput:checked + .toggle:after {
  position: absolute;
  content: "";
  left: 14px;
  top: 50%;
  width: 18px;
  height: 17px;
  margin-top: -8px;
  background: transparent 0 0 no-repeat;
}
.toggle-field-wrapper .toggle-box .toggle {
  background: #dee2e4;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 0.3s;
  border-radius: 34px;
}
.toggle-field-wrapper .toggle-box .toggle:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 2px;
  top: 2px;
  background-color: white;
  transition: all 0.3s;
  border-radius: 50%;
}
.toggle-field-wrapper .toggle-label {
  float: left;
  font-size: 15px;
  line-height: 20px;
}
.toggle-field-wrapper label {
  margin: 0;
}

.tooltip-helper {
  position: absolute;
  height: 100%;
  width: 100%;
}

.toggle-button-wrapper .toggle-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.toggle-button-wrapper .icon {
  width: 55px;
  height: 46px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1019607843);
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 28px 26px;
}
.toggle-button-wrapper .icon.condo {
  background-image: url("assets/images/icon-condo-black.svg");
}
.toggle-button-wrapper .icon.condo.active {
  background-image: url("assets/images/icon-condo-white.svg");
}
.toggle-button-wrapper .icon.multifamily {
  background-image: url("assets/images/icon-residential-black.svg");
}
.toggle-button-wrapper .icon.multifamily.active {
  background-image: url("assets/images/icon-multifamily-white.svg");
}
.toggle-button-wrapper .icon.hotel {
  background-image: url("assets/images/icon-hotel-black.svg");
}
.toggle-button-wrapper .icon.hotel.active {
  background-image: url("assets/images/icon-hotel-white.svg");
}
.toggle-button-wrapper .icon.office {
  background-image: url("assets/images/icon-office-black.svg");
}
.toggle-button-wrapper .icon.office.active {
  background-image: url("assets/images/icon-office-white.svg");
}
.toggle-button-wrapper .icon.industrial {
  background-image: url("assets/images/icon-industrial-black.svg");
}
.toggle-button-wrapper .icon.industrial.active {
  background-image: url("assets/images/icon-industrial-white.svg");
}
.toggle-button-wrapper .icon.retail {
  background-image: url("assets/images/icon-retail-black.svg");
}
.toggle-button-wrapper .icon.retail.active {
  background-image: url("assets/images/icon-retail-white.svg");
}
.toggle-button-wrapper .toggle-label {
  margin-top: 5px;
  font: normal 12px/12px Roboto;
  color: #313131;
}

.component--dropdown {
  width: 100%;
  display: flex;
  align-items: center;
  outline: none;
  position: relative;
}
.component--dropdown .dropdown-content {
  background-color: #59618D;
  border-radius: 10px;
  box-sizing: border-box;
  opacity: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  pointer-events: none;
  position: absolute;
  transition: all 0.2s linear;
  transition-property: height, opacity, overflow;
  z-index: 200;
  width: 240px;
  display: flex;
  top: 45px;
}
.component--dropdown .dropdown-content.empty {
  padding: 0;
}
.component--dropdown .dropdown-content .button {
  display: flex;
  align-items: center;
  font-family: inherit;
  font-size: 13px;
  line-height: 20px;
  height: 27px;
  border-top: 0.5px solid #3A4377;
  color: #EBECF1;
}
.component--dropdown .dropdown-content .button span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 10px;
}
.component--dropdown .dropdown-content .button:hover {
  text-decoration: none;
}
.component--dropdown .dropdown-content .dropdown-content-inner {
  width: 100%;
  height: fit-content;
  max-height: 410px;
  overflow-y: auto;
}
.component--dropdown .dropdown-content ul {
  overflow-y: auto;
  overflow-x: hidden;
}
.component--dropdown .dropdown-content li {
  padding-left: 15px;
  padding-right: 15px;
  margin: 0;
}
.component--dropdown .dropdown-content li:hover {
  background-color: #3A4377;
  cursor: pointer;
}
.component--dropdown .dropdown-content li:hover .button {
  border-color: transparent;
}
.component--dropdown .dropdown-content li:first-child .button {
  border: none;
}
.component--dropdown .dropdown-content li:hover + li .button {
  border-color: transparent;
}
.component--dropdown .dropdown-content ::-webkit-scrollbar-track {
  border: none;
  background-color: #59618D;
}
.component--dropdown .dropdown-content ::-webkit-scrollbar-thumb {
  background: #9db0b8;
}
.component--dropdown .dropdown-content.active {
  opacity: 1;
  pointer-events: auto;
}

.component--navbar {
  position: absolute;
  top: 0;
  height: 47px;
  width: 100%;
  background: none;
  background-color: #FFFFFF;
}
.component--navbar a {
  display: block;
  width: fit-content;
}
.component--navbar .logo {
  width: 155px;
  height: 25.5px;
  background-image: url("assets/images/logo-inline.svg");
  background-repeat: no-repeat;
  margin-top: 11px;
  margin-left: 15px;
}

.popup {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 2000;
}
.popup .pdf-viewer {
  width: 60vw;
  border: none;
}
.popup .popup-title {
  font-size: 16px;
  color: #333333;
  margin: 0 0 0 5px;
  text-transform: uppercase;
  font-weight: 500;
}
.popup .popup-content {
  font-weight: 400;
  padding: 30px 15px 0px 15px;
}
.popup .popup-inner-wrapper {
  margin-top: 15px;
}
.popup .inp-field {
  background-color: #FCFCFC;
  color: #333333;
  padding: 5px;
  margin-top: 8px;
  border-radius: 7px;
  position: relative;
}
.popup .inp-field label {
  width: 80px;
  color: #888888;
  padding-left: 20px;
  border-right: 1px solid #888888;
  line-height: 35px;
}
.popup .inp-field input {
  display: inline-block;
  width: 80%;
  padding: 8px 15px;
  background-color: transparent;
  font-size: 16px;
}

.popup-container-appear {
  opacity: 0;
}

.popup-container-appear.popup-container-appear-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.popup-container-enter {
  opacity: 0;
}

.popup-container-enter.popup-container-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.popup-container-leave {
  opacity: 1;
  pointer-events: none;
}

.popup-container-leave.popup-container-leave-active {
  opacity: 0;
  transition: opacity 300ms ease-in;
}

.component--toolbar {
  position: relative;
  background-color: #FFFFFF;
  height: 50px;
  display: flex;
  flex-shrink: 0;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.05);
  z-index: 1000;
}
.component--toolbar div:focus {
  outline: 0;
}
.component--toolbar .toolbar-children-container {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.component--toolbar .toolbar-children-container .buttons-container {
  position: relative;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  margin-right: 20px;
}
.component--toolbar .toolbar-children-container .buttons-container .button {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.component--toolbar .toolbar-children-container .buttons-container .button:not(:first-child) {
  margin-left: 15px;
}
.component--toolbar .toolbar-children-container .buttons-container .button .icon {
  width: 18px;
  height: 18px;
  margin-bottom: 3px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.component--toolbar .toolbar-children-container .buttons-container .button .icon.duplicate-project {
  background-image: url("assets/images/icon-duplicate-project-inactive.svg");
}
.component--toolbar .toolbar-children-container .buttons-container .button .icon.new-project {
  background-image: url("assets/images/icon-new-project-inactive.svg");
}
.component--toolbar .toolbar-children-container .buttons-container .button .icon.log-in {
  background-image: url("assets/images/icon-log-in-inactive.svg");
}
.component--toolbar .toolbar-children-container .buttons-container .button .icon.sign-up {
  background-image: url("assets/images/icon-toolbar-sign-up-inactive.svg");
}
.component--toolbar .toolbar-children-container .buttons-container .button .text {
  font: 500 12px/12px Roboto;
  color: #3A4276;
}
.component--toolbar .toolbar-children-container .buttons-container .button:hover .log-in, .component--toolbar .toolbar-children-container .buttons-container .button.active .log-in {
  background-image: url("assets/images/icon-log-in-active.svg");
}
.component--toolbar .toolbar-children-container .buttons-container .button:hover .sign-up, .component--toolbar .toolbar-children-container .buttons-container .button.active .sign-up {
  background-image: url("assets/images/icon-toolbar-sign-up-active.svg");
}
.component--toolbar .toolbar-children-container .buttons-container .button:hover .text, .component--toolbar .toolbar-children-container .buttons-container .button.active .text {
  color: #4ACBC6;
}
.component--toolbar .toolbar-children-container .component--unit-system-input {
  margin-right: 26px;
}

.component--toolbar-settings {
  position: relative;
  display: flex;
  align-items: center;
}
.component--toolbar-settings .dropdown-content {
  line-height: 1;
  position: absolute;
  width: 162px;
  right: 10px;
  top: 40px;
}
.component--toolbar-settings .dropdown-content a {
  font-weight: 400;
}
.component--toolbar-settings .user-settings-menu {
  width: 40px;
  height: 50px;
  right: 0;
  top: 0;
  outline: none;
}
.component--toolbar-settings .user-settings-menu .toggle-dropdown {
  height: 100%;
  width: 100%;
  cursor: pointer;
}
.component--toolbar-settings .user-settings-menu .toggle-dropdown .menu-button {
  position: relative;
  margin-right: auto;
  margin-left: auto;
  height: 20px;
  width: 4px;
  top: 15px;
  background-image: url("assets/images/toolbar-settings.svg");
}
.component--toolbar-settings .user-settings-menu:hover .menu-button,
.component--toolbar-settings .user-settings-menu .menu-button.active {
  background-image: url("assets/images/toolbar-settings-hover.svg");
}

.component--branding-watermark {
  width: 100px;
  height: 19px;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
}
.component--branding-watermark .icon-watermark {
  display: block;
  height: 100%;
  width: 100%;
  background: url("assets/images/branding-watermark.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.component--sidebar {
  display: flex;
  flex-direction: column;
  min-width: 195px;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #F8F8F8;
  padding: 21px 0 13px;
}
.component--sidebar .sidebar-block {
  margin-bottom: 38px;
  padding: 0px 16px;
}
.component--sidebar .sidebar-block h5 {
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;
  color: #777777;
  padding-bottom: 3px;
  border-bottom: 1px solid #777777;
}
.component--sidebar .sidebar-block .sidebar-nav {
  margin: 8px 0;
}
.component--sidebar .sidebar-block .sidebar-nav .side-bar-links {
  margin-bottom: 5px;
  color: #777777;
  font-size: 13px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  transition-property: height;
  height: 28px;
}
.component--sidebar .sidebar-block .sidebar-nav .side-bar-links .icon {
  width: 30px;
  height: 25px;
  margin-right: 7px;
}
.component--sidebar .sidebar-block .sidebar-nav .side-bar-links.hidden {
  visibility: hidden;
  height: 0px;
  margin: 0px;
}

.component--pdf-generator .component--pdf-graph-generators {
  width: 300px;
  position: fixed;
  left: -10000px;
  bottom: -10000px;
  z-index: -100;
}

.component--pdf-generator .component--map-image {
  position: fixed;
  left: -10000px;
  bottom: -10000px;
  height: 800px;
  width: 566px;
  z-index: -100;
}
.component--pdf-generator .component--map-image.cover {
  height: 826px;
}

.component--pdf-viewer {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  width: 900px;
  max-width: 80vw;
  border: none;
  z-index: 3000;
}

.close-cross {
  background-image: url("assets/images/white-cross.svg");
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  top: 100px;
  right: 50px;
  width: 27.4px;
  height: 29.07px;
  cursor: pointer;
}

.component--busy-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.component--busy-spinner .spinner {
  width: 40px;
  height: 40px;
  background-image: url("assets/images/busy-spinner-black.svg");
  animation: spin 1s infinite linear;
}
.component--busy-spinner.white-spinner .spinner {
  background-image: url("assets/images/busy-spinner-white.svg");
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
.component--busy-popup {
  width: 300px;
}
.component--busy-popup .text-box {
  font-family: Roboto Condensed;
  font-weight: 700;
  font-size: 20px;
  color: white;
  text-align: center;
}
.component--busy-popup .component--busy-spinner {
  height: 120px;
}
.component--busy-popup .component--busy-spinner .spinner {
  width: 60px;
  height: 60px;
  background-image: url("assets/images/busy-spinner-white.svg");
}

.component--activity-feedback .spinner-wrapper {
  height: 140px;
}
.component--activity-feedback .secondary-text {
  text-align: center;
  font-size: 16px;
  color: #777777;
}

td.component--cell {
  text-align: left;
}
td.component--cell.bold {
  font-weight: bold;
}
td.component--cell.aligned-to-right {
  text-align: right;
  font-weight: bold;
}
td.component--cell.blurry-text,
td.component--cell .blurry-text {
  color: transparent;
  text-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  user-select: none;
  pointer-events: none;
}
td.component--cell.justify-left {
  text-align: left;
}
td.component--cell .value,
td.component--cell .array-item {
  white-space: pre-line;
}

.component--parcel-information-table {
  width: 100%;
}
.component--parcel-information-table table {
  background-color: #FFFFFF;
  color: #939393;
}
.component--parcel-information-table table th {
  height: 40px;
  padding-top: 15px;
  font: Bold 14px/18px Roboto;
  letter-spacing: 1.19px;
  text-align: center;
  color: #3A4276;
  text-transform: uppercase;
  border-bottom: 2px solid #3A4276;
}
.component--parcel-information-table table tr {
  line-height: 15px;
}
.component--parcel-information-table table tr.center td {
  text-align: center;
}
.component--parcel-information-table table tr:nth-child(even) {
  background-color: #FBFBFB;
}
.component--parcel-information-table table tr td {
  border: 1px solid #D1D5DD;
  border-top: none;
  vertical-align: top;
}
.component--parcel-information-table table tr td:first-child {
  padding: 10px 3px 10px 11px;
  font-weight: 400;
  width: 150px;
}
.component--parcel-information-table table tr td:last-child {
  padding: 10px 18px 10px 10px;
  font: 700 14px/15px Roboto;
  color: #3A4276;
}
.component--parcel-information-table table tr td.yellow {
  color: #F9B923;
  font-weight: 700;
}
.component--parcel-information-table table tr td.capitalize {
  text-transform: capitalize;
}
.component--parcel-information-table table tr td.uppercase {
  text-transform: uppercase;
}
.component--parcel-information-table table tr .array-item {
  margin-bottom: 6px;
  display: block;
  word-break: break-word;
}
.component--parcel-information-table table tr .array-item:last-child {
  margin-bottom: 0;
}

.component--federated-authentication-providers .component--button-facebook {
  background-color: #3F569A;
}

.component--federated-authentication-providers .component--button-google {
  background-color: #DB4437;
  margin-top: 8px;
}

.component--federated-authentication-providers {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}
.component--federated-authentication-providers .top-separator {
  position: relative;
  display: flex;
  justify-content: center;
  height: 52px;
  width: 360px;
  background-color: #EEEEEE;
  border-top: solid #FFFFFF 23px;
  border-bottom: solid #FFFFFF 23px;
}
.component--federated-authentication-providers .circle {
  position: absolute;
  bottom: -23px;
  background-color: #FFFFFF;
  color: #E0E0E0;
  height: 52px;
  width: 52px;
  font: Bold 20px/40px Roboto;
  border-radius: 26px;
  border: solid #EEEEEE 6px;
  text-align: center;
}

.component--user-loading-feedback {
  height: 100%;
  weight: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}
.component--user-loading-feedback .text-box {
  font-family: "Roboto Condensed";
  font-weight: 700;
  font-size: 20px;
  color: #333333;
  text-align: center;
}
.component--user-loading-feedback .component--busy-spinner {
  height: 120px;
}
.component--user-loading-feedback .component--busy-spinner .spinner {
  width: 60px;
  height: 60px;
}

.component--unit-system-input {
  display: flex;
  align-items: center;
  font: normal 12px/22px Roboto;
  color: #333333;
  position: relative;
}
.component--unit-system-input .select-container {
  display: flex;
}
.component--unit-system-input label {
  pointer-events: none;
  margin: 0 23px 0 0;
  padding-left: 25px;
  position: relative;
}
.component--unit-system-input .custom-radio {
  position: absolute;
  top: 3px;
  left: 0;
  height: 15px;
  width: 15px;
  background: #FDFDFD 0% 0% no-repeat padding-box;
  border-radius: 50%;
  border: 2px solid #3A4276;
}
.component--unit-system-input .custom-radio.selected:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  height: 9px;
  width: 9px;
  border-radius: 50%;
  background: #3A4276 0% 0% no-repeat padding-box;
}
.component--unit-system-input input[name=unitSystem] {
  position: absolute;
  top: 2px;
  left: 0;
  height: 17px;
  width: 17px;
  pointer-events: all;
  cursor: pointer;
  opacity: 0;
}

.component--redirect-button {
  color: #3A4276;
  font-size: 13px;
  height: 32px;
  min-height: 32px;
  padding: 6px 15px;
  border: 1px solid #3A4276;
  margin: auto 10px;
  flex-shrink: 0;
}
.component--redirect-button:hover {
  border: 1px solid #3A4276;
  background-color: #3A4276;
  color: white;
}
.component--redirect-button.btn.basic, .component--application-links .component--redirect-button.basic.zoning-report-link {
  border: 1px solid #F26C6C;
  background-color: #F26C6C;
}
.component--redirect-button.btn.basic:hover, .component--application-links .component--redirect-button.basic.zoning-report-link:hover {
  border: 1px solid #3A4276;
  background-color: #3A4276;
}

.component--mapbox-geocoder .geocoder-input {
  position: relative;
  display: flex;
  align-items: center;
  font: 500 16px/16px Roboto;
  /* Suggestions */
}
.component--mapbox-geocoder .geocoder-input input {
  color: #777777;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  height: 32px;
}
.component--mapbox-geocoder .geocoder-input ul {
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 0 0 3px 3px;
  left: 0;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  width: 100%;
  top: 100%;
  overflow: hidden;
  font-size: 12px;
  z-index: 100;
}
.component--mapbox-geocoder .geocoder-input li {
  margin-bottom: 0;
  padding: 0px 0px;
}
.component--mapbox-geocoder .geocoder-input .suggestion {
  clear: both;
  cursor: default;
  display: block;
  padding: 5px 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  color: #404040;
}
.component--mapbox-geocoder .geocoder-input .suggestion:hover {
  color: #202020;
  background-color: #f3f3f3;
  text-decoration: none;
  cursor: pointer;
}
.component--mapbox-geocoder .geocoder-input ul > li:last-child > .suggestion {
  border-bottom: none;
}
.component--mapbox-geocoder .geocoder-input ul > li.active > .suggestion {
  color: #202020;
  background-color: #e3e3e3;
  text-decoration: none;
  cursor: pointer;
}
.component--mapbox-geocoder .geocoder-input .geocoder-icon {
  position: absolute;
  background-repeat: no-repeat;
  cursor: pointer;
}
.component--mapbox-geocoder .geocoder-input .geocoder-icon-search {
  top: 7px;
  right: 8px;
  width: 15px;
  height: 17px;
}
.component--mapbox-geocoder .geocoder-input .geocoder-icon-search.active {
  background-image: url("assets/images/icon-search-red.svg");
  pointer-events: all;
}
.component--mapbox-geocoder .geocoder-input .geocoder-icon-search.inactive {
  cursor: inherit;
  background-image: url("assets/images/icon-search-gray.svg");
  pointer-events: none;
}
.component--mapbox-geocoder .geocoder-input .geocoder-icon-close {
  top: 10px;
  right: 10px;
  width: 10px;
  height: 11px;
  background-image: url("assets/images/icon-cross-out.svg");
  background-color: white;
}

.component--return-on-cost {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 20px;
  right: 60px;
  width: 120px;
  padding: 8px 8px 15px;
  border-radius: 10px;
  background-color: #3A4276;
  color: #FFFFFF;
  text-align: center;
  line-height: 15px;
}
.component--return-on-cost .header {
  font: Bold 16px/18px Roboto;
  letter-spacing: 0.33px;
  text-transform: uppercase;
}
.component--return-on-cost .separator {
  margin-top: 3px;
  width: 35px;
  height: 3px;
  background-color: #F66C6C;
  border-radius: 1.5px;
}
.component--return-on-cost .value {
  margin-top: 15px;
  font: 700 30px/30px Roboto;
  letter-spacing: -0.5px;
}

.component-react-tooltip {
  opacity: 1 !important;
  margin-left: 63px !important;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137) !important;
  padding: 8px 10px !important;
}
.component-react-tooltip::before {
  background-image: url("assets/images/tooltip-triangle.svg");
  background-repeat: no-repeat;
  background-size: contain;
  border: none !important;
  width: 85px !important;
  height: 85px !important;
  margin: 0 !important;
  top: 155px !important;
  left: -60px !important;
}
.component-react-tooltip::after {
  top: 161px !important;
  left: 0px !important;
  border: none !important;
  width: 20px !important;
  height: 85px !important;
  background-color: #fff;
}
.component-react-tooltip.no-margin {
  margin: 0 !important;
}
.component-react-tooltip.left {
  margin-left: -50px !important;
}
.component-react-tooltip.left::before {
  transform: rotate(180deg);
  left: unset !important;
  right: -60px !important;
}
.component-react-tooltip.left::after {
  left: unset !important;
  right: 0 !important;
}

.component--tooltip {
  width: 325px;
  height: 377px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 14px 2px 0;
}
.component--tooltip a {
  color: #3A4276;
}
.component--tooltip .image {
  width: 230px;
  height: 152px;
  margin-bottom: 26px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.component--tooltip .image.tooltip-smart-search {
  background-image: url("assets/images/tooltip-smart-search.png");
}
.component--tooltip .image.tooltip-back-of-envelope {
  background-image: url("assets/images/tooltip-boe-panel.png");
}
.component--tooltip .image.tooltip-dashboard {
  background-image: url("assets/images/tooltip-dashboard.png");
}
.component--tooltip .image.tooltip-information-panel {
  background-image: url("assets/images/tooltip-information-panel.png");
}
.component--tooltip .image.tooltip-pdf {
  background-image: url("assets/images/tooltip-pdf-panel.png");
}
.component--tooltip .image.tooltip-layer-selection-panel {
  background-image: url("assets/images/tooltip-layer-selection.png");
}
.component--tooltip .image.tooltip-market-insight-panel {
  background-image: url("assets/images/tooltip-graph.png");
}
.component--tooltip .header {
  font: 16px/18px Roboto;
  letter-spacing: 0.32px;
  color: #3A4276;
  text-transform: uppercase;
}
.component--tooltip .header.thin {
  font-weight: 100;
}
.component--tooltip .header.bold {
  font-weight: bold;
}
.component--tooltip .content {
  text-align: center;
  font: 100 14px/18px Roboto;
  letter-spacing: 0.24px;
  color: #999999;
  margin-top: 14px;
  white-space: pre-wrap;
}
.component--tooltip .btn, .component--tooltip .component--application-links .zoning-report-link, .component--application-links .component--tooltip .zoning-report-link {
  width: 124px;
  height: 24px;
  min-height: 24px;
  color: #fff;
  padding: 0;
  background-color: #3A4276;
  border-radius: 5px;
  margin: 26px 0 0;
  font: normal 13px/100% Roboto;
  letter-spacing: 0.26px;
}
.component--tooltip .not-now {
  text-decoration: underline;
  font: normal 10px/10px Roboto;
  letter-spacing: 0.2px;
  color: #999999;
  text-transform: capitalize;
  margin: 8px 0 0;
  cursor: pointer;
}

.component--sign-up-link {
  display: flex;
  justify-content: center;
}
.component--sign-up-link .sign-up-link {
  font: 400 14px/14px Roboto;
  color: #777777;
  text-decoration: underline;
  text-align: center;
  margin-top: 5px;
}

.component--log-in {
  position: relative;
  background-color: #FFFFFF;
  padding: 20px 12px;
  border-radius: 3px;
}
.component--log-in form {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.component--log-in .header {
  height: 35px;
  border-bottom: 1px solid #E0E0E0;
  text-align: center;
  font: 700 20px/25px Roboto;
  color: #263238;
  text-transform: uppercase;
}
.component--log-in .input-field {
  margin-top: 16px;
}
.component--log-in .error-container p {
  font: 300 10px/20px Roboto;
  font-style: italic;
  color: #F26C6C;
  margin-top: 7px;
  margin-bottom: -15px;
  white-space: pre-line;
}
.component--log-in .tos-agreement {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.component--log-in .text-box {
  font: 400 12px/14px Roboto;
  color: #777777;
}
.component--log-in a {
  font: 400 12px/14px Roboto;
  color: #777777;
  text-decoration: underline;
}
.component--log-in a.forgot-password {
  margin-left: auto;
}
.component--log-in input {
  width: 100%;
  background-color: #F1F5F7;
  border: 1px solid #EEEEEE;
  border-radius: 0;
}
.component--log-in input.tos {
  width: 24px;
}
.component--log-in button {
  width: 100%;
  height: 46px;
  background-color: #3A4276;
  border-radius: 3PX;
  margin-top: 24px;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 30px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2509803922);
}
.component--log-in .submit:hover {
  background-color: #2C3258;
}

.component--log-in-popup {
  width: 360px;
  background-color: #ffffff;
  border-radius: 3px;
}
.component--log-in-popup::before {
  position: absolute;
  top: -36px;
  right: 155px;
  content: "";
  width: 50px;
  height: 50px;
  background-image: url("assets/images/tooltip-triangle.svg");
  background-size: contain;
  background-repeat: no-repeat;
  transform: rotate(90deg);
}
.component--log-in-popup.on-home-page {
  position: absolute;
  top: 50px;
  right: 125px;
}
.component--log-in-popup.on-home-page::before {
  top: 12px;
  right: -32px;
  transform: rotate(58deg);
}
.component--log-in-popup.on-explorer-page {
  position: absolute;
  top: 125px;
  right: 95px;
}
.component--log-in-popup.on-explorer-page::before {
  top: -28px;
  right: 68px;
}
.component--log-in-popup.on-new-project-page {
  position: absolute;
  top: 120px;
  right: 113px;
}
.component--log-in-popup.on-new-project-page::before {
  top: -28px;
  right: 68px;
}
.component--log-in-popup.on-subscribe-page {
  width: 483px;
}
.component--log-in-popup.on-subscribe-page::before, .component--log-in-popup.on-subscribe-page::after {
  content: none;
}
.component--log-in-popup.on-subscribe-page .component--log-in {
  position: relative;
  padding: 0px 12px 45px;
}
.component--log-in-popup.on-subscribe-page .component--log-in form {
  margin-top: 14px;
}
.component--log-in-popup.on-subscribe-page .component--log-in .header {
  position: relative;
  right: 12px;
  width: 483px;
  height: 66px;
  color: #3a4276;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid #3a4276;
}
.component--log-in-popup.on-subscribe-page .component--log-in .input-field {
  margin-top: 21px;
}
.component--log-in-popup.on-subscribe-page .component--log-in input {
  height: 61px;
  padding: 0 17px;
}
.component--log-in-popup.on-subscribe-page .component--log-in input[type=checkbox] {
  height: 25px;
  margin-right: 10px;
}
.component--log-in-popup.on-subscribe-page .component--log-in .component--federated-authentication-providers {
  margin-top: 44px;
  flex-direction: column;
}
.component--log-in-popup.on-subscribe-page .component--log-in .component--federated-authentication-providers .top-separator {
  width: 483px;
}
.component--log-in-popup.on-subscribe-page .component--log-in .component--federated-authentication-providers .buttons-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 37px;
}
.component--log-in-popup.on-subscribe-page .component--log-in .component--federated-authentication-providers .component--button-facebook {
  background-color: #3f569a;
  margin: 0;
}
.component--log-in-popup.on-subscribe-page .component--log-in .component--federated-authentication-providers .component--button-google {
  background-color: #db4437;
  margin: 18px 0 0;
}
.component--log-in-popup.on-subscribe-page .component--log-in button {
  height: 60px;
}

.component--geocoder-search {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.component--geocoder-search .logo {
  width: 600px;
  height: 239px;
  background-image: url("assets/images/deepblocks-blue-logo-vertical.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 20px;
}
.component--geocoder-search .geocoder-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
}
.component--geocoder-search .count-container {
  margin-top: 18px;
}
.component--geocoder-search .component--mapbox-geocoder {
  border: 3px solid #2691F3;
  border-radius: 29px;
  margin-bottom: 50px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.0509803922);
}
.component--geocoder-search .component--mapbox-geocoder .geocoder-icon-search {
  opacity: 0;
}
.component--geocoder-search .component--mapbox-geocoder .geocoder-icon-close {
  background-color: transparent;
  top: 21px;
  right: 18px;
  width: 13px;
  height: 14px;
}
.component--geocoder-search .component--mapbox-geocoder input {
  width: 700px;
  height: 58px;
  padding: 17px 46px;
  color: #999999;
  font: 500 16px/16px Poppins;
  letter-spacing: 0.4px;
}
.component--geocoder-search .tagline-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 80px;
}
.component--geocoder-search .tagline-label {
  text-align: center;
  font: 600 34px/20px Poppins;
  color: #2691F3;
}
.component--geocoder-search .tagline-label.bold {
  color: #CA68F9;
  font: 700 34px/20px Poppins;
}
.component--geocoder-search .error-container p {
  font: 300 13px/20px Roboto;
  font-style: italic;
  color: #F26C6C;
  margin-top: -40px;
  margin-bottom: 20px;
}
.component--geocoder-search .component--redirect-button {
  color: #FFFFFF;
  background-image: linear-gradient(to right, rgb(38, 145, 243), rgb(202, 104, 249));
  height: 54px;
  width: 242px;
  border-radius: 27px;
  font: Bold 21px/34px Roboto;
  letter-spacing: 0.21px;
  padding: 0;
  margin: 0;
  border: none;
}
.component--geocoder-search .component--redirect-button:hover {
  background-color: #2C3258;
}

.component--share-project-popup {
  min-width: 400px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  color: #818181;
  font: 400 13px/16px Roboto;
  text-align: center;
  border-radius: 6px;
}
.component--share-project-popup .top {
  width: 100%;
  background-color: #3A4276;
  padding: 15px 12px 10px;
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 6px 6px 0 0;
}
.component--share-project-popup .top .header {
  font: 900 16px/21px Roboto;
}
.component--share-project-popup .top .top-separator {
  width: 52px;
  height: 1px;
  background-color: #FFFFFF;
  margin: 13px 0;
}
.component--share-project-popup .bottom {
  width: 100%;
  padding: 20px 16px;
}
.component--share-project-popup .bottom .url-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  background-color: #F5F7F8;
  border-radius: 6px;
  font: 400 11px/21px Roboto;
  color: #3A4276;
  padding: 0 10px;
}
.component--share-project-popup .bottom button {
  margin-top: 13px;
  width: 130px;
  height: 25px;
  font: 700 13px/21px Roboto;
  color: #FFFFFF;
  background-color: #4ACBC6;
  border-radius: 6px;
}
.component--share-project-popup.explorer {
  position: absolute;
  top: 269px;
  left: 35px;
}
.component--share-project-popup.explorer::before {
  position: absolute;
  top: -34px;
  left: 245px;
  content: "";
  width: 30px;
  height: 50px;
  background-image: url("assets/images/tooltip-triangle-blue.svg");
  background-size: contain;
  background-repeat: no-repeat;
  transform: rotate(90deg);
}
.component--share-project-popup.explorer::after {
  position: absolute;
  top: 0px;
  left: 245px;
  content: "";
  width: 50px;
  height: 10px;
  background-color: #3A4276;
  border-radius: 3px;
}

.component--share-project {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.component--share-project .icon {
  width: 18px;
  height: 18px;
  margin-bottom: 3px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("assets/images/icon-share-project-inactive.svg");
}
.component--share-project .text {
  font: 500 8px/8px Roboto;
  color: #3A4276;
  text-align: center;
}
.component--share-project:hover .icon, .component--share-project.active .icon {
  background-image: url("assets/images/icon-share-project-active.svg");
}
.component--share-project:hover .text, .component--share-project.active .text {
  color: #4ACBC6;
}

.component--delete-development-popup {
  width: 360px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  background-color: #FFFFFF;
  font: 400 13px/16px Roboto;
  text-align: center;
  border-radius: 6px;
}
.component--delete-development-popup .top {
  background-color: #3A4276;
  padding: 20px 18px 12px;
  color: #FFFFFF;
  border-radius: 6px 6px 0 0;
}
.component--delete-development-popup .top .separator {
  margin-top: 9px;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.5019607843);
}
.component--delete-development-popup .top .italic {
  margin-top: 9px;
  font: Italic 12px/21px Roboto;
}
.component--delete-development-popup .top .bold {
  font-weight: 700;
}
.component--delete-development-popup .bottom {
  padding: 20px 0 25px;
}
.component--delete-development-popup .buttons-container {
  display: flex;
  width: 100%;
  justify-content: center;
}
.component--delete-development-popup button {
  width: 131px;
  height: 25px;
  min-height: 25px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2509803922);
  border-radius: 6px;
  border: none;
  color: #FFFFFF;
  font: 700 13px/13px Roboto;
  padding: 0;
}
.component--delete-development-popup button.cancel {
  background-color: #3A4276;
}
.component--delete-development-popup button.confirm {
  background-color: #F26C6C;
  margin-left: 35px;
}
.component--delete-development-popup button:hover {
  border: none;
}

.component--delete-development {
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.component--delete-development .icon {
  width: 18px;
  height: 18px;
  margin-bottom: 3px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("assets/images/icon-trash-can-blue.svg");
}
.component--delete-development .text {
  font: 500 8px/8px Roboto;
  color: #3A4276;
}
.component--delete-development:hover .icon, .component--delete-development.active .icon {
  background-image: url("assets/images/icon-trash-can-green.svg");
}
.component--delete-development:hover .text, .component--delete-development.active .text {
  color: #4ACBC6;
}

.component--accordion-section {
  margin-bottom: 18px;
  width: 100%;
}
.component--accordion-section .section-title {
  height: 35px;
  display: flex;
  padding: 0 16px;
  align-items: center;
  justify-content: space-between;
  font: 700 18px/21px Roboto;
  letter-spacing: 1.53px;
  text-transform: uppercase;
  cursor: pointer;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  color: #3A4276;
  border: 1px solid rgba(58, 66, 118, 0.5);
  margin-bottom: 20px;
}
.component--accordion-section .section-title .title-wrapper {
  display: flex;
}
.component--accordion-section .section-title .icon {
  width: 18px;
  height: 18px;
  background-repeat: no-repeat;
  background-size: contain;
}
.component--accordion-section .section-title .title-icon {
  margin-right: 11px;
}
.component--accordion-section .section-title .open-close-icon {
  background-position: center;
  background-image: url("assets/images/icon-plus.svg");
}
.component--accordion-section .section-title .name-uses {
  background-image: url("assets/images/icon-name-uses-blue.svg");
}
.component--accordion-section .section-title .demographics {
  background-image: url("assets/images/icon-demographics-blue.svg");
}
.component--accordion-section .section-title .property-info {
  background-image: url("assets/images/icon-property-info-blue.svg");
}
.component--accordion-section .section-title .zoning {
  background-image: url("assets/images/icon-zoning-blue.svg");
}
.component--accordion-section .section-title.open {
  background: #EFEFF4 0% 0% no-repeat padding-box;
  border: none;
  margin: 0;
}
.component--accordion-section .section-title.open .open-close-icon {
  background-image: url("assets/images/icon-minus.svg");
}

.component--logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 14px;
}
.component--logo .logo {
  width: 168px;
  height: 38px;
  background-position: center;
  background-repeat: no-repeat;
  margin-left: 18px;
  cursor: pointer;
}
.component--logo .logo.pro {
  background-image: url("assets/images/logo-pro-inline.svg");
}
.component--logo .logo.none {
  background-image: url("assets/images/logo-demo-underline.svg");
}
.component--logo .logo.free {
  background-image: url("assets/images/logo-free-underline.svg");
}
.component--logo .logo.developer {
  background-image: url("assets/images/logo-developer-underline.svg");
}

.component--map-style-button {
  width: 80px;
  height: 80px;
  background-repeat: no-repeat;
  background-size: 80px;
  background-color: transparent;
  cursor: pointer;
}
.component--map-style-button.streets-map-button {
  background-image: url("assets/images/map-view-streets.png");
}
.component--map-style-button.streets-map-button:hover {
  background-image: url("assets/images/map-view-streets-hover.png");
}
.component--map-style-button.satellite-map-button {
  background-image: url("assets/images/map-view-satellite.png");
}
.component--map-style-button.satellite-map-button:hover {
  background-image: url("assets/images/map-view-satellite-hover.png");
}

.component--tab-panel {
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
  margin-bottom: 15px;
}

.component--tabs {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
}
.component--tabs .component--tab-container {
  padding: 18px;
  background: #E9EAEF 0% 0% no-repeat padding-box;
  display: flex;
  flex-shrink: 0;
}
.component--tabs .component--tab {
  cursor: pointer;
  width: 126px;
  height: 55px;
  border-radius: 5px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1019607843);
  background: #FFFFFF 0% 0% no-repeat padding-box;
  color: #333333;
  font: Bold 14px/16px Roboto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.component--tabs .component--tab.active {
  background: #3A4276 0% 0% no-repeat padding-box;
  color: #fff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.4);
}
.component--tabs .component--tab:not(:first-child) {
  margin-left: 13px;
}
.component--tabs .component--tab.disabled {
  color: #D5D5E0;
  box-shadow: none;
  cursor: default;
}

.component--invitations-popup {
  width: 595px;
  max-height: calc(100vh - 120px);
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 30px 10px;
  overflow: auto;
}
.component--invitations-popup .invitation {
  width: 100%;
  padding: 20px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0.47px 3px 10px rgba(119, 119, 119, 0.4);
  border-radius: 3px;
  margin-bottom: 30px;
}
.component--invitations-popup .invitation .text {
  text-align: center;
  font: 400 15px/20px Roboto;
  color: #333333;
}
.component--invitations-popup .invitation .text span {
  font-weight: 700;
  color: #3A4276;
}
.component--invitations-popup .invitation .buttons-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
}
.component--invitations-popup .invitation .buttons-container :first-child {
  margin-right: 40px;
}
.component--invitations-popup button {
  width: 160px;
  height: 34px;
  border-radius: 7px;
  background-color: #FFFFFF;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  font: 700 14px/14px Roboto;
  color: #333333;
  flex-shrink: 0;
}
.component--invitations-popup button:hover {
  background-color: #3A4276;
  color: #FFFFFF;
}

.component--button {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  padding: 14px;
  width: 70px;
  height: 70px;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 16px;
}
.component--button .button {
  width: 26px;
  height: 26px;
  margin-bottom: 6px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("assets/images/icon-demo-inactive.svg");
}
.component--button .text {
  font: 400 12px/12px Roboto;
  color: #3a4276;
}
.component--button.demo .button {
  background-image: url("assets/images/icon-demo-inactive.svg");
}
.component--button.demo:hover .button, .component--button.demo.active .button {
  background-image: url("assets/images/icon-demo-active.svg");
}
.component--button.login .button {
  background-image: url("assets/images/icon-log-in-inactive.svg");
}
.component--button.login:hover .button, .component--button.login.active .button {
  background-image: url("assets/images/icon-log-in-active.svg");
}
.component--button.sign-up .button {
  background-image: url("assets/images/icon-toolbar-sign-up-inactive.svg");
}
.component--button.sign-up:hover .button, .component--button.sign-up.active .button {
  background-image: url("assets/images/icon-toolbar-sign-up-active.svg");
}
.component--button.log-out .button {
  background-image: url("assets/images/icon-log-out-inactive.svg");
}
.component--button.log-out:hover .button, .component--button.log-out.active .button {
  background-image: url("assets/images/icon-log-out-active.svg");
}
.component--button.dashboard .button {
  background-image: url("assets/images/icon-dashboard-inactive.svg");
}
.component--button.dashboard:hover .button, .component--button.dashboard.active .button {
  background-image: url("assets/images/icon-dashboard-active.svg");
}
.component--button.tiers .button {
  background-image: url("assets/images/icon-tiers-inactive.svg");
}
.component--button.tiers:hover .button, .component--button.tiers.active .button {
  background-image: url("assets/images/icon-tiers-active.svg");
}
.component--button.invitations {
  position: relative;
}
.component--button.invitations .number {
  position: absolute;
  top: -10px;
  left: -10px;
  height: 25px;
  width: 25px;
  border-radius: 13px;
  background-color: #f26c6c;
  font: 700 16px/25px Roboto;
  text-align: center;
  color: #ffffff;
}
.component--button.invitations .button {
  background-image: url("assets/images/icon-bell-inactive.svg");
}
.component--button.invitations:hover .button, .component--button.invitations.active .button {
  background-image: url("assets/images/icon-bell-active.svg");
}
.component--button:hover, .component--button.active {
  background-color: #3a4276;
}
.component--button:hover .text, .component--button.active .text {
  color: #ffffff;
}

.component--authentication-buttons {
  position: relative;
  display: flex;
  flex-shrink: 0;
  align-items: center;
}
.component--authentication-buttons .component--button {
  margin: 0;
  box-shadow: none;
}

.component--layer-button {
  display: flex;
  align-items: center;
  width: 90px;
  min-height: 35px;
  background: #fff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px rgba(58, 66, 118, 0.4);
  border-radius: 5px;
  cursor: pointer;
  font: 400 12px/13px Roboto;
  color: #333333;
  padding: 5px;
}
.component--layer-button .text {
  text-align: center;
  width: 100%;
  text-transform: capitalize;
  pointer-events: none;
}
.component--layer-button.active {
  background-color: #3A4276;
  color: #FFFFFF;
}
.component--layer-button.disable-for-demo {
  background-color: #E9EAEF;
  box-shadow: none;
}
.component--layer-button:hover {
  background: #3A4276;
  color: #ffffff;
}

.component--demo-layer-button {
  display: flex;
  align-items: center;
  width: 90px;
  min-height: 35px;
  box-shadow: 0px 3px 6px rgba(58, 66, 118, 0.4);
  border-radius: 5px;
  cursor: pointer;
  font: 400 12px/13px Roboto;
  color: #333333;
  padding: 5px;
  margin-top: 15px;
  margin-right: 10px;
  background: rgba(58, 66, 118, 0.062745098);
  box-shadow: none;
}
.component--demo-layer-button .text {
  text-align: center;
  width: 100%;
  text-transform: capitalize;
  pointer-events: none;
}

.component--layer-sector-panel {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 0 13px 8px 25px;
}

.component--layer-selection {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  padding-bottom: 10px;
}
.component--layer-selection .component--layer-button {
  margin-top: 15px;
  margin-right: 10px;
}
.component--layer-selection .component--busy-spinner {
  margin-top: 5px;
}
.component--layer-selection .sector-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.component--layer-selection .sector-label {
  text-align: center;
  font: 12px/20px Roboto;
  font-weight: 500;
  color: #333333;
  text-transform: capitalize;
  margin: 5px 0 0 0;
}
.component--layer-selection .component--tabs .component--tab-container {
  display: flex;
  justify-content: space-around;
  padding: 18px 18px 12px 18px;
}
.component--layer-selection .component--tabs .component--tab {
  height: 40px;
  width: 50px;
  border-radius: 0;
  box-shadow: 0px 3px 6px rgba(51, 51, 51, 0.062745098);
}
.component--layer-selection .component--tabs .component--tab.active {
  box-shadow: 0px 3px 6px rgba(51, 51, 51, 0.4);
  border-radius: 5px;
}
.component--layer-selection .component--tabs .component--tab.active .sector-icon.zoning {
  background-image: url("assets/images/icon-layers-sector-zoning-active.svg");
}
.component--layer-selection .component--tabs .component--tab.active .sector-icon.census {
  background-image: url("assets/images/icon-layers-sector-people-active.svg");
}
.component--layer-selection .component--tabs .component--tab.active .sector-icon.rates {
  background-image: url("assets/images/icon-layers-sector-multifamily-active.svg");
}
.component--layer-selection .component--tabs .component--tab.active .sector-icon.local {
  background-image: url("assets/images/icon-layers-sector-hotel-active.svg");
}
.component--layer-selection .component--tabs .component--tab.active .sector-icon.incentives {
  background-image: url("assets/images/icon-layers-sector-office-active.svg");
}
.component--layer-selection .component--tabs .component--tab.active .sector-icon.borders {
  background-image: url("assets/images/icon-layers-sector-general-active.svg");
}
.component--layer-selection .component--tabs .component--tab.active .sector-icon.environmental {
  background-image: url("assets/images/icon-layers-sector-environmental-active.svg");
}
.component--layer-selection .component--tabs .component--tab .sector-icon {
  background-repeat: no-repeat;
  background-position: center;
  width: 50px;
  height: 40px;
}
.component--layer-selection .component--tabs .component--tab .sector-icon.zoning {
  background-image: url("assets/images/icon-layers-sector-zoning.svg");
}
.component--layer-selection .component--tabs .component--tab .sector-icon.census {
  background-image: url("assets/images/icon-layers-sector-people.svg");
}
.component--layer-selection .component--tabs .component--tab .sector-icon.rates {
  background-image: url("assets/images/icon-layers-sector-multifamily.svg");
}
.component--layer-selection .component--tabs .component--tab .sector-icon.local {
  background-image: url("assets/images/icon-layers-sector-hotel.svg");
}
.component--layer-selection .component--tabs .component--tab .sector-icon.incentives {
  background-image: url("assets/images/icon-layers-sector-office.svg");
}
.component--layer-selection .component--tabs .component--tab .sector-icon.borders {
  background-image: url("assets/images/icon-layers-sector-general.svg");
}
.component--layer-selection .component--tabs .component--tab .sector-icon.environmental {
  background-image: url("assets/images/icon-layers-sector-environmental.svg");
}

.component--parcel-tools {
  display: flex;
  align-items: center;
  padding: 18px;
  height: 118px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
}
.component--parcel-tools .column {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.component--parcel-tools .column .control-icon {
  flex: 1 1 auto;
}
.component--parcel-tools .column .control-icon:not(:first-child) {
  margin-top: 6px;
}
.component--parcel-tools .column:not(:first-child) {
  margin-left: 13px;
}
.component--parcel-tools .component--map-style-button {
  width: 112px;
  height: 81px;
  border-radius: 7px;
  box-shadow: 0px 3px 6px rgba(58, 66, 118, 0.4);
  background-size: cover;
}
.component--parcel-tools .control-icon {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px rgba(58, 66, 118, 0.4);
  border-radius: 7px;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-position: center;
  width: 45px;
  height: 38px;
  display: flex;
  align-items: center;
  font: 500 20px/21px Roboto;
  justify-content: center;
  cursor: pointer;
}
.component--parcel-tools .control-icon.disabled {
  pointer-events: none;
}
.component--parcel-tools .control-icon:hover, .component--parcel-tools .control-icon.active {
  background-color: #3A4276;
}
.component--parcel-tools .select-parcel {
  background-image: url("./assets/images/parcel-tools-select-parcel.svg");
}
.component--parcel-tools .select-parcel:hover, .component--parcel-tools .select-parcel.active {
  background-image: url("./assets/images/parcel-tools-select-parcel-hover.svg");
}
.component--parcel-tools .combine-parcels {
  background-image: url("./assets/images/parcel-tools-combine-parcels.svg");
}
.component--parcel-tools .combine-parcels:hover, .component--parcel-tools .combine-parcels.active {
  background-image: url("./assets/images/parcel-tools-combine-parcels-hover.svg");
}
.component--parcel-tools .draw-parcel {
  background-image: url("./assets/images/parcel-tools-draw-parcel.svg");
}
.component--parcel-tools .draw-parcel:hover, .component--parcel-tools .draw-parcel.active {
  background-image: url("./assets/images/parcel-tools-draw-parcel-hover.svg");
}
.component--parcel-tools .edit-parcel {
  background-image: url("./assets/images/parcel-tools-edit-parcel.svg");
}
.component--parcel-tools .edit-parcel:hover, .component--parcel-tools .edit-parcel.active {
  background-image: url("./assets/images/parcel-tools-edit-parcel-hover.svg");
}
.component--parcel-tools .delete-parcel {
  background-image: url("./assets/images/parcel-tools-delete-parcel.svg");
}
.component--parcel-tools .delete-parcel:hover, .component--parcel-tools .delete-parcel.active {
  background-image: url("./assets/images/parcel-tools-delete-parcel-hover.svg");
}
.component--parcel-tools .zoom-in {
  font-size: 25px;
  line-height: 25px;
}
.component--parcel-tools .zoom-in::after {
  content: "+";
}
.component--parcel-tools .zoom-in:hover {
  color: #ffffff;
}
.component--parcel-tools .zoom-out {
  font: normal 44px/45px Roboto;
  line-height: 40px;
}
.component--parcel-tools .zoom-out::after {
  content: "-";
}
.component--parcel-tools .zoom-out:hover {
  color: #ffffff;
}
.component--parcel-tools .rotate {
  background-image: url("./assets/images/icon-maps-rotate-blue.svg");
}
.component--parcel-tools .rotate:hover {
  background-image: url("./assets/images/icon-maps-rotate-white.svg");
}
.component--parcel-tools .toggle-pitch:hover {
  color: #ffffff;
}
.component--parcel-tools .toggle-pitch.is-3d::after {
  content: "2D";
}
.component--parcel-tools .toggle-pitch.is-2d::after {
  content: "3D";
}

.component--parcel-tools {
  display: flex;
  align-items: center;
  padding: 18px;
  height: 118px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
}
.component--parcel-tools .column {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.component--parcel-tools .column .control-icon {
  flex: 1 1 auto;
}
.component--parcel-tools .column .control-icon:not(:first-child) {
  margin-top: 6px;
}
.component--parcel-tools .column:not(:first-child) {
  margin-left: 13px;
}
.component--parcel-tools .component--map-style-button {
  width: 112px;
  height: 81px;
  border-radius: 7px;
  box-shadow: 0px 3px 6px rgba(58, 66, 118, 0.4);
  background-size: cover;
}
.component--parcel-tools .control-icon {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px rgba(58, 66, 118, 0.4);
  border-radius: 7px;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-position: center;
  width: 45px;
  height: 38px;
  display: flex;
  align-items: center;
  font: 500 20px/21px Roboto;
  justify-content: center;
  cursor: pointer;
}
.component--parcel-tools .control-icon.disabled {
  pointer-events: none;
}
.component--parcel-tools .control-icon:hover, .component--parcel-tools .control-icon.active {
  background-color: #3A4276;
}
.component--parcel-tools .select-parcel {
  background-image: url("./assets/images/parcel-tools-select-parcel.svg");
}
.component--parcel-tools .select-parcel:hover, .component--parcel-tools .select-parcel.active {
  background-image: url("./assets/images/parcel-tools-select-parcel-hover.svg");
}
.component--parcel-tools .combine-parcels {
  background-image: url("./assets/images/parcel-tools-combine-parcels.svg");
}
.component--parcel-tools .combine-parcels:hover, .component--parcel-tools .combine-parcels.active {
  background-image: url("./assets/images/parcel-tools-combine-parcels-hover.svg");
}
.component--parcel-tools .copy-parcel {
  background-image: url("./assets/images/parcel-tools-copy-parcel.svg");
}
.component--parcel-tools .copy-parcel:hover, .component--parcel-tools .copy-parcel.active {
  background-image: url("./assets/images/parcel-tools-copy-parcel-hover.svg");
}
.component--parcel-tools .draw-parcel {
  background-image: url("./assets/images/parcel-tools-draw-parcel.svg");
}
.component--parcel-tools .draw-parcel:hover, .component--parcel-tools .draw-parcel.active {
  background-image: url("./assets/images/parcel-tools-draw-parcel-hover.svg");
}
.component--parcel-tools .edit-parcel {
  background-image: url("./assets/images/parcel-tools-edit-parcel.svg");
}
.component--parcel-tools .edit-parcel:hover, .component--parcel-tools .edit-parcel.active {
  background-image: url("./assets/images/parcel-tools-edit-parcel-hover.svg");
}
.component--parcel-tools .delete-parcel {
  background-image: url("./assets/images/parcel-tools-delete-parcel.svg");
}
.component--parcel-tools .delete-parcel:hover, .component--parcel-tools .delete-parcel.active {
  background-image: url("./assets/images/parcel-tools-delete-parcel-hover.svg");
}
.component--parcel-tools .zoom-in {
  font-size: 25px;
  line-height: 25px;
}
.component--parcel-tools .zoom-in::after {
  content: "+";
}
.component--parcel-tools .zoom-in:hover {
  color: #ffffff;
}
.component--parcel-tools .zoom-out {
  font: normal 44px/45px Roboto;
  line-height: 40px;
}
.component--parcel-tools .zoom-out::after {
  content: "-";
}
.component--parcel-tools .zoom-out:hover {
  color: #ffffff;
}
.component--parcel-tools .rotate {
  background-image: url("./assets/images/icon-maps-rotate-blue.svg");
}
.component--parcel-tools .rotate:hover {
  background-image: url("./assets/images/icon-maps-rotate-white.svg");
}
.component--parcel-tools .toggle-pitch:hover {
  color: #ffffff;
}
.component--parcel-tools .toggle-pitch.is-3d::after {
  content: "2D";
}
.component--parcel-tools .toggle-pitch.is-2d::after {
  content: "3D";
}
.component--parcel-tools .component--share-project,
.component--parcel-tools .component--copy-project,
.component--parcel-tools .component--new-project {
  height: 100%;
  padding: 14px 0 8px;
  justify-content: space-between;
}
.component--parcel-tools .component--share-project .icon,
.component--parcel-tools .component--copy-project .icon,
.component--parcel-tools .component--new-project .icon {
  margin-bottom: 10px;
}
.component--parcel-tools .component--share-project .text,
.component--parcel-tools .component--copy-project .text,
.component--parcel-tools .component--new-project .text {
  font: 500 12px/13px Roboto;
  color: #000000;
}
.component--parcel-tools .component--share-project:hover .text, .component--parcel-tools .component--share-project.active .text,
.component--parcel-tools .component--copy-project:hover .text,
.component--parcel-tools .component--copy-project.active .text,
.component--parcel-tools .component--new-project:hover .text,
.component--parcel-tools .component--new-project.active .text {
  color: #ffffff;
}
.component--parcel-tools .component--share-project .icon {
  background-image: url("assets/images/icon-share-project-black.svg");
}
.component--parcel-tools .component--share-project:hover .icon, .component--parcel-tools .component--share-project.active .icon {
  background-image: url("assets/images/icon-share-project-white.svg");
}
.component--parcel-tools .component--share-project:hover .text, .component--parcel-tools .component--share-project.active .text {
  color: #ffffff;
}

.component--copy-project-popup {
  position: absolute;
  top: 270px;
  left: 310px;
  width: 360px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  color: #818181;
  font: 400 13px/16px Roboto;
  text-align: center;
  border-radius: 6px;
}
.component--copy-project-popup::before {
  position: absolute;
  top: -34px;
  left: 30px;
  content: "";
  width: 30px;
  height: 50px;
  background-image: url("assets/images/tooltip-triangle-blue.svg");
  background-size: contain;
  background-repeat: no-repeat;
  transform: rotate(90deg);
}
.component--copy-project-popup::after {
  position: absolute;
  top: 0px;
  left: 30px;
  content: "";
  width: 50px;
  height: 10px;
  background-color: #3A4276;
  border-radius: 3px;
}
.component--copy-project-popup .top {
  width: 100%;
  background-color: #3A4276;
  padding: 15px 12px 10px;
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 6px 6px 0 0;
}
.component--copy-project-popup .top .header {
  font: 900 16px/21px Roboto;
}
.component--copy-project-popup .top .top-separator {
  width: 52px;
  height: 1px;
  background-color: #FFFFFF;
  margin: 13px 0;
}
.component--copy-project-popup form {
  width: 100%;
  padding: 20px 16px;
}
.component--copy-project-popup form .input-container {
  display: flex;
  align-items: center;
  height: 25px;
  background-color: #F5F7F8;
  border-radius: 6px;
}
.component--copy-project-popup form label {
  color: #606060;
  font: 700 12px/21px Roboto;
  margin: 0 0 0 11px;
}
.component--copy-project-popup form input {
  font: 400 11px/21px Roboto;
  color: #ADADAD;
  flex-grow: 1;
}
.component--copy-project-popup form input::placeholder {
  color: #ADADAD;
}
.component--copy-project-popup form button {
  margin-top: 13px;
  width: 130px;
  height: 25px;
  font: 700 13px/21px Roboto;
  color: #FFFFFF;
  background-color: #4ACBC6;
  border-radius: 6px;
}

.component--unauthenticated-copy-project-popup {
  position: absolute;
  top: 270px;
  left: 310px;
  width: 360px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  color: #818181;
  font: 400 13px/16px Roboto;
  text-align: center;
  border-radius: 6px;
}
.component--unauthenticated-copy-project-popup::before {
  position: absolute;
  top: -34px;
  left: 30px;
  content: "";
  width: 30px;
  height: 50px;
  background-image: url("assets/images/tooltip-triangle-blue.svg");
  background-size: contain;
  background-repeat: no-repeat;
  transform: rotate(90deg);
}
.component--unauthenticated-copy-project-popup::after {
  position: absolute;
  top: 0px;
  left: 30px;
  content: "";
  width: 50px;
  height: 10px;
  background-color: #3A4276;
  border-radius: 3px;
}
.component--unauthenticated-copy-project-popup .top {
  width: 100%;
  background-color: #3A4276;
  padding: 15px 12px 10px;
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 6px 6px 0 0;
}
.component--unauthenticated-copy-project-popup .top .message {
  width: 310px;
}
.component--unauthenticated-copy-project-popup .bottom {
  width: 100%;
  padding: 22px 36px;
  display: flex;
  justify-content: space-between;
}
.component--unauthenticated-copy-project-popup .bottom button {
  width: 128px;
  height: 25px;
  background: #F26C6C 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.4);
  color: #fff;
  border-radius: 5px;
  font: Bold 13px/21px Roboto;
}
.component--unauthenticated-copy-project-popup .bottom button.cancel {
  background-color: #FFFFFF;
  color: #333333;
}
.component--unauthenticated-copy-project-popup .bottom button.create-account {
  border: none;
  margin: 0;
  padding: 0;
  min-height: auto;
}

.component--copy-project {
  display: flex;
  flex-direction: column;
}
.component--copy-project .text {
  font: 500 12px/13px Roboto;
  text-align: center;
}
.component--copy-project .duplicate-project {
  width: 18px;
  height: 18px;
  margin-bottom: 3px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("assets/images/icon-duplicate-project-black.svg");
}
.component--copy-project:hover .duplicate-project {
  background-image: url("assets/images/icon-duplicate-project-active.svg");
}

.component--redirect-warning-popup {
  position: absolute;
  top: 275px;
  left: 190px;
  width: 360px;
  height: 285px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  color: #818181;
  font: 400 13px/16px Roboto;
  text-align: center;
  border-radius: 6px;
}
.component--redirect-warning-popup .top {
  background-color: #3A4276;
  padding: 15px 12px 28px;
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 6px 6px 0 0;
}
.component--redirect-warning-popup .top .top-separator {
  width: 52px;
  height: 1px;
  background-color: #FFFFFF;
  margin-top: 13px;
}
.component--redirect-warning-popup::before {
  position: absolute;
  top: -34px;
  right: 122px;
  content: "";
  width: 30px;
  height: 50px;
  background-image: url("assets/images/tooltip-triangle-blue.svg");
  background-size: contain;
  background-repeat: no-repeat;
  transform: rotate(90deg);
}
.component--redirect-warning-popup::after {
  position: absolute;
  top: 0px;
  right: 110px;
  content: "";
  width: 50px;
  height: 10px;
  background-color: #3A4276;
  border-radius: 3px;
}
.component--redirect-warning-popup .buttons-container {
  margin-top: 25px;
  display: flex;
  width: 100%;
  justify-content: center;
}
.component--redirect-warning-popup button {
  width: 131px;
  height: 25px;
  min-height: 25px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2509803922);
  border-radius: 6px;
  border: none;
  color: #333333;
  font: 900 12px/12px Roboto;
  text-transform: capitalize;
  padding: 0;
}
.component--redirect-warning-popup button.continue {
  margin: 0 0 0 40px;
}
.component--redirect-warning-popup button.subscribe {
  background-color: #F26C6C;
  color: #FFFFFF;
  margin: 22px 0 0;
}
.component--redirect-warning-popup button:hover {
  border: none;
}
.component--redirect-warning-popup .separator {
  position: relative;
  margin-bottom: 32px;
  display: flex;
  justify-content: center;
  width: 360px;
  border-top: solid #EEEEEE 3px;
}
.component--redirect-warning-popup .circle {
  position: absolute;
  bottom: -17px;
  background-color: #FFFFFF;
  color: #E0E0E0;
  height: 38px;
  width: 38px;
  font: Bold 12px/12px Roboto;
  border-radius: 19px;
  border: solid #EEEEEE 3px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.component--redirect-warning-popup .subscribe-message {
  margin: 0 30px;
}

.component--new-project {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.component--new-project .icon {
  width: 18px;
  height: 18px;
  margin-bottom: 3px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("assets/images/icon-plus-black.svg");
}
.component--new-project .text {
  font: 500 8px/8px Roboto;
  color: #3A4276;
  text-align: center;
}
.component--new-project:hover .icon, .component--new-project.active .icon {
  background-image: url("assets/images/icon-plus-white.svg");
}

.component--with-panel-header {
  box-shadow: 3px 0px 6px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 23px;
}
.component--with-panel-header .bottom-container {
  height: 23px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.1019607843);
  flex-shrink: 0;
  z-index: 100;
  border-radius: 0 0 10px 10px;
}
.component--with-panel-header .with-panel-header {
  background: #3A4276 0% 0% no-repeat padding-box;
  border-radius: 10px;
  display: flex;
  height: 23px;
  width: 100%;
  justify-content: center;
  align-items: center;
  font: Bold 14px/25px Roboto;
  letter-spacing: 1.2px;
  color: #FFFFFF;
  position: relative;
  flex-shrink: 0;
}
.component--with-panel-header .with-panel-header.active {
  cursor: default;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.component--with-panel-header .with-panel-header .expand-icon {
  cursor: pointer;
  position: absolute;
  left: 18px;
  width: 14px;
  height: 14px;
  background: #F9F9FA 0% 0% no-repeat padding-box;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
}
.component--with-panel-header .with-panel-header .expand-icon.active::after {
  content: "–";
  font: 500 14px Roboto;
}
.component--with-panel-header .with-panel-header .expand-icon::after {
  position: relative;
  top: 0.5px;
  left: 0.5px;
  content: "+";
  font: 400 14px Roboto;
  text-align: center;
  width: 100%;
}

.component--subscribe-button, .disabled-button {
  height: 48px;
  width: 240px;
  border: none;
  border-radius: 4px;
  background-color: #FFFFFF;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2509803922);
  font: 700 15px/48px Roboto;
  color: #333333;
  text-transform: uppercase;
  text-align: center;
}
.component--subscribe-button:hover, .disabled-button:hover {
  background-color: #3A4276;
  color: #FFFFFF;
  text-decoration: none;
}

.disabled-button {
  background-color: rgba(51, 51, 51, 0.0823529412);
  color: rgba(51, 51, 51, 0.1450980392);
}
.disabled-button:hover {
  background-color: rgba(51, 51, 51, 0.0823529412);
  color: rgba(51, 51, 51, 0.1450980392);
  cursor: default;
}

.component-type-of-user {
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 35px 30px 20px;
}
.component-type-of-user .selection-container {
  display: flex;
}
.component-type-of-user .selection-container .user-type-box {
  height: 42px;
  width: 145px;
  display: flex;
  align-items: center;
  background-color: #E3E5E8;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1019607843);
  border-radius: 5px;
  padding: 0 10px;
  font: 700 14px/17px Roboto;
  color: #3A4276;
}
.component-type-of-user .selection-container .user-type-box:first-child {
  margin-right: 90px;
}
.component-type-of-user .selection-container .user-type-box input[type=checkbox] {
  appearance: none;
  height: 23px;
  width: 23px;
  border-radius: 12px;
  background-color: #CCCCCC;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  border: 3px solid #FFFFFF;
  margin-right: 12px;
}
.component-type-of-user .selection-container .user-type-box input[type=checkbox]:checked {
  background-color: #3A4276;
}
.component-type-of-user .selection-container .user-type-box input[type=checkbox]:focus {
  outline: none;
}
.component-type-of-user .selection-container .icon {
  width: 15px;
  height: 17px;
  margin-right: 6px;
  background-size: contain;
  background-repeat: no-repeat;
}
.component-type-of-user .selection-container .icon.individual {
  background-image: url("assets/images/icon-demographics-blue.svg");
}
.component-type-of-user .selection-container .icon.team {
  width: 25px;
  background-image: url("assets/images/icon-team.svg");
}
.component-type-of-user button {
  height: 34px;
  width: 142px;
  margin-top: 36px;
  border: none;
  border-radius: 4px;
  background-color: #3A4276;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2509803922);
  font: 700 15px/36px Roboto;
  color: #FFFFFF;
  text-align: center;
}

.component--tiers-panel {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  height: 620px;
}
.component--tiers-panel .sub-title-box {
  margin: 0 auto 20px auto;
  text-align: center;
  z-index: 100;
  flex-shrink: 0;
}
.component--tiers-panel .subtitle-label {
  color: #FFFFFF;
  font: 600 30px/45px Roboto;
  margin: 5px 0 0 0;
}
.component--tiers-panel .tier-sub-description, .component--tiers-panel .external-links {
  text-align: center;
  font: 700 15px/30px Roboto;
  color: #333333;
  margin-bottom: 0;
}
.component--tiers-panel .external-links {
  font-weight: 800;
  color: #4457d7;
  text-decoration: underline;
}
.component--tiers-panel .background {
  position: absolute;
  width: 100%;
  height: 600px;
  background-color: #3A4276;
  background-image: url("assets/images/world-map.svg");
  background-repeat: no-repeat;
  background-position: top;
  clip-path: polygon(101% 0, 101% 460px, 50% 580px, 0 460px, 0 0);
}
.component--tiers-panel .toggle-wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 282px;
  height: 64px;
  border-radius: 32px;
  background-color: #EDF1F7;
  margin: 5px auto 16px;
  z-index: 100;
  flex-shrink: 0;
}
.component--tiers-panel .toggle-wrapper .toggle-button {
  width: 122px;
  height: 44px;
  border-radius: 22px;
  font: 700 15px/44px Roboto;
  text-align: center;
  color: #333333;
  cursor: pointer;
}
.component--tiers-panel .toggle-wrapper .toggle-button.selected {
  background-color: #F26C6C;
  color: #FFFFFF;
}
.component--tiers-panel .discount-message {
  width: 350px;
  margin: 0 auto 35px auto;
  font: 700 14px/14px Roboto;
  color: #FFFFFF;
  text-align: center;
  z-index: 100;
  flex-shrink: 0;
}
.component--tiers-panel .tiers-wrapper {
  padding: 0 48px 35px;
  display: flex;
  justify-content: space-between;
  flex: 1 1 auto;
  height: 200px;
  background-color: #FFFFFF;
}
.component--tiers-panel .component--tier-panel {
  display: flex;
  flex-direction: column;
  width: 310px;
  background-color: #FFFFFF;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.1019607843);
  border-radius: 10px;
  overflow: hidden;
  z-index: 100;
}
.component--tiers-panel .component--tier-panel .plan-name {
  text-align: center;
  font: 700 26px/32px Roboto;
  color: #F26C6C;
  margin-top: 20px;
}
.component--tiers-panel .component--tier-panel .price {
  display: flex;
  justify-content: center;
  min-height: 56px;
  margin-top: 10px;
  font: 900 48px/56px Roboto;
  color: #3A4276;
}
.component--tiers-panel .component--tier-panel .price.custom {
  font-size: 30px;
}
.component--tiers-panel .component--tier-panel .price .suffix {
  margin-left: 16px;
  font: 700 18px/56px Roboto;
  color: #3A4276;
}
.component--tiers-panel .component--tier-panel .price.selected-plan {
  background-color: #FFFFFF;
  border-left: 1px solid #F26C6C;
  border-right: 1px solid #F26C6C;
}
.component--tiers-panel .component--tier-panel .no-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1019607843);
  flex-shrink: 0;
}
.component--tiers-panel .component--tier-panel .button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1019607843);
  flex-shrink: 0;
}
.component--tiers-panel .component--tier-panel .button-wrapper .selected-plan {
  background-color: #F26C6C;
  width: 100%;
  color: #FFFFFF;
  font: 700 20px/70px Roboto;
  text-transform: uppercase;
  text-align: center;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2509803922);
  border-radius: 4px;
  align-self: flex-end;
}
.component--tiers-panel .component--tier-panel .list-wrapper {
  padding: 0 10px;
  flex-grow: 1;
  overflow: auto;
}
.component--tiers-panel .component--tier-panel .list-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  font: 400 15px/45px Roboto;
  text-align: center;
  color: #333333;
  border-bottom: solid 1px #E4E9F2;
}
.component--tiers-panel .component--tier-panel .list-item.bold {
  font: 700 16px/45px Roboto;
}
.component--tiers-panel .component--tier-panel .list-item:first-child {
  margin-top: 8px;
}
.component--tiers-panel .component--tier-panel .bottom-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  margin: 35px 0 25px;
}
.component--tiers-panel .component--tier-panel .bottom-content .text {
  text-align: center;
  font: 400 15px/17px Roboto;
  color: #333333;
  margin-top: 15px;
}
.component--tiers-panel .component--tier-panel .bottom-content .button-wrapper {
  box-shadow: none;
  margin-top: 10px;
}
.component--tiers-panel .component--tier-panel .icon-graph {
  height: 51px;
  width: 64px;
  background-image: url("assets/images/icon-graph.svg");
  background-repeat: no-repeat;
  background-position: center;
}
.component--tiers-panel .component--tier-panel .bottom {
  height: 30px;
  width: 100%;
  border-radius: 0 0 10px 10px;
  box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.1019607843);
  flex-shrink: 0;
}

.component--no-credit-card-popup {
  width: 550px;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.component--no-credit-card-popup .text {
  margin: 35px 45px;
  text-align: center;
  font: 400 15px/20px Roboto;
  color: #333333;
}
.component--no-credit-card-popup button {
  width: 160px;
  height: 34px;
  border-radius: 7px;
  background-color: #FFFFFF;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  font: 700 14px/14px Roboto;
  color: #333333;
  margin-bottom: 20px;
}
.component--no-credit-card-popup button:hover {
  background-color: #3A4276;
  color: #FFFFFF;
}

.component--cancel-subscription-popup {
  width: 550px;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.component--cancel-subscription-popup .text {
  margin: 35px 45px;
  text-align: center;
  font: 400 15px/20px Roboto;
  color: #333333;
}
.component--cancel-subscription-popup .text span {
  font-weight: 700;
  color: #3A4276;
}
.component--cancel-subscription-popup .buttons-container {
  display: flex;
  width: 100%;
  justify-content: center;
}
.component--cancel-subscription-popup button {
  width: 160px;
  height: 34px;
  border-radius: 7px;
  background-color: #FFFFFF;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  font: 700 14px/14px Roboto;
  color: #333333;
  margin-bottom: 20px;
}
.component--cancel-subscription-popup button:hover, .component--cancel-subscription-popup button.cancel {
  background-color: #3A4276;
  color: #FFFFFF;
}
.component--cancel-subscription-popup button.cancel {
  display: flex;
  justify-content: center;
  align-items: center;
}
.component--cancel-subscription-popup button.close-button {
  margin-right: 30px;
}
.component--cancel-subscription-popup button.close-button :first-child {
  margin-right: 0;
}

.show-only-filter {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200;
}

.filter-box {
  display: flex;
  flex-direction: row;
  margin-left: 20px;
}

.label-text {
  color: #3a4276;
  margin: auto;
  font: 500 12px/20px Roboto;
  margin-left: 5px;
}

.custom-radio-selected {
  background-color: #c40243;
  border-radius: 50%;
  color: #c40243;
}

.component-label {
  color: #3a4276;
  font: 500 12px/20px Roboto;
  margin-bottom: 0;
}

.button-box {
  color: #c40243;
  margin-left: 10px;
  padding: 6px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: #c40243;
  background-color: #3a4276;
}

.filter-label {
  font: 500 10px/20px Roboto;
  color: #3a4276;
  margin-bottom: 0;
}

.component--layer-legend {
  display: flex;
  color: #333333;
  padding-bottom: 21px;
  flex-shrink: 0;
}
.component--layer-legend .icon {
  width: 100px;
  height: 84px;
  flex-shrink: 0;
  margin-right: 18px;
  background-size: cover;
  box-shadow: 0px 3px 6px rgba(58, 66, 118, 0.4);
  border-radius: 5px;
}
.component--layer-legend .legend-content {
  width: 100%;
}
.component--layer-legend .legend-content .content-wrapper {
  display: flex;
  justify-content: space-between;
}
.component--layer-legend .legend-content .content-wrapper .toggle-field-wrapper {
  width: auto;
  padding: 0;
}
.component--layer-legend .legend-content .content-wrapper .toggle-field-wrapper .toggle-container {
  padding: 0;
}
.component--layer-legend .legend-content .content-wrapper .toggle-field-wrapper .toggle-box {
  width: 34px;
  height: 23px;
  margin-right: 0;
}
.component--layer-legend .legend-content .content-wrapper .toggle-field-wrapper .toggle-box .toggle:before {
  height: 19px;
  width: 19px;
}
.component--layer-legend .legend-content .content-wrapper .toggle-field-wrapper .toggle-box input:checked + .toggle:before {
  transform: translateX(11px);
}
.component--layer-legend .legend-content .title-container {
  margin-right: 25px;
}
.component--layer-legend .legend-content .title-container .title {
  font: 900 16px/21px Roboto;
  text-transform: capitalize;
}
.component--layer-legend .legend-content .title-container .text {
  font: 400 12px/14px Roboto;
  margin: 3px 0;
}
.component--layer-legend .legend-content .legend-button {
  margin-top: 2px;
  width: 100%;
  color: #333333;
  cursor: pointer;
  background-color: #ebecf1;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.component--layer-legend .legend-content .legend-button.disabled {
  background: #fafbfc 0% 0% no-repeat padding-box;
  color: #D1D5DD;
}
.component--layer-legend .legend-content .legend-button.disabled span {
  background-image: url("assets/images/icon-expand-grey.svg");
  background-position: center;
}
.component--layer-legend .legend-content .legend-button span {
  flex-shrink: 0;
  margin-left: 10px;
  width: 16px;
  height: 10px;
  background: url("assets/images/icon-expand-blue.svg") no-repeat;
}
.component--layer-legend .legend-content .legend-button span.active {
  background: url("assets/images/icon-collapse-blue.svg") no-repeat;
}

.component--legend {
  border-top: 1px solid #e4e5e9;
  background-color: #ebecf1;
  padding: 10px;
}

.component--legend-row {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.component--legend-row .color {
  width: 20px;
  height: 20px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  margin-right: 15px;
  flex-shrink: 0;
}
.component--legend-row .color.circle {
  border-radius: 10px;
}
.component--legend-row .color.wide {
  width: 40px;
  height: 15px;
}

.layer-disclaimer-component {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.title-label {
  font: 500 11px/15px Roboto;
}

.text-label {
  font: 400 11px/15px Roboto;
}

.component--map-legend-panel {
  width: 100%;
  background-color: #ffffff;
  padding: 30px 18px;
  pointer-events: all;
  display: flex;
  flex-direction: column;
}
.component--map-legend-panel .section-divisor {
  padding-bottom: 5px;
  border-bottom: 1px solid #3a4276;
  margin-bottom: 27px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
}
.component--map-legend-panel .section-divisor label {
  margin: 0;
  font: bold 16px/21px Roboto;
  color: #333333;
}
.component--map-legend-panel .component--layer-legend {
  margin-bottom: 28px;
  border-bottom: 1px solid #f2f2f2;
}
.component--map-legend-panel .component--layer-legend:last-child {
  border-bottom: none;
  margin-bottom: 0;
}
.component--map-legend-panel .buildable-area-ratio .icon {
  background-image: url("assets/images/icon-legend-businesses.png");
}
.component--map-legend-panel .businesses .icon {
  background-image: url("assets/images/icon-legend-businesses.png");
}
.component--map-legend-panel .historic-buildings .icon {
  background-image: url("assets/images/icon-legend-historic-buildings.jpg");
}
.component--map-legend-panel .reits-projects .icon {
  background-image: url("assets/images/icon-reits-project.png");
}
.component--map-legend-panel .hotel-average-daily-rate .icon {
  background-image: url("assets/images/icon-hotel-average-daily-rate.png");
}
.component--map-legend-panel .office-rate .icon {
  background-image: url("assets/images/icon-office-rate.png");
}
.component--map-legend-panel .retail-rate .icon {
  background-image: url("assets/images/icon-retail-rate.png");
}
.component--map-legend-panel .industrial-rate .icon {
  background-image: url("assets/images/icon-industrial-rate.png");
}
.component--map-legend-panel .multifamily-rent .icon {
  background-image: url("assets/images/icon-multifamily-rent.png");
}
.component--map-legend-panel .education .icon {
  background-image: url("assets/images/icon-education.png");
}
.component--map-legend-panel .residential-vacancy .icon {
  background-image: url("assets/images/icon-legend-residential-vacancy.png");
}
.component--map-legend-panel .rent-per-income .icon {
  background-image: url("assets/images/icon-legend-rent-income-ratio.png");
}
.component--map-legend-panel .traffic-count .icon {
  background-image: url("assets/images/icon-legend-traffic-count.png");
}
.component--map-legend-panel .road-type .icon {
  background-image: url("assets/images/icon-legend-road-type.png");
}
.component--map-legend-panel .permits .icon {
  background-image: url("assets/images/icon-legend-permits.png");
}
.component--map-legend-panel .permits-change .icon {
  background-image: url("assets/images/icon-legend-permits-change.png");
}
.component--map-legend-panel .smart-search-results .icon {
  background-image: url("assets/images/icon-legend-search-results.png");
}
.component--map-legend-panel .buildings .icon {
  background-image: url("assets/images/icon-legend-buildings.png");
}
.component--map-legend-panel .opportunity-zone .icon {
  background-image: url("assets/images/icon-legend-opportunity-zone.png");
}
.component--map-legend-panel .zoning .icon {
  background-image: url("assets/images/icon-legend-zoning.png");
}
.component--map-legend-panel .census-tracts .icon {
  background-image: url("assets/images/icon-legend-census-tracts.png");
}
.component--map-legend-panel .employment-per-population .icon {
  background-image: url("assets/images/icon-employment-per-population.png");
}
.component--map-legend-panel .hospitals .icon {
  background-image: url("assets/images/icon-legend-reits-projects.png");
}
.component--map-legend-panel .hotels .icon {
  background-image: url("assets/images/icon-hotels.png");
}
.component--map-legend-panel .median-income .icon {
  background-image: url("assets/images/icon-median-income.png");
}
.component--map-legend-panel .median-rent .icon {
  background-image: url("assets/images/icon-median-rent.png");
}
.component--map-legend-panel .population .icon {
  background-image: url("assets/images/icon-population.png");
}
.component--map-legend-panel .zoning-density .icon {
  background-image: url("assets/images/icon-legend-zoning-density.png");
}
.component--map-legend-panel .cap-rates .icon {
  background-image: url("assets/images/icon-legend-cap-rates.png");
}
.component--map-legend-panel .federal-land .icon {
  background-image: url("assets/images/icon-legend-federal-land.png");
}
.component--map-legend-panel .protected-areas .icon {
  background-image: url("assets/images/icon-legend-protected-areas.png");
}
.component--map-legend-panel .forests .icon {
  background-image: url("assets/images/icon-legend-forests.png");
}
.component--map-legend-panel .hazardous-sites .icon {
  background-image: url("assets/images/icon-legend-hazardous-sites.png");
}
.component--map-legend-panel .watersheds .icon {
  background-image: url("assets/images/icon-legend-watersheds.png");
}
.component--map-legend-panel .historic-sites .icon {
  background-image: url("assets/images/icon-legend-historic-sites.png");
}
.component--map-legend-panel .parks .icon {
  background-image: url("assets/images/icon-legend-parks.png");
}
.component--map-legend-panel .indian-reservations .icon {
  background-image: url("assets/images/icon-legend-indian-reservations.png");
}
.component--map-legend-panel .bay-area-sites .icon {
  background-image: url("assets/images/icon-legend-bay-area-sites.png");
}
.component--map-legend-panel .lihtc-inventory .icon {
  background-image: url("assets/images/icon-legend-lihtc-inventory.png");
}
.component--map-legend-panel .city-boundaries .icon {
  background-image: url("assets/images/icon-legend-city-boundaries.png");
}
.component--map-legend-panel .dda .icon {
  background-image: url("assets/images/icon-legend-dda.png");
}
.component--map-legend-panel .qct .icon {
  background-image: url("assets/images/icon-legend-qct.png");
}
.component--map-legend-panel .toc .icon {
  background-image: url("assets/images/icon-legend-toc.png");
}
.component--map-legend-panel .wetlands .icon {
  background-image: url("assets/images/icon-legend-wetlands.png");
}
.component--map-legend-panel .sea-level .icon {
  background-image: url("assets/images/icon-legend-sea-level.png");
}
.component--map-legend-panel .areas-with-parcels .icon {
  background-image: url("assets/images/icon-legend-areas-with-parcels.png");
}
.component--map-legend-panel .listings .icon {
  background-image: url("assets/images/icon-legend-listing.png");
}
.component--map-legend-panel .industrial-sites-legend .icon {
  background-image: url("assets/images/industrial-sites-legend.png");
}
.component--map-legend-panel .multifamily-sites-legend .icon {
  background-image: url("assets/images/multifamily-sites-legend.png");
}

.component--demo-onboarding {
  position: fixed;
  bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 900;
}
.component--demo-onboarding .process {
  display: flex;
  justify-content: space-around;
  background-image: url("assets/images/demo-onboarding-background.jpg");
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2509803922);
  border-radius: 10px;
  width: 580px;
  height: 100px;
}
.component--demo-onboarding .step-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 100px;
}
.component--demo-onboarding .step-box .icon {
  width: 32px;
  height: 32px;
  margin: 15px 0;
}
.component--demo-onboarding .step-box .icon.step-one {
  background-image: url("assets/images/icon-demo-onboarding-step-one.svg");
}
.component--demo-onboarding .step-box .icon.step-two {
  background-image: url("assets/images/icon-demo-onboarding-step-two.svg");
}
.component--demo-onboarding .step-box .icon.step-three {
  background-image: url("assets/images/icon-demo-onboarding-step-three.svg");
}
.component--demo-onboarding .step-box .icon.step-four {
  background-image: url("assets/images/icon-demo-onboarding-step-four.svg");
}
.component--demo-onboarding .step-box .icon.step-five {
  background-image: url("assets/images/icon-demo-onboarding-step-five.svg");
}
.component--demo-onboarding .step-box .label {
  margin: 0px;
  text-align: center;
  font: 14px/20px Roboto;
  font-weight: normal;
  color: #D5D5E0;
}
.component--demo-onboarding .step-box.active .icon {
  width: 40px;
  height: 40px;
  margin: 10px 0;
}
.component--demo-onboarding .step-box.active .icon.step-one {
  background-image: url("assets/images/icon-demo-onboarding-step-one-active.svg");
}
.component--demo-onboarding .step-box.active .icon.step-two {
  background-image: url("assets/images/icon-demo-onboarding-step-two-active.svg");
}
.component--demo-onboarding .step-box.active .icon.step-three {
  background-image: url("assets/images/icon-demo-onboarding-step-three-active.svg");
}
.component--demo-onboarding .step-box.active .icon.step-four {
  background-image: url("assets/images/icon-demo-onboarding-step-four-active.svg");
}
.component--demo-onboarding .step-box.active .icon.step-five {
  background-image: url("assets/images/icon-demo-onboarding-step-five-active.svg");
}
.component--demo-onboarding .step-box.active .label {
  font: Bold 14px/20px Roboto;
  color: #3A4276;
  min-width: 100px;
}

.component--cancel-loading-button {
  display: flex;
  justify-content: center;
  padding: 10px 30px;
}
.component--cancel-loading-button .button {
  width: 150px;
  height: 30px;
  font-family: Roboto Condensed;
  background-color: #F26C6C;
  font-weight: 700;
  font-size: 18px;
  color: white;
  text-align: center;
  cursor: pointer;
}

.component--invitation-error-popup {
  width: 550px;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.component--invitation-error-popup .text {
  margin: 35px 45px;
  text-align: center;
  font: 400 15px/20px Roboto;
  color: #333333;
}
.component--invitation-error-popup .text span {
  font-weight: 700;
  color: #3A4276;
}
.component--invitation-error-popup .buttons-container {
  display: flex;
  width: 100%;
  justify-content: center;
}
.component--invitation-error-popup button {
  width: 160px;
  height: 34px;
  border-radius: 7px;
  background-color: #FFFFFF;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  font: 700 14px/14px Roboto;
  color: #333333;
  margin-bottom: 20px;
}
.component--invitation-error-popup button:hover, .component--invitation-error-popup button.cancel {
  background-color: #3A4276;
  color: #FFFFFF;
}
.component--invitation-error-popup button.cancel {
  display: flex;
  justify-content: center;
  align-items: center;
}
.component--invitation-error-popup button.close-button {
  margin-right: 30px;
}
.component--invitation-error-popup button.close-button :first-child {
  margin-right: 0;
}

td.component--cell-text {
  text-align: left;
  max-width: 200px;
}
td.component--cell-text.bold {
  font-weight: bold;
}
td.component--cell-text.blurry-text,
td.component--cell-text .blurry-text {
  color: transparent;
  text-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  user-select: none;
  pointer-events: none;
}
td.component--cell-text.justify-left {
  text-align: left;
}
td.component--cell-text .value,
td.component--cell-text .array-item {
  white-space: pre-line;
}

.search-loading-component {
  display: flex;
  flex-direction: column;
  width: 439px;
  padding: 16px 19px 19px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5019607843);
  border-radius: 10px;
}

.search-loading-label {
  text-align: center;
  font: bold 14px/22px Roboto;
  color: #3A4276;
  margin-top: 5px;
}

.component--map-style-button {
  width: 80px;
  height: 80px;
  background-repeat: no-repeat;
  background-size: 80px;
  background-color: transparent;
  cursor: pointer;
}
.component--map-style-button.streets-map-button {
  background-image: url("assets/images/map-view-streets.png");
}
.component--map-style-button.streets-map-button:hover {
  background-image: url("assets/images/map-view-streets-hover.png");
}
.component--map-style-button.satellite-map-button {
  background-image: url("assets/images/map-view-satellite.png");
}
.component--map-style-button.satellite-map-button:hover {
  background-image: url("assets/images/map-view-satellite-hover.png");
}

.component--dynamic-layers-selection {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  overflow-x: auto;
}

.dynamic-layer-selection-panel {
  margin: 15px 25px 10px 25px;
}

.dynamic-steps-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  padding: 0 10px;
}

.dynamic-layer-panel-title-box {
  display: flex;
}

.dynamic-layer-panel-title {
  font: normal 14px/16px Roboto;
  color: #666666;
  margin-bottom: 0;
  margin-top: 15px;
  padding-left: 5px;
}
.dynamic-layer-panel-title.value {
  font: bold 16px/18px Roboto;
  margin-top: 14px;
  color: #3A4276;
}

.component--dynamic-layer-box {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.slider-inner-wrapper {
  background-color: #FFFFFF;
  border: 1px solid #E0E1E9;
  border-radius: 10px;
  width: 100%;
  height: "auto";
  padding: 0 8px;
  margin: 1px 0px 0px 0px;
}

.slider-wrapper {
  background-color: #f5f8f9;
  border-radius: 10px;
  height: 16px;
  margin: 4px;
}

.years-label-box {
  display: flex;
  justify-content: space-between;
}

.year-label {
  font: bold 12px/12px Roboto;
  color: #3A4276;
  margin-top: 5px;
}

.divider-component-box {
  border-bottom: 1px solid rgba(58, 66, 118, 0.1882352941);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 25px 0 25px;
}

.divider-label {
  font: bold 14px/12px Roboto;
  color: #3A4276;
  margin-top: 5px;
}

.component--dynamic-layer-tabs {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 0 13px 8px 25px;
}

.dynamic-layer-button {
  margin-top: 15px;
  margin-right: 10px;
  align-items: center;
  width: 90px;
  min-height: 35px;
  background: #fff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px rgba(58, 66, 118, 0.4);
  border-radius: 5px;
  cursor: pointer;
  font: 400 12px/13px Roboto;
  color: #333333;
  padding: 5px;
  text-align: center;
}
.dynamic-layer-button.enabled {
  background: #3A4276;
  color: #ffffff;
}
.dynamic-layer-button:hover {
  background: #3A4276;
  color: #ffffff;
}

.button-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 100%;
  background: #7dc896 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2);
  border-radius: 7px;
  padding: 0 10px;
  text-align: center;
  font-family: "Roboto Condensed", sans-serif;
  color: white;
  font-weight: 600;
  font-size: 20px;
  margin-left: 10px;
}
.button-link:hover {
  background-color: #3a4276;
  color: white;
}

.component--email-verification-complete-page {
  color: #333333;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  background-color: #F6F6F6;
  min-height: 100%;
}
.component--email-verification-complete-page .hidden {
  display: none;
}
.component--email-verification-complete-page a {
  font: inherit;
  font-weight: bold;
  color: #3A4276;
}
.component--email-verification-complete-page button {
  background-color: #3A4276;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
}
.component--email-verification-complete-page .underlined-link {
  font-weight: 500;
  color: #333333;
  text-decoration: underline;
  cursor: pointer;
}
.component--email-verification-complete-page .underlined-link:hover {
  color: #3A4276;
  text-decoration: underline;
}
.component--email-verification-complete-page .flex-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.component--email-verification-complete-page .content-wrapper {
  position: absolute;
  top: 241px;
}
.component--email-verification-complete-page .header {
  font-size: 25px;
  font-family: "Roboto Condensed";
  font-weight: 700;
}
.component--email-verification-complete-page .container {
  width: 395px;
  background-color: #fbfbfb;
  display: flex;
  flex-direction: column;
  padding: 35px 30px 38px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.0901960784);
  border-radius: 10px;
}
.component--email-verification-complete-page .content-header {
  flex-shrink: 0;
  width: 240.5px;
  height: 39.5px;
  background-image: url("assets/images/logo-inline.svg");
  background-repeat: no-repeat;
  background-size: contain;
}
.component--email-verification-complete-page .text-box {
  height: auto;
  width: auto;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 16px;
  line-height: 19px;
}
.component--email-verification-complete-page .form-container + .text-box {
  margin-top: 33px;
}
.component--email-verification-complete-page .form-container .input-section {
  width: auto;
  margin-bottom: 16px;
}
.component--email-verification-complete-page .form-container .input-section .input-label {
  width: auto;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 14px;
  font-weight: bold;
  margin: 15px 0 5px 10px;
  line-height: 10px;
}
.component--email-verification-complete-page .form-container .input-section .input-field {
  position: relative;
  height: 32px;
  width: 335px;
  background-color: #FDFDFD;
  padding: 4px 13px 4px 4px;
  border: 1px solid #CCCCCC;
  border-radius: 8px;
}
.component--email-verification-complete-page .form-container .input-section .input-field input {
  border: none;
  width: 100%;
  height: 100%;
  padding-left: 10px;
  padding-right: 15px;
  font-family: inherit;
  font-size: 15px;
  background-color: transparent;
}
.component--email-verification-complete-page .form-container .input-section .input-field input:-webkit-autofill {
  border: none;
  box-shadow: 0 0 0px 1000px white inset;
}
.component--email-verification-complete-page .form-container .input-section .input-field input::placeholder {
  color: #CCCCCC;
}
.component--email-verification-complete-page .form-container .input-section .input-field.required:after {
  content: "*";
  position: absolute;
  top: 5px;
  right: 8px;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: bold;
  color: #F26C6C;
}
.component--email-verification-complete-page .form-container .submit {
  height: 35px;
  width: 97px;
  letter-spacing: 0.25px;
  padding: 0;
  pointer-events: all;
}
.component--email-verification-complete-page .form-container .submit.disabled {
  cursor: inherit;
  color: white;
}
.component--email-verification-complete-page .bold {
  font-weight: bold;
}
.component--email-verification-complete-page .error-message {
  display: flex;
  align-items: flex-end;
  height: 31px;
  margin: 25px 0 13px 5px;
  width: 294px;
  text-align: left;
  vertical-align: middle;
  font-family: "Roboto Condensed", "Regular";
  font-size: 13px;
  color: #F26C6C;
  line-height: 16px;
}
.component--email-verification-complete-page .reset-password {
  margin-top: 33px;
}
.component--email-verification-complete-page .tos-agreement {
  height: 19px;
  display: flex;
  align-items: center;
  margin-bottom: 36px;
}
.component--email-verification-complete-page .tos-agreement input[type=checkbox] {
  appearance: none;
  background-color: #F5F7F8;
  border: 1px solid #CCCCCC;
  padding: 6.5px;
  border-radius: 5px;
  width: 0;
  height: 0;
}
.component--email-verification-complete-page .tos-agreement input[type=checkbox]:checked {
  border: 1px solid #333333;
  background: url("assets/images/checkmark.svg");
  background-size: contain;
  background-repeat: no-repeat;
}
.component--email-verification-complete-page .tos-agreement input[type=checkbox]:focus {
  outline: none;
}
.component--email-verification-complete-page .tos-agreement .text-box {
  margin-left: 14px;
}
@media screen and (max-width: 794px) {
  .component--email-verification-complete-page .flex-wrapper {
    flex-direction: column-reverse;
  }
}

.component--explorer-toolbar {
  display: flex;
  height: 100%;
  gap: 10px;
  align-items: flex-start;
}

.explorer-toolbar {
  height: 100%;
  width: 439px;
  background: #fff 0 0 no-repeat padding-box;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}
.explorer-toolbar .component--logo .logo {
  margin: 0;
}
@media screen and (max-width: 800px) {
  .explorer-toolbar .component--go-to-new-project-button {
    visibility: hidden;
  }
}

.component--map {
  height: 100%;
  width: 100%;
  position: relative;
}
.component--map .map-pin {
  width: 30px;
}

.component--setback-icon {
  background-size: cover;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.4392156863);
  transition: width 0.3s, height 0.3s ease;
}
.component--setback-icon:hover, .component--setback-icon.selected {
  width: 45px;
  height: 45px;
}
.component--setback-icon.A {
  background-image: url("assets/images/setback-button-a.svg");
}
.component--setback-icon.A.selected {
  background-image: url("assets/images/setback-button-a-selected.svg");
}
.component--setback-icon.B {
  background-image: url("assets/images/setback-button-b.svg");
}
.component--setback-icon.B.selected {
  background-image: url("assets/images/setback-button-b-selected.svg");
}
.component--setback-icon.C {
  background-image: url("assets/images/setback-button-c.svg");
}
.component--setback-icon.C.selected {
  background-image: url("assets/images/setback-button-c-selected.svg");
}
.component--setback-icon.D {
  background-image: url("assets/images/setback-button-d.svg");
}
.component--setback-icon.D.selected {
  background-image: url("assets/images/setback-button-d-selected.svg");
}

.tool-tip {
  background-color: #333333;
  color: #FFFFFF;
  border-radius: 5px;
  padding: 3px 10px;
}

.component--thumbnail-map {
  position: fixed;
  right: -10000px;
  bottom: -10000px;
  z-index: -100;
}

.component--keep-project {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 182px;
  background-color: #ffffff;
  border-radius: 7px;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
  margin-left: 10px;
}
.component--keep-project .text {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #3a4276 0% 0% no-repeat padding-box;
  border-radius: 7px 7px 0px 0px;
  height: 37px;
  text-transform: uppercase;
  font: 900 13px/21px Roboto;
  color: #ffffff;
}
.component--keep-project .text .close {
  position: absolute;
  top: 3px;
  right: 3px;
  width: 15px;
  height: 15px;
  background-image: url("assets/images/icon-close-transparent.svg");
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  opacity: 1;
}
.component--keep-project .project-name {
  width: 180px;
  margin-top: 10px;
  font: 900 15px/15px Roboto;
  color: #333333;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  overflow: hidden;
}
.component--keep-project .button-container {
  display: flex;
  justify-content: center;
}
.component--keep-project .button-container button {
  width: 94px;
  height: 27px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  border-radius: 7px;
  margin-top: 18px;
  font: 900 12px/21px Roboto;
  color: #333333;
  letter-spacing: 0.18px;
}
.component--keep-project .underlined-text {
  margin-top: 6px;
  font: 300 12px/12px Roboto;
  color: #999999;
  text-decoration: underline;
  cursor: pointer;
}

.component--slider-box {
  height: 73px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  border-top: 1px solid #8E8E8E;
}
.component--slider-box.disabled {
  pointer-events: none;
}
.component--slider-box.disabled ::placeholder {
  color: rgb(204, 204, 204);
}
.component--slider-box.disabled .slider-title {
  color: rgb(204, 204, 204);
}
.component--slider-box.disabled .slider-row-value input::placeholder {
  color: rgb(204, 204, 204);
}
.component--slider-box.disabled .rc-slider-rail {
  background-color: #E9EAEF;
}
.component--slider-box:first-child {
  border-top: none;
}
.component--slider-box:last-child {
  border-bottom: 1px solid #8E8E8E;
  height: 74px;
}
.component--slider-box .slider-title {
  text-align: left;
  padding: 8px 15px 0px 0px;
  color: #666666;
  width: 100%;
  font: 400 12px/14px Roboto;
}
.component--slider-box .read-only-slider-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  padding: 8px 15px 0px 0px;
  color: #666666;
  width: 100%;
  font: 400 12px/14px Roboto;
}
.component--slider-box .slider-inner-wrapper {
  background-color: #FFFFFF;
  border: 1px solid #E0E1E9;
  border-radius: 10px;
  width: 100%;
  height: 15px;
  padding-top: 0px;
  padding-left: 4px;
  padding-right: 4px;
  margin: 2px 0px 0px 0px;
}
.component--slider-box .slider-limits-wrapper {
  display: flex;
  margin-top: 7px;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}
.component--slider-box .slider-row-limits {
  width: 50%;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #7d7d7d;
  white-space: pre-wrap;
  font: 400 12px/14px Roboto;
}
.component--slider-box .slider-row-limits input {
  padding-bottom: 3px;
  padding-top: 3px;
  height: 16px;
  width: 100px;
  background: #E0E1E9 0% 0% no-repeat padding-box;
  border-radius: 8px;
}
.component--slider-box .slider-row-limits input:focus {
  outline: 1px solid #13a7da;
  outline-offset: 0;
}
.component--slider-box .slider-row-limits:hover > input::placeholder {
  color: #13a7da;
}
.component--slider-box .slider-row-limits:first-child {
  justify-content: flex-start;
}
.component--slider-box .slider-row-limits:first-child input {
  padding-left: 5px;
  text-align: left;
}
.component--slider-box .slider-row-limits:last-child {
  justify-content: flex-end;
}
.component--slider-box .slider-row-limits:last-child input {
  padding-right: 15px;
  text-align: right;
}
.component--slider-box .slider-row-body {
  width: 268px;
}
.component--slider-box .slider-row-body .toggle-field-wrapper {
  align-items: center;
  display: flex;
  width: 100%;
  height: 100%;
  color: #666666;
}
.component--slider-box .slider-row-body .toggle-field-wrapper .toggle-container {
  align-items: center;
  align-content: flex-start;
  padding-left: 0px;
}
.component--slider-box .slider-row-body .toggle-field-wrapper .toggle-container .toggle-box {
  order: 2;
  margin-left: 26px;
  margin-bottom: 0;
}
.component--slider-box .slider-row-body .toggle-field-wrapper .toggle-container .toggle-label {
  margin-bottom: 0;
  order: 1;
  font-size: 12px;
}
.component--slider-box .slider-row-value {
  padding: 1px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1 1 0;
  white-space: pre-wrap;
}
.component--slider-box .slider-row-value input {
  background: #E9EAEF 0% 0% no-repeat padding-box;
  border-radius: 13px;
  width: 112px;
  height: 27px;
  font-size: 14px;
}
.component--slider-box .slider-row-value input::placeholder {
  color: #333333;
}
.component--slider-box .slider-row-value input:hover::placeholder {
  color: #13a7da;
}
.component--slider-box .slider-row-value input:focus {
  outline: 1px solid #13a7da;
  outline-offset: 1px;
}
.component--slider-box input[type=submit] {
  display: none;
}
.component--slider-box input {
  text-align: center;
  border: none;
  color: #13a7da;
  font-size: 12px;
  width: 80%;
}
.component--slider-box input ::placeholder {
  text-align: center;
  color: #333333;
}

.rc-slider-wrapper {
  background-color: #f5f8f9;
  border-radius: 10px;
  height: 16px;
  margin: 4px auto;
}

.rc-slider {
  position: relative;
  height: 12px;
  padding: 5px 0;
  width: 100%;
  border-radius: 6px;
  -ms-touch-action: none;
  touch-action: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rc-slider * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rc-slider-rail {
  position: absolute;
  width: 100%;
  background-color: #ACACAC;
  height: 6.4px;
  border-radius: 6px;
  top: 0px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto 0;
}

.rc-slider-track {
  position: absolute;
  left: 0;
  height: 4px;
  border-radius: 6px;
  background-color: #abe2fb;
  top: 0px;
  bottom: 0;
  height: 7px;
  left: 0;
  right: 0;
  margin: auto 0;
}

.rc-slider-handle {
  position: absolute;
  margin-left: -10px;
  margin-top: -8.5px;
  width: 20px;
  height: 20px;
  cursor: -webkit-grab;
  cursor: grab;
  border-radius: 50%;
  border: 4px solid #FFFFFF;
  box-shadow: 1.5px 1px 6px rgba(0, 0, 0, 0.5);
  transform: none !important;
}
.rc-slider-handle:active {
  cursor: grabbing;
}
.rc-slider-handle:focus {
  outline: none;
}

.rc-slider-mark {
  position: absolute;
  top: 18px;
  left: 0;
  width: 100%;
  font-size: 12px;
}

.rc-slider-mark-text {
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: #999;
}

.rc-slider-mark-text-active {
  color: #666;
}

.rc-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent;
}

.rc-slider-dot {
  position: absolute;
  bottom: -2px;
  margin-left: -4px;
  width: 8px;
  height: 8px;
  border: 2px solid #e9e9e9;
  background-color: #fff;
  cursor: pointer;
  border-radius: 50%;
  vertical-align: middle;
}

.rc-slider-dot:first-child {
  margin-left: -4px;
}

.rc-slider-dot:last-child {
  margin-left: -4px;
}

.rc-slider-dot-active {
  border-color: #96dbfa;
}

.rc-slider-vertical {
  width: 14px;
  height: 100%;
  padding: 0 5px;
}

.rc-slider-vertical .rc-slider-rail {
  height: 100%;
  width: 4px;
}

.rc-slider-vertical .rc-slider-track {
  left: 5px;
  bottom: 0;
  width: 4px;
}

.rc-slider-vertical .rc-slider-handle {
  margin-left: -5px;
  margin-bottom: -7px;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.rc-slider-vertical .rc-slider-mark {
  top: 0;
  left: 18px;
  height: 100%;
}

.rc-slider-vertical .rc-slider-step {
  height: 100%;
  width: 4px;
}

.rc-slider-vertical .rc-slider-dot {
  left: 2px;
  margin-bottom: -4px;
}

.rc-slider-vertical .rc-slider-dot:first-child {
  margin-bottom: -4px;
}

.rc-slider-vertical .rc-slider-dot:last-child {
  margin-bottom: -4px;
}

.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  display: block !important;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.rc-slider-tooltip-zoom-down-leave {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  display: block !important;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active,
.rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
  -webkit-animation-name: rcSliderTooltipZoomDownIn;
  animation-name: rcSliderTooltipZoomDownIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
  -webkit-animation-name: rcSliderTooltipZoomDownOut;
  animation-name: rcSliderTooltipZoomDownOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  -webkit-transform: scale(0, 0);
  transform: scale(0, 0);
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}

.rc-slider-tooltip-zoom-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

@-webkit-keyframes rcSliderTooltipZoomDownIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scale(0, 0);
    transform: scale(0, 0);
  }
  100% {
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@keyframes rcSliderTooltipZoomDownIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scale(0, 0);
    transform: scale(0, 0);
  }
  100% {
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@-webkit-keyframes rcSliderTooltipZoomDownOut {
  0% {
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scale(0, 0);
    transform: scale(0, 0);
  }
}
@keyframes rcSliderTooltipZoomDownOut {
  0% {
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scale(0, 0);
    transform: scale(0, 0);
  }
}
.rc-slider-tooltip {
  position: absolute;
  left: -9999px;
  top: -9999px;
  visibility: visible;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rc-slider-tooltip * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rc-slider-tooltip-hidden {
  display: none;
}

.rc-slider-tooltip-placement-top {
  padding: 4px 0 8px 0;
}

.rc-slider-tooltip-inner {
  padding: 6px 2px;
  min-width: 24px;
  height: 24px;
  font-size: 12px;
  line-height: 1;
  color: #fff;
  text-align: center;
  text-decoration: none;
  background-color: #6c6c6c;
  border-radius: 6px;
  box-shadow: 0 0 4px #d9d9d9;
}

.rc-slider-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
  bottom: 4px;
  left: 50%;
  margin-left: -4px;
  border-width: 4px 4px 0;
  border-top-color: #6c6c6c;
}

.component--developments-dropdown {
  width: 439px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  padding: 14px 18px;
}
.component--developments-dropdown .title-wrapper {
  letter-spacing: 0px;
  color: #3A4276;
  display: flex;
  justify-content: space-between;
  padding-bottom: 3px;
  align-items: flex-end;
  border-bottom: 1px solid #3A4276;
}
.component--developments-dropdown .title-wrapper .title {
  display: flex;
  align-items: center;
  justify-content: center;
  font: 700 14px/21px Roboto;
  border-bottom: none;
  padding: 0;
}
.component--developments-dropdown .title-wrapper .saved-message {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 66px;
  height: 19px;
  background-color: #F9CB58;
  color: #3A4276;
  font: 500 12px/14px Roboto;
  border-radius: 5px;
}
.component--developments-dropdown .projects-container {
  display: flex;
  height: 34px;
  margin-top: 12px;
  background: #3A4276 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  color: #fff;
  font: bold 14px/24px Roboto;
  cursor: pointer;
  width: 70%;
}
.component--developments-dropdown .projects-container .component--dropdown {
  padding: 0 11px;
}
.component--developments-dropdown .projects-container .component--dropdown .project-name-container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.component--developments-dropdown .projects-container .component--dropdown .project-name-container .project-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 230px;
}
.component--developments-dropdown .projects-container .component--dropdown .project-name-container .dropdown-icon {
  width: 20px;
  height: 12px;
  background: url("assets/images/icon-down-arrow-white.svg") no-repeat;
}
.component--developments-dropdown .projects-container .component--dropdown .dropdown-content {
  width: 100%;
  top: 35px;
  left: 0;
}
.component--developments-dropdown .projects-container .component--dropdown .dropdown-content .button {
  max-width: none;
}
.component--developments-dropdown .projects-container .button {
  justify-content: space-between;
  max-width: 210px;
}
.component--developments-dropdown .projects-container .button .component--delete-development .icon {
  min-width: 11.3px;
  height: 12.4px;
  background-image: none;
}
.component--developments-dropdown .projects-container .button .component--delete-development li:hover .button .component--delete-development .icon {
  background-image: url("assets/images/icon-trash-can-light.svg");
}
.component--developments-dropdown .projects-container .button:hover .icon {
  background-image: url("assets/images/icon-trash-can-red.svg");
}
.component--developments-dropdown .street-view-button {
  width: 30%;
}
.component--developments-dropdown .project-header-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.component--recalculate-popup {
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 550px;
  padding: 37px;
  color: #333333;
}
.component--recalculate-popup p {
  font-size: 15px;
}
.component--recalculate-popup .section-header {
  font: Bold 19px/23px Roboto Condensed;
  letter-spacing: 0;
  opacity: 1;
}
.component--recalculate-popup .bold {
  font-weight: bold;
}
.component--recalculate-popup .far-container {
  margin-bottom: 15px;
}
.component--recalculate-popup .far-container input {
  border: 2px solid #CCCCCC;
  border-radius: 8px;
  width: 100px;
  height: 37px;
  margin: 0 10px;
  text-align: center;
  font-size: 15px;
  font-family: "Roboto", sans-serif;
}
.component--recalculate-popup .far-container input:focus, .component--recalculate-popup .far-container input:hover {
  outline: none;
  outline-offset: 0;
}
.component--recalculate-popup .buttons-container {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}
.component--recalculate-popup .buttons-container button {
  flex-shrink: 0;
  flex-grow: 0;
  padding: 0;
  width: 115px;
  height: 32px;
  border-radius: 16px;
  font: Bold 13px/21px Roboto;
}
.component--recalculate-popup .buttons-container button:first-child {
  margin-right: 11px;
}
.component--recalculate-popup .buttons-container button.disabled {
  background: #CCCCCC;
  cursor: none;
}
.component--recalculate-popup .buttons-container button.continue {
  width: 200px;
  background: #F65959 0% 0% no-repeat padding-box;
}
.component--recalculate-popup .buttons-container button.continue:hover {
  background-color: #3A4276;
}
.component--recalculate-popup .buttons-container button.cancel {
  color: #F26C6C;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 2px solid #F26C6C;
}
.component--recalculate-popup .buttons-container button.cancel:hover {
  background-color: #F65959;
  color: white;
}

.component--remove-setback-popup {
  width: 360px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  background-color: #FFFFFF;
  font: 400 13px/16px Roboto;
  text-align: center;
  border-radius: 6px;
}
.component--remove-setback-popup .top {
  background-color: #3A4276;
  padding: 20px 18px 12px;
  color: #FFFFFF;
  border-radius: 6px 6px 0 0;
}
.component--remove-setback-popup .top .bold {
  font-weight: 700;
}
.component--remove-setback-popup .bottom {
  padding: 20px 0 25px;
}
.component--remove-setback-popup .buttons-container {
  display: flex;
  width: 100%;
  justify-content: center;
}
.component--remove-setback-popup button {
  width: 131px;
  height: 25px;
  min-height: 25px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2509803922);
  border-radius: 6px;
  border: none;
  color: #FFFFFF;
  font: 700 13px/13px Roboto;
  padding: 0;
}
.component--remove-setback-popup button.cancel {
  background-color: #3A4276;
}
.component--remove-setback-popup button.confirm {
  background-color: #F26C6C;
  margin-left: 35px;
}

.component--height-and-setbacks .setback-mode-title {
  margin-top: 55px;
}
.component--height-and-setbacks .text-box {
  color: #333333;
  margin: 10px 0 18px 0;
  font-size: 13px;
}
.component--height-and-setbacks .button-wrapper {
  display: flex;
  justify-content: center;
  margin: 23px 0;
}
.component--height-and-setbacks .button-wrapper button {
  width: 178px;
  height: 34px;
  border-radius: 6px;
  font: 900 14px/21px Roboto;
}
.component--height-and-setbacks .component--dropdown {
  width: 180px;
  background: #3A4276 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  color: #FFFFFF;
  border-radius: 3px;
  outline: none;
  margin-left: 5px;
  padding: 7px 9px;
}
.component--height-and-setbacks .component--dropdown .floor-toggle-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
}
.component--height-and-setbacks .component--dropdown .floor-toggle-button .down-arrow {
  background-image: url("assets/images/icon-down-arrow-white.svg");
  background-size: contain;
  width: 15px;
  height: 9px;
}
.component--height-and-setbacks .component--dropdown .dropdown-content {
  top: 35px;
  left: 0;
  width: 180px;
  border-radius: 0 0 3px 3px;
}
.component--height-and-setbacks .component--dropdown .dropdown-content li {
  padding: 0 10px;
}
.component--height-and-setbacks .component--dropdown .dropdown-content li.inactive-divisor {
  padding: 0 10px;
  display: flex;
  margin: 0 10px;
  justify-content: center;
  align-items: center;
  height: 35px;
  color: #fff;
  border-top: 1px solid #fff;
  cursor: inherit;
}
.component--height-and-setbacks .component--dropdown .dropdown-content li.inactive-divisor:hover {
  background-color: inherit;
}
.component--height-and-setbacks .component--dropdown .dropdown-content li.inactive-divisor:hover + li .button {
  border-color: #3A4377;
}
.component--height-and-setbacks .component--dropdown .dropdown-content .button {
  justify-content: space-between;
}
.component--height-and-setbacks .component--dropdown .dropdown-content .button.with-setback {
  color: #F9CB58;
}
.component--height-and-setbacks .component--dropdown .dropdown-content .button.inactive {
  color: #D5D5E0;
}
.component--height-and-setbacks .component--dropdown .dropdown-content .button.inactive .remove-setback {
  background-image: url("assets/images/icon-trash-can-light.svg");
}
.component--height-and-setbacks .remove-setback {
  margin-right: 3px;
  min-width: 12px;
  height: 12px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("assets/images/icon-trash-can-yellow.svg");
}

.component--define-building-panel {
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  height: 100%;
  transition: height 0.3s linear, visibility 0.2s linear, opacity 0.2s linear;
  overflow: hidden;
}
.component--define-building-panel .project-name {
  border: 1px solid #cccccc;
  border-radius: 7px;
}
.component--define-building-panel .project-name {
  width: 100%;
  padding: 8px 10px;
  font-size: 15px;
}
.component--define-building-panel .panel-content {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
}
.component--define-building-panel .panel-content * {
  flex-shrink: 0;
}
.component--define-building-panel .recalculate-button {
  display: block;
  width: 178px;
  height: 34px;
  text-transform: capitalize;
  font: 800 15px/21px Roboto;
  letter-spacing: 0.13px;
  margin: 30px auto 10px auto;
  border-radius: 7px;
  background: #F26C6C 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3137254902);
}
.component--define-building-panel .recalculate-button:hover {
  background-color: #3A4276;
}
.component--define-building-panel label {
  margin: 0;
}
.component--define-building-panel .top-label {
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
  font: 700 16px/21px Roboto;
  color: #3A4276;
  text-transform: capitalize;
  border-bottom: 1px solid #3A4276;
}
.component--define-building-panel .uses-container {
  color: #3A4276;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}
.component--define-building-panel .component--unit-system-input {
  color: #3A4276;
  padding: 0 18px;
  height: 73px;
  background-color: #E9EAEF;
  flex-shrink: 0;
}
.component--define-building-panel .component--unit-system-input label {
  font-size: 15px;
}
.component--define-building-panel .component--unit-system-input label:first-child {
  margin-right: 35px;
}
.component--define-building-panel .input-field-wrapper .top-label {
  margin-bottom: 8px;
}
.component--define-building-panel .project-name {
  height: 30px;
}
.component--define-building-panel .hidden {
  display: none;
}
.component--define-building-panel .error-message {
  display: none;
}

.component--setback-tools {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 18px 18px 0 18px;
}
.component--setback-tools .column {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.component--setback-tools .column .control-icon {
  flex: 1 1 auto;
}
.component--setback-tools .column .control-icon:not(:first-child) {
  margin-top: 6px;
}
.component--setback-tools .column:not(:first-child) {
  margin-left: 13px;
}
.component--setback-tools .control-icon {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px rgba(58, 66, 118, 0.4);
  border-radius: 7px;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-position: center;
  width: 45px;
  height: 38px;
  display: flex;
  align-items: center;
  font: 500 20px/21px Roboto;
  justify-content: center;
  cursor: pointer;
}
.component--setback-tools .control-icon.disabled {
  pointer-events: none;
}
.component--setback-tools .control-icon:hover, .component--setback-tools .control-icon.active {
  background-color: #3a4276;
}
.component--setback-tools .zoom-in {
  font-size: 25px;
  line-height: 25px;
}
.component--setback-tools .zoom-in::after {
  content: "+";
}
.component--setback-tools .zoom-in:hover {
  color: #ffffff;
}
.component--setback-tools .zoom-out {
  font: normal 44px/45px Roboto;
  line-height: 40px;
}
.component--setback-tools .zoom-out::after {
  content: "-";
}
.component--setback-tools .zoom-out:hover {
  color: #ffffff;
}
.component--setback-tools .visibility-box {
  display: flex;
  justify-content: left;
  margin-top: 20px;
}
.component--setback-tools .check {
  display: flex;
  justify-content: center;
  width: 150px;
  margin-right: 15px;
}
.component--setback-tools .check .checkmark {
  width: 18px;
  height: 18px;
  box-shadow: 0px 3px 6px rgba(58, 66, 118, 0.4);
  border-radius: 2px;
  cursor: pointer;
}
.component--setback-tools .check .checkmark.selected {
  background-color: #3a4276;
  background-image: url("assets/images/setback-check-active.svg");
  background-position: center;
  background-repeat: no-repeat;
}
.component--setback-tools .options-label {
  font: 500 13px Roboto;
  line-height: 20px;
  color: #333333;
  margin-left: 5px;
}
.component--setback-tools .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1019607843);
  border-radius: 7px;
  flex: 1 1 auto;
  padding: 10px 25px;
  height: 82px;
}
.component--setback-tools .setback-list-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 14px 0 0 0;
}
.component--setback-tools .setback-row {
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 14px;
  margin-bottom: 7px;
}
.component--setback-tools .header {
  font: 900 14px/14px Roboto;
  color: #3a4276;
}
.component--setback-tools .text {
  font: 400 12px/14px Roboto;
  color: #666666;
  float: left;
}
.component--setback-tools input[type=radio] {
  cursor: pointer;
  position: relative;
  float: right;
  border-radius: 50%;
  margin-right: 9px;
  border: 2px solid #e3e5e8;
  width: 13px;
  height: 13px;
  outline: none;
}
.component--setback-tools input[type=radio]:checked {
  background-color: #f26c6c;
  box-shadow: inset 0px 0px 0px 1px #ffffff;
  width: 13px;
  height: 13px;
}

.component--setbacks-floor-area {
  align-items: center;
  background-color: black;
  color: white;
  padding: 10px;
  border-radius: 3px;
  transition: padding 0.3s ease;
}
.component--setbacks-floor-area .area-value {
  font: 500 16px/12px Roboto;
  margin: 0;
}

.component--setback-close-panel {
  width: 172px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5019607843);
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.component--setback-close-panel .floor-label {
  height: 37px;
  background: #3A4276 0% 0% no-repeat padding-box;
  border-radius: 7px 7px 0px 0px;
  font: 900 13px/13px Roboto;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  width: 100%;
  text-transform: uppercase;
  align-items: center;
}
.component--setback-close-panel button {
  background: #F26C6C 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  border-radius: 7px;
  width: 136px;
  height: 26px;
  font: 900 12px/21px Roboto;
  color: #FFFFFF;
  margin-top: 17px;
}

.component--property-uses {
  width: 100%;
}
.component--property-uses .component--tabs .component--tab-container {
  background: #FFFFFF;
  align-items: flex-end;
  flex-wrap: wrap;
  padding-bottom: 0;
  padding-left: 5px;
}
.component--property-uses .component--tabs .component--tab {
  cursor: pointer;
  height: auto;
  width: auto;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  font: 400 12px/8px Roboto;
  flex-direction: column;
  box-shadow: none;
}
.component--property-uses .component--tabs .component--tab.active {
  color: inherit;
}
.component--property-uses .component--tabs .component--tab.active .icon {
  width: 73px;
  height: 59px;
}
.component--property-uses .component--tabs .component--tab.active label {
  margin-top: 4px;
}
.component--property-uses .component--tabs .component--tab:first-child {
  margin-left: 13px;
}
.component--property-uses .component--tabs .component--tab label {
  margin-top: 6px;
  text-transform: capitalize;
  cursor: pointer;
}
.component--property-uses .component--tabs .component--tab .icon {
  width: 50px;
  height: 46px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1019607843);
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 28px 26px;
  border-radius: 5px;
}
.component--property-uses .component--tabs .component--tab .icon.condo {
  background-image: url("assets/images/icon-condo-white.svg");
}
.component--property-uses .component--tabs .component--tab .icon.multifamily {
  background-image: url("assets/images/icon-multifamily-white.svg");
}
.component--property-uses .component--tabs .component--tab .icon.hotel {
  background-image: url("assets/images/icon-hotel-white.svg");
}
.component--property-uses .component--tabs .component--tab .icon.office {
  background-image: url("assets/images/icon-office-white.svg");
}
.component--property-uses .component--tabs .component--tab .icon.industrial {
  background-image: url("assets/images/icon-industrial-white.svg");
}
.component--property-uses .component--tabs .component--tab .icon.retail {
  background-image: url("assets/images/icon-retail-white.svg");
}
.component--property-uses .component--tabs .component--tab .icon.parking {
  background-image: url("assets/images/icon-parking-white.svg");
}

.component--assumptions-panel {
  display: flex;
  background-color: #FFFFFF;
  height: 100%;
  transition: height 0.3s linear, visibility 0.2s linear, opacity 0.2s linear;
  overflow: auto;
}
.component--assumptions-panel .panel-content {
  padding-bottom: 24px;
}

.component--from-contact-details {
  display: flex;
  align-items: center;
  margin-top: 25px;
}
.component--from-contact-details .label-container {
  height: 68px;
  border-right: 1px solid #F26C6C;
}
.component--from-contact-details .label-container label {
  font: Bold 15px Roboto;
  text-transform: uppercase;
  margin: 8px 0 0 0;
  color: #F26C6C;
}
.component--from-contact-details .label-container .contact-icon {
  width: 28px;
  height: 28px;
  background: url("assets/images/icon-contact-red.svg") no-repeat;
  background-size: contain;
}
.component--from-contact-details .inputs-container {
  width: 100%;
  flex-shrink: 1;
}
.component--from-contact-details .inputs-container input {
  width: 100%;
  height: 21px;
  border: 1px solid #CCCCCC;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.0509803922);
  margin-bottom: 4px;
  border-radius: 3px;
  padding-left: 8px;
  font: normal 12px/12px Roboto;
  text-transform: capitalize;
  outline: none;
}
.component--from-contact-details .inputs-container input::placeholder {
  color: #ABAFB3;
  text-transform: capitalize;
}
.component--from-contact-details .inputs-container .with-label {
  display: flex;
}
.component--from-contact-details .inputs-container .with-label * {
  flex-shrink: 1;
}
.component--from-contact-details .inputs-container .with-label .input-label {
  width: 21px;
  height: 21px;
  border-radius: 3px 0 0 3px;
  background-repeat: no-repeat;
  background-size: 7px 12px;
  background-position: center;
}
.component--from-contact-details .inputs-container .with-label .input-label.phone-icon {
  background-color: #F26C6C;
  background-image: url("assets/images/icon-phone-white.svg");
}
.component--from-contact-details .inputs-container .with-label .input-label.email-icon {
  background-color: #F26C6C;
  background-image: url("assets/images/icon-at-white.svg");
}
.component--from-contact-details .inputs-container .with-label input {
  border-radius: 0 3px 3px 0;
  border-left: 0;
}
.component--from-contact-details .inputs-container .email {
  text-transform: none;
}
.component--from-contact-details .inputs-container .phone-container input {
  margin-bottom: 0;
}

.component--to-contact-details {
  display: flex;
  align-items: center;
  margin-top: 25px;
}
.component--to-contact-details .label-container {
  height: 68px;
  border-right: 1px solid #59608D;
}
.component--to-contact-details .label-container label {
  font: Bold 15px Roboto;
  text-transform: uppercase;
  margin: 8px 0 0 0;
  color: #59608D;
}
.component--to-contact-details .label-container .contact-icon {
  width: 28px;
  height: 28px;
  background: url("assets/images/icon-contact-blue.svg") no-repeat;
  background-size: contain;
}
.component--to-contact-details .inputs-container {
  width: 100%;
  flex-shrink: 1;
}
.component--to-contact-details .inputs-container input {
  width: 100%;
  height: 21px;
  border: 1px solid #CCCCCC;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.0509803922);
  margin-bottom: 4px;
  border-radius: 3px;
  padding-left: 8px;
  font: normal 12px/12px Roboto;
  text-transform: capitalize;
  outline: none;
}
.component--to-contact-details .inputs-container input::placeholder {
  color: #ABAFB3;
  text-transform: capitalize;
}
.component--to-contact-details .inputs-container .with-label {
  display: flex;
}
.component--to-contact-details .inputs-container .with-label * {
  flex-shrink: 1;
}
.component--to-contact-details .inputs-container .with-label .input-label {
  width: 21px;
  height: 21px;
  border-radius: 3px 0 0 3px;
  background-repeat: no-repeat;
  background-size: 7px 12px;
  background-position: center;
}
.component--to-contact-details .inputs-container .with-label .input-label.phone-icon {
  background-color: #59608D;
  background-image: url("assets/images/icon-phone-white.svg");
}
.component--to-contact-details .inputs-container .with-label .input-label.email-icon {
  background-color: #59608D;
  background-image: url("assets/images/icon-at-white.svg");
}
.component--to-contact-details .inputs-container .with-label input {
  border-radius: 0 3px 3px 0;
  border-left: 0;
}
.component--to-contact-details .inputs-container .email {
  text-transform: none;
}
.component--to-contact-details .inputs-container .phone-container input {
  margin-bottom: 0;
}

.component--summary {
  margin-top: 40px;
  display: flex;
}
.component--summary .label-container {
  font: Bold 12px Roboto;
}
.component--summary .label-container .summary-label {
  width: 25px;
  height: 30px;
  margin-bottom: 4px;
  background: url("assets/images/icon-summary.svg") no-repeat;
}
.component--summary .text-container {
  height: 98px;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
}
.component--summary .text-container .summary-text {
  line-height: 13px;
  padding: 8px;
  outline: none;
  height: 84px;
  resize: none;
  width: 100%;
  border-radius: 3px;
  border: 1px solid #CCCCCC;
  font: normal 12px/13px Roboto;
}
.component--summary .text-container .summary-text ::placeholder {
  color: #ABAFB3;
}
.component--summary .text-container .warning-message {
  font: Italic 9px/14px Roboto;
  color: #E86969;
  align-self: flex-end;
}

.component--drag-and-drop {
  box-sizing: content-box;
}
.component--drag-and-drop .drop-container {
  position: relative;
  border-radius: 3px;
  border: 0.5px dashed #CCCCCC;
  height: 49px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.component--drag-and-drop .drop-container.drag-over {
  border-color: black;
}
.component--drag-and-drop .drop-container .upload-logo {
  display: flex;
  align-items: flex-end;
  background: url("assets/images/icon-upload.svg") no-repeat;
  background-position: center;
  background-size: 25px 25px;
  height: 45px;
  font: normal 9px/10px Roboto;
}

.component--image-upload {
  display: flex;
  margin-top: 35px;
}
.component--image-upload .label-container .icon {
  width: 23px;
  height: 23px;
  background-size: contain;
  background-repeat: no-repeat;
}
.component--image-upload .label-container .cover-icon {
  background-image: url("assets/images/icon-upload-cover.svg");
}
.component--image-upload .label-container .logo-icon {
  background-image: url("assets/images/icon-upload-logo.svg");
}
.component--image-upload .drag-and-drop-container {
  border: 0.5px solid #CCCCCC;
  border-radius: 3px;
  flex: 1 1 auto;
  padding: 8px 7px 5px;
  display: flex;
  flex-direction: column;
}
.component--image-upload .drag-and-drop-container .component--busy-spinner {
  flex: 1 1 50px;
}
.component--image-upload .drag-and-drop-container .title {
  font: Bold 12px Roboto;
}
.component--image-upload .drag-and-drop-container .file-name {
  position: relative;
  color: #59608D;
  border: 1px dashed #59608D;
  padding: 5px 15px 5px 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  font: normal 12px/12px Roboto;
}
.component--image-upload .drag-and-drop-container .file-name .file-remove {
  position: absolute;
  top: 6px;
  right: 3px;
  width: 10px;
  height: 10px;
  border: 1px solid #F26C6C;
  border-radius: 5px;
  cursor: pointer;
  background: url("assets/images/icon-cross-out.svg") no-repeat;
  background-size: 4px 4px;
  background-position: center;
}

.component--color-palette {
  display: flex;
  margin-top: 40px;
  margin-bottom: 20px;
  position: relative;
}
.component--color-palette .palette-icon {
  width: 25px;
  height: 25px;
  background: url("assets/images/icon-color-palette.svg") no-repeat;
  background-size: contain;
}
.component--color-palette .color-selection-container {
  display: flex;
  flex: 1 1 100%;
  justify-content: space-evenly;
  height: 95px;
  border: 1px solid #CCCCCC;
  border-radius: 3px;
}
.component--color-palette .color-selection-container .color-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 auto;
  padding-top: 12px;
}
.component--color-palette .color-selection-container .color-section label {
  margin: 0;
  font: 500 10px/13px Roboto;
}
.component--color-palette .color-selection-container .color-section .color-preview {
  margin-top: 20px;
  width: 22px;
  height: 22px;
  border-radius: 11px;
  cursor: pointer;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
}
.component--color-palette .color-selection-container .color-section .pick-color {
  font: normal 8px/13px Roboto;
  color: #8D8D8D;
  margin-top: 5px;
}
.component--color-palette .color-picker-wrapper {
  position: absolute;
  top: -60px;
  left: 30%;
}
.component--color-palette .color-picker-wrapper .cover {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.component--color-palette .color-picker-wrapper .close-color-picker {
  position: absolute;
  top: -20px;
  left: 2px;
  cursor: pointer;
}

.component--pdf-panel {
  color: #464A53;
  width: 100%;
  padding: 0 18px;
}
.component--pdf-panel * {
  flex-shrink: 0;
}
.component--pdf-panel .panel-inner-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.component--pdf-panel .panel-inner-wrapper .export-button-box {
  width: 100%;
  display: flex;
  justify-content: center;
}
.component--pdf-panel .panel-inner-wrapper #export-pdf-button {
  font: 12px/21px Roboto;
  font-weight: 600;
  line-height: 1.5;
  border-radius: 3px;
  background: #F26C6C;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2509803922);
  padding: 0 20px;
  height: 32px;
  vertical-align: middle;
  align-self: center;
  margin: 18px 0 10px;
}
.component--pdf-panel .panel-inner-wrapper .end {
  height: 1px;
}
.component--pdf-panel .report-title {
  border: none;
  border-bottom: 1px solid #CCCCCC;
  padding-bottom: 4px;
  font: normal 15px/18px Roboto;
  outline: none;
  margin-top: 15px;
}
.component--pdf-panel .report-title ::placeholder {
  color: #CCCCCC;
}
.component--pdf-panel .address {
  border: none;
  border-bottom: 1px solid #CCCCCC;
  padding-bottom: 4px;
  font: normal 15px/18px Roboto;
  outline: none;
  margin-top: 18px;
}
.component--pdf-panel .address ::placeholder {
  color: #CCCCCC;
}
.component--pdf-panel .city,
.component--pdf-panel .state,
.component--pdf-panel .zip-code {
  border: none;
  border-bottom: 1px solid #CCCCCC;
  padding-bottom: 4px;
  font: normal 15px/18px Roboto;
  outline: none;
  width: 100px;
}
.component--pdf-panel .city ::placeholder,
.component--pdf-panel .state ::placeholder,
.component--pdf-panel .zip-code ::placeholder {
  color: #CCCCCC;
}
.component--pdf-panel .city {
  flex: 2;
}
.component--pdf-panel .state,
.component--pdf-panel .zip-code {
  flex: 1;
}
.component--pdf-panel .address-container {
  display: flex;
  margin-top: 18px;
}
.component--pdf-panel .address-container input {
  margin-right: 20px;
  flex: 1 0 auto;
}
.component--pdf-panel .address-container input:last-child {
  margin-right: 0;
}
.component--pdf-panel .label-container {
  margin-right: 20px;
  width: 80px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.component--pdf-panel .label-container label {
  font: Bold 12px Roboto;
  margin: 4px 0 0;
}
.component--pdf-panel .dimension-message {
  font: 400 Italic 12px/13px Roboto;
  text-align: right;
  margin-top: 5px;
  color: #4f4f4f;
}

.component--assumptions-csv-export-button {
  font: 12px/21px Roboto;
  font-weight: 600;
  line-height: 1;
  border-radius: 3px;
  background: #F26C6C;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2509803922);
  padding: 10px;
  margin-bottom: 20px;
  margin-left: 120px;
}

.component--return-analysis-panel {
  display: flex;
  background-color: #ffffff;
  height: 100%;
  transition: height 0.3s linear, visibility 0.2s linear, opacity 0.2s linear;
  overflow: auto;
}
.component--return-analysis-panel .panel-content {
  background-color: #ffffff;
  padding: 18px 18px 24px;
}
.component--return-analysis-panel .panel-content .component--accordion-section .section-title {
  background-color: #efeff4;
  border: none;
  text-transform: capitalize;
}
.component--return-analysis-panel .panel-content table {
  background-color: #ffffff;
  color: #939393;
}
.component--return-analysis-panel .panel-content table tr {
  line-height: 15px;
}
.component--return-analysis-panel .panel-content table tr.center td {
  text-align: center;
}
.component--return-analysis-panel .panel-content table tr:nth-child(even) {
  background-color: #fbfbfb;
}
.component--return-analysis-panel .panel-content table tr td {
  border: 1px solid #d1d5dd;
  border-top: none;
  vertical-align: top;
}
.component--return-analysis-panel .panel-content table tr td:first-child {
  padding: 10px 3px 10px 11px;
  font-weight: 400;
  width: 150px;
}
.component--return-analysis-panel .panel-content table tr td:last-child {
  padding: 10px 18px 10px 3px;
  font: 700 14px/15px Roboto;
  color: #3a4276;
}
.component--return-analysis-panel .panel-content table tr td.yellow {
  color: #f9b923;
  font-weight: 700;
}
.component--return-analysis-panel .panel-content table tr td.capitalize {
  text-transform: capitalize;
}
.component--return-analysis-panel .panel-content table tr td.uppercase {
  text-transform: uppercase;
}
.component--return-analysis-panel .panel-content table tr .array-item {
  margin-bottom: 6px;
  padding-left: 25px;
  display: block;
}
.component--return-analysis-panel .panel-content table tr .array-item:last-child {
  margin-bottom: 0;
}
.component--return-analysis-panel .panel-content table.section-end {
  margin: -18px 0 18px;
  background-color: #efeff4;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.0509803922);
  border-radius: 0px 0px 5px 5px;
}
.component--return-analysis-panel .panel-content table.section-end.separate {
  margin-top: 18px;
}
.component--return-analysis-panel .panel-content table.section-end td {
  border: none;
  padding-left: 16px !important;
}
.component--return-analysis-panel .panel-content table.section-end td .value {
  font: 700 16px/19px Roboto;
  color: #3a4276;
  text-transform: capitalize;
}
.component--return-analysis-panel .panel-content .selection-container {
  background: #efeff4 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.0509803922);
  margin-bottom: 30px;
  padding: 15px 10px 5px;
}
.component--return-analysis-panel .panel-content .selection-container .sale-lease {
  display: flex;
  border-bottom: 1px solid rgba(112, 112, 112, 0.2039215686);
  padding-bottom: 15px;
}
.component--return-analysis-panel .panel-content .selection-container .sale-lease label {
  display: flex;
  align-items: center;
  font: 500 14px/14px Roboto;
  color: #333333;
  margin: 0 35px 0 0;
}
.component--return-analysis-panel .panel-content .selection-container .sale-lease label.disabled {
  color: #cccccc;
}
.component--return-analysis-panel .panel-content .selection-container .sale-lease label input[type=radio] {
  cursor: pointer;
  border-radius: 50%;
  margin-right: 9px;
  border: 2px solid #3a4276;
  width: 15px;
  height: 15px;
  outline: none;
}
.component--return-analysis-panel .panel-content .selection-container .sale-lease label input[type=radio]:checked {
  background-color: #3a4276;
  box-shadow: inset 0px 0px 0px 1px #ffffff;
  width: 15px;
  height: 15px;
}
.component--return-analysis-panel .panel-content .selection-container .sale-lease label input[type=radio]:disabled {
  border: 2px solid #cccccc;
}
.component--return-analysis-panel .panel-content .slider-container {
  display: flex;
  padding-top: 5px;
}
.component--return-analysis-panel .panel-content .slider-container .text {
  font: 700 14px/16px Roboto;
  letter-spacing: 0.35px;
  color: #333333;
  margin-right: 15px;
}
.component--return-analysis-panel .panel-content .slider-container .component--slider-box {
  border: none;
}
.component--return-analysis-panel .panel-content .slider-container .component--slider-box .slider-row-body {
  width: 250px;
}
.component--return-analysis-panel .panel-content .slider-container .component--slider-box .slider-row-body .slider-title {
  background: #efeff4 0% 0% no-repeat padding-box;
  text-align: left;
  margin-left: 5px;
  padding: 5px 15px 0px 0px;
  color: #333333;
  font: 700 14px/14px Roboto;
}
.component--return-analysis-panel .panel-content .slider-container .component--slider-box input {
  background-color: #ffffff;
}
.component--return-analysis-panel .panel-content .slider-container .component--slider-box.disabled .slider-title {
  color: #cccccc;
}

.component--kpi-panel {
  display: flex;
  justify-content: center;
  height: 100%;
}
.component--kpi-panel .component--kpi-section {
  display: flex;
  flex-direction: column;
  min-width: 134px;
  position: relative;
}
.component--kpi-panel .component--kpi-section.dotted-right::after {
  height: 100%;
  content: "";
  position: absolute;
  right: 0;
  opacity: 0.6;
  border: 1px dashed #FFFFFF;
}
.component--kpi-panel .component--kpi-section.solid-right {
  border-right: 1px solid #ffffff;
}
.component--kpi-panel .component--kpi-section.roc-text {
  width: 150px;
}
.component--kpi-panel .component--kpi-section.roc-text .value {
  font: 900 34px/40px Roboto;
  color: #82DEDE;
}
.component--kpi-panel .component--kpi-section .value {
  color: #FFFFFF;
  font: 900 18px/21px Roboto;
  background-color: #000000;
  opacity: 0.77;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  padding: 0 3px;
}
.component--kpi-panel .component--kpi-section .text {
  background-color: #82DEDE;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  font: 900 13px/15px Roboto;
  color: #333333;
  text-transform: uppercase;
}

.component--property-info-panel {
  background-color: #FFFFFF;
  overflow: hidden;
  display: flex;
}
.component--property-info-panel .component--parcel-information-table {
  padding: 28px 18px;
  overflow-y: auto;
  overflow-x: hidden;
}

.component--setback-measurement-tools {
  background-color: white;
}
.component--setback-measurement-tools .header {
  font: 400 12px/14px Roboto;
  color: #333333;
  text-align: center;
  margin: 10px 10px 0 10px;
}
.component--setback-measurement-tools .tools-row {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  margin: 5px 18px 0 18px;
}
.component--setback-measurement-tools .tool-box {
  display: flex;
  flex-direction: column;
}
.component--setback-measurement-tools .shapes-buttons {
  display: flex;
  justify-content: space-between;
  width: 70%;
}
.component--setback-measurement-tools .map-controls {
  display: flex;
  justify-content: space-between;
  width: 30%;
}
.component--setback-measurement-tools .tool-name {
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  font: 500 13px/30px Roboto;
  color: #333333;
  vertical-align: text-top;
}
.component--setback-measurement-tools .zoom-tool-name {
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  font: 500 13px/30px Roboto;
  color: #333333;
  vertical-align: text-top;
  margin-left: 20px;
}
.component--setback-measurement-tools .dimensions-box {
  background-color: #E9EAEF;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid rgba(51, 51, 51, 0.1254901961);
  padding: 0 18px;
}
.component--setback-measurement-tools .value-box {
  display: flex;
  flex-direction: column;
  padding: 10px 10px 0 10px;
  width: 40%;
}
.component--setback-measurement-tools .label {
  font: 500 18px/30px Roboto;
  color: #333333;
  border-bottom: 1px solid rgba(51, 51, 51, 0.1254901961);
  width: 100%;
  text-align: center;
}
.component--setback-measurement-tools .value {
  font: 500 18px/30px Roboto;
  color: #333333;
  width: 100%;
  text-align: center;
}
.component--setback-measurement-tools .control-icon {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px rgba(58, 66, 118, 0.4);
  border-radius: 7px;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-position: center;
  width: 45px;
  height: 38px;
  display: flex;
  align-items: center;
  font: 500 20px/21px Roboto;
  justify-content: center;
  cursor: pointer;
}
.component--setback-measurement-tools .control-icon.disabled {
  pointer-events: none;
}
.component--setback-measurement-tools .control-icon:hover, .component--setback-measurement-tools .control-icon.active {
  background-color: #3A4276;
}
.component--setback-measurement-tools .zoom-in {
  font-size: 25px;
  line-height: 25px;
}
.component--setback-measurement-tools .zoom-in::after {
  content: "+";
}
.component--setback-measurement-tools .zoom-in:hover {
  color: #ffffff;
}
.component--setback-measurement-tools .zoom-out {
  font: normal 44px/45px Roboto;
  line-height: 40px;
}
.component--setback-measurement-tools .zoom-out::after {
  content: "-";
}
.component--setback-measurement-tools .zoom-out:hover {
  color: #ffffff;
}
.component--setback-measurement-tools .column {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-left: 12px;
}
.component--setback-measurement-tools .select-shape {
  background-image: url("./assets/images/parcel-tools-select-parcel.svg");
}
.component--setback-measurement-tools .select-shape:hover, .component--setback-measurement-tools .select-shape.active {
  background-image: url("./assets/images/parcel-tools-select-parcel-hover.svg");
}
.component--setback-measurement-tools .measurement {
  background-image: url("./assets/images/parcel-tools-draw-parcel.svg");
}
.component--setback-measurement-tools .measurement:hover, .component--setback-measurement-tools .measurement.active {
  background-image: url("./assets/images/parcel-tools-draw-parcel-hover.svg");
}
.component--setback-measurement-tools .edit-shape {
  background-image: url("./assets/images/parcel-tools-edit-parcel.svg");
}
.component--setback-measurement-tools .edit-shape:hover, .component--setback-measurement-tools .edit-shape.active {
  background-image: url("./assets/images/parcel-tools-edit-parcel-hover.svg");
}
.component--setback-measurement-tools .delete-shape {
  background-image: url("./assets/images/parcel-tools-delete-parcel.svg");
}
.component--setback-measurement-tools .delete-shape:hover, .component--setback-measurement-tools .delete-shape.active {
  background-image: url("./assets/images/parcel-tools-delete-parcel-hover.svg");
}
.component--setback-measurement-tools .copy-shape {
  background-image: url("./assets/images/parcel-tools-copy-parcel.svg");
}
.component--setback-measurement-tools .copy-shape:hover, .component--setback-measurement-tools .copy-shape.active {
  background-image: url("./assets/images/parcel-tools-copy-parcel-hover.svg");
}

.component--setback-tools-wrapper {
  display: flex;
  flex-direction: column;
}

.component--explorer-page {
  height: 100vh;
  width: 100vw;
  overflow-x: auto;
  overflow-y: hidden;
  min-width: 937px;
  position: relative;
}
.component--explorer-page .top-menus-container {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
}
.component--explorer-page .top-menus-container .projects-wrapper {
  display: flex;
}
.component--explorer-page .explorer-content {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 11px 14px 0px 24px;
  z-index: 5;
  pointer-events: none;
}
.component--explorer-page .explorer-content .panels-container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;
  box-sizing: content-box;
  pointer-events: none;
  height: calc(100% - 200px);
}
.component--explorer-page .explorer-content .panels-container.setback-mode .component--with-panel-header:first-child {
  flex-shrink: 1;
}
.component--explorer-page .explorer-content .panels-container .left-panel-wrapper {
  position: absolute;
  left: -10px;
  padding: 10px;
  width: 459px;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.component--explorer-page .explorer-content .panels-container .right-panel-wrapper {
  position: absolute;
  right: -10px;
  padding: 10px;
  width: 459px;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.component--explorer-page .explorer-content .panels-container .panel-content {
  width: 100%;
  padding-left: 18px;
  padding-right: 18px;
}
.component--explorer-page .explorer-content .panels-container .panel-content .top-label {
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
  font: 700 16px/21px Roboto;
  color: #3a4276;
  text-transform: capitalize;
  margin-top: 25px;
  border-bottom: 1px solid #3a4276;
}
.component--explorer-page .explorer-content .kpi-container {
  height: 100px;
}
.component--explorer-page .map-loading-container {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  background-color: rgba(0, 0, 0, 0.6);
  justify-content: center;
  align-items: center;
}
.component--explorer-page .map-loading-container .component--busy-spinner {
  height: 120px;
}
.component--explorer-page .map-loading-container .component--busy-spinner .spinner {
  background-image: url("assets/images/busy-spinner-white.svg");
}
.component--explorer-page .map-loading-container .header {
  color: white;
  font-family: Roboto Condensed;
  font-weight: 700;
  font-size: 20px;
  text-align: center;
}

.component--with-panel-header {
  pointer-events: all;
  margin-bottom: 10px;
}
.component--with-panel-header.first-child {
  flex-shrink: 0;
}
.component--with-panel-header:last-child {
  margin-bottom: 0;
}

.component--developments-dropdown,
.component--setback-close-panel,
.component--keep-project {
  flex-shrink: 0;
}

.component--developments-dropdown,
.component--setback-close-panel,
.component--explorer-toolbar,
.component--keep-project {
  pointer-events: all;
}

.building-alert-wrapper {
  position: absolute;
  left: 50%;
  top: 152px;
  flex-shrink: 0;
}
.building-alert-wrapper .building-alert {
  position: relative;
  left: -50%;
  margin: 0 10px;
  flex-shrink: 0;
  right: 30%;
  display: flex;
  align-items: center;
  background-color: #f26c6c;
  z-index: 2100;
  height: 36px;
  width: 631px;
  font: 400 13px/36px Roboto;
  color: #ffffff;
  padding: 0 15px;
  border-radius: 3px;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
}
.building-alert-wrapper .building-alert .icon-info {
  height: 13px;
  width: 13px;
  margin: 0 10px 2px 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("assets/images/icon-information-white.svg");
}
.building-alert-wrapper .building-alert .icon-close {
  position: absolute;
  top: -8px;
  right: -8px;
  height: 18px;
  width: 18px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("assets/images/icon-close.svg");
  cursor: pointer;
}

.component--forgot-password-page {
  color: #333333;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  background-color: #F6F6F6;
  min-height: 100%;
}
.component--forgot-password-page .hidden {
  display: none;
}
.component--forgot-password-page a {
  font: inherit;
  font-weight: bold;
  color: #3A4276;
}
.component--forgot-password-page button {
  background-color: #3A4276;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
}
.component--forgot-password-page .underlined-link {
  font-weight: 500;
  color: #333333;
  text-decoration: underline;
  cursor: pointer;
}
.component--forgot-password-page .underlined-link:hover {
  color: #3A4276;
  text-decoration: underline;
}
.component--forgot-password-page .flex-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.component--forgot-password-page .content-wrapper {
  position: absolute;
  top: 241px;
}
.component--forgot-password-page .header {
  font-size: 25px;
  font-family: "Roboto Condensed";
  font-weight: 700;
}
.component--forgot-password-page .container {
  width: 395px;
  background-color: #fbfbfb;
  display: flex;
  flex-direction: column;
  padding: 35px 30px 38px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.0901960784);
  border-radius: 10px;
}
.component--forgot-password-page .content-header {
  flex-shrink: 0;
  width: 240.5px;
  height: 39.5px;
  background-image: url("assets/images/logo-inline.svg");
  background-repeat: no-repeat;
  background-size: contain;
}
.component--forgot-password-page .text-box {
  height: auto;
  width: auto;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 16px;
  line-height: 19px;
}
.component--forgot-password-page .form-container + .text-box {
  margin-top: 33px;
}
.component--forgot-password-page .form-container .input-section {
  width: auto;
  margin-bottom: 16px;
}
.component--forgot-password-page .form-container .input-section .input-label {
  width: auto;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 14px;
  font-weight: bold;
  margin: 15px 0 5px 10px;
  line-height: 10px;
}
.component--forgot-password-page .form-container .input-section .input-field {
  position: relative;
  height: 32px;
  width: 335px;
  background-color: #FDFDFD;
  padding: 4px 13px 4px 4px;
  border: 1px solid #CCCCCC;
  border-radius: 8px;
}
.component--forgot-password-page .form-container .input-section .input-field input {
  border: none;
  width: 100%;
  height: 100%;
  padding-left: 10px;
  padding-right: 15px;
  font-family: inherit;
  font-size: 15px;
  background-color: transparent;
}
.component--forgot-password-page .form-container .input-section .input-field input:-webkit-autofill {
  border: none;
  box-shadow: 0 0 0px 1000px white inset;
}
.component--forgot-password-page .form-container .input-section .input-field input::placeholder {
  color: #CCCCCC;
}
.component--forgot-password-page .form-container .input-section .input-field.required:after {
  content: "*";
  position: absolute;
  top: 5px;
  right: 8px;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: bold;
  color: #F26C6C;
}
.component--forgot-password-page .form-container .submit {
  height: 35px;
  width: 97px;
  letter-spacing: 0.25px;
  padding: 0;
  pointer-events: all;
}
.component--forgot-password-page .form-container .submit.disabled {
  cursor: inherit;
  color: white;
}
.component--forgot-password-page .bold {
  font-weight: bold;
}
.component--forgot-password-page .error-message {
  display: flex;
  align-items: flex-end;
  height: 31px;
  margin: 25px 0 13px 5px;
  width: 294px;
  text-align: left;
  vertical-align: middle;
  font-family: "Roboto Condensed", "Regular";
  font-size: 13px;
  color: #F26C6C;
  line-height: 16px;
}
.component--forgot-password-page .reset-password {
  margin-top: 33px;
}
.component--forgot-password-page .tos-agreement {
  height: 19px;
  display: flex;
  align-items: center;
  margin-bottom: 36px;
}
.component--forgot-password-page .tos-agreement input[type=checkbox] {
  appearance: none;
  background-color: #F5F7F8;
  border: 1px solid #CCCCCC;
  padding: 6.5px;
  border-radius: 5px;
  width: 0;
  height: 0;
}
.component--forgot-password-page .tos-agreement input[type=checkbox]:checked {
  border: 1px solid #333333;
  background: url("assets/images/checkmark.svg");
  background-size: contain;
  background-repeat: no-repeat;
}
.component--forgot-password-page .tos-agreement input[type=checkbox]:focus {
  outline: none;
}
.component--forgot-password-page .tos-agreement .text-box {
  margin-left: 14px;
}
@media screen and (max-width: 794px) {
  .component--forgot-password-page .flex-wrapper {
    flex-direction: column-reverse;
  }
}
.component--forgot-password-page form {
  display: flex;
  justify-content: center;
}
.component--forgot-password-page .content-wrapper .header {
  text-align: center;
  margin-bottom: 53px;
}
.component--forgot-password-page .content-wrapper .text-box {
  font-size: 16px;
  width: 415px;
  text-align: center;
  font-family: "Roboto Condensed";
  margin-bottom: 36px;
}
.component--forgot-password-page .content-wrapper .text-box.email-sent {
  width: 465px;
}
.component--forgot-password-page .form-container .input-section {
  width: 284px;
  margin-right: 20px;
}
.component--forgot-password-page .form-container .input-section .input-field {
  height: 35px;
  width: inherit;
}
.component--forgot-password-page .form-container input {
  width: 100%;
}
.component--forgot-password-page .form-container .submit {
  padding: 0px 28px;
}
.component--forgot-password-page .form-container .error-container {
  color: #F26C6C;
}

.component--search-bar {
  z-index: 200;
  pointer-events: all;
  display: flex;
}
.component--search-bar .geocoder-container {
  width: 439px;
  padding: 16px 19px 19px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5019607843);
  border-radius: 10px;
}
.component--search-bar .geocoder-container .geocoder-input {
  font-size: 17px;
  position: relative;
  height: 32px;
  min-width: 240px;
  border: 1px solid #d5d7e2;
  background: #f6f6fb 0% 0% no-repeat padding-box;
  border-radius: 16px;
}
.component--search-bar .geocoder-container .geocoder-input input {
  font-size: 15px;
  width: 90%;
  height: 28px;
  padding: 5px 10px;
  border-radius: 15px;
  border: 0;
  margin: 0;
}
.component--search-bar .geocoder-container .geocoder-input input:focus {
  color: rgba(0, 0, 0, 0.75);
  box-shadow: none;
  outline: thin dotted\8 ;
}
.component--search-bar .component--unit-system-input {
  margin: 16px 0 0 0px;
}

.component--parcel-information-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: white;
  overflow: hidden;
}
.component--parcel-information-panel label {
  margin: 0;
}
.component--parcel-information-panel .component--parcel-information-table {
  padding: 28px 18px;
  overflow-y: auto;
  overflow-x: hidden;
}

.collaboration-container {
  align-self: center;
  padding: 15px;
  margin-bottom: 0;
  border: 1px solid #D1D5DD;
  color: #939393;
  font-size: 14px;
}
.collaboration-container a {
  color: #3A4276;
  font-weight: 700;
  text-decoration: underline;
}

.component--drawing-information {
  background-color: #ffffff;
  position: absolute;
  width: 267px;
  height: 164px;
  top: 11px;
  left: 473px;
  padding: 13px 17px;
  font-family: "Roboto", sans-serif;
  font-size: 17px;
  border-radius: 8px;
  box-shadow: 4px 4px 12px rgba(51, 51, 51, 0.3);
}
.component--drawing-information.with-selected-feature {
  top: 121px;
}
.component--drawing-information .header {
  font-weight: 300;
  padding-bottom: 7px;
  border-bottom: #cccccc 1px solid;
}
.component--drawing-information .section {
  margin-top: 12px;
  height: 42px;
}
.component--drawing-information .section .section-header {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 15px;
  font-weight: bold;
}
.component--drawing-information .section .section-body {
  margin-top: 4px;
  font-weight: 400;
}

.component--new-project-map {
  display: flex;
  width: 100%;
  height: 100%;
}
.component--new-project-map .component--branding-watermark {
  position: absolute;
  bottom: 13px;
  left: 47.5px;
}
.component--new-project-map .tool-tip {
  background-color: #333333;
  color: #FFFFFF;
  border-radius: 5px;
  padding: 3px 10px;
}
.component--new-project-map .map-pin {
  z-index: 0 !important;
  width: 30px;
  height: 46px;
  background-image: url("assets/images/map-pin.svg");
}
.component--new-project-map.delete-parcel .mode-simple_select.mouse-pointer .mapboxgl-interactive {
  cursor: url("./assets/images/cursor-delete-parcel.svg") 6 15, auto;
}
.component--new-project-map.select-parcel .hover-parcel {
  cursor: url("./assets/images/cursor-select-parcel.svg"), auto;
}
.component--new-project-map.combine-parcels .hover-parcel {
  cursor: url("./assets/images/cursor-combine-parcels.svg"), auto;
}
.component--new-project-map .mouse-pointer .mapboxgl-interactive {
  cursor: pointer;
}
.component--new-project-map .mode-direct_select.mouse-move .mapboxgl-interactive {
  cursor: move;
}
.component--new-project-map .mouse-add .mapboxgl-interactive {
  cursor: cell;
}

.component--search-row {
  width: 100%;
  margin: 1px;
}
.component--search-row[data-tip] > div {
  pointer-events: none;
}
.component--search-row .slider-filter {
  width: 100%;
  display: flex;
  align-items: center;
}
.component--search-row .slider-filter .check {
  width: 21px;
  height: 21px;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.4);
  border-radius: 2px;
  margin-right: 20px;
  cursor: pointer;
}
.component--search-row .slider-filter .check.active {
  background-image: url("assets/images/icon-check-active.svg");
  background-color: #3a4276;
}
.component--search-row .slider-filter .slider-wrapper {
  width: 100%;
  height: 100%;
  padding: 8px 20px 8px 0;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #c1c4d4;
}
.component--search-row .slider-filter .slider-wrapper .slider-label {
  margin-bottom: 6px;
  font: normal 12px/14px Roboto;
  color: #666666;
}
.component--search-row .slider-filter .slider-wrapper .rc-slider-wrapper {
  width: 100%;
  padding: 1px 5px 0;
  border: 1px solid #e0e1e9;
  background-color: #ffffff;
}
.component--search-row .slider-filter .slider-wrapper .rc-slider-wrapper .rc-slider-handle {
  width: 21px;
  height: 21px;
  border: 4px solid #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3607843137);
  margin-top: calc(-21px / 2);
}
.component--search-row .slider-filter .slider-wrapper .values {
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
}
.component--search-row .slider-filter .slider-wrapper .values input {
  background: #e0e1e9 0% 0% no-repeat padding-box;
  border-radius: 8px;
  width: 80px;
  height: 16px;
  border-style: hidden;
  text-align: center;
  outline: none;
  font: normal 12px/16px Roboto;
  color: #13a7da;
}
.component--search-row .slider-filter .slider-wrapper .values input.disabled {
  pointer-events: none;
}
.component--search-row .slider-filter .slider-wrapper .values input:hover::placeholder {
  color: #13a7da;
}
.component--search-row .slider-filter .slider-wrapper .values input:focus {
  outline: 1px solid #13a7da;
  outline-offset: 1px;
}
.component--search-row .toggle-field-wrapper {
  width: 50%;
  display: flex;
  align-items: center;
  padding: 0;
}
.component--search-row .toggle-field-wrapper .toggle-box {
  margin: 0 10px 0 0;
}
.component--search-row .toggle-field-wrapper .toggle-label {
  margin: 0;
}

.component--parcels-csv-export-button {
  height: 20px;
  width: 85px;
  font: 12px/21px Roboto;
  font-weight: 600;
  border-radius: 3px;
  background: #3A4276;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2509803922);
  margin-top: 1px;
  align-self: flex-end;
}

.component--parcel-counter {
  font: Bold 13px/21px Roboto;
  color: #3A4276;
  margin-bottom: 5px;
}

.component--search-engine-header {
  display: flex;
  color: #333333;
  padding-bottom: 15px;
  flex-shrink: 0;
  width: 100%;
  border-bottom: solid 1px rgba(153, 153, 153, 0.1254901961);
  margin-bottom: 20px;
}
.component--search-engine-header .icon {
  width: 100px;
  height: 57px;
  flex-shrink: 0;
  margin-right: 18px;
  background-size: cover;
  box-shadow: 0px 3px 6px rgba(58, 66, 118, 0.4);
  border-radius: 5px;
  background-image: url("assets/images/icon-legend-search-results.png");
}
.component--search-engine-header .smart-search-toggle {
  margin-top: -8px;
  margin-left: -10px;
}
.component--search-engine-header .legend-content {
  width: 100%;
  margin-left: -10px;
}
.component--search-engine-header .legend-content .content-wrapper {
  display: flex;
  justify-content: space-between;
}
.component--search-engine-header .legend-content .title-container {
  margin-right: 25px;
}
.component--search-engine-header .legend-content .title-container .title {
  font: 900 16px/21px Roboto;
  text-transform: capitalize;
}
.component--search-engine-header .legend-content .title-container .text {
  font: 12px/14px Roboto;
  font-weight: 400;
  margin-top: 8px;
  max-width: 200px;
}
.component--search-engine-header .legend-content .functionalities-container {
  display: flex;
  flex-direction: column;
  justify-content: right;
}

.component--zoning-filters {
  width: 100%;
}
.component--zoning-filters .zoning-dropdown-box {
  background: rgba(51, 51, 51, 0.0823529412);
  /* Chrome, Safari, Edge */
}
.component--zoning-filters .zoning-dropdown-box *::-webkit-scrollbar {
  width: 0px;
}
.component--zoning-filters .zoning-dropdown-box * {
  -ms-overflow-style: none; /* IE, Edge */
  scrollbar-width: none; /* Firefox */
}

.component--allowed-uses-filters {
  width: 100%;
}
.component--allowed-uses-filters .allowed-uses-dropdown-box {
  background: rgba(51, 51, 51, 0.0823529412);
  /* Chrome, Safari, Edge */
}
.component--allowed-uses-filters .allowed-uses-dropdown-box *::-webkit-scrollbar {
  width: 0px;
}
.component--allowed-uses-filters .allowed-uses-dropdown-box * {
  -ms-overflow-style: none;
  /* IE, Edge */
  scrollbar-width: none;
  /* Firefox */
}

.component--owner-name-filters {
  width: 100%;
}
.component--owner-name-filters .owner-name-dropdown-box {
  background: rgba(51, 51, 51, 0.0823529412);
  /* Chrome, Safari, Edge */
}
.component--owner-name-filters .owner-name-dropdown-box *::-webkit-scrollbar {
  width: 0px;
}
.component--owner-name-filters .owner-name-dropdown-box * {
  -ms-overflow-style: none; /* IE, Edge */
  scrollbar-width: none; /* Firefox */
}

.component--owner-address-filters {
  width: 100%;
}
.component--owner-address-filters .owner-address-dropdown-box {
  background: rgba(51, 51, 51, 0.0823529412);
  /* Chrome, Safari, Edge */
}
.component--owner-address-filters .owner-address-dropdown-box *::-webkit-scrollbar {
  width: 0px;
}
.component--owner-address-filters .owner-address-dropdown-box * {
  -ms-overflow-style: none; /* IE, Edge */
  scrollbar-width: none; /* Firefox */
}

.component--land-use-filters {
  width: 100%;
}
.component--land-use-filters .land-use-dropdown-box {
  background: rgba(51, 51, 51, 0.0823529412);
  /* Chrome, Safari, Edge */
}
.component--land-use-filters .land-use-dropdown-box *::-webkit-scrollbar {
  width: 0px;
}
.component--land-use-filters .land-use-dropdown-box * {
  -ms-overflow-style: none;
  /* IE, Edge */
  scrollbar-width: none;
  /* Firefox */
}

.component--search-panel {
  width: 100%;
  background-color: #fff;
  padding: 19px 18px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  overflow-y: scroll;
}
.component--search-panel .searchBox {
  width: 100%;
}
.component--search-panel .searchBox::-webkit-input-placeholder {
  font-size: 15px;
}
.component--search-panel .section-divisor {
  padding-bottom: 5px;
  border-bottom: 1px solid #3a4276;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.component--search-panel .section-divisor label {
  margin: 0;
  font: bold 16px/21px Roboto;
  color: #333333;
}
.component--search-panel .missing-data-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font: normal 14px/14px Roboto;
  color: #666666;
  text-align: center;
  padding: 40px 0;
}
.component--search-panel .missing-data-container p {
  width: 324px;
  margin: 0;
}
.component--search-panel .missing-data-container .request-icon {
  width: 252px;
  height: 170px;
  margin-bottom: 18px;
  background: url("assets/images/icon-request-zoning-data.svg") no-repeat;
}
.component--search-panel .missing-data-container .request-button {
  width: 126px;
  height: 41px;
  margin-top: 18px;
  background: #3a4276 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  color: #ffffff;
  font: Bold 12px/41px Roboto;
}
.component--search-panel .count-wrapper {
  color: #3a4276;
  font: Bold 16px/21px Roboto;
  width: 100%;
  margin-bottom: 5px;
}
.component--search-panel .count-wrapper .count-container {
  font: Bold 14px/21px Roboto;
}
.component--search-panel .divisor {
  height: 1px;
  width: 100%;
  background: #000000 0% 0% no-repeat padding-box;
  opacity: 0.05;
  margin-bottom: 25px;
}

.component--draw-parcel-information-panel {
  width: 100%;
  background-color: #FFFFFF;
  padding: 30px 18px;
  pointer-events: all;
}
.component--draw-parcel-information-panel .header {
  color: #3A4276;
  font: Bold 14px/21px Roboto;
  border-bottom: 1px solid rgba(58, 66, 118, 0.502);
  padding-bottom: 6px;
}
.component--draw-parcel-information-panel .blocks-container {
  padding: 31px 27px 0;
}
.component--draw-parcel-information-panel .block {
  display: flex;
  align-items: center;
  margin-top: 30px;
}
.component--draw-parcel-information-panel .block:first-child {
  margin-top: 0;
}
.component--draw-parcel-information-panel .block .number {
  font: 700 40px/71px Roboto;
  margin-right: 17px;
  color: #3A4473;
}
.component--draw-parcel-information-panel .block .image {
  width: 61px;
  height: 61px;
  background-color: #3A4473;
  border-radius: 2px;
  margin-right: 28px;
  background-position: center;
  background-repeat: no-repeat;
}
.component--draw-parcel-information-panel .block .image.select-parcel {
  background-image: url("./assets/images/parcel-tools-select-parcel-hover.svg");
  background-size: 40%;
}
.component--draw-parcel-information-panel .block .image.combine-parcels {
  background-image: url("./assets/images/parcel-tools-combine-parcels-hover.svg");
  background-size: 50%;
}
.component--draw-parcel-information-panel .block .image.preliminary-underwriting {
  display: flex;
  padding: 0 20px;
  align-items: center;
  width: 172px;
  height: 98px;
  background: #F26C6C 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5019607843);
  border-radius: 7px;
  text-align: left;
  font: Bold 14px/16px Roboto;
  color: white;
}
.component--draw-parcel-information-panel .block .image.draw-parcel {
  background-image: url("./assets/images/parcel-tools-draw-parcel-hover.svg");
  background-size: 70%;
}
.component--draw-parcel-information-panel .block .image.edit-parcel {
  background-image: url("./assets/images/parcel-tools-edit-parcel-hover.svg");
  background-size: 50%;
}
.component--draw-parcel-information-panel .block .image.delete-parcel {
  background-image: url("./assets/images/parcel-tools-delete-parcel-hover.svg");
  background-size: 60%;
}
.component--draw-parcel-information-panel .block .text {
  font: 300 12px/15px Roboto;
  letter-spacing: 0.16px;
  max-width: 224px;
  color: #333333;
}
.component--draw-parcel-information-panel .block .text .text-header {
  font: Bold 16px/16px Roboto;
  letter-spacing: 0.2px;
  border-bottom: 1px solid #C1C4D4;
  text-align: start;
  padding-bottom: 5px;
  margin-bottom: 8px;
}

.component--parcel-finder-panel {
  background: white;
  width: 100%;
  pointer-events: all;
  display: flex;
  overflow: hidden;
}

.component--initial-values-panel {
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 552px;
  padding: 46px 63px;
  color: #333333;
}
.component--initial-values-panel p {
  font-size: 17px;
}
.component--initial-values-panel .input-container {
  display: flex;
  padding: 21px 10px 0 0;
}
.component--initial-values-panel .input-container .note-container {
  margin-left: 15px;
}
.component--initial-values-panel .input-container .note-container .note {
  font-size: 12px;
  margin-bottom: 5px;
  font: normal 14px/16px Roboto;
}
.component--initial-values-panel .input-container .note-container .default-far-note {
  color: #F65959;
  font-size: 12px;
  margin: 0;
}
.component--initial-values-panel .section-header {
  margin: 0;
  font: Bold 14px/16px Roboto;
  color: #333333;
  border-bottom: 1px solid #3A4276;
  padding-bottom: 4px;
}
.component--initial-values-panel .bold {
  font-weight: bold;
}
.component--initial-values-panel .uses-container {
  display: flex;
  justify-content: space-between;
  margin-top: 23px;
  margin-bottom: 30px;
}
.component--initial-values-panel .uses-container .toggle-field-wrapper .toggle-container {
  padding-left: 6px;
}
.component--initial-values-panel .far-container input {
  border: 1px solid #CCCCCC;
  border-radius: 8px;
  width: 116px;
  height: 37px;
  color: #999999;
  text-align: center;
  font-size: 15px;
  font-family: "Roboto", sans-serif;
}
.component--initial-values-panel .far-container input:focus, .component--initial-values-panel .far-container input:hover {
  outline: none;
  outline-offset: 0;
}
.component--initial-values-panel .buttons-container {
  display: flex;
  justify-content: center;
  margin-top: 39px;
}
.component--initial-values-panel .buttons-container button {
  font: Bold 13px/21px Roboto;
  border-radius: 6px;
  width: 188px;
  height: 41px;
  box-shadow: 0px 5px 10px rgba(58, 66, 118, 0.4);
}
.component--initial-values-panel .buttons-container button:first-child {
  margin-right: 46px;
}
.component--initial-values-panel .buttons-container button.disabled {
  background: #CCCCCC;
  cursor: none;
}
.component--initial-values-panel .buttons-container button.continue {
  background: #3A4276 0% 0% no-repeat padding-box;
}
.component--initial-values-panel .buttons-container button.back {
  color: #333333;
  background: #FFFFFF 0% 0% no-repeat padding-box;
}

.component--welcome-slideshow {
  display: flex;
  flex-direction: column;
  width: 570px;
  height: 480px;
  background-color: #fff;
  border-radius: 10px;
}
.component--welcome-slideshow .header {
  background: #3a4276;
  color: #ffffff;
  border-radius: 9px 9px 0 0;
  padding: 8px 0 0 0;
  height: 50px;
  border-bottom: 0.5px solid #707070;
  font: 30px Roboto;
  font-weight: 800;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  text-align: center;
}
.component--welcome-slideshow .content {
  display: flex;
  justify-content: space-around;
  height: 240px;
  margin-top: 10px;
  padding: 15px 15px 0 15px;
}
.component--welcome-slideshow .content label {
  margin-top: 30px;
  color: #3a4276;
  font: 22px/22px Roboto;
  font-weight: 800;
  letter-spacing: 0.6px;
  text-transform: uppercase;
}
.component--welcome-slideshow .content p {
  font: 16px/23px Roboto;
  font-weight: 400;
  letter-spacing: 0.4px;
  color: #333333;
  max-width: 240px;
}
.component--welcome-slideshow .content .image {
  flex-shrink: 0;
  width: 250px;
  background-repeat: no-repeat;
  background-size: contain;
}
.component--welcome-slideshow .content .image.smart-search {
  background-image: url("assets/images/slideshow-smart-search.png");
}
.component--welcome-slideshow .content .image.zoning-data {
  background-image: url("assets/images/slideshow-zoning-data.png");
}
.component--welcome-slideshow .content .image.assumptions {
  background-image: url("assets/images/slideshow-assumptions.png");
}
.component--welcome-slideshow .content .image.back-of-envelope {
  background-image: url("assets/images/slideshow-back-of-envelope.png");
}
.component--welcome-slideshow .content .image.custom-pdf {
  background-image: url("assets/images/slideshow-custom-pdf.png");
}
.component--welcome-slideshow .content .image.parcel-and-uses {
  background-image: url("assets/images/slideshow-parcel-and-uses.png");
}
.component--welcome-slideshow .content .image.draw-parcel {
  background-image: url("assets/images/slideshow-draw-parcel.png");
}
.component--welcome-slideshow .dots-container {
  display: flex;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
}
.component--welcome-slideshow .dots-container .dot {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  margin-right: 40px;
  cursor: pointer;
}
.component--welcome-slideshow .dots-container .dot::before {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: #a3b5d3;
}
.component--welcome-slideshow .dots-container .dot:last-child {
  margin-right: 0;
}
.component--welcome-slideshow .dots-container .dot.active {
  border-radius: 11px;
  background-color: #3a4276;
}
.component--welcome-slideshow .dots-container .dot.active::before {
  background-color: #fff;
}
.component--welcome-slideshow .buttons-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}
.component--welcome-slideshow .buttons-container button {
  width: 150px;
  height: 45px;
  background-color: #3a4276;
  border-radius: 5px;
  font: 20px/20px Roboto;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: #ffffff;
}
.component--welcome-slideshow .buttons-container button:hover {
  background-color: #2b325c;
}
.component--welcome-slideshow .buttons-container .skip {
  margin: 15px 0 0 0;
  text-decoration: underline;
  font: normal 20px/25px Roboto;
  letter-spacing: 0.4px;
  color: #999999;
  cursor: pointer;
}

.component--new-project-toolbar {
  display: flex;
  height: 100%;
  align-items: flex-start;
  z-index: 200;
  pointer-events: all;
}

.new-project-toolbar {
  width: 439px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  pointer-events: all;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5019607843);
  border-radius: 10px;
  padding: 0 20px;
  margin-left: 10px;
}
.new-project-toolbar .component--button {
  box-shadow: none;
  margin: 0;
}
.new-project-toolbar .component--logo .logo {
  margin: 0;
}

.component--start-project-button {
  margin-left: 10px;
}
.component--start-project-button .icon {
  width: 20px;
  height: 32px;
  background: url("assets/images/icon-right-arrow-white.svg") no-repeat;
  flex-shrink: 0;
}
.component--start-project-button button {
  display: flex;
  padding: 0 20px;
  align-items: center;
  width: 172px;
  height: 98px;
  background: #f26c6c 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5019607843);
  border-radius: 7px;
  text-align: left;
  font: Bold 16px/20px Roboto;
}
.component--start-project-button button:hover {
  background-color: #3a4276;
}

.component--street-view-button {
  margin-left: 10px;
}
.component--street-view-button.explorer {
  display: flex;
  justify-content: center;
  margin-left: 10px;
}
.component--street-view-button .button {
  display: flex;
  padding: 0 20px;
  align-items: center;
  width: 85px;
  height: 98px;
  background: #c161c6 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5019607843);
  border-radius: 7px;
  text-align: left;
  color: white;
  font: 500 14px/16px Roboto;
}
.component--street-view-button .button.explorer {
  font: 500 13px/21px Roboto;
  letter-spacing: 0.13px;
  height: 34px;
  width: 110px;
  margin-right: 0;
  margin-top: 12px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3137254902);
}
.component--street-view-button .button:hover {
  background-color: #3a4276;
  text-decoration: none;
}

.component--line-graph {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  border-radius: 10px;
  padding: 12px 0 6px;
  position: relative;
  flex-shrink: 0;
}
.component--line-graph:first-child {
  margin-top: 0;
}
.component--line-graph.hidden canvas {
  pointer-events: none;
  filter: blur(5px);
}
.component--line-graph .title-wrapper {
  font: 900 16px/21px Roboto;
  color: #3A4276;
  margin: 0 0 15px 10px;
  width: 100%;
  padding-right: 24px;
}
.component--line-graph .title-wrapper .title {
  display: block;
  padding-bottom: 7px;
  width: 100%;
  border-bottom: 1px solid #3A4276;
}
.component--line-graph .legend {
  font: 400 12px/21px Roboto;
  color: #999999;
  margin-left: 10px;
  width: 93%;
  border-top: 1px solid rgba(213, 213, 224, 0.3137254902);
  padding-top: 5px;
}

.component--market-graphs-panel {
  display: flex;
  pointer-events: all;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  overflow: hidden;
}
.component--market-graphs-panel .graphs-wrapper {
  padding: 20px 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.component--market-graphs-panel .missing-data-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font: normal 14px/14px Roboto;
  color: #666666;
  text-align: center;
  padding: 40px 0;
  width: 100%;
  overflow: auto;
}
.component--market-graphs-panel .missing-data-container p {
  width: 324px;
  margin: 0;
}
.component--market-graphs-panel .missing-data-container .request-icon {
  display: flex;
  flex-shrink: 0;
  width: 252px;
  height: 170px;
  margin-bottom: 18px;
  background: url("assets/images/icon-request-zoning-data.svg") no-repeat;
}
.component--market-graphs-panel .missing-data-container .request-button {
  width: 126px;
  height: 41px;
  margin-top: 18px;
  background: #3A4276 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  color: #FFFFFF;
  font: Bold 12px/41px Roboto;
}

.component--new-project-page {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  overflow: hidden;
  min-width: 937px;
}
.component--new-project-page .content {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 11px 14px 30px 24px;
  z-index: 1;
  pointer-events: none;
}
.component--new-project-page .top-menus-container {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
}
.component--new-project-page .panels-container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;
  box-sizing: content-box;
  pointer-events: none;
  height: calc(100% - 200px);
}
.component--new-project-page .panels-container .left-panel-wrapper {
  position: absolute;
  left: -10px;
  padding: 10px;
  width: 459px;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.component--new-project-page .panels-container .right-panel-wrapper {
  position: absolute;
  right: -10px;
  padding: 10px 10px 60px 10px;
  width: 459px;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.component--new-project-page .panels-container .component--with-panel-header {
  pointer-events: all;
  margin-bottom: 10px;
}
.component--new-project-page .panels-container .component--with-panel-header .first-child {
  flex-shrink: 0;
}
.component--new-project-page .panels-container .component--with-panel-header:last-child {
  margin-bottom: 0;
}
.component--new-project-page .map-loading-container {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  background-color: rgba(0, 0, 0, 0.6);
  justify-content: center;
  align-items: center;
}
.component--new-project-page .map-loading-container .component--busy-spinner {
  height: 120px;
}
.component--new-project-page .map-loading-container .component--busy-spinner .spinner {
  background-image: url("assets/images/busy-spinner-white.svg");
}
.component--new-project-page .map-loading-container .header {
  color: white;
  font-family: Roboto Condensed;
  font-weight: 700;
  font-size: 20px;
  text-align: center;
}

.component--reset-password-page {
  color: #333333;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  background-color: #F6F6F6;
  min-height: 100%;
}
.component--reset-password-page .hidden {
  display: none;
}
.component--reset-password-page a {
  font: inherit;
  font-weight: bold;
  color: #3A4276;
}
.component--reset-password-page button {
  background-color: #3A4276;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
}
.component--reset-password-page .underlined-link {
  font-weight: 500;
  color: #333333;
  text-decoration: underline;
  cursor: pointer;
}
.component--reset-password-page .underlined-link:hover {
  color: #3A4276;
  text-decoration: underline;
}
.component--reset-password-page .flex-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.component--reset-password-page .content-wrapper {
  position: absolute;
  top: 241px;
}
.component--reset-password-page .header {
  font-size: 25px;
  font-family: "Roboto Condensed";
  font-weight: 700;
}
.component--reset-password-page .container {
  width: 395px;
  background-color: #fbfbfb;
  display: flex;
  flex-direction: column;
  padding: 35px 30px 38px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.0901960784);
  border-radius: 10px;
}
.component--reset-password-page .content-header {
  flex-shrink: 0;
  width: 240.5px;
  height: 39.5px;
  background-image: url("assets/images/logo-inline.svg");
  background-repeat: no-repeat;
  background-size: contain;
}
.component--reset-password-page .text-box {
  height: auto;
  width: auto;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 16px;
  line-height: 19px;
}
.component--reset-password-page .form-container + .text-box {
  margin-top: 33px;
}
.component--reset-password-page .form-container .input-section {
  width: auto;
  margin-bottom: 16px;
}
.component--reset-password-page .form-container .input-section .input-label {
  width: auto;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 14px;
  font-weight: bold;
  margin: 15px 0 5px 10px;
  line-height: 10px;
}
.component--reset-password-page .form-container .input-section .input-field {
  position: relative;
  height: 32px;
  width: 335px;
  background-color: #FDFDFD;
  padding: 4px 13px 4px 4px;
  border: 1px solid #CCCCCC;
  border-radius: 8px;
}
.component--reset-password-page .form-container .input-section .input-field input {
  border: none;
  width: 100%;
  height: 100%;
  padding-left: 10px;
  padding-right: 15px;
  font-family: inherit;
  font-size: 15px;
  background-color: transparent;
}
.component--reset-password-page .form-container .input-section .input-field input:-webkit-autofill {
  border: none;
  box-shadow: 0 0 0px 1000px white inset;
}
.component--reset-password-page .form-container .input-section .input-field input::placeholder {
  color: #CCCCCC;
}
.component--reset-password-page .form-container .input-section .input-field.required:after {
  content: "*";
  position: absolute;
  top: 5px;
  right: 8px;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: bold;
  color: #F26C6C;
}
.component--reset-password-page .form-container .submit {
  height: 35px;
  width: 97px;
  letter-spacing: 0.25px;
  padding: 0;
  pointer-events: all;
}
.component--reset-password-page .form-container .submit.disabled {
  cursor: inherit;
  color: white;
}
.component--reset-password-page .bold {
  font-weight: bold;
}
.component--reset-password-page .error-message {
  display: flex;
  align-items: flex-end;
  height: 31px;
  margin: 25px 0 13px 5px;
  width: 294px;
  text-align: left;
  vertical-align: middle;
  font-family: "Roboto Condensed", "Regular";
  font-size: 13px;
  color: #F26C6C;
  line-height: 16px;
}
.component--reset-password-page .reset-password {
  margin-top: 33px;
}
.component--reset-password-page .tos-agreement {
  height: 19px;
  display: flex;
  align-items: center;
  margin-bottom: 36px;
}
.component--reset-password-page .tos-agreement input[type=checkbox] {
  appearance: none;
  background-color: #F5F7F8;
  border: 1px solid #CCCCCC;
  padding: 6.5px;
  border-radius: 5px;
  width: 0;
  height: 0;
}
.component--reset-password-page .tos-agreement input[type=checkbox]:checked {
  border: 1px solid #333333;
  background: url("assets/images/checkmark.svg");
  background-size: contain;
  background-repeat: no-repeat;
}
.component--reset-password-page .tos-agreement input[type=checkbox]:focus {
  outline: none;
}
.component--reset-password-page .tos-agreement .text-box {
  margin-left: 14px;
}
@media screen and (max-width: 794px) {
  .component--reset-password-page .flex-wrapper {
    flex-direction: column-reverse;
  }
}
.component--reset-password-page .info-message {
  margin-top: 5px;
  margin-bottom: 8px;
}
.component--reset-password-page .error-message {
  margin-top: 0;
  margin-bottom: 0;
  height: 16px;
}
.component--reset-password-page .submit {
  margin-top: 10px;
}

.component--home-page {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
}
.component--home-page .buttons-container {
  position: absolute;
  top: 50px;
  right: 20px;
  display: flex;
  align-items: center;
  gap: 14px;
  height: 70px;
}
.component--home-page .zoning-report-box {
  height: 70px;
  margin-bottom: 16px;
}
.component--home-page .disclaimer {
  position: absolute;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-weight: 400;
  bottom: 110px;
}

.component--subscription-required-content {
  height: 100%;
  display: flex;
  justify-content: center;
  padding: 50px 20%;
}
.component--subscription-required-content .component--with-panel-header {
  background-color: #FFFFFF;
  flex: 1;
  min-width: 1070px;
  max-width: 1200px;
  min-height: 680px;
}

.component--subscribe {
  position: relative;
  padding: 13px 17px 0;
  overflow: scroll;
}
.component--subscribe .field {
  outline: none;
  height: 61px;
  background: #F1F5F7 0% 0% no-repeat padding-box;
  border: 1px solid #EEEEEE;
  margin-bottom: 21px;
  font: normal 14px/14px Roboto;
  padding: 0 18px;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.component--subscribe .field .CardForm {
  background-color: red;
}
.component--subscribe .field::placeholder {
  color: #BDBDBD;
}
.component--subscribe .input-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 21px;
}
.component--subscribe .input-row .field {
  flex: 1 1 50%;
  margin-bottom: 0;
}
.component--subscribe .input-row .error::placeholder {
  color: #F26C6C;
}
.component--subscribe .input-row label {
  margin: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1 1 50%;
  color: #BDBDBD;
}
.component--subscribe .input-row .field:nth-child(2) {
  margin-left: 32px;
}
.component--subscribe .input-row discount-input {
  text-transform: uppercase;
}
.component--subscribe .divisor {
  border-top: 1px solid #EFEFEF;
  margin: 22px 0;
}
.component--subscribe .error-container {
  color: #F66C6C;
  font: 100 10px/11px Roboto;
  font-style: italic;
  text-align: center;
}
.component--subscribe .prices-container {
  position: relative;
}
.component--subscribe .prices-container .fetching-coupon-message {
  position: absolute;
  background-color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.component--subscribe .prices-container .price-row {
  display: flex;
  justify-content: flex-end;
  color: #1A1824;
  font: normal 14px/14px Roboto;
}
.component--subscribe .prices-container .price-row .price {
  width: 200px;
  text-align: right;
}
.component--subscribe .prices-container .price-row.discount {
  color: #F26C6C;
}
.component--subscribe .prices-container .price-row.final-price {
  color: #3A4276;
  font: bold 18px/24px Roboto;
}
.component--subscribe .initial-spinner {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 2;
}
.component--subscribe .submit {
  width: 450px;
  height: 61px;
  background: #3A4276 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2509803922);
  border-radius: 3px;
  margin-bottom: 20px;
}

.component--sign-up {
  position: relative;
  background-color: #FFFFFF;
  padding: 20px 12px;
  border-radius: 0px 0px 3px 3px;
  overflow-y: auto;
  max-height: calc(100vh - 171px);
}
.component--sign-up form {
  display: flex;
  flex-direction: column;
}
.component--sign-up form.hide {
  display: none;
}
.component--sign-up .input-field {
  margin-top: 16px;
}
.component--sign-up .error-container p {
  font: 300 10px/20px Roboto;
  font-style: italic;
  color: #F26C6C;
  margin-top: 7px;
  margin-bottom: -15px;
  white-space: pre-line;
}
.component--sign-up .tos-agreement {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.component--sign-up .text-box {
  font: 400 12px/14px Roboto;
  color: #777777;
}
.component--sign-up .text-box .underlined {
  text-decoration: underline;
  cursor: pointer;
}
.component--sign-up .text-box.right {
  margin-left: auto;
}
.component--sign-up a {
  font: 400 12px/14px Roboto;
  color: #777777;
  text-decoration: underline;
}
.component--sign-up input {
  width: 100%;
  background-color: #F1F5F7;
  border: 1px solid #EEEEEE;
  border-radius: 0;
}
.component--sign-up input.tos {
  width: 24px;
}
.component--sign-up .buttons-container {
  width: 100%;
}
.component--sign-up button {
  height: 60px;
  width: 100%;
  background-color: #3A4276;
  border-radius: 3px;
  margin-top: 31px;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2509803922);
}
.component--sign-up .submit:hover {
  background-color: #2C3258;
}

.component-verify-email {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 28px 0;
}
.component-verify-email .icon {
  width: 220px;
  height: 189px;
  background-image: url("assets/images/icon-email-verification.png");
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: 60px;
}
.component-verify-email .content-wrapper {
  margin-top: 58px;
}
.component-verify-email .text-box {
  text-align: center;
  font: 400 13px/20px Roboto;
  color: #777777;
}
.component-verify-email .text-box .bold {
  font-weight: 700;
  color: #3A4276;
}
.component-verify-email .text-box .underlined-link {
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
}
.component-verify-email .footer {
  width: 80%;
  margin-top: 65px;
  display: flex;
  justify-content: space-between;
}
.component-verify-email .footer .text-box {
  font-size: 12px;
}
.component-verify-email button {
  height: 38px;
  width: 100%;
  background-color: #3A4276;
  border-radius: 3PX;
  margin-top: 24px;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 30px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2509803922);
}

.component--welcome {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 87px 0;
}
.component--welcome .welcome-image {
  width: 302px;
  height: 268px;
  background: url("assets/images/icon-welcome.svg") 0% 0% no-repeat padding-box;
}
.component--welcome p {
  font: 500 15px/20px Roboto;
  color: #3A4276;
  margin-top: 62px;
}
.component--welcome button {
  width: 190px;
  color: #fff;
  height: 38px;
  background: #3A4276 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2509803922);
  border-radius: 3px;
  font: 400 16px/16px Roboto;
  margin-top: 45px;
}

.component--sign-up-flow-popup {
  width: 483px;
  background-color: #FFFFFF;
  border-radius: 3px;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
}
.component--sign-up-flow-popup .header-container {
  height: 66px;
  color: #3A4276;
  text-transform: uppercase;
  font: bold 20px/20px Roboto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid #3A4276;
}
.component--sign-up-flow-popup .navigation {
  padding: 36px 65px 0;
  color: #BDBDBD;
}
.component--sign-up-flow-popup .navigation .icon-wrapper {
  display: flex;
  justify-content: space-between;
  padding-right: 3px;
}
.component--sign-up-flow-popup .navigation .label-wrapper {
  display: flex;
  justify-content: space-between;
}
.component--sign-up-flow-popup .navigation .label-wrapper p {
  font: normal 9px/9px Roboto;
}
.component--sign-up-flow-popup .navigation .label-wrapper p.right-adjust {
  margin-right: -5px;
}
.component--sign-up-flow-popup .navigation .label-wrapper p.active {
  color: #3A4276;
}
.component--sign-up-flow-popup .navigation .section {
  position: relative;
}
.component--sign-up-flow-popup .navigation .section .icon {
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  background-position: center;
  border: 1px solid #D5D5E0;
  margin-bottom: 8px;
}
.component--sign-up-flow-popup .navigation .section .icon.email-verification {
  background-image: url("assets/images/icon-verify-email-inactive.svg");
}
.component--sign-up-flow-popup .navigation .section .icon.checkout {
  background-image: url("assets/images/icon-subscribe-inactive.svg");
}
.component--sign-up-flow-popup .navigation .section .line-divider {
  position: absolute;
  width: 127px;
  left: -127px;
  border-top: 1px #D5D5E0 solid;
  top: 16px;
}
.component--sign-up-flow-popup .navigation .section .line-divider.active {
  border-top: 1px #3A4276 solid;
}
.component--sign-up-flow-popup .navigation .section.active .icon {
  background-color: #3A4276;
  border: 1px solid #3A4276;
  box-shadow: 0px 4px 16px rgba(58, 66, 118, 0.4);
}
.component--sign-up-flow-popup .navigation .section.active .icon.sign-up {
  background-image: url("assets/images/icon-sign-up-active.svg");
}
.component--sign-up-flow-popup .navigation .section.active .icon.email-verification {
  background-image: url("assets/images/icon-verify-email-active.svg");
}
.component--sign-up-flow-popup .navigation .section.active .icon.checkout {
  background-image: url("assets/images/icon-subscribe-active.svg");
}
.component--sign-up-flow-popup .component--federated-authentication-providers .top-separator {
  width: 483px;
}
.component--sign-up-flow-popup .component--federated-authentication-providers .buttons-container {
  display: flex;
  justify-content: space-between;
  margin-top: 33px;
}
.component--sign-up-flow-popup .component--federated-authentication-providers .component--button-facebook {
  margin: 0;
}
.component--sign-up-flow-popup .component--federated-authentication-providers .component--button-google {
  margin: 20px 0 0 0;
}
.component--sign-up-flow-popup .component--activity-feedback {
  height: 499px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #777777;
}

.component--subscription-required-page {
  position: absolute;
  width: 100%;
  height: 100%;
  min-width: 1280px;
  background-color: #E3E5E8;
}
.component--subscription-required-page .buttons-container {
  position: absolute;
  top: 50px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.component--reset-password-popup {
  width: 550px;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.component--reset-password-popup .text {
  margin: 35px 45px;
  text-align: center;
  font: 400 15px/20px Roboto;
  color: #333333;
}
.component--reset-password-popup button {
  width: 160px;
  height: 34px;
  border-radius: 7px;
  background-color: #FFFFFF;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  font: 700 14px/14px Roboto;
  color: #333333;
  margin-bottom: 20px;
}
.component--reset-password-popup button:hover {
  background-color: #3A4276;
  color: #FFFFFF;
}

.component--upgrade-popup {
  width: 430px;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
}
.component--upgrade-popup .payment-error-container {
  color: #F26C6C;
  align-self: flex-start;
  margin: 10px 0;
  padding: 0;
  width: 100%;
}
.component--upgrade-popup .payment-error-container p {
  font-size: 14px;
  text-align: center;
  line-height: 16px;
  margin: 0;
}
.component--upgrade-popup .top {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #3A4276;
  padding: 15px 30px;
  color: #FFFFFF;
}
.component--upgrade-popup .top .toggle-wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 162px;
  height: 36px;
  border-radius: 18px;
  background-color: #EDF1F7;
  margin: 20px auto 16px;
  z-index: 100;
  flex-shrink: 0;
}
.component--upgrade-popup .top .toggle-wrapper .toggle-button {
  width: 70px;
  height: 26px;
  border-radius: 13px;
  font: 700 10px/26px Roboto;
  text-align: center;
  color: #333333;
  cursor: pointer;
}
.component--upgrade-popup .top .toggle-wrapper .toggle-button.selected {
  background-color: #F26C6C;
  color: #FFFFFF;
}
.component--upgrade-popup .bottom {
  padding: 20px 0 25px;
}
.component--upgrade-popup .text {
  font: 400 16px/21px Roboto;
  text-align: center;
  color: #FFFFFF;
}
.component--upgrade-popup .price {
  font-weight: 700;
  font-size: 14px;
}
.component--upgrade-popup .input-field {
  width: 330px;
  margin: 20px 0 10px;
}
.component--upgrade-popup .input-field input {
  height: 32px;
  width: 100%;
  padding: 9px 15px;
  border-radius: 5px;
  background-color: #F6F6FB;
  font: 400 13px/13px Roboto;
}
.component--upgrade-popup .input-field.invalid input {
  color: red;
}
.component--upgrade-popup .buttons-container {
  display: flex;
  width: 100%;
  justify-content: center;
}
.component--upgrade-popup button {
  width: 160px;
  height: 34px;
  border-radius: 7px;
  background-color: #FFFFFF;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  font: 700 14px/14px Roboto;
  color: #333333;
}
.component--upgrade-popup button:hover {
  background-color: #3A4276;
  color: #FFFFFF;
}
.component--upgrade-popup button.upgrade {
  margin-left: 20px;
}
.component--upgrade-popup button.upgrading {
  margin-left: 20px;
  background-color: #3A4276;
  color: #FFFFFF;
}

.component--display-information .bottom span {
  margin-left: 5px;
  font: 700 14px/14px Roboto;
  color: #3A4276;
}
.component--display-information .bottom .bar {
  height: 6px;
  background-color: #D5D5E0;
  border-radius: 3px;
  margin-top: 8px;
}
.component--display-information .bottom .bar.active {
  position: relative;
  top: -14px;
  background-color: #3A4276;
  box-shadow: 0px 3px 8px rgba(58, 66, 118, 0.4);
}
.component--display-information .subscription-status-label {
  color: #333333;
  font-family: "Roboto Condensed";
  font-size: 14px;
}

.component--account-information {
  margin-bottom: 30px;
}
.component--account-information .content {
  padding: 20px 0;
}
.component--account-information .content .information-container {
  display: flex;
}
.component--account-information .content .information-container .thumbnail-container {
  display: flex;
  flex-direction: column;
  width: 160px;
  height: 160px;
  margin-right: 40px;
  align-items: center;
  justify-content: space-evenly;
}
.component--account-information .content .information-container .thumbnail-container .thumbnail {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 5px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1019607843);
}
.component--account-information .content .information-container .thumbnail-container .thumbnail.edit {
  width: 128px;
  height: 128px;
}
.component--account-information .content .information-container .thumbnail-container .text {
  text-align: center;
  text-decoration: underline;
  font: Regular 12px/42px Roboto;
  letter-spacing: 0px;
  color: #3A4276;
  cursor: pointer;
}
.component--account-information .content .information-container .data-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.component--account-information .content .information-container .data-container .name {
  font: 400 14px/16px Roboto;
  color: #333333;
}
.component--account-information .content .information-container .data-container .name .bold {
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 5px;
}
.component--account-information .content .information-container .data-container .subscription-container {
  display: flex;
  justify-content: space-between;
}
.component--account-information .content .information-container .data-container .subscription-container .section {
  width: 130px;
  font: 700 14px/14px Roboto;
}
.component--account-information .content .information-container .data-container .subscription-container .section .section-header {
  color: #3A4276;
  border-bottom: 2px solid #D5D5E0;
  padding: 5px;
}
.component--account-information .content .information-container .data-container .subscription-container .section .section-content {
  display: flex;
  color: #333333;
  padding: 5px;
}
.component--account-information .content .information-container .data-container .subscription-container .section .section-content .marker {
  height: 13px;
  width: 13px;
  border-radius: 7px;
  margin-right: 5px;
}
.component--account-information .content .information-container .data-container .subscription-container .section .section-content .marker.none, .component--account-information .content .information-container .data-container .subscription-container .section .section-content .marker.cancelled {
  background-color: #F9CB58;
}
.component--account-information .content .information-container .data-container .subscription-container .section .section-content .marker.active, .component--account-information .content .information-container .data-container .subscription-container .section .section-content .marker.non_renewing {
  background-color: #5FB217;
}
.component--account-information .content .buttons-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
.component--account-information .content .buttons-wrapper .space {
  width: 160px;
}

.component--required-fields-popup {
  width: 550px;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.component--required-fields-popup .text {
  margin: 35px 45px;
  text-align: center;
  font: 400 15px/20px Roboto;
  color: #333333;
}
.component--required-fields-popup button {
  width: 160px;
  height: 34px;
  border-radius: 7px;
  background-color: #FFFFFF;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  font: 700 14px/14px Roboto;
  color: #333333;
  margin-bottom: 20px;
}
.component--required-fields-popup button:hover {
  background-color: #3A4276;
  color: #FFFFFF;
}

.component--send-invitation-popup {
  width: 550px;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.component--send-invitation-popup .text {
  margin: 15px 45px;
  text-align: center;
  font: 400 15px/20px Roboto;
  color: #333333;
}
.component--send-invitation-popup .component--busy-spinner {
  margin-top: 15px;
}

.component--invitation-success-popup {
  width: 550px;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.component--invitation-success-popup .text {
  margin: 35px 45px;
  text-align: center;
  font: 400 15px/20px Roboto;
  color: #333333;
}
.component--invitation-success-popup button {
  width: 160px;
  height: 34px;
  border-radius: 7px;
  background-color: #FFFFFF;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  font: 700 14px/14px Roboto;
  color: #333333;
  margin-bottom: 20px;
}
.component--invitation-success-popup button:hover {
  background-color: #3A4276;
  color: #FFFFFF;
}

.component--invitation-failure-popup {
  width: 550px;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.component--invitation-failure-popup .text {
  margin: 35px 45px;
  text-align: center;
  font: 400 15px/20px Roboto;
  color: #333333;
}
.component--invitation-failure-popup button {
  width: 160px;
  height: 34px;
  border-radius: 7px;
  background-color: #FFFFFF;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  font: 700 14px/14px Roboto;
  color: #333333;
  margin-bottom: 20px;
}
.component--invitation-failure-popup button:hover {
  background-color: #3A4276;
  color: #FFFFFF;
}

.component--duplicate-invitation-popup {
  width: 550px;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.component--duplicate-invitation-popup .text {
  margin: 35px 45px;
  text-align: center;
  font: 400 15px/20px Roboto;
  color: #333333;
}
.component--duplicate-invitation-popup button {
  width: 160px;
  height: 34px;
  border-radius: 7px;
  background-color: #FFFFFF;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  font: 700 14px/14px Roboto;
  color: #333333;
  margin-bottom: 20px;
}
.component--duplicate-invitation-popup button:hover {
  background-color: #3A4276;
  color: #FFFFFF;
}

.component--my-team {
  background-color: #FFFFFF;
}
.component--my-team .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 12px 18px;
  margin-top: 35px;
  background-color: #FFFFFF;
  box-shadow: 0.47px 3px 10px rgba(119, 119, 119, 0.1019607843);
  border-radius: 3px;
}
.component--my-team .title {
  margin: 36px 0 0;
  font: Bold 16px/19px Roboto;
  color: #3A4276;
}
.component--my-team .input-field {
  width: 330px;
  margin-bottom: 12px;
}
.component--my-team .input-field.invalid input {
  color: red;
}
.component--my-team button {
  margin-top: 16px;
  margin-bottom: 36px;
}
.component--my-team .toggle-wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 162px;
  height: 36px;
  border-radius: 18px;
  background-color: #EDF1F7;
  margin: 20px auto 16px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1607843137);
  flex-shrink: 0;
}
.component--my-team .toggle-wrapper .toggle-button {
  width: 70px;
  height: 26px;
  border-radius: 13px;
  font: 700 10px/26px Roboto;
  text-align: center;
  color: #333333;
  cursor: pointer;
}
.component--my-team .toggle-wrapper .toggle-button.selected {
  background-color: #F26C6C;
  color: #FFFFFF;
}
.component--my-team .text {
  font: 700 14px/14px Roboto;
  margin-bottom: 20px;
}

.component--credit-card-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 575px;
  padding: 40px 35px 0;
  background-color: #FFFFFF;
}
.component--credit-card-popup .section {
  width: 100%;
  margin-bottom: 25px;
}
.component--credit-card-popup .section .section-content {
  padding: 15px;
  box-shadow: 2px 3px 10px rgba(119, 119, 119, 0.1019607843);
  border-radius: 3px;
  font: 400 14px/50px Roboto;
  color: #333333;
}
.component--credit-card-popup .section .error-container {
  font: italic 400 11px/11px Roboto;
  color: #F26C6C;
}
.component--credit-card-popup .header {
  font: 700 16px/19px Roboto;
  color: #3A4276;
  text-transform: capitalize;
  border-bottom: 1px solid #3A4276;
  padding: 3px;
}
.component--credit-card-popup .field, .component--credit-card-popup input {
  display: flex;
  align-items: center;
  flex-grow: 1;
  height: 32px;
  padding: 0 15px;
  background-color: #F6F6FB;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1019607843);
  border: 1px solid #D5D7E2;
  border-radius: 5px;
  outline: none;
  font: 400 12px/12px Roboto;
}
.component--credit-card-popup .field:not(:last-child), .component--credit-card-popup input:not(:last-child) {
  margin-right: 16px;
}
.component--credit-card-popup .field::placeholder, .component--credit-card-popup input::placeholder {
  color: #999999;
}
.component--credit-card-popup .input-row {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
}
.component--credit-card-popup .input-row .error::placeholder {
  color: #F26C6C;
}
.component--credit-card-popup .text {
  font: 400 14px/20px Roboto;
  color: #333333;
}
.component--credit-card-popup .text .capitalize {
  text-transform: capitalize;
}
.component--credit-card-popup .text .bold {
  font-weight: 700;
}
.component--credit-card-popup .text .warning {
  font: italic 400 12px/12px Roboto;
  color: #F26C6C;
}
.component--credit-card-popup .buttons-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.component--credit-card-popup button {
  width: 160px;
  height: 34px;
  border-radius: 7px;
  background-color: #FFFFFF;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  font: 700 14px/14px Roboto;
  color: #333333;
  margin-bottom: 20px;
}
.component--credit-card-popup button:hover {
  background-color: #3A4276;
  color: #FFFFFF;
}
.component--credit-card-popup button.submitting {
  background-color: #3A4276;
}
.component--credit-card-popup button.submitting .spinner {
  width: 30px;
  height: 30px;
}

.component--payment-method {
  margin-bottom: 55px;
}
.component--payment-method .content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 18px;
  margin-top: 7px;
  background-color: #FFFFFF;
  box-shadow: 0.47px 3px 10px rgba(119, 119, 119, 0.1019607843);
  border-radius: 3px;
}
.component--payment-method .text {
  font: 400 14px/16px Roboto;
  color: #333333;
}
.component--payment-method .text .capitalize {
  text-transform: capitalize;
}
.component--payment-method .text .bold {
  font-weight: 700;
}
.component--payment-method .text .warning {
  font: italic 400 12px/12px Roboto;
  color: #F26C6C;
}

.component--account-settings {
  width: 605px;
  height: 100%;
  background-color: #FFFFFF;
  padding: 30px 50px;
  overflow: auto;
}
.component--account-settings .header {
  font: 700 16px/19px Roboto;
  color: #3A4276;
  text-transform: capitalize;
  border-bottom: 1px solid #3A4276;
  padding: 3px;
}
.component--account-settings .input-field {
  height: 32px;
  background-color: #F6F6FB;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1019607843);
  border: 1px solid #D5D7E2;
  border-radius: 5px;
}
.component--account-settings .input-field input {
  height: 100%;
  width: 100%;
  padding: 9px 15px;
}
.component--account-settings .input-field input::placeholder {
  font: 400 12px/12px Roboto;
  color: #999999;
}
.component--account-settings .input-field:last-child {
  margin-bottom: 30px;
}

.component--delete-invitation-popup {
  width: 550px;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.component--delete-invitation-popup .text {
  margin: 35px 45px;
  text-align: center;
  font: 400 15px/20px Roboto;
  color: #333333;
}
.component--delete-invitation-popup .text span {
  font-weight: 700;
  color: #3A4276;
}
.component--delete-invitation-popup .buttons-container {
  display: flex;
  width: 100%;
  justify-content: center;
}
.component--delete-invitation-popup button {
  width: 160px;
  height: 34px;
  border-radius: 7px;
  background-color: #FFFFFF;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  font: 700 14px/14px Roboto;
  color: #333333;
  margin-bottom: 20px;
}
.component--delete-invitation-popup button:hover, .component--delete-invitation-popup button.delete {
  background-color: #3A4276;
  color: #FFFFFF;
}
.component--delete-invitation-popup button.delete {
  display: flex;
  justify-content: center;
  align-items: center;
}
.component--delete-invitation-popup button.cancel {
  margin-right: 30px;
}
.component--delete-invitation-popup button.cancel :first-child {
  margin-right: 0;
}

.component--cancel-invitation-popup {
  width: 550px;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.component--cancel-invitation-popup .text {
  margin: 35px 45px;
  text-align: center;
  font: 400 15px/20px Roboto;
  color: #333333;
}
.component--cancel-invitation-popup .text span {
  font-weight: 700;
  color: #3A4276;
}
.component--cancel-invitation-popup .buttons-container {
  display: flex;
  width: 100%;
  justify-content: center;
}
.component--cancel-invitation-popup button {
  width: 160px;
  height: 34px;
  border-radius: 7px;
  background-color: #FFFFFF;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  font: 700 14px/14px Roboto;
  color: #333333;
  margin-bottom: 20px;
}
.component--cancel-invitation-popup button:hover, .component--cancel-invitation-popup button.cancel {
  background-color: #3A4276;
  color: #FFFFFF;
}
.component--cancel-invitation-popup button.cancel {
  display: flex;
  justify-content: center;
  align-items: center;
}
.component--cancel-invitation-popup button.close-button {
  margin-right: 30px;
}
.component--cancel-invitation-popup button.close-button :first-child {
  margin-right: 0;
}

.component--reinvite-popup {
  width: 427px;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
}
.component--reinvite-popup .top-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px 10px 0 0;
  padding: 25px 17px 0;
  width: 100%;
  background-color: #3A4276;
  color: white;
}
.component--reinvite-popup .top-container .input-field {
  width: 331px;
  height: 32px;
  margin: 12px 0 9px;
  background-color: #F6F6FB;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1019607843);
  border: 1px solid #D5D7E2;
  border-radius: 5px;
}
.component--reinvite-popup .top-container .input-field input {
  height: 100%;
  width: 100%;
  padding: 9px 15px;
}
.component--reinvite-popup .top-container .input-field input::placeholder {
  font: 400 12px/12px Roboto;
  color: #999999;
}
.component--reinvite-popup .top-container .input-field.invalid input {
  color: red;
}
.component--reinvite-popup .top-container .text {
  width: 340px;
  padding: 0 33px 20px;
  text-align: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2980392157);
  font: 400 15px/20px Roboto;
}
.component--reinvite-popup .top-container .text span {
  font-weight: 900;
}
.component--reinvite-popup .top-container .price-text {
  margin-top: 15px;
  font-weight: 900;
}
.component--reinvite-popup .top-container .error {
  font: italic normal normal 12px/14px Roboto;
  color: #F26C6C;
  margin-bottom: 8px;
}
.component--reinvite-popup .buttons-container {
  display: flex;
  padding: 30px 0;
  width: 100%;
  justify-content: center;
}
.component--reinvite-popup .buttons-container :first-child {
  margin-right: 20px;
}
.component--reinvite-popup .buttons-container button {
  width: 160px;
  height: 34px;
  border-radius: 7px;
  background-color: #FFFFFF;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  font: 700 14px/14px Roboto;
  color: #333333;
}
.component--reinvite-popup .buttons-container button:hover, .component--reinvite-popup .buttons-container button.delete {
  background-color: #3A4276;
  color: #FFFFFF;
}
.component--reinvite-popup .buttons-container button[disabled] {
  background-color: #E3E5E8;
  cursor: default;
}

.component--team-member-settings {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  padding: 30px 40px;
}
.component--team-member-settings .header {
  font: 700 16px/19px Roboto;
  color: #3A4276;
  text-transform: capitalize;
  border-bottom: 1px solid #3A4276;
  padding: 3px;
}
.component--team-member-settings .information-container {
  display: flex;
  margin: 15px 0 60px;
}
.component--team-member-settings .information-container .thumbnail-container {
  display: flex;
  flex-direction: column;
  width: 160px;
  height: 160px;
  margin-right: 80px;
  align-items: center;
  justify-content: space-evenly;
}
.component--team-member-settings .information-container .thumbnail-container .thumbnail {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 5px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1019607843);
}
.component--team-member-settings .information-container .selected-name {
  font: 400 14px/16px Roboto;
  color: #333333;
}
.component--team-member-settings .information-container .selected-name .bold {
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 5px;
}
.component--team-member-settings .information-container .user-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.component--team-member-settings .information-container .subscription-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 450px;
  margin-left: 80px;
}
.component--team-member-settings .information-container .subscription-container .top {
  display: flex;
}
.component--team-member-settings .information-container .subscription-container .bottom span {
  margin-left: 5px;
  font: 700 14px/14px Roboto;
  color: #3A4276;
}
.component--team-member-settings .information-container .subscription-container .bottom .bar {
  height: 6px;
  background-color: #D5D5E0;
  border-radius: 3px;
  margin-top: 8px;
}
.component--team-member-settings .information-container .subscription-container .bottom .bar.active {
  position: relative;
  top: -14px;
  background-color: #3A4276;
  box-shadow: 0px 3px 8px rgba(58, 66, 118, 0.4);
}
.component--team-member-settings .information-container .subscription-container .section {
  width: 180px;
  font: 700 14px/14px Roboto;
}
.component--team-member-settings .information-container .subscription-container .section:first-child {
  margin-right: 120px;
}
.component--team-member-settings .information-container .subscription-container .section .section-header {
  color: #3A4276;
  border-bottom: 2px solid #D5D5E0;
  padding: 5px;
}
.component--team-member-settings .information-container .subscription-container .section .section-content {
  display: flex;
  color: #333333;
  text-transform: capitalize;
  padding: 5px;
}
.component--team-member-settings .marker {
  height: 13px;
  width: 13px;
  border-radius: 7px;
  margin-right: 5px;
}
.component--team-member-settings .marker.pending {
  background-color: #F9CB58;
}
.component--team-member-settings .marker.active {
  background-color: #5FB217;
}
.component--team-member-settings .marker.cancelled {
  background-color: #F26C6C;
}
.component--team-member-settings .list-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1019607843);
  border-radius: 5px;
  overflow: hidden;
}
.component--team-member-settings .list-container .bottom {
  width: 100%;
  height: 30px;
  margin-top: 10px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.1019607843);
  border-radius: 0px 0px 7px 7px;
}
.component--team-member-settings .list-container .rows-container {
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.component--team-member-settings .list-container .row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 52px;
  text-align: center;
  font: 400 12px/12px Roboto;
  color: #464A53;
  margin-left: 20px;
  margin-right: 20px;
  flex-shrink: 0;
}
.component--team-member-settings .list-container .row:not(:last-child) {
  border-bottom: 1px solid #DDDFE1;
}
.component--team-member-settings .list-container .row .check {
  display: flex;
  justify-content: center;
  width: 61px;
}
.component--team-member-settings .list-container .row .check .checkmark {
  width: 21px;
  height: 21px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1019607843);
  border-radius: 2px;
  cursor: pointer;
}
.component--team-member-settings .list-container .row .check .checkmark.selected {
  background-color: #3A4276;
  background-image: url("assets/images/icon-check-active.svg");
  background-position: center;
  background-repeat: no-repeat;
}
.component--team-member-settings .list-container .row .name {
  width: 180px;
}
.component--team-member-settings .list-container .row .email {
  width: 180px;
}
.component--team-member-settings .list-container .row .subscription {
  display: flex;
  width: 170px;
  text-transform: capitalize;
  padding-left: 45px;
}
.component--team-member-settings .list-container .row .subscription .marker {
  margin-right: 8px;
}
.component--team-member-settings .list-container .row .renewal {
  width: 170px;
}
.component--team-member-settings .list-container .row .since {
  position: relative;
  width: 170px;
}
.component--team-member-settings .list-container .row .since .delete {
  position: absolute;
  top: -5px;
  right: 13px;
  width: 0;
  height: 0;
  background-image: url("assets/images/icon-trash-can-red.svg");
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}
.component--team-member-settings .list-container .row.selected, .component--team-member-settings .list-container .row:hover {
  background-color: #E2E5E8;
}
.component--team-member-settings .list-container .row.selected .since .delete, .component--team-member-settings .list-container .row:hover .since .delete {
  width: 15px;
  height: 17px;
}
.component--team-member-settings .list-container .row.top {
  height: 35px;
  background: #EFEFF4 0% 0% no-repeat padding-box;
  border: 1px solid #DDDFE1;
  font: 400 14px/14px Roboto;
  color: #3A4276;
  margin: 0 0 10px 0;
  padding: 0 20px;
}
.component--team-member-settings .list-container .row.top .subscription {
  justify-content: center;
  padding: 0;
}

.component--subscription-management-page {
  position: absolute;
  width: 100%;
  height: 100%;
  min-width: 1875px;
  background-color: #E3E5E8;
  padding: 30px 30px 30px 130px;
}
.component--subscription-management-page .content-container {
  display: flex;
  height: 100%;
}
.component--subscription-management-page .content-container .component--with-panel-header {
  height: 100%;
}
.component--subscription-management-page .content-container .left-container {
  margin-right: 30px;
}
.component--subscription-management-page .content-container .right-container {
  flex-grow: 1;
}
.component--subscription-management-page .buttons-container {
  position: absolute;
  top: 50px;
  left: 0px;
  width: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.component--subscription-management-page button {
  width: 160px;
  height: 34px;
  border-radius: 7px;
  background-color: #FFFFFF;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  font: 700 14px/14px Roboto;
  color: #333333;
}
.component--subscription-management-page button.upgrade {
  background-color: #F26C6C;
  color: #FFFFFF;
}
.component--subscription-management-page button:hover, .component--subscription-management-page button.active {
  background-color: #3A4276;
  color: #FFFFFF;
}

.component--account-links .icon-billing {
  background-image: url("assets/images/icon-sidebar-billing-inactive.svg");
}
.component--account-links .icon-subscribe {
  background-image: url("assets/images/icon-sidebar-subscription-inactive.svg");
}
.component--account-links .side-bar-link .number {
  position: relative;
  top: -12px;
  height: 25px;
  width: 25px;
  border-radius: 13px;
  background-color: #F26C6C;
  font: 700 16px/25px Roboto;
  text-align: center;
  color: #FFFFFF;
}
.component--account-links .side-bar-link:hover .icon-billing {
  background-image: url("assets/images/icon-sidebar-billing-active.svg");
}
.component--account-links .side-bar-link:hover .icon-subscribe {
  background-image: url("assets/images/icon-sidebar-subscription-active.svg");
}

.component--dashboard-page .component--sidebar .component--support {
  margin-top: 94px;
  display: flex;
  flex-flow: column;
  min-height: calc(100vh - 250px);
}
.component--dashboard-page .component--sidebar .component--support .icon {
  height: 19px;
  background-size: contain;
}
.component--dashboard-page .component--sidebar .component--support .icon-cities {
  background-image: url("assets/images/icon-sidebar-cities-inactive.svg");
}
.component--dashboard-page .component--sidebar .component--support .icon-webinar {
  background-image: url("assets/images/icon-sidebar-webinar-inactive.svg");
}
.component--dashboard-page .component--sidebar .component--support .icon-support {
  background-image: url("assets/images/icon-sidebar-support-inactive-dashboard.svg");
}
.component--dashboard-page .component--sidebar .component--support .icon-blog {
  background-image: url("assets/images/icon-sidebar-blog-inactive.svg");
}
.component--dashboard-page .component--sidebar .component--support .icon-contact-us {
  background-image: url("assets/images/icon-sidebar-contact-us-inactive.svg");
}
.component--dashboard-page .component--sidebar .component--support .side-bar-link:hover .icon-cities {
  background-image: url("assets/images/icon-sidebar-cities-active.svg");
}
.component--dashboard-page .component--sidebar .component--support .side-bar-link:hover .icon-webinar {
  background-image: url("assets/images/icon-sidebar-webinar-active.svg");
}
.component--dashboard-page .component--sidebar .component--support .side-bar-link:hover .icon-support {
  background-image: url("assets/images/icon-sidebar-support-active.svg");
}
.component--dashboard-page .component--sidebar .component--support .side-bar-link:hover .icon-blog {
  background-image: url("assets/images/icon-sidebar-blog-active.svg");
}
.component--dashboard-page .component--sidebar .component--support .side-bar-link:hover .icon-contact-us {
  background-image: url("assets/images/icon-sidebar-contact-us-active.svg");
}

.component--dashboard-page .component--sidebar {
  padding: 29px 30px 15px 30px;
  background-color: #E5E7ED;
  position: relative;
  font-weight: 500;
  overflow: hidden;
}
.component--dashboard-page .component--sidebar .header {
  font-size: 13px;
  font-weight: bold;
  color: #333333;
  height: 24px;
  border-bottom: solid 1px #999999;
}
.component--dashboard-page .component--sidebar .links-container {
  margin-top: 19px;
}
.component--dashboard-page .component--sidebar .side-bar-link {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #777777;
  margin-bottom: 10px;
}
.component--dashboard-page .component--sidebar .side-bar-link:hover {
  color: #F26C6C;
  text-decoration: none;
}
.component--dashboard-page .component--sidebar .icon {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 20px;
  height: 19px;
  margin-right: 13px;
}

.component--application-links {
  display: flex;
  justify-content: space-around;
  align-items: baseline;
}
.component--application-links .application-link {
  width: 285px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.component--application-links .application-link.coming-soon p,
.component--application-links .application-link.coming-soon h5 {
  color: #acb0c6;
}
.component--application-links .bottom {
  font-size: 13px;
  font-weight: bold;
}
.component--application-links h5 {
  color: #333333;
  font-family: "Roboto Condensed";
  font-size: 17.5px;
  font-weight: bold;
  margin-top: 20px;
}
.component--application-links p {
  color: #777777;
  font-size: 12.5px;
  text-align: center;
  margin: 15px 0 0;
}
.component--application-links .btn, .component--application-links .zoning-report-link {
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: #3a4276;
  width: 186px;
  font-size: 13px;
  color: white;
  background-color: #3a4276;
  margin-top: 15px;
}
.component--application-links .btn:hover, .component--application-links .zoning-report-link:hover {
  background-color: #f26c6c;
  border-color: #f26c6c;
}
.component--application-links .zoning-report-link {
  background: #7dc896 0% 0% no-repeat padding-box;
  border-color: #7dc896;
}
.component--application-links .site-analysis-icon {
  height: 130px;
  width: 158px;
  background-size: contain;
  background-image: url("assets/images/icon-dashboard-analyze-site.svg");
  background-repeat: no-repeat;
}
.component--application-links .existing-building-icon {
  height: 128px;
  width: 158px;
  background-size: contain;
  background-image: url("assets/images/icon-dashboard-existing-building.svg");
  background-repeat: no-repeat;
}
.component--application-links .zoning-report-icon {
  height: 128px;
  width: 158px;
  background-size: contain;
  background-image: url("assets/images/active-icon-dashboard-existing-building.svg");
  background-repeat: no-repeat;
}
.component--application-links .new-development-icon {
  height: 134px;
  width: 158px;
  background-size: contain;
  background-image: url("assets/images/icon-dashboard-new-development.svg");
  background-repeat: no-repeat;
}

.component--thumbnail {
  height: 153px;
  min-height: 153px;
  line-height: 153px;
  text-align: center;
  background-image: url("assets/images/default-thumbnail.png");
  background-size: cover;
  color: rgba(102, 139, 152, 0.8);
  font-size: 16px;
  font-weight: bold;
  border-bottom: solid 1px #59618D;
  border-radius: 10px 10px 0 0;
  cursor: pointer;
}
.component--thumbnail .spinner {
  background-image: url("assets/images/busy-spinner-blue.svg");
  background-repeat: no-repeat;
}

.component--development-card {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}
.component--development-card .component--return-on-cost {
  top: 6px;
  right: 6px;
  width: 80px;
  padding: 5px 5px 11px;
}
.component--development-card .component--return-on-cost .header {
  font: Bold 11px/13px Roboto;
  letter-spacing: 0.33px;
  text-transform: uppercase;
}
.component--development-card .component--return-on-cost .separator {
  margin-top: 2px;
  width: 20px;
  height: 2px;
  background-color: #F66C6C;
  border-radius: 1px;
}
.component--development-card .component--return-on-cost .value {
  margin-top: 8px;
  font: 700 20px/20px Roboto;
}
.component--development-card .component--return-on-cost .secondary {
  bottom: -13px;
  width: 80px;
  height: 21px;
  padding: 2px 0 0;
}
.component--development-card .component--return-on-cost .secondary .label {
  font: 300 10px/10px Roboto;
}
.component--development-card .component--return-on-cost .secondary .value {
  font: 700 10px/10px Roboto;
}
.component--development-card .shared {
  position: absolute;
  bottom: 104px;
  width: 100%;
  height: 25px;
  background-color: #3A4276;
  font: Bold 13px/25px Roboto;
  color: #FFFFFF;
  text-align: center;
  text-transform: uppercase;
}
.component--development-card .card-content {
  position: relative;
  box-sizing: border-box;
  flex: 1;
  margin: 12px 12px 9px;
}
.component--development-card .development-name {
  font: 900 13px/21px Roboto;
  color: #3A4276;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  overflow: hidden;
}
.component--development-card .development-name:hover {
  cursor: pointer;
}
.component--development-card .last-opened {
  margin-top: 5px;
  font: 300 10px/8px Roboto;
  color: #3A4276;
  text-align: center;
}
.component--development-card .buttons-container {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.component--pagination {
  display: flex;
  justify-content: space-between;
  margin-top: 9px;
  line-height: 14px;
  color: #777777;
  font-weight: 500;
  font-size: 12px;
  white-space: pre;
  cursor: default;
}
.component--pagination .page-selector {
  display: flex;
}
.component--pagination .numbers {
  display: flex;
}
.component--pagination .current {
  color: #F26C6C;
}
.component--pagination .button {
  cursor: pointer;
}
.component--pagination .button:hover {
  color: #F26C6C;
}

.component--developments-list {
  margin: 36px 0 65px;
}
.component--developments-list .developments-header {
  font-size: 13px;
  font-weight: bold;
  color: #333333;
  padding-bottom: 4px;
  border-bottom: solid 1px #999999;
}
.component--developments-list .developments-wrapper {
  min-height: 533px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 230px);
  grid-template-rows: repeat(auto-fit, 256px);
  justify-content: space-between;
  grid-gap: 22px 32px;
  padding: 19px 0;
  border-bottom: solid 1px #999999;
}

.component--dashboard-content .component--footer {
  margin-top: auto;
  margin-bottom: 19px;
}
.component--dashboard-content .component--footer .component--branding-watermark {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  bottom: 0;
  left: 0;
  width: 110.5px;
  height: 21px;
}

.component--site-analysis-popup {
  width: 890px;
  height: 605px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 43px;
  color: #333333;
}
.component--site-analysis-popup .site-analysis-icon {
  width: 258px;
  height: 218px;
  background: transparent url("assets/images/icon-dashboard-analyze-site.svg") 0% 0% no-repeat padding-box;
  background-size: contain;
}
.component--site-analysis-popup p {
  font: normal 18px/25px Roboto;
  text-align: center;
  max-width: 514px;
}
.component--site-analysis-popup .header {
  font: Bold 24px/25px Roboto;
  margin-top: 33px;
  margin-bottom: 27px;
}
.component--site-analysis-popup .geocoder-container {
  margin-top: 45px;
  display: flex;
  align-items: center;
  width: 697px;
  border: 2px solid #3A4276;
  border-radius: 26px;
  height: 51px;
  padding-left: 27px;
}
.component--site-analysis-popup .geocoder-container.error {
  border: 2px solid #F26C6C;
}
.component--site-analysis-popup .geocoder-container .geocoder-icon {
  top: auto;
}
.component--site-analysis-popup .geocoder-container .component--mapbox-geocoder {
  flex: 1 1 100%;
}
.component--site-analysis-popup .geocoder-container .component--mapbox-geocoder input {
  font: normal 18px/22px Roboto;
  width: calc(100% - 30px);
  height: 21px;
}
.component--site-analysis-popup .geocoder-container button {
  font: 500 18px/22px Roboto;
  position: relative;
  right: -3px;
  letter-spacing: 0.36px;
  color: #FFFFFF;
  height: 51px;
  width: 242px;
  margin: 0;
  border-radius: 0px 26px 26px 0px;
  background: #3A4276 0% 0% no-repeat padding-box;
  border: 0px;
}
.component--site-analysis-popup .geocoder-container button:hover {
  background-color: #F26C6C;
}
.component--site-analysis-popup .error-container {
  color: #F26C6C;
  align-self: flex-start;
  margin-left: 90px;
}
.component--site-analysis-popup .error-container p {
  font-size: 14px;
  margin: 0;
}

.component--new-development-popup {
  width: 890px;
  height: 605px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 43px;
}
.component--new-development-popup .new-development-icon {
  width: 258px;
  height: 218px;
  background: transparent url("assets/images/icon-dashboard-new-development.svg") 0% 0% no-repeat padding-box;
  background-size: contain;
}
.component--new-development-popup p {
  font: normal 18px/25px Roboto;
  text-align: center;
  max-width: 514px;
}
.component--new-development-popup .header {
  font: Bold 24px/25px Roboto;
  margin-top: 33px;
  margin-bottom: 27px;
}
.component--new-development-popup .geocoder-container {
  margin-top: 45px;
  display: flex;
  align-items: center;
  width: 697px;
  border: 2px solid #3A4276;
  border-radius: 26px;
  height: 51px;
  padding-left: 27px;
}
.component--new-development-popup .geocoder-container.error {
  border: 2px solid #F26C6C;
}
.component--new-development-popup .geocoder-container .geocoder-icon {
  top: auto;
}
.component--new-development-popup .geocoder-container .component--mapbox-geocoder {
  flex: 1 1 100%;
}
.component--new-development-popup .geocoder-container .component--mapbox-geocoder input {
  font: normal 18px/22px Roboto;
  width: calc(100% - 30px);
}
.component--new-development-popup .geocoder-container button {
  font: 500 18px/22px Roboto;
  position: relative;
  right: -3px;
  letter-spacing: 0.36px;
  color: #FFFFFF;
  height: 51px;
  width: 242px;
  margin: 0;
  border-radius: 0px 26px 26px 0px;
  background: #3A4276 0% 0% no-repeat padding-box;
  border: 0px;
}
.component--new-development-popup .geocoder-container button:hover {
  background-color: #F26C6C;
}
.component--new-development-popup .error-container {
  color: #F26C6C;
  align-self: flex-start;
  margin-left: 90px;
}
.component--new-development-popup .error-container p {
  font-size: 14px;
  margin: 0;
}

.component--dashboard-content {
  display: flex;
  flex-direction: column;
  padding: 40px 65px 0;
}

.component--dashboard-page {
  display: grid;
  grid-template-columns: minmax(1147px, 1fr) 250px;
  grid-template-rows: 50px auto;
  grid-template-areas: "toolbar toolbar" "content sidebar";
  height: 100%;
  overflow: auto;
  background-color: #F6F6F6;
  color: #777777;
  font-weight: 400;
  font-size: 12.5px;
}
.component--dashboard-page .component--sidebar {
  grid-area: sidebar;
}
.component--dashboard-page .component--dashboard-toolbar {
  grid-area: toolbar;
}
.component--dashboard-page .component--dashboard-content {
  grid-area: content;
}

