@import "./PdfPanel/styles";
@import "./Assumptions/AssumptionsCsvExportButton/styles";

.component--return-analysis-panel {
  display: flex;
  background-color: #ffffff;
  height: 100%;
  transition: height 0.3s linear, visibility 0.2s linear, opacity 0.2s linear;
  overflow: auto;

  .panel-content {
    background-color: #ffffff;
    padding: 18px 18px 24px;

    .component--accordion-section {
      .section-title {
        background-color: #efeff4;
        border: none;
        text-transform: capitalize;
      }
    }

    table {
      background-color: #ffffff;
      color: #939393;

      tr {
        line-height: 15px;

        &.center {
          td {
            text-align: center;
          }
        }

        &:nth-child(even) {
          background-color: #fbfbfb;
        }

        td {
          border: 1px solid #d1d5dd;
          border-top: none;
          vertical-align: top;

          &:first-child {
            padding: 10px 3px 10px 11px;
            font-weight: 400;
            width: 150px;
          }

          &:last-child {
            padding: 10px 18px 10px 3px;
            font: 700 14px/15px Roboto;
            color: #3a4276;
          }

          &.yellow {
            color: #f9b923;
            font-weight: 700;
          }

          &.capitalize {
            text-transform: capitalize;
          }

          &.uppercase {
            text-transform: uppercase;
          }
        }

        .array-item {
          margin-bottom: 6px;
          padding-left: 25px;
          display: block;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      &.section-end {
        margin: -18px 0 18px;
        background-color: #efeff4;
        box-shadow: 0px 3px 6px #0000000d;
        border-radius: 0px 0px 5px 5px;

        &.separate {
          margin-top: 18px;
        }

        td {
          border: none;
          padding-left: 16px !important;

          .value {
            font: 700 16px/19px Roboto;
            color: #3a4276;
            text-transform: capitalize;
          }
        }
      }
    }

    .selection-container {
      background: #efeff4 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #0000000d;
      margin-bottom: 30px;
      padding: 15px 10px 5px;

      .sale-lease {
        display: flex;
        border-bottom: 1px solid #70707034;
        padding-bottom: 15px;

        label {
          display: flex;
          align-items: center;
          font: 500 14px/14px Roboto;
          color: #333333;
          margin: 0 35px 0 0;

          &.disabled {
            color: #cccccc;
          }

          input[type="radio"] {
            cursor: pointer;
            border-radius: 50%;
            margin-right: 9px;
            border: 2px solid #3a4276;
            width: 15px;
            height: 15px;
            outline: none;

            &:checked {
              background-color: #3a4276;
              box-shadow: inset 0px 0px 0px 1px #ffffff;
              width: 15px;
              height: 15px;
            }

            &:disabled {
              border: 2px solid #cccccc;
            }
          }
        }
      }
    }

    .slider-container {
      display: flex;
      padding-top: 5px;

      .text {
        font: 700 14px/16px Roboto;
        letter-spacing: 0.35px;
        color: #333333;
        margin-right: 15px;
      }

      .component--slider-box {
        border: none;

        .slider-row-body {
          width: 250px;

          .slider-title {
            background: #efeff4 0% 0% no-repeat padding-box;
            text-align: left;
            margin-left: 5px;
            padding: 5px 15px 0px 0px;
            color: #333333;
            font: 700 14px/14px Roboto;
          }
        }

        input {
          background-color: #ffffff;
        }

        &.disabled {
          .slider-title {
            color: #cccccc;
          }
        }
      }
    }
  }
}
