.component--setback-close-panel {
  width: 172px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000080;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .floor-label {
    height: 37px;
    background: #3A4276 0% 0% no-repeat padding-box;
    border-radius: 7px 7px 0px 0px;
    font: 900 13px/13px Roboto;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    width: 100%;
    text-transform: uppercase;
    align-items: center;
  }

  button {
    background: #F26C6C 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000033;
    border-radius: 7px;
    width: 136px;
    height: 26px;
    font: 900 12px/21px Roboto;
    color: #FFFFFF;
    margin-top: 17px;
  }
}
