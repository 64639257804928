.component--redirect-button {
  color: #3A4276;
  font-size: 13px;
  height: 32px;
  min-height: 32px;
  padding: 6px 15px;
  border: 1px solid #3A4276;
  margin: auto 10px;
  flex-shrink: 0;

  &:hover {
    border: 1px solid #3A4276;
    background-color: #3A4276;
    color: white;
  }

  &.btn.basic {
    border: 1px solid #F26C6C;
    background-color: #F26C6C;

    &:hover {
      border: 1px solid #3A4276;
      background-color: #3A4276;
    }
  }
}
