.component--search-row {
  $input-color: #13a7da;
  width: 100%;
  margin: 1px;

  &[data-tip] > div {
    pointer-events: none;
  }

  .slider-filter {
    width: 100%;
    display: flex;
    align-items: center;

    .check {
      width: 21px;
      height: 21px;
      background-color: #ffffff;
      background-repeat: no-repeat;
      background-position: center;
      box-shadow: 0px 3px 6px #00000066;
      border-radius: 2px;
      margin-right: 20px;
      cursor: pointer;

      &.active {
        background-image: url("assets/images/icon-check-active.svg");
        background-color: #3a4276;
      }
    }

    .slider-wrapper {
      width: 100%;
      height: 100%;
      padding: 8px 20px 8px 0;
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid #c1c4d4;

      .slider-label {
        margin-bottom: 6px;
        font: normal 12px/14px Roboto;
        color: #666666;
      }

      .rc-slider-wrapper {
        width: 100%;
        padding: 1px 5px 0;
        border: 1px solid #e0e1e9;
        background-color: #ffffff;

        .rc-slider-handle {
          $size: 21px;
          width: $size;
          height: $size;
          border: 4px solid #ffffff;
          box-shadow: 0px 0px 5px #0000005c;
          margin-top: calc(-#{$size} / 2);
        }
      }

      .values {
        display: flex;
        justify-content: space-between;
        margin-top: 4px;

        input {
          background: #e0e1e9 0% 0% no-repeat padding-box;
          border-radius: 8px;
          width: 80px;
          height: 16px;
          border-style: hidden;
          text-align: center;
          outline: none;
          font: normal 12px/16px Roboto;
          color: $input-color;

          &.disabled {
            pointer-events: none;
          }

          &:hover {
            &::placeholder {
              color: $input-color;
            }
          }

          &:focus {
            outline: 1px solid $input-color;
            outline-offset: 1px;
          }
        }
      }
    }
  }

  .toggle-field-wrapper {
    width: 50%;
    display: flex;
    align-items: center;
    padding: 0;

    .toggle-box {
      margin: 0 10px 0 0;
    }

    .toggle-label {
      margin: 0;
    }
  }
}
