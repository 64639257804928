@import "./DragAndDrop/styles";

.component--image-upload {
  display: flex;
  margin-top: 35px;

  .label-container {

    .icon {
      width: 23px;
      height: 23px;
      background-size: contain;
      background-repeat: no-repeat;
    }

    .cover-icon {
      background-image: url("assets/images/icon-upload-cover.svg");
    }

    .logo-icon {
      background-image: url("assets/images/icon-upload-logo.svg");
    }
  }

  .drag-and-drop-container {
    border: 0.5px solid #CCCCCC;
    border-radius: 3px;
    flex: 1 1 auto;
    padding: 8px 7px 5px;
    display: flex;
    flex-direction: column;

    .component--busy-spinner {
      flex: 1 1 50px;
    }

    .title {
      font: Bold 12px Roboto;
    }

    .file-name {
      position: relative;
      color: #59608D;
      border: 1px dashed #59608D;
      padding: 5px 15px 5px 5px;
      white-space: nowrap;
      text-overflow: ellipsis;
      font: normal 12px/12px Roboto;

      .file-remove {
        position: absolute;
        top: 6px;
        right: 3px;
        width: 10px;
        height: 10px;
        border: 1px solid #F26C6C;
        border-radius: 5px;
        cursor: pointer;
        background: url("assets/images/icon-cross-out.svg") no-repeat;
        background-size: 4px 4px;
        background-position: center;
      }
    }
  }
}
