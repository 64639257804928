@mixin popupStyle {
  $warningWidth: 360px;
  position: absolute;
  top: 270px;
  left: 310px;
  width: $warningWidth;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  color: #818181;
  font: 400 13px/16px Roboto;
  text-align: center;
  border-radius: 6px;

  &::before {
    position: absolute;
    top: -34px;
    left: 30px;
    content: "";
    width: 30px;
    height: 50px;
    background-image: url("assets/images/tooltip-triangle-blue.svg");
    background-size: contain;
    background-repeat: no-repeat;
    transform: rotate(90deg);
  }

  &::after {
    position: absolute;
    top: 0px;
    left: 30px;
    content: "";
    width: 50px;
    height: 10px;
    background-color: #3A4276;
    border-radius: 3px;
  }
}
