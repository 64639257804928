.component--parcels-csv-export-button {
  height: 20px;
  width: 85px;
  font: 12px/21px Roboto;
  font-weight: 600;
  border-radius: 3px;
  background: #3A4276;
  box-shadow: 3px 3px 6px #00000040;
  margin-top: 1px;
  align-self: flex-end;
}
