.popup {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 2000; // Important that this z-index is above 1000 inorder to be on top of helphero icons.

  .pdf-viewer {
    width: 60vw;
    border: none;
  }

  .popup-title {
    font-size: 16px;
    color: #333333;
    margin: 0 0 0 5px;
    text-transform: uppercase;
    font-weight: 500;
  }

  .popup-content {
    font-weight: 400;
    padding: 30px 15px 0px 15px;
  }

  .popup-inner-wrapper {
    margin-top: 15px;
  }

  .inp-field {
    background-color: #FCFCFC;
    color: #333333;
    padding: 5px;
    margin-top: 8px;
    border-radius: 7px;
    position: relative;

    label {
      width: 80px;
      color: #888888;
      padding-left: 20px;
      border-right: 1px solid #888888;
      line-height: 35px;
    }

    input {
      display: inline-block;
      width: 80%;
      padding: 8px 15px;
      background-color: transparent;
      font-size: 16px;
    }
  }
}

// DO NOT DELETE: The below classes do not appear anywhere else in our code.
// They are used by the `CSSTransitionGroup` library for transition effects
// (e.g. fade-in/fade-out). As long as we are using `CSSTransitionGroup`, these
// classes should not be removed.
.popup-container-appear {
  opacity: 0;
}

.popup-container-appear.popup-container-appear-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.popup-container-enter {
  opacity: 0;
}

.popup-container-enter.popup-container-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.popup-container-leave {
  opacity: 1;
  pointer-events: none;
}

.popup-container-leave.popup-container-leave-active {
  opacity: 0;
  transition: opacity 300ms ease-in;
}
