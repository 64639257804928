.component--new-project-toolbar {
  display: flex;
  height: 100%;
  align-items: flex-start;
  z-index: 200;
  pointer-events: all;
}

.new-project-toolbar {
  width: 439px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  pointer-events: all;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000080;
  border-radius: 10px;
  padding: 0 20px;
  margin-left: 10px;

  .component--button {
    box-shadow: none;
    margin: 0;
  }

  .component--logo {
    .logo {
      margin: 0;
    }
  }
}
