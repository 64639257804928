.component-type-of-user {
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 35px 30px 20px;

  .selection-container {
    display: flex;

    .user-type-box {
      height: 42px;
      width: 145px;
      display: flex;
      align-items: center;
      background-color: #E3E5E8;
      box-shadow: 0px 10px 20px #0000001A;
      border-radius: 5px;
      padding: 0 10px;
      font: 700 14px/17px Roboto;
      color: #3A4276;

      &:first-child {
        margin-right: 90px;
      }

      input[type="checkbox"] {
        appearance: none;
        height: 23px;
        width: 23px;
        border-radius: 12px;
        background-color: #CCCCCC;
        box-shadow: 0px 3px 6px #00000029;
        border: 3px solid #FFFFFF;
        margin-right: 12px;

        &:checked {
          background-color: #3A4276;
        }

        &:focus {
          outline: none;
        }
      }
    }

    .icon {
      width: 15px;
      height: 17px;
      margin-right: 6px;
      background-size: contain;
      background-repeat: no-repeat;

      &.individual {
        background-image: url("assets/images/icon-demographics-blue.svg");
      }

      &.team {
        width: 25px;
        background-image: url("assets/images/icon-team.svg");
      }
    }

  }

  button {
    height: 34px;
    width: 142px;
    margin-top: 36px;
    border: none;
    border-radius: 4px;
    background-color: #3A4276;
    box-shadow: 0px 3px 6px #00000040;
    font: 700 15px/36px Roboto;
    color: #FFFFFF;
    text-align: center;
  }
}
