.component--branding-watermark {
  width: 100px;
  height: 19px;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;

  .icon-watermark {
    display: block;
    height: 100%;
    width: 100%;
    background: url("assets/images/branding-watermark.svg");
    background-size: contain;
    background-repeat: no-repeat;
  }
}
