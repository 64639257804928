@import "./AccountSettings/styles";
@import "./TeamMemberSettings/styles";

.component--subscription-management-page {
  position: absolute;
  width: 100%;
  height: 100%;
  min-width: 1875px;
  background-color: #E3E5E8;
  padding: 30px 30px 30px 130px;

  .content-container {
    display: flex;
    height: 100%;

    .component--with-panel-header {
      height: 100%;
    }

    .left-container {
      margin-right: 30px;
    }

    .right-container {
      flex-grow: 1;
    }
  }

  .buttons-container {
    position: absolute;
    top: 50px;
    left: 0px;
    width: 130px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  button {
    width: 160px;
    height: 34px;
    border-radius: 7px;
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px #00000033;
    font: 700 14px/14px Roboto;
    color: #333333;

    &.upgrade {
      background-color: #F26C6C;
      color: #FFFFFF;
    }

    &:hover, &.active {
      background-color: #3A4276;
      color: #FFFFFF;
    }
  }
}
