@import "../sharedStyles/styles";

.component--copy-project-popup {
  @include popupStyle();

  .top {
    width: 100%;
    background-color: #3A4276;
    padding: 15px 12px 10px;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 6px 6px 0 0;

    .header {
      font: 900 16px/21px Roboto;
    }

    .top-separator {
      width: 52px;
      height: 1px;
      background-color: #FFFFFF;
      margin: 13px 0;
    }
  }

  form {
    width: 100%;
    padding: 20px 16px;

    .input-container {
      display: flex;
      align-items: center;
      height: 25px;
      background-color: #F5F7F8;
      border-radius: 6px;
    }

    label {
      color: #606060;
      font: 700 12px/21px Roboto;
      margin: 0 0 0 11px;
    }

    input {
      font: 400 11px/21px Roboto;
      color: #ADADAD;
      flex-grow: 1;

      &::placeholder {
        color: #ADADAD;
      }
    }

    button {
      margin-top: 13px;
      width: 130px;
      height: 25px;
      font: 700 13px/21px Roboto;
      color: #FFFFFF;
      background-color: #4ACBC6;
      border-radius: 6px;
    }
  }
}
