.component--logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 14px;

  .logo {
    width: 168px;
    height: 38px;
    background-position: center;
    background-repeat: no-repeat;
    margin-left: 18px;
    cursor: pointer;

    &.pro {
      background-image: url("assets/images/logo-pro-inline.svg");
    }

    &.none {
      background-image: url("assets/images/logo-demo-underline.svg");
    }

    &.free {
      background-image: url("assets/images/logo-free-underline.svg");
    }

    &.developer {
      background-image: url("assets/images/logo-developer-underline.svg");
    }
  }
}
