.component--map {
  $buttonSize: 80px;
  height: 100%;
  width: 100%;
  position: relative;

  .map-pin {
    width: 30px;
  }
}
