.component--busy-popup {
  width: 300px;

  .text-box {
    font-family: Roboto Condensed;
    font-weight: 700;
    font-size: 20px;
    color: white;
    text-align: center;
  }

  .component--busy-spinner {
    height: 120px;

    .spinner {
      width: 60px;
      height: 60px;
      background-image: url("assets/images/busy-spinner-white.svg");
    }
  }
}
