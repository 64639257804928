@import "./ApplicationLinks/styles";
@import "./DevelopmentsList/styles";
@import "./Footer/styles";
@import "./sharedComponents/styles";

.component--dashboard-content {
  display: flex;
  flex-direction: column;
  padding: 40px 65px 0;
}
