@import "./SubscribeButton/styles";
@import "./TypeOfUserPopup/styles";

.component--tiers-panel {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  height: 620px;

  .sub-title-box {
    margin: 0 auto 20px auto;
    text-align: center;
    z-index: 100;
    flex-shrink: 0;
  }

  .subtitle-label {
    color: #FFFFFF;
    font: 600 30px/45px Roboto;
    margin: 5px 0 0 0;
  }

  .tier-sub-description {
    text-align: center;
    font: 700 15px/30px Roboto;
    color: #333333;
    margin-bottom: 0;
  }

  .external-links {
    @extend .tier-sub-description;

    font-weight: 800;
    color: #4457d7;
    text-decoration: underline;
  }

  .background {
    position: absolute;
    width: 100%;
    height: 600px;
    background-color: #3A4276;
    background-image: url("assets/images/world-map.svg");
    background-repeat: no-repeat;
    background-position: top;
    clip-path: polygon(101% 0, 101% 460px, 50% 580px, 0 460px, 0 0);
  }

  .toggle-wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 282px;
    height: 64px;
    border-radius: 32px;
    background-color: #EDF1F7;
    margin: 5px auto 16px;
    z-index: 100;
    flex-shrink: 0;

    .toggle-button {
      width: 122px;
      height: 44px;
      border-radius: 22px;
      font: 700 15px/44px Roboto;
      text-align: center;
      color: #333333;
      cursor: pointer;

      &.selected {
        background-color: #F26C6C;
        color: #FFFFFF;
      }
    }
  }

  .discount-message {
    width: 350px;
    margin: 0 auto 35px auto;
    font: 700 14px/14px Roboto;
    color: #FFFFFF;
    text-align: center;
    z-index: 100;
    flex-shrink: 0;
  }

  .tiers-wrapper {
    padding: 0 48px 35px;
    display: flex;
    justify-content: space-between;
    flex: 1 1 auto;
    height: 200px;
    background-color: #FFFFFF;
  }

  .component--tier-panel {
    display: flex;
    flex-direction: column;
    width: 310px;
    background-color: #FFFFFF;
    box-shadow: 3px 3px 6px #0000001A;
    border-radius: 10px;
    overflow: hidden;
    z-index: 100;

    .plan-name {
      text-align: center;
      font: 700 26px/32px Roboto;
      color: #F26C6C;
      margin-top: 20px;
    }

    .price {
      display: flex;
      justify-content: center;
      min-height: 56px;
      margin-top: 10px;
      font: 900 48px/56px Roboto;
      color: #3A4276;

      &.custom {
        font-size: 30px;
      }

      .suffix {
        margin-left: 16px;
        font: 700 18px/56px Roboto;
        color: #3A4276;
      }

      &.selected-plan {
        background-color: #FFFFFF;
        border-left: 1px solid #F26C6C;
        border-right: 1px solid #F26C6C;
      }
    }

    .no-button-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 20px;
      box-shadow: 0px 1px 2px #0000001A;
      flex-shrink: 0;
    }

    .button-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 80px;
      box-shadow: 0px 1px 2px #0000001A;
      flex-shrink: 0;

      .selected-plan {
        background-color: #F26C6C;
        width: 100%;
        color: #FFFFFF;
        font: 700 20px/70px Roboto;
        text-transform: uppercase;
        text-align: center;
        box-shadow: 0px 3px 6px #00000040;
        border-radius: 4px;
        align-self: flex-end;
      }
    }

    .list-wrapper {
      padding: 0 10px;
      flex-grow: 1;
      overflow: auto;
    }

    .list-item {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30px;
      font: 400 15px/45px Roboto;
      text-align: center;
      color: #333333;
      border-bottom: solid 1px #E4E9F2;

      &.bold {
        font: 700 16px/45px Roboto;
      }

      &:first-child {
        margin-top: 8px;
      }
    }

    .bottom-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-grow: 1;
      margin: 35px 0 25px;

      .text {
        text-align: center;
        font: 400 15px/17px Roboto;
        color: #333333;
        margin-top: 15px;
      }

      .button-wrapper {
        box-shadow: none;
        margin-top: 10px;
      }
    }

    .icon-graph {
      height: 51px;
      width: 64px;
      background-image: url("assets/images/icon-graph.svg");
      background-repeat: no-repeat;
      background-position: center;
    }

    .bottom {
      height: 30px;
      width: 100%;
      border-radius: 0 0 10px 10px;
      box-shadow: 0px -1px 2px #0000001A;
      flex-shrink: 0;
    }
  }
}
