.component--cancel-loading-button {
  display: flex;
  justify-content: center;
  padding: 10px 30px;

  .button {
    width: 150px;
    height: 30px;
    font-family: Roboto Condensed;
    background-color: #F26C6C;
    font-weight: 700;
    font-size: 18px;
    color: white;
    text-align: center;
    cursor: pointer;
  }
}
