@import "./LayerButton/styles";
@import "./LayerSectorPanel/styles";

.component--layer-selection {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  padding-bottom: 10px;

  .component--layer-button {
    margin-top: 15px;
    margin-right: 10px;
  }

  .component--busy-spinner {
    margin-top: 5px;
  }

  .sector-box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .sector-label {
    text-align: center;
    font: 12px/20px Roboto;
    font-weight: 500;
    color: #333333;
    text-transform: capitalize;
    margin: 5px 0 0 0;
  }

  .component--tabs {

    .component--tab-container {
      display: flex;
      justify-content: space-around;
      padding: 18px 18px 12px 18px;
    }

    .component--tab {
      height: 40px;
      width: 50px;
      border-radius: 0;
      box-shadow: 0px 3px 6px #33333310;

      &.active {
        box-shadow: 0px 3px 6px #33333366;
        border-radius: 5px;

        .sector-icon {

          &.zoning {
            background-image: url("assets/images/icon-layers-sector-zoning-active.svg");
          }

          &.census {
            background-image: url("assets/images/icon-layers-sector-people-active.svg");
          }

          &.rates {
            background-image: url("assets/images/icon-layers-sector-multifamily-active.svg");
          }

          &.local {
            background-image: url("assets/images/icon-layers-sector-hotel-active.svg");
          }

          &.incentives {
            background-image: url("assets/images/icon-layers-sector-office-active.svg");
          }

          &.borders {
            background-image: url("assets/images/icon-layers-sector-general-active.svg");
          }

          &.environmental {
            background-image: url("assets/images/icon-layers-sector-environmental-active.svg");
          }
        }
      }

      .sector-icon {
        background-repeat: no-repeat;
        background-position: center;
        width: 50px;
        height: 40px;

        &.zoning {
          background-image: url("assets/images/icon-layers-sector-zoning.svg");
        }

        &.census {
          background-image: url("assets/images/icon-layers-sector-people.svg");
        }

        &.rates {
          background-image: url("assets/images/icon-layers-sector-multifamily.svg");
        }

        &.local {
          background-image: url("assets/images/icon-layers-sector-hotel.svg");
        }

        &.incentives {
          background-image: url("assets/images/icon-layers-sector-office.svg");
        }

        &.borders {
          background-image: url("assets/images/icon-layers-sector-general.svg");
        }

        &.environmental {
          background-image: url("assets/images/icon-layers-sector-environmental.svg");
        }
      }
    }
  }
}
