@import "./AccountLinks/styles";
@import "./Support/styles";

.component--dashboard-page {

  .component--sidebar {
    $left-padding: 29px;
    padding: $left-padding 30px 15px 30px;
    background-color: #E5E7ED;
    position: relative;
    font-weight: 500;
    overflow: hidden;

    .header {
      font-size: 13px;
      font-weight: bold;
      color: #333333;
      height: 24px;
      border-bottom: solid 1px #999999;
    }

    .links-container {
      margin-top: 19px;
    }

    .side-bar-link {
      display: flex;
      align-items: center;
      cursor: pointer;
      color: #777777;
      margin-bottom: 10px;

      &:hover {
        color: #F26C6C;
        text-decoration: none;
      }
    }

    .icon {
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      width: 20px;
      height: 19px;
      margin-right: 13px;
    }
  }
}
