.component--pdf-generator {

  .component--pdf-graph-generators {
    width: 300px;
    position: fixed;
    left: -10000px;
    bottom: -10000px;
    z-index: -100;
  }
}
