.component--map-style-button {
  $buttonSize: 80px;

  width: $buttonSize;
  height: $buttonSize;
  background-repeat: no-repeat;
  background-size: $buttonSize;
  background-color: transparent;
  cursor: pointer;

  &.streets-map-button {
    background-image: url("assets/images/map-view-streets.png");

    &:hover {
      background-image: url("assets/images/map-view-streets-hover.png");
    }
  }

  &.satellite-map-button {
    background-image: url("assets/images/map-view-satellite.png");

    &:hover {
      background-image: url("assets/images/map-view-satellite-hover.png");
    }
  }
}
