@import "./Subscribe/styles";
@import "./SignUp/styles";
@import "./VerifyEmail/styles";
@import "./Welcome/styles";

.component--sign-up-flow-popup {
  width: 483px;
  background-color: #FFFFFF;
  border-radius: 3px;
  max-height: 100vh;
  display: flex;
  flex-direction: column;

  .header-container {
    height: 66px;
    color: #3A4276;
    text-transform: uppercase;
    font: bold 20px/20px Roboto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid #3A4276;
  }

  .navigation {
    padding: 36px 65px 0;
    color: #BDBDBD;

    .icon-wrapper {
      display:flex;
      justify-content: space-between;
      padding-right: 3px
    }

    .label-wrapper {
      display: flex;
      justify-content: space-between;

      p {
        font: normal 9px/9px Roboto;

        &.right-adjust {
          margin-right: -5px;
        }

        &.active {
          color: #3A4276;
        }
      }
    }

    .section {
      position: relative;

      .icon {
        width: 32px;
        height: 32px;
        border-radius: 16px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        background-position: center;
        border: 1px solid #D5D5E0;
        margin-bottom: 8px;

        &.email-verification {
          background-image: url("assets/images/icon-verify-email-inactive.svg");
        }

        &.checkout {
          background-image: url("assets/images/icon-subscribe-inactive.svg");
        }
      }

      .line-divider {
        position: absolute;
        width: 127px;;
        left: -127px;
        border-top: 1px #D5D5E0 solid;
        top: 16px;

        &.active {
          border-top: 1px #3A4276 solid;
        }
      }

      &.active {

        .icon {
          background-color: #3A4276;
          border: 1px solid #3A4276;
          box-shadow: 0px 4px 16px #3A427666;

          &.sign-up {
            background-image: url("assets/images/icon-sign-up-active.svg");
          }

          &.email-verification {
            background-image: url("assets/images/icon-verify-email-active.svg");
          }

          &.checkout {
            background-image: url("assets/images/icon-subscribe-active.svg");
          }
        }
      }
    }
  }

  .component--federated-authentication-providers {

    .top-separator {
      width: 483px;
    }

    .buttons-container {
      display: flex;
      justify-content: space-between;
      margin-top: 33px;
    }

    .component--button-facebook {
      margin: 0;
    }

    .component--button-google {
      margin: 20px 0 0 0;
    }

  }

  .component--activity-feedback {
    height: 499px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #777777;
  }
}
