.component--site-analysis-popup {
  width: 890px;
  height: 605px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 43px;
  color: #333333;

  .site-analysis-icon {
    width: 258px;
    height: 218px;
    background: transparent url("assets/images/icon-dashboard-analyze-site.svg") 0% 0% no-repeat padding-box;
    background-size: contain;
  }

  p {
    font: normal 18px/25px Roboto;
    text-align: center;
    max-width: 514px;
  }

  .header {
    font: Bold 24px/25px Roboto;
    margin-top: 33px;
    margin-bottom: 27px;
  }

  $borderRadius: 26px;
  $height: 51px;
  .geocoder-container {
    margin-top: 45px;
    display: flex;
    align-items: center;
    width: 697px;
    border: 2px solid #3A4276;
    border-radius: $borderRadius;
    height: $height;
    padding-left: 27px;

    &.error {
      border: 2px solid #F26C6C;
    }

    .geocoder-icon {
      top: auto;
    }

    .component--mapbox-geocoder {
      flex: 1 1 100%;

      input {
        font: normal 18px/22px Roboto;
        width: calc(100% - 30px);
        height: 21px;
      }
    }

    button {
      font: 500 18px/22px Roboto;
      position: relative;
      right: -3px;
      letter-spacing: 0.36px;
      color: #FFFFFF;
      height: $height;
      width: 242px;
      margin: 0;
      border-radius: 0px $borderRadius $borderRadius 0px;
      background: #3A4276 0% 0% no-repeat padding-box;
      border: 0px;

      &:hover {
        background-color: #F26C6C;
      }
    }
  }

  .error-container {
    color: #F26C6C;
    align-self: flex-start;
    margin-left: 90px;

    p {
      font-size: 14px;
      margin: 0;
    }
  }
}
