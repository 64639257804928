.show-only-filter {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200;
}

.filter-box {
  display: flex;
  flex-direction: row;
  margin-left: 20px;
}

.label-text {
  color: #3a4276;
  margin: auto;
  font: 500 12px/20px Roboto;
  margin-left: 5px;
}

.custom-radio-selected {
  background-color: #c40243;
  border-radius: 50%;
  color: #c40243;
}

.component-label {
  color: #3a4276;
  font: 500 12px/20px Roboto;
  margin-bottom: 0;
}

.button-box {
  color: #c40243;
  margin-left: 10px;
  padding: 6px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: #c40243;
  background-color: #3a4276;
}

.filter-label {
  font: 500 10px/20px Roboto;
  color: #3a4276;
  margin-bottom: 0;
}
