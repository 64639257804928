@import "./AccountInformation/styles";
@import "./MyTeam/styles";
@import "./PaymentMethod/styles";

.component--account-settings {
  width: 605px;
  height: 100%;
  background-color: #FFFFFF;
  padding: 30px 50px;
  overflow: auto;

  .header {
    font: 700 16px/19px Roboto;
    color: #3A4276;
    text-transform: capitalize;
    border-bottom: 1px solid #3A4276;
    padding: 3px;
  }

  .input-field {
    height: 32px;
    background-color: #F6F6FB;
    box-shadow: 0px 3px 6px #0000001A;
    border: 1px solid #D5D7E2;
    border-radius: 5px;

    input {
      height: 100%;
      width: 100%;
      padding: 9px 15px;

      &::placeholder {
        font: 400 12px/12px Roboto;
        color: #999999;
      }
    }

    &:last-child {
      margin-bottom: 30px;
    }
  }
}
