@import "./LineGraph/styles";

.component--market-graphs-panel {
  display: flex;
  pointer-events: all;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  overflow: hidden;

  .graphs-wrapper {
    padding: 20px 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  .missing-data-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font: normal 14px/14px Roboto;
    color: #666666;
    text-align: center;
    padding: 40px 0;
    width: 100%;
    overflow: auto;

    p {
      width: 324px;
      margin: 0;
    }

    .request-icon {
      display: flex;
      flex-shrink: 0;
      width: 252px;
      height: 170px;
      margin-bottom: 18px;
      background: url("assets/images/icon-request-zoning-data.svg") no-repeat;
    }

    .request-button {
      width: 126px;
      height: 41px;
      margin-top: 18px;
      background: #3A4276 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000066;
      border-radius: 5px;
      color: #FFFFFF;
      font: Bold 12px/41px Roboto;
    }
  }
}
