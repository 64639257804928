.component--kpi-panel {
  display: flex;
  justify-content: center;
  height: 100%;

  .component--kpi-section {
    display: flex;
    flex-direction: column;
    min-width: 134px;
    position: relative;

    &.dotted-right::after {
      height: 100%;
      content: "";
      position: absolute;
      right: 0;
      opacity: 0.6;
      border: 1px dashed #FFFFFF;
    }

    &.solid-right {
      border-right: 1px solid #ffffff;
    }

    &.roc-text {
      width: 150px;

      .value {
        font: 900 34px/40px Roboto;
        color: #82DEDE;
      }
    }

    .value {
      color: #FFFFFF;
      font: 900 18px/21px Roboto;
      background-color: #000000;
      opacity: 0.77;
      display: flex;
      flex-grow: 1;
      align-items: center;
      justify-content: center;
      padding: 0 3px;
    }

    .text {
      background-color: #82DEDE;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 28px;
      font: 900 13px/15px Roboto;
      color: #333333;
      text-transform: uppercase;
    }
  }
}
