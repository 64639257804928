.component--legend-row {
  display: flex;
  align-items: center;
  margin-bottom: 5px;

  .color {
    width: 20px;
    height: 20px;
    box-shadow: 0px 3px 6px #00000033;
    border-radius: 2px;
    margin-right: 15px;
    flex-shrink: 0;

    &.circle {
      border-radius: 10px;
    }

    &.wide {
      width: 40px;
      height: 15px;
    }
  }
}
