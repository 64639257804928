.divider-component-box {
  border-bottom: 1px solid #3A427630;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 25px 0 25px;
}

.divider-label {
  font: bold 14px/12px Roboto;
  color: #3A4276;
  margin-top: 5px;
}