.component--setback-measurement-tools {
  background-color: white;

  .header {
    font: 400 12px/14px Roboto;
    color: #333333;
    text-align: center;
    margin: 10px 10px 0 10px;
  }

  .tools-row {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    margin: 5px 18px 0 18px;
  }

  .tool-box {
    display: flex;
    flex-direction: column;
  }

  .shapes-buttons {
    display: flex;
    justify-content: space-between;
    width: 70%;
  }

  .map-controls {
    display: flex;
    justify-content: space-between;
    width: 30%;
  }

  .tool-name {
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    font: 500 13px/30px Roboto;
    color: #333333;
    vertical-align: text-top;
  }

  .zoom-tool-name {
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    font: 500 13px/30px Roboto;
    color: #333333;
    vertical-align: text-top;
    margin-left: 20px;
  }

  .dimensions-box {
    background-color: #E9EAEF;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #33333320;
    padding: 0 18px;
  }

  .value-box {
    display: flex;
    flex-direction: column;
    padding: 10px 10px 0 10px ;
    width: 40%;
  }

  .label {
    font: 500 18px/30px Roboto;
    color: #333333;
    border-bottom: 1px solid #33333320;
    width: 100%;
    text-align: center;
  }

  .value {
    font: 500 18px/30px Roboto;
    color: #333333;
    width: 100%;
    text-align: center;
  }

  .control-icon {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #3A427666;
    border-radius: 7px;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-position: center;
    width: 45px;
    height: 38px;
    display: flex;
    align-items: center;
    font: 500 20px/21px Roboto;
    justify-content: center;
    cursor: pointer;

    &.disabled {
      pointer-events: none;
    }

    &:hover,
    &.active {
      background-color: #3A4276;
    }
  }

  .zoom-in {
    font-size: 25px;
    line-height: 25px;

    &::after {
      content: "+";
    }

    &:hover {
      color: #ffffff;
    }
  }

  .zoom-out {
    font: normal 44px/45px Roboto;
    line-height: 40px;

    &::after {
      content: "-";
    }

    &:hover {
      color: #ffffff;
    }
  }

  .column {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: 12px;
  }


  .select-shape {
    background-image: url("./assets/images/parcel-tools-select-parcel.svg");

    &:hover,
    &.active {
      background-image: url("./assets/images/parcel-tools-select-parcel-hover.svg");
    }
  }

  .measurement {
    background-image: url("./assets/images/parcel-tools-draw-parcel.svg");

    &:hover,
    &.active {
      background-image: url("./assets/images/parcel-tools-draw-parcel-hover.svg");
    }
  }

  .edit-shape {
    background-image: url("./assets/images/parcel-tools-edit-parcel.svg");

    &:hover,
    &.active {
      background-image: url("./assets/images/parcel-tools-edit-parcel-hover.svg");
    }
  }

  .delete-shape {
    background-image: url("./assets/images/parcel-tools-delete-parcel.svg");

    &:hover,
    &.active {
      background-image: url("./assets/images/parcel-tools-delete-parcel-hover.svg");
    }
  }

  .copy-shape {
    background-image: url("./assets/images/parcel-tools-copy-parcel.svg");

    &:hover,
    &.active {
      background-image: url("./assets/images/parcel-tools-copy-parcel-hover.svg");
    }
  }
}
