.component--invitations-popup {
  width: 595px;
  max-height: calc(100vh - 120px);
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 30px 10px;
  overflow: auto;

  .invitation {
    width: 100%;
    padding: 20px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0.47px 3px 10px #77777766;
    border-radius: 3px;
    margin-bottom: 30px;

    .text {
      text-align: center;
      font: 400 15px/20px Roboto;
      color: #333333;

      span {
        font-weight: 700;
        color: #3A4276;
      }
    }

    .buttons-container {
      display: flex;
      justify-content: center;
      margin-top: 20px;
      width: 100%;

      :first-child {
        margin-right: 40px;
      }
    }
  }

  button {
    width: 160px;
    height: 34px;
    border-radius: 7px;
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px #00000033;
    font: 700 14px/14px Roboto;
    color: #333333;
    flex-shrink: 0;

    &:hover {
      background-color: #3A4276;
      color: #FFFFFF;
    }
  }
}
