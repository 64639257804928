@import "./Thumbnail/styles";

.component--development-card {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;

  .component--return-on-cost {
    $width: 80px;
    top: 6px;
    right: 6px;
    width: $width;
    padding: 5px 5px 11px;

    .header {
      font: Bold 11px/13px Roboto;
      letter-spacing: 0.33px;
      text-transform: uppercase;
    }

    .separator {
      margin-top: 2px;
      width: 20px;
      height: 2px;
      background-color: #F66C6C;
      border-radius: 1px;
    }

    .value {
      margin-top: 8px;
      font: 700 20px/20px Roboto;
    }

    .secondary {
      bottom: -13px;
      width: $width;
      height: 21px;
      padding: 2px 0 0;

      .label {
        font: 300 10px/10px Roboto;
      }

      .value {
        font: 700 10px/10px Roboto;
      }
    }
  }

  .shared {
    position: absolute;
    bottom: 104px;
    width: 100%;
    height: 25px;
    background-color: #3A4276;
    font: Bold 13px/25px Roboto;
    color: #FFFFFF;
    text-align: center;
    text-transform: uppercase;
  }

  .card-content {
    position: relative;
    box-sizing: border-box;
    flex: 1;
    margin: 12px 12px 9px;
  }

  .development-name {
    font: 900 13px/21px Roboto;
    color: #3A4276;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
    overflow: hidden;

    &:hover {
      cursor: pointer;
    }
  }

  .last-opened {
    margin-top: 5px;
    font: 300 10px/8px Roboto;
    color: #3A4276;
    text-align: center;
  }

  .buttons-container {
    display: flex;
    justify-content: center;
    margin-top: 16px;
  }
}
