.component--share-project-popup {
  min-width: 400px;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  color: #818181;
  font: 400 13px/16px Roboto;
  text-align: center;
  border-radius: 6px;

  .top {
    width: 100%;
    background-color: #3A4276;
    padding: 15px 12px 10px;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 6px 6px 0 0;

    .header {
      font: 900 16px/21px Roboto;
    }

    .top-separator {
      width: 52px;
      height: 1px;
      background-color: #FFFFFF;
      margin: 13px 0;
    }
  }

  .bottom {
    width: 100%;
    padding: 20px 16px;

    .url-container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 25px;
      background-color: #F5F7F8;
      border-radius: 6px;
      font: 400 11px/21px Roboto;
      color: #3A4276;
      padding: 0 10px;
    }

    button {
      margin-top: 13px;
      width: 130px;
      height: 25px;
      font: 700 13px/21px Roboto;
      color: #FFFFFF;
      background-color: #4ACBC6;
      border-radius: 6px;
    }
  }

  &.explorer {
    position: absolute;
    top: 269px;
    left: 35px;

    &::before {
      position: absolute;
      top: -34px;
      left: 245px;
      content: "";
      width: 30px;
      height: 50px;
      background-image: url("assets/images/tooltip-triangle-blue.svg");
      background-size: contain;
      background-repeat: no-repeat;
      transform: rotate(90deg);
    }

    &::after {
      position: absolute;
      top: 0px;
      left: 245px;
      content: "";
      width: 50px;
      height: 10px;
      background-color: #3A4276;
      border-radius: 3px;
    }
  }
}
