@import "./CreditCardPopup/styles";

.component--payment-method {
  margin-bottom: 55px;

  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 18px;
    margin-top: 7px;
    background-color: #FFFFFF;
    box-shadow: 0.47px 3px 10px #7777771A;
    border-radius: 3px;
  }

  .text {
    font: 400 14px/16px Roboto;
    color: #333333;

    .capitalize {
      text-transform: capitalize;
    }

    .bold {
      font-weight: 700;
    }

    .warning {
      font: italic 400 12px/12px Roboto;
      color: #F26C6C;
    }
  }
}
