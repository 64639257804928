.component--return-on-cost {
  $width: 120px;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 20px;
  right: 60px;
  width: $width;
  padding: 8px 8px 15px;
  border-radius: 10px;
  background-color: #3A4276;
  color: #FFFFFF;
  text-align: center;
  line-height: 15px;

  .header {
    font: Bold 16px/18px Roboto;
    letter-spacing: 0.33px;
    text-transform: uppercase;
  }

  .separator {
    margin-top: 3px;
    width: 35px;
    height: 3px;
    background-color: #F66C6C;
    border-radius: 1.5px;
  }

  .value {
    margin-top: 15px;
    font: 700 30px/30px Roboto;
    letter-spacing: -0.5px;
  }
}
