@import "./LogIn/styles";

.component--log-in-popup {
  width: 360px;
  background-color: #ffffff;
  border-radius: 3px;

  &::before {
    position: absolute;
    top: -36px;
    right: 155px;
    content: "";
    width: 50px;
    height: 50px;
    background-image: url("assets/images/tooltip-triangle.svg");
    background-size: contain;
    background-repeat: no-repeat;
    transform: rotate(90deg);
  }

  &.on-home-page {
    position: absolute;
    top: 50px;
    right: 125px;

    &::before {
      top: 12px;
      right: -32px;
      transform: rotate(58deg);
    }
  }

  &.on-explorer-page {
    position: absolute;
    top: 125px;
    right: 95px;

    &::before {
      top: -28px;
      right: 68px;
    }
  }

  &.on-new-project-page {
    position: absolute;
    top: 120px;
    right: 113px;

    &::before {
      top: -28px;
      right: 68px;
    }
  }

  &.on-subscribe-page {
    width: 483px;

    &::before,
    &::after {
      content: none;
    }

    .component--log-in {
      $lateral-padding: 12px;
      position: relative;
      padding: 0px $lateral-padding 45px;

      form {
        margin-top: 14px;
      }

      .header {
        position: relative;
        right: $lateral-padding;
        width: 483px;
        height: 66px;
        color: #3a4276;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 2px solid #3a4276;
      }

      .input-field {
        margin-top: 21px;
      }

      input {
        height: 61px;
        padding: 0 17px;
      }

      input[type="checkbox"] {
        height: 25px;
        margin-right: 10px;
      }

      .component--federated-authentication-providers {
        margin-top: 44px;
        flex-direction: column;

        .top-separator {
          width: 483px;
        }

        .buttons-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          margin-top: 37px;
        }

        .component--button-facebook {
          background-color: #3f569a;
          margin: 0;
        }

        .component--button-google {
          background-color: #db4437;
          margin: 18px 0 0;
        }
      }

      button {
        height: 60px;
      }
    }
  }
}
