.component--with-panel-header {
  $panel-header-height: 23px;
  box-shadow: 3px 0px 6px #00000066;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: $panel-header-height;

  .bottom-container {
    height: 23px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px -1px 2px #0000001A;
    flex-shrink: 0;
    z-index: 100;
    border-radius: 0 0 10px 10px;
  }

  .with-panel-header {
    background: #3A4276 0% 0% no-repeat padding-box;
    border-radius: 10px;
    display: flex;
    height: $panel-header-height;
    width: 100%;
    justify-content: center;
    align-items: center;
    font: Bold 14px/25px Roboto;
    letter-spacing: 1.2px;
    color: #FFFFFF;
    position: relative;
    flex-shrink: 0;

    &.active {
      cursor: default;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    .expand-icon {
      cursor: pointer;
      position: absolute;
      left: 18px;
      width: 14px;
      height: 14px;
      background: #F9F9FA 0% 0% no-repeat padding-box;
      border-radius: 7px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: black;

      &.active::after {
        content: "–";
        font: 500 14px Roboto;
      }

      &::after {
        position: relative;
        top: 0.5px;
        left: 0.5px;
        content: "+";
        font: 400 14px Roboto;
        text-align: center;
        width: 100%;
      }
    }
  }
}
