@import "./TabPanel/styles";

.component--tabs {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;

  .component--tab-container {
    padding: 18px;
    background: #E9EAEF 0% 0% no-repeat padding-box;
    display: flex;
    flex-shrink: 0;
  }

  .component--tab {
    cursor: pointer;
    width: 126px;
    height: 55px;
    border-radius: 5px;
    box-shadow: 0px 3px 6px #0000001A;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    color: #333333;
    font: Bold 14px/16px Roboto;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    &.active {
      background: #3A4276 0% 0% no-repeat padding-box;
      color: #fff;
      box-shadow: 0px 3px 6px #00000066;
    }

    &:not(:first-child) {
      margin-left: 13px;
    }

    &.disabled {
      color: #D5D5E0;
      box-shadow: none;
      cursor: default;
    }
  }
}
