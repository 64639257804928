.component--cancel-invitation-popup {
  width: 550px;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;

  .text {
    margin: 35px 45px;
    text-align: center;
    font: 400 15px/20px Roboto;
    color: #333333;

    span {
      font-weight: 700;
      color: #3A4276;
    }
  }

  .buttons-container {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  button {
    width: 160px;
    height: 34px;
    border-radius: 7px;
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px #00000033;
    font: 700 14px/14px Roboto;
    color: #333333;
    margin-bottom: 20px;

    &:hover,
    &.cancel {
      background-color: #3A4276;
      color: #FFFFFF;
    }

    &.cancel {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.close-button {
      margin-right: 30px;

      :first-child {
        margin-right: 0;
      }
    }
  }
}
