.component--assumptions-csv-export-button {
  font: 12px/21px Roboto;
  font-weight: 600;
  line-height: 1;
  border-radius: 3px;
  background: #F26C6C;
  box-shadow: 3px 3px 6px #00000040;
  padding: 10px;
  margin-bottom: 20px;
  margin-left: 120px;
}