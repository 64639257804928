.component--pdf-generator {

  .component--map-image {
    position: fixed;
    left: -10000px;
    bottom: -10000px;
    height: 800px;
    width: 566px;
    z-index: -100;

    &.cover {
      height: 826px;
    }
  }
}
