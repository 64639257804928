.component--draw-parcel-information-panel {
  width: 100%;
  background-color: #FFFFFF;
  padding: 30px 18px;
  pointer-events: all;

  .header {
    color: #3A4276;
    font: Bold 14px/21px Roboto;
    border-bottom: 1px solid rgba(58, 66, 118, 0.502);
    padding-bottom: 6px;
  }

  .blocks-container {
    padding: 31px 27px 0;
  }

  .block {
    display: flex;
    align-items: center;
    margin-top: 30px;

    &:first-child {
      margin-top: 0;
    }

    .number {
      font: 700 40px/71px Roboto;
      margin-right: 17px;
      color: #3A4473;
    }

    .image {
      width: 61px;
      height: 61px;
      background-color: #3A4473;
      border-radius: 2px;
      margin-right: 28px;
      background-position: center;
      background-repeat: no-repeat;

      &.select-parcel {
        background-image: url("./assets/images/parcel-tools-select-parcel-hover.svg");
        background-size: 40%;
      }

      &.combine-parcels {
        background-image: url("./assets/images/parcel-tools-combine-parcels-hover.svg");
        background-size: 50%;
      }

      &.preliminary-underwriting {
        display: flex;
        padding: 0 20px;
        align-items: center;
        width: 172px;
        height: 98px;
        background: #F26C6C 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000080;
        border-radius: 7px;
        text-align: left;
        font: Bold 14px/16px Roboto;
        color: white;
      }

      &.draw-parcel {
        background-image: url("./assets/images/parcel-tools-draw-parcel-hover.svg");
        background-size: 70%;
      }

      &.edit-parcel {
        background-image: url("./assets/images/parcel-tools-edit-parcel-hover.svg");
        background-size: 50%;
      }

      &.delete-parcel {
        background-image: url("./assets/images/parcel-tools-delete-parcel-hover.svg");
        background-size: 60%;
      }
    }

    .text {
      font: 300 12px/15px Roboto;
      letter-spacing: 0.16px;
      max-width: 224px;;
      color: #333333;

      .text-header {
        font: Bold 16px/16px Roboto;
        letter-spacing: 0.2px;
        border-bottom: 1px solid #C1C4D4;
        text-align: start;
        padding-bottom: 5px;
        margin-bottom: 8px;
      }
    }
  }
}
