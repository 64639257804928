.component--summary {
  margin-top: 40px;
  display: flex;

  .label-container {
    font: Bold 12px Roboto;

    .summary-label {
      width: 25px;
      height: 30px;
      margin-bottom: 4px;
      background: url("assets/images/icon-summary.svg") no-repeat ;
    }
  }

  .text-container {
    height: 98px;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-shrink: 1;

    .summary-text {
      line-height: 13px;
      padding: 8px;
      outline: none;
      height: 84px;
      resize: none;
      width: 100%;
      border-radius: 3px;
      border: 1px solid #CCCCCC;
      font: normal 12px/13px Roboto;

      ::placeholder {
        color: #ABAFB3;
      }
    }

    .warning-message {
      font: Italic 9px/14px Roboto;
      color: #E86969;
      align-self: flex-end;
    }
  }
}
