.search-loading-component {
  display: flex;
  flex-direction: column ;
  width: 439px;
  padding: 16px 19px 19px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000080;
  border-radius: 10px;
}

.search-loading-label {
  text-align: center;
  font: bold 14px/22px Roboto;
  color: #3A4276;
  margin-top: 5px;
}