.component--thumbnail {
  $height: 153px;
  height: $height;
  min-height: $height;
  line-height: $height;
  text-align: center;
  background-image: url("assets/images/default-thumbnail.png");
  background-size: cover;
  color: rgba(102,139,152, 0.8);
  font-size: 16px;
  font-weight: bold;
  border-bottom: solid 1px #59618D;
  border-radius: 10px 10px 0 0;
  cursor: pointer;

  .spinner {
    background-image: url("assets/images/busy-spinner-blue.svg");
    background-repeat: no-repeat;
  }
}
