.component--land-use-filters {
  width: 100%;

  .land-use-dropdown-box {
    background: #33333315;

    // v1.6.2 of `multiselect-react-dropdown` has a bug when clicking on the scrollbar. We hide the
    // scrollbar completely to avoid this bug.
    // See https://github.com/srigar/multiselect-react-dropdown/issues/72
    *::-webkit-scrollbar {
      width: 0px;
    }

    /* Chrome, Safari, Edge */
    * {
      -ms-overflow-style: none;
      /* IE, Edge */
      scrollbar-width: none;
      /* Firefox */
    }
  }
}
