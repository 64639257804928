.component--navbar {
  position: absolute;
  top: 0;
  height: 47px;
  width: 100%;
  background: none;
  background-color: #FFFFFF;

  a {
    display: block;
    width: fit-content;
  }

  .logo {
    width: 155px;
    height: 25.5px;
    background-image: url("assets/images/logo-inline.svg");
    background-repeat: no-repeat;
    margin-top: 11px;
    margin-left: 15px;
  }
}
