.component--redirect-warning-popup {
  $warningWidth: 360px;
  position: absolute;
  top: 275px;
  left: 190px;
  width: $warningWidth;
  height: 285px;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  color: #818181;
  font: 400 13px/16px Roboto;
  text-align: center;
  border-radius: 6px;

  .top {
    background-color: #3A4276;
    padding: 15px 12px 28px;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 6px 6px 0 0;

    .top-separator {
      width: 52px;
      height: 1px;
      background-color: #FFFFFF;
      margin-top: 13px;
    }
  }

  &::before {
    position: absolute;
    top: -34px;
    right: 122px;
    content: "";
    width: 30px;
    height: 50px;
    background-image: url("assets/images/tooltip-triangle-blue.svg");
    background-size: contain;
    background-repeat: no-repeat;
    transform: rotate(90deg);
  }

  &::after {
    position: absolute;
    top: 0px;
    right: 110px;
    content: "";
    width: 50px;
    height: 10px;
    background-color: #3A4276;
    border-radius: 3px;
  }

  .buttons-container {
    margin-top: 25px;
    display: flex;
    width: 100%;
    justify-content: center;
  }

  button {
    width: 131px;
    height: 25px;
    min-height: 25px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 10px #00000040;
    border-radius: 6px;
    border: none;
    color: #333333;
    font: 900 12px/12px Roboto;
    text-transform: capitalize;
    padding: 0;

    &.continue {
      margin: 0 0 0 40px;
    }

    &.subscribe {
      background-color: #F26C6C;
      color: #FFFFFF;
      margin: 22px 0 0;
    }

    &:hover {
      border: none;
    }
  }

  .separator {
    position: relative;
    margin-bottom: 32px;
    display: flex;
    justify-content: center;
    width: $warningWidth;
    border-top: solid #EEEEEE 3px;
  }

  .circle {
    position: absolute;
    bottom: -17px;
    background-color: #FFFFFF;
    color: #E0E0E0;
    height: 38px;
    width: 38px;
    font: Bold 12px/12px Roboto;
    border-radius: 19px;
    border: solid #EEEEEE 3px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .subscribe-message {
    margin: 0 30px;
  }
}
