.component--toolbar {
  position: relative;
  background-color: #FFFFFF;
  height: 50px;
  display: flex;
  flex-shrink: 0;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.05);
  z-index: 1000;

  div:focus {
    outline: 0;
  }

  .toolbar-children-container {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: flex-end;

    .buttons-container {
      position: relative;
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: flex-end;
      margin-right: 20px;

      .button {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;

        &:not(:first-child) {
          margin-left: 15px;
        }

        .icon {
          width: 18px;
          height: 18px;
          margin-bottom: 3px;
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;

          &.duplicate-project {
            background-image: url("assets/images/icon-duplicate-project-inactive.svg");
          }

          &.new-project {
            background-image: url("assets/images/icon-new-project-inactive.svg");
          }

          &.log-in {
            background-image: url("assets/images/icon-log-in-inactive.svg");
          }

          &.sign-up {
            background-image: url("assets/images/icon-toolbar-sign-up-inactive.svg");
          }
        }

        .text {
          font: 500 12px/12px Roboto;
          color: #3A4276;
        }

        &:hover, &.active {

          .log-in {
            background-image: url("assets/images/icon-log-in-active.svg");
          }

          .sign-up {
            background-image: url("assets/images/icon-toolbar-sign-up-active.svg");
          }

          .text {
            color: #4ACBC6;
          }
        }
      }
    }

    .component--unit-system-input {
      margin-right: 26px;
    }
  }
}
