.component--layer-legend {
  display: flex;
  color: #333333;
  padding-bottom: 21px;
  flex-shrink: 0;

  .icon {
    width: 100px;
    height: 84px;
    flex-shrink: 0;
    margin-right: 18px;
    background-size: cover;
    box-shadow: 0px 3px 6px #3A427666;
    border-radius: 5px;
  }

  .legend-content {
    width: 100%;

    .content-wrapper {
      display: flex;
      justify-content: space-between;

      .toggle-field-wrapper {
        width: auto;
        padding: 0;

        .toggle-container {
          padding: 0;
        }

        .toggle-box {
          width: 34px;
          height: 23px;
          margin-right: 0;

          .toggle:before {
            height: 19px;
            width: 19px;
          }

          input:checked + .toggle:before {
            transform: translateX(11px);
          }
        }
      }
    }

    .title-container {
      margin-right: 25px;

      .title {
        font: 900 16px/21px Roboto;
        text-transform: capitalize;
      }

      .text {
        font: 400 12px/14px Roboto;
        margin: 3px 0;
      }
    }

    .legend-button {
      margin-top: 2px;
      width: 100%;
      color: #333333;
      cursor: pointer;
      background-color: #ebecf1;
      border-radius: 2px;
      display: flex;
      justify-content: center;
      align-items: center;

      &.disabled {
        background: #fafbfc 0% 0% no-repeat padding-box;
        color: #D1D5DD;

        span {
          background-image: url("assets/images/icon-expand-grey.svg");
          background-position: center;
          ;
        }
      }

      span {
        flex-shrink: 0;
        margin-left: 10px;
        width: 16px;
        height: 10px;
        background: url("assets/images/icon-expand-blue.svg") no-repeat;

        &.active {
          background: url("assets/images/icon-collapse-blue.svg") no-repeat;
        }
      }
    }
  }
}
