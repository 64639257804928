.component--forgot-password-page {
  @include information-pages;

  form {
    display: flex;
    justify-content: center;
  }

  .content-wrapper {

    .header {
      text-align: center;
      margin-bottom: 53px;
    }

    .text-box {
      font-size: 16px;
      width: 415px;
      text-align: center;
      font-family: "Roboto Condensed";
      margin-bottom: 36px;

      &.email-sent {
        width: 465px;
      }
    }
  }

  .form-container {

    .input-section {
      width: 284px;
      margin-right: 20px;

      .input-field {
        height: 35px;
        width: inherit;
      }
    }

    input {
      width: 100%;
    }

    .submit {
      padding: 0px 28px;
    }

    .error-container {
      color: #F26C6C;
    }
  }
}
