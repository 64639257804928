.component--sign-up-link {
    display: flex;
    justify-content: center;

  .sign-up-link {
    font: 400 14px/14px Roboto;
    color: #777777;
    text-decoration: underline;
    text-align: center;
    margin-top: 5px;
  }
}
