.component--pagination {
  display: flex;
  justify-content: space-between;
  margin-top: 9px;
  line-height: 14px;
  color: #777777;
  font-weight: 500;
  font-size: 12px;
  white-space: pre;
  cursor: default;

  .page-selector {
    display: flex;
  }

  .numbers {
    display: flex;
  }

  .current {
    color: #F26C6C;
  }

  .button {
    cursor: pointer;

    &:hover {
      color: #F26C6C;
    }
  }
}
