.component--parcel-information-table {
  width: 100%;

  table {
    background-color: #FFFFFF;
    color: #939393;

    th {
      height: 40px;
      padding-top: 15px;
      font: Bold 14px/18px Roboto;
      letter-spacing: 1.19px;
      text-align: center;
      color: #3A4276;
      text-transform: uppercase;
      border-bottom: 2px solid #3A4276;
    }

    tr {
      line-height: 15px;

      &.center {

        td {
          text-align: center;
        }
      }

      &:nth-child(even) {
        background-color: #FBFBFB;
      }

      td {
        border: 1px solid #D1D5DD;
        border-top: none;
        vertical-align: top;

        &:first-child {
          padding: 10px 3px 10px 11px;
          font-weight: 400;
          width: 150px;
        }

        &:last-child {
          padding: 10px 18px 10px 10px;
          font: 700 14px/15px Roboto;
          color: #3A4276;
        }

        &.yellow {
          color: #F9B923;
          font-weight: 700;
        }

        &.capitalize {
          text-transform: capitalize;
        }

        &.uppercase {
          text-transform: uppercase;
        }
      }

      .array-item {
        margin-bottom: 6px;
        display: block;
        word-break: break-word;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
