.component--line-graph {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  padding: 12px 0 6px;
  position: relative;
  flex-shrink: 0;

  &:first-child {
    margin-top: 0;
  }

  &.hidden canvas {
    pointer-events: none;
    filter: blur(5px);
  }

  .title-wrapper {
    font: 900 16px/21px Roboto;
    color: #3A4276;
    margin: 0 0 15px 10px;
    width: 100%;
    padding-right: 24px;

    .title {
      display: block;
      padding-bottom: 7px;
      width: 100%;
      border-bottom: 1px solid #3A4276;
    }
  }

  .legend {
    font: 400 12px/21px Roboto;
    color: #999999;
    margin-left: 10px;
    width: 93%;
    border-top: 1px solid #D5D5E050;
    padding-top: 5px;
  }
}
