.component--reinvite-popup {
  width: 427px;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  $border-radius: 10px;
  border-radius: $border-radius;

  .top-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: $border-radius $border-radius 0 0;
    padding: 25px 17px 0;
    width: 100%;
    background-color: #3A4276;
    color: white;

    .input-field {
      width: 331px;
      height: 32px;
      margin: 12px 0 9px;
      background-color: #F6F6FB;
      box-shadow: 0px 3px 6px #0000001A;
      border: 1px solid #D5D7E2;
      border-radius: 5px;

      input {
        height: 100%;
        width: 100%;
        padding: 9px 15px;

        &::placeholder {
          font: 400 12px/12px Roboto;
          color: #999999;
        }
      }

      &.invalid {

        input {
          color: red;
        }
      }
    }

    .text {
      width: 340px;
      padding: 0 33px 20px;
      text-align: center;
      border-bottom: 1px solid #ffffff4C;
      font: 400 15px/20px Roboto;

      span {
        font-weight: 900;
      }
    }

    .price-text {
      margin-top: 15px;
      font-weight: 900;
    }

    .error {
      font: italic normal normal 12px/14px Roboto;
      color: #F26C6C;
      margin-bottom: 8px;
    }
  }

  .buttons-container {
    display: flex;

    padding: 30px 0;
    width: 100%;
    justify-content: center;

    :first-child {
      margin-right: 20px;
    }

    button {
      width: 160px;
      height: 34px;
      border-radius: 7px;
      background-color: #FFFFFF;
      box-shadow: 0px 3px 6px #00000033;
      font: 700 14px/14px Roboto;
      color: #333333;

      &:hover,
      &.delete {
        background-color: #3A4276;
        color: #FFFFFF;
      }

      &[disabled] {
        background-color: #E3E5E8;
        cursor: default;
      }
    }
  }
}
