@import "./DevelopmentCard/styles";
@import "./Pagination/styles";

.component--developments-list {
  $border: solid 1px #999999;
  $development-card-height: 256px;
  margin: 36px 0 65px;

  .developments-header {
    font-size: 13px;
    font-weight: bold;
    color: #333333;
    padding-bottom: 4px;
    border-bottom: $border;
  }

  .developments-wrapper {
    min-height: 533px;
    display: grid;
    grid-template-columns: repeat(auto-fill, 230px);
    grid-template-rows: repeat(auto-fit, $development-card-height);
    justify-content: space-between;
    grid-gap: 22px 32px;
    padding: 19px 0;
    border-bottom: $border;
  }
}
