.component--welcome {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 87px 0;

  .welcome-image {
    width: 302px;
    height: 268px;
    background: url("assets/images/icon-welcome.svg") 0% 0% no-repeat padding-box;
  }

  p {
    font: 500 15px/20px Roboto;
    color: #3A4276;
    margin-top: 62px;
  }

  button {
    width: 190px;
    color: #fff;
    height: 38px;
    background: #3A4276 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 10px #00000040;
    border-radius: 3px;
    font: 400 16px/16px Roboto;
    margin-top: 45px;
  }
}
