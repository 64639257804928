.component--street-view-button {
  margin-left: 10px;

  &.explorer {
    display: flex;
    justify-content: center;
    margin-left: 10px;
  }

  .button {
    display: flex;
    padding: 0 20px;
    align-items: center;
    width: 85px;
    height: 98px;
    background: #c161c6 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000080;
    border-radius: 7px;
    text-align: left;
    color: white;
    font: 500 14px/16px Roboto;

    &.explorer {
      font: 500 13px/21px Roboto;
      letter-spacing: 0.13px;
      height: 34px;
      width: 110px;
      margin-right: 0;
      margin-top: 12px;
      box-shadow: 0px 3px 6px #00000050;
    }

    &:hover {
      background-color: #3a4276;
      text-decoration: none;
    }
  }
}
