.component--credit-card-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 575px;
  padding: 40px 35px 0;
  background-color: #FFFFFF;

  .section {
    width: 100%;
    margin-bottom: 25px;

    .section-content {
      padding: 15px;
      box-shadow: 2px 3px 10px #7777771A;
      border-radius: 3px;
      font: 400 14px/50px Roboto;
      color: #333333;
    }

    .error-container {
      font: italic 400 11px/11px Roboto;
      color: #F26C6C;
    }
  }

  .header {
    font: 700 16px/19px Roboto;
    color: #3A4276;
    text-transform: capitalize;
    border-bottom: 1px solid #3A4276;
    padding: 3px;
  }

  .field, input {
    display: flex;
    align-items: center;
    flex-grow: 1;
    height: 32px;
    padding: 0 15px;
    background-color: #F6F6FB;
    box-shadow: 0px 3px 6px #0000001A;
    border: 1px solid #D5D7E2;
    border-radius: 5px;
    outline: none;
    font: 400 12px/12px Roboto;

    &:not(:last-child) {
      margin-right: 16px;
    }

    &::placeholder {
      color: #999999;
    }
  }

  .input-row {
    display: flex;
    width: 100%;
    margin-bottom: 20px;

    .error {

      &::placeholder {
        color: #F26C6C;
      }
    }
  }

  .text {
    font: 400 14px/20px Roboto;
    color: #333333;

    .capitalize {
      text-transform: capitalize;
    }

    .bold {
      font-weight: 700;
    }

    .warning {
      font: italic 400 12px/12px Roboto;
      color: #F26C6C;
    }
  }

  .buttons-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  button {
    width: 160px;
    height: 34px;
    border-radius: 7px;
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px #00000033;
    font: 700 14px/14px Roboto;
    color: #333333;
    margin-bottom: 20px;

    &:hover {
      background-color: #3A4276;
      color: #FFFFFF;
    }

    &.submitting {
      background-color: #3A4276;

      .spinner {
        width: 30px;
        height: 30px;
      }
    }
  }
}
