@import "./CopyProjectPopup/styles";
@import "./UnauthenticatedCopyProjectPopup/styles";

.component--copy-project {
  display: flex;
  flex-direction: column;

  .text {
    font: 500 12px/13px Roboto;
    text-align: center;
  }

  .duplicate-project {
    width: 18px;
    height: 18px;
    margin-bottom: 3px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("assets/images/icon-duplicate-project-black.svg");
  }

  &:hover {

    .duplicate-project {
      background-image: url("assets/images/icon-duplicate-project-active.svg");
    }
  }
}
