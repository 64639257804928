@mixin setbackIcon($icon-url, $icon-selected-url) {
  background-image: url($icon-url);

  &.selected { background-image: url($icon-selected-url); }
}

.component--setback-icon {
  background-size: cover;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0px 3px 6px #00000070;
  transition: width .3s, height .3s ease;

  &:hover, &.selected {
    width: 45px;
    height: 45px;
  }

  &.A {
    @include setbackIcon("assets/images/setback-button-a.svg", "assets/images/setback-button-a-selected.svg");
  }

  &.B {
    @include setbackIcon("assets/images/setback-button-b.svg", "assets/images/setback-button-b-selected.svg");
  }

  &.C {
    @include setbackIcon("assets/images/setback-button-c.svg", "assets/images/setback-button-c-selected.svg");
  }

  &.D {
    @include setbackIcon("assets/images/setback-button-d.svg", "assets/images/setback-button-d-selected.svg");
  }
}
