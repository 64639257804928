.component--send-invitation-popup {
  width: 550px;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;

  .text {
    margin: 15px 45px;
    text-align: center;
    font: 400 15px/20px Roboto;
    color: #333333;
  }

  .component--busy-spinner {
    margin-top: 15px;
  }
}
