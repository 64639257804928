.component--delete-development-popup {
  $warningWidth: 360px;
  width: $warningWidth;
  box-shadow: 0px 3px 6px #00000029;
  background-color: #FFFFFF;
  font: 400 13px/16px Roboto;
  text-align: center;
  border-radius: 6px;

  .top {
    background-color: #3A4276;
    padding: 20px 18px 12px;
    color: #FFFFFF;
    border-radius: 6px 6px 0 0;

    .separator {
      margin-top: 9px;
      height: 1px;
      background-color: #FFFFFF80
    }

    .italic {
      margin-top: 9px;
      font: Italic 12px/21px Roboto;
    }

    .bold {
      font-weight: 700;
    }
  }

  .bottom {
    padding: 20px 0 25px;
  }

  .buttons-container {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  button {
    width: 131px;
    height: 25px;
    min-height: 25px;
    box-shadow: 0px 4px 10px #00000040;
    border-radius: 6px;
    border: none;
    color: #FFFFFF;
    font: 700 13px/13px Roboto;
    padding: 0;

    &.cancel {
      background-color: #3A4276;
    }

    &.confirm {
      background-color: #F26C6C;
      margin-left: 35px;
    }

    &:hover {
      border: none;
    }
  }
}
