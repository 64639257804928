.component--dashboard-page {

  .component--sidebar {

    .component--support {
      $margin-right: 30px;
      margin-top: 94px;
      display: flex;
      flex-flow: column;
      min-height: calc(100vh - 250px);

      .icon {
        height: 19px;
        background-size: contain;
      }

      .icon-cities {
        background-image: url("assets/images/icon-sidebar-cities-inactive.svg");
      }

      .icon-webinar {
        background-image: url("assets/images/icon-sidebar-webinar-inactive.svg");
      }

      .icon-support {
        background-image: url("assets/images/icon-sidebar-support-inactive-dashboard.svg");
      }

      .icon-blog {
        background-image: url("assets/images/icon-sidebar-blog-inactive.svg");
      }

      .icon-contact-us {
        background-image: url("assets/images/icon-sidebar-contact-us-inactive.svg");
      }

      .side-bar-link {

        &:hover {

          .icon-cities {
            background-image: url("assets/images/icon-sidebar-cities-active.svg");
          }

          .icon-webinar {
            background-image: url("assets/images/icon-sidebar-webinar-active.svg");
          }

          .icon-support {
            background-image: url("assets/images/icon-sidebar-support-active.svg");
          }

          .icon-blog {
            background-image: url("assets/images/icon-sidebar-blog-active.svg");
          }

          .icon-contact-us {
            background-image: url("assets/images/icon-sidebar-contact-us-active.svg");
          }
        }
      }
    }
  }
}
